import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN} from 'utils/colors';

const BlackCheckBox = withStyles({
  root: {
    color: "rgba(44,42,55, 0.4)",
    '&$checked': {
      color: ALENGREEN,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function ALENCheckBox(props) {
  return(
    <BlackCheckBox
        checked={props.checked}
        onChange={props.handleChange}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
  )
}

export default ALENCheckBox
