import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {checkEmpty} from 'utils/checkForm'

import ALENLayout from 'components/ALENLayout';
import ALENAddParcelsTable from 'components/ALENAddParcelsTable';
import {ALENWHITE, ALENBG, ALENBLACK, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENSelect from 'components/ALENSelect';
import ALENULDStockTable from 'components/ALENULDStockTable';
import ALENButton from 'components/ALENButton';
import ALENDatePicker from 'components/ALENDatePicker';
import ALENCheckBox from 'components/ALENCheckBox';
import origami from 'assets/images/origami.png';

class CreateLoadView extends Component {
  constructor(props) {
    super(props)

    this.state={
      tiedownId: '',
      aircraft: '',
      weight: '',
      height: '',
      width: '',
      length: '',
      custom_reference: '',
      flight_number: '',
      description: '',
      ac_registration: '',
      slr_number: '',
      position_loaded: '',
      floating_pallet: false,
      frangible_cargo: false,

      errorAircraft: false,
      errorTiedownId: false,
    }
  }

  onSelectValue = (value) => {
    this.setState({valueSelected: value})
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  createTiedown = () => {
    let valid = true

    this.setState({errorTiedownId: false, errorAircraft: false})

    const newTiedown = {
      name: this.state.tiedownId,
      aircraft_id: this.state.aircraft,
      weight: this.state.weight,
      height: this.state.height,
      width: this.state.width,
      length: this.state.length,
      custom_reference: this.state.custom_reference,
      flight_number: this.state.flight_number,
      description: this.state.description,
      ac_registration: this.state.ac_registration,
      slr_number: this.state.slr_number,
      position_loaded: this.state.position_loaded,
      floating_pallet: this.state.floating_pallet,
      frangible_cargo: this.state.frangible_cargo,
      tiedown_configs: [],
    }

    if(newTiedown.name === undefined || newTiedown.name === null || newTiedown.name === "") {
      valid = false
      this.setState({errorTiedownId: true})
    }

    if(newTiedown.aircraft_id === '') {
      valid = false
      this.setState({errorAircraft: true})
    }

    if(valid) {
      this.props.createTiedown(newTiedown)
    }
  }

  render() {
    const t = this.props.t

    let values = []

    this.props.aircraftList.forEach(aircraft => {
      let newAircraft = {
        value: aircraft.id,
        libelle: aircraft.name
      };
      values.push(newAircraft);
    })

    const ariane = [
      {
        label: t('tiedowns.title'),
        link: '/tiedowns',
      },
      {
        label: t('tiedowncalculator.create.title'),
        link: 'current',
      }
    ]

    return(
      <ALENLayout
        title={t("tiedowns.mainTitle")}
        ariane = {ariane}
      >
        <div
          style={{
            padding:32,
          }}>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
            }}>
            <div
              style={{
                minWidth:1077,
                width:1077,
                display:'flex',
                flexDirection:'row',
              }}>
              <div
                style={{
                  paddingLeft:32,
                  flexDirection:'column',
                  display:'flex',
                }}>
                <ALENInput
                  label={t('tiedowncalculator.create.name')}
                  placeholder={t('tiedowncalculator.idPlaceholder')}
                  value={this.state.loadId}
                  onChange={(event) => {
                    this.setState({tiedownId: event.target.value})
                  }}
                  error={this.state.errorTiedownId}
                  errorMessage={t('tiedowncalculator.errorID')}
                />
                <div style={{height:16}}/>
                <ALENSelect
                  datas={values}
                  minWidth={245}
                  value={this.state.aircraft}
                  label={t('loads.list.planeLabel')}
                  placeholder={t('loads.list.aircraftPlaceholder')}
                  onChange={(event) => {
                    this.setState({aircraft: event.target.value})
                  }}
                  error={this.state.errorAircraft}
                  errorMessage={t('loads.list.errorValue')}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.weight')}
                  placeholder={t('tiedowncalculator.create.weightPlaceholder')}
                  value={this.state.weight}
                  onChange={(event) => {
                    this.setState({weight: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.height')}
                  placeholder={t('tiedowncalculator.create.heightPlaceholder')}
                  value={this.state.height}
                  onChange={(event) => {
                    this.setState({height: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.width')}
                  placeholder={t('tiedowncalculator.create.widthPlaceholder')}
                  value={this.state.width}
                  onChange={(event) => {
                    this.setState({width: event.target.value})
                  }}

                />
              </div>
              <div
                style={{
                  paddingLeft:32,
                  flexDirection:'column',
                  display:'flex',
                }}>
                <ALENInput
                  label={t('tiedowncalculator.create.length')}
                  placeholder={t('tiedowncalculator.create.lengthPlaceholder')}
                  value={this.state.length}
                  onChange={(event) => {
                    this.setState({length: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.custom_reference')}
                  placeholder={t('tiedowncalculator.create.custom_referencePlaceholder')}
                  value={this.state.custom_reference}
                  onChange={(event) => {
                    this.setState({custom_reference: event.target.value})
                  }}

                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.flight_number')}
                  placeholder={t('tiedowncalculator.create.flight_numberPlaceholder')}
                  value={this.state.flight_number}
                  onChange={(event) => {
                    this.setState({flight_number: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.description')}
                  placeholder={t('tiedowncalculator.create.descriptionPlaceholder')}
                  value={this.state.description}
                  onChange={(event) => {
                    this.setState({description: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.ac_registration')}
                  placeholder={t('tiedowncalculator.create.ac_registrationPlaceholder')}
                  value={this.state.ac_registration}
                  onChange={(event) => {
                    this.setState({ac_registration: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
              </div>
              <div
                style={{
                  paddingLeft:32,
                  flexDirection:'column',
                  display:'flex',
                }}>
                <ALENInput
                  label={t('tiedowncalculator.create.slr_number')}
                  placeholder={t('tiedowncalculator.create.slr_numberPlaceholder')}
                  value={this.state.slr_number}
                  onChange={(event) => {
                    this.setState({slr_number: event.target.value})
                  }}
                />
                <div style={{height:16}}/>
                <ALENInput
                  label={t('tiedowncalculator.create.position_loaded')}
                  placeholder={t('tiedowncalculator.create.position_loadedPlaceholder')}
                  value={this.state.position_loaded}
                  onChange={(event) => {
                    this.setState({position_loaded: event.target.value})
                  }}

                />
                <div style={{height:16}}/>
                <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                  <ALENCheckBox
                    checked={this.state.floating_pallet}
                    handleChange={(event) => {
                      this.setState({floating_pallet: event.target.checked})
                    }}
                  />
                  <ALENText>
                    {t('tiedowncalculator.create.floating_pallet')}
                  </ALENText>
                </div>
                <div style={{height:16}}/>

                <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                  <ALENCheckBox
                    checked={this.state.frangible_cargo}
                    handleChange={(event) => {
                      this.setState({frangible_cargo: event.target.checked})
                    }}
                  />
                  <ALENText>
                    {t('tiedowncalculator.create.frangible_cargo')}
                  </ALENText>
                </div>
                <div style={{height:16}}/>
              </div>
            </div>
          </div>
          <div
            style={{
              flexDirection:'row',
              display:'flex',
              justifyContent:'center',
              marginTop:24,
            }}>
            <ALENButton
              medium
              label={t('tiedowns.create')}
              onPress={this.createTiedown}
              />
          </div>
        </div>
      </ALENLayout>
    )
  }
}

export default withTranslation()(CreateLoadView)
