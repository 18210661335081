import * as React from "react";

function SvgIsHeavy(props) {
  return (
    <svg width={31.081} height={37} viewBox={'0 0 31.081 37'} {...props}>
      <path
        d="M8.872 28v-8.59h1.735v3.815l3.503-3.815h2.332l-3.234 3.346L16.618 28h-2.244l-2.361-4.031-1.406 1.435V28zm8.545.41l1.881.229c.031.218.104.369.217.45.156.118.402.177.738.177.43 0 .752-.065.967-.194a.797.797 0 00.328-.416c.051-.136.076-.388.076-.756v-.908c-.492.672-1.113 1.008-1.863 1.008-.836 0-1.498-.354-1.986-1.06-.383-.56-.574-1.254-.574-2.086 0-1.043.25-1.84.752-2.391a2.43 2.43 0 011.873-.826c.77 0 1.404.338 1.904 1.013v-.873h1.54v5.584c0 .735-.06 1.284-.18 1.647-.122.363-.292.648-.51.855-.22.207-.511.37-.877.487-.365.117-.827.175-1.385.175-1.055 0-1.803-.18-2.244-.542-.442-.36-.663-.819-.663-1.374a4.1 4.1 0 01.006-.199zm1.471-3.65c0 .66.128 1.143.384 1.45.256.307.571.46.946.46.402 0 .742-.157 1.02-.472.277-.314.416-.78.416-1.397 0-.645-.133-1.123-.399-1.436a1.266 1.266 0 00-1.008-.469c-.394 0-.72.154-.975.46-.256.307-.384.775-.384 1.404zM15.54 12a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4zm-7.418 1a8 8 0 1114.837 0h.955a3 3 0 012.937 2.388l4.167 20A3 3 0 0128.08 37H3a3 3 0 01-2.937-3.612l4.167-20A3 3 0 017.167 11zm2.493.428A1 1 0 019.795 13H7.167a1 1 0 00-.98.796l-4.166 20A1 1 0 003 35h25.08a1 1 0 00.979-1.204l-4.166-20a1 1 0 00-.979-.796h-2.628a1 1 0 01-.82-1.572 6 6 0 10-9.85 0z"
        fill={props.fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIsHeavy;
