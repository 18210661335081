import React, { Component } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  ALENBLUE,
  ALENWHITE,
  ALENDISABLEDDARK,
  ALENGREY,
  ALENYELLOW,
  ALENBLACK,
} from "utils/colors";
import { FONTNORMAL, FONTBIG } from "utils/fontSizes";
import ALENText from "components/ALENText";

class ALENButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      over: false,
    };
  }

  render() {
    const { t, i18n } = this.props;

    let color = ALENBLUE;
    let size = 24;

    this.props.big ? (size = 24) : this.props.medium ? (size = 16) : (size = 8);
    this.props.variant ? (color = ALENGREY) : (color = ALENBLUE);

    this.props.yellow ? (color = ALENYELLOW) : (color = color);

    return (
      <>
        <Button
          startIcon={this.props.startIcon}
          variant="contained"
          onClick={(event) => {
            if (this.props.stopPropagation) {
              event.stopPropagation();
            }
            this.props.onPress();
          }}
          onMouseOver={() => this.setState({ over: true })}
          onMouseLeave={() => this.setState({ over: false })}
          style={{
            boxShadow: this.state.over
              ? "0px 8px 12px rgba(18, 5, 80, 0.2)"
              : "0px 8px 32px rgba(18, 5, 80, 0.2)",
            padding: size,
            fontSize: FONTNORMAL,
            textTransform: this.props.small ? "uppercase" : "none",
            fontFamily: this.props.big ? "ALENFontBold" : "ALENFont",
            fontWeight: 500,
            color: this.props.variant
              ? color
              : this.props.yellow
              ? ALENBLACK
              : ALENWHITE,
            backgroundColor: this.state.over
              ? this.props.variant
                ? "rgba(18, 5, 80, 0.04)"
                : this.props.yellow
                ? "rgba(232, 168, 15, 0.9)"
                : "rgba(18, 5, 80, 0.9)"
              : this.props.variant
              ? "transparent"
              : color,
            borderColor: color,
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: "solid",
          }}
        >
          {this.props.label}
        </Button>
      </>
    );
  }
}

ALENButton.propTypes = {
  label: PropTypes.string,
  onPress: PropTypes.func,
  startIcon: PropTypes.object,
  big: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  variant: PropTypes.bool,
  yellow: PropTypes.bool,
  stopPropagation: PropTypes.bool,
};

ALENButton.defaultProps = {
  label: "ALEN",
  onPress: () => console.log("Press"),
  startIcon: null,
  big: false,
  medium: false,
  small: false,
  variant: false,
  yellow: false,
  stopPropagation: false,
};

export default ALENButton;
