import React, {Component} from 'react';
import {ALENBLUE, ALENBORDER, ALENBLACK, ALENWHITE, ALENDISABLEDDARK, TABLEBORDER, ALENGREY, ALENSUCCESS, ALENBG, BLUEINFO2} from "utils/colors";
import ALENLoading from 'components/ALENLoading';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENCheckBox from 'components/ALENCheckBox';
import {FONTNORMAL, FONTBIGSUP, FONTMINIBIG} from 'utils/fontSizes';
import SvgClose from 'components/icons/Close';
import SvgSuccess from 'components/icons/Success';
import { withTranslation } from 'react-i18next';
import ALENButton from 'components/ALENButton';
import ALENChargingPercent from 'components/ALENChargedPercent';
import SvgDownload from 'components/icons/Download';

function ALENModalTiedownPdf(props) {
  const t = props.t
  return(
    <div
      style={{
        position:'fixed',
        top:0,
        left:0,
        zIndex:9999,
        width:'100%',
        height:'100%',
        backgroundColor:"rgba(0, 0, 0, 0.7)",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
      }}>
        <div
          style={{
            width:789,
            height:550,
            borderRadius: 8,
            backgroundColor:ALENWHITE,
          }}>
          <div
            style={{
              height:80,
              width:789,
              flexDirection:'row',
              display:'flex',
              borderWidth:0,
              borderBottomWidth:1,
              borderStyle:'solid',
              borderColor:"rgba(150, 147, 163, 0.7)",
            }}>
            <div
              style={{
                height:80,
                width:715,
                paddingLeft:32,
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
              }}>
              <ALENText
                size={FONTBIGSUP}
                bold>
                {props.t("tiedowncalculator.modalPdf.title")}
              </ALENText>
            </div>
            <div
              style={{
                height:80,
                width:74,
                flexDirection:'row',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}>
                <SvgClose
                  onClick={props.back}
                  fill={ALENGREY}
                  style={{
                    cursor:'pointer',
                    width:30,
                    height:30,
                  }}
                />
            </div>
          </div>
          <div style={{
            flexDirection:'row',
            display:'flex',
            marginTop:16,
          }}>
            <div
              style={{
                paddingLeft:32,
                flexDirection:'column',
                display:'flex',
              }}>
              <ALENInput
                label={t('tiedowncalculator.create.weight')}
                placeholder={t('tiedowncalculator.create.weightPlaceholder')}
                value={props.weight}
                onChange={(event) => {
                  props.onChangeweight(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.height')}
                placeholder={t('tiedowncalculator.create.heightPlaceholder')}
                value={props.height}
                onChange={(event) => {
                  props.onChangeheight(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.width')}
                placeholder={t('tiedowncalculator.create.widthPlaceholder')}
                value={props.width}
                onChange={(event) => {
                  props.onChangewidth(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.length')}
                placeholder={t('tiedowncalculator.create.lengthPlaceholder')}
                value={props.length}
                onChange={(event) => {
                  props.onChangelength(event.target.value)
                }}
              />
            </div>
            <div
              style={{
                paddingLeft:32,
                flexDirection:'column',
                display:'flex',
              }}>
              <ALENInput
                label={t('tiedowncalculator.create.custom_reference')}
                placeholder={t('tiedowncalculator.create.custom_referencePlaceholder')}
                value={props.custom_reference}
                onChange={(event) => {
                  props.onChangecustom_reference(event.target.value)
                }}

              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.flight_number')}
                placeholder={t('tiedowncalculator.create.flight_numberPlaceholder')}
                value={props.flight_number}
                onChange={(event) => {
                  props.onChangeflight_number(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.description')}
                placeholder={t('tiedowncalculator.create.descriptionPlaceholder')}
                value={props.description}
                onChange={(event) => {
                  props.onChangedescription(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.ac_registration')}
                placeholder={t('tiedowncalculator.create.ac_registrationPlaceholder')}
                value={props.ac_registration}
                onChange={(event) => {
                  props.onChangeac_registration(event.target.value)
                }}
              />
              <div style={{height:16}}/>
            </div>
            <div
              style={{
                paddingLeft:32,
                paddingRight:32,
                flexDirection:'column',
                display:'flex',
              }}>
              <ALENInput
                label={t('tiedowncalculator.create.slr_number')}
                placeholder={t('tiedowncalculator.create.slr_numberPlaceholder')}
                value={props.slr_number}
                onChange={(event) => {
                  props.onChangeslr_number(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('tiedowncalculator.create.position_loaded')}
                placeholder={t('tiedowncalculator.create.position_loadedPlaceholder')}
                value={props.position_loaded}
                onChange={(event) => {
                  props.onChangeposition_loaded(event.target.value)
                }}

              />
              <div style={{height:16}}/>
              <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                <ALENCheckBox
                  checked={props.floating_pallet}
                  handleChange={(event) => {
                    props.onChangefloating_pallet(event.target.value)
                  }}
                />
                <ALENText>
                  {t('tiedowncalculator.create.floating_pallet')}
                </ALENText>
              </div>
              <div style={{height:16}}/>

              <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
                <ALENCheckBox
                  checked={props.frangible_cargo}
                  handleChange={(event) => {
                    props.onChangefrangible_cargo(event.target.value)
                  }}
                />
                <ALENText>
                  {t('tiedowncalculator.create.frangible_cargo')}
                </ALENText>
              </div>
              <div style={{height:16}}/>
            </div>
          </div>
          <div
            style={{
              flexDirection:'row',
              justifyContent:'center',
              display:'flex',
              marginTop:32,
            }}>
            <ALENButton
              medium
              variant
              onPress={props.cancel}
              label={props.t('tiedowncalculator.modalPdf.cancel')}
              />
            <div style={{width:32}}/>
            <ALENButton
              medium
              onPress={props.confirm}
              startIcon={<SvgDownload fill={ALENWHITE} style={{width:22, height:22}}/>}
              label={props.t('tiedowncalculator.modalPdf.confirm')}
              />
          </div>
        </div>
    </div>
  )
}

export default withTranslation()(ALENModalTiedownPdf)
