import en from './en'
import fr from './fr'

const transContent = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};

export default transContent
