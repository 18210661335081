export const DEMOVALUES = {
  A1: {
    right: {
      id: '',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
  },
  A2 : {
    right: {
      id: 'A2_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'A2_ID_LEFT',
      dest: 'TLS',
      WT: 0,
      size_code: 'A',
    },
  },
  B1 : {
    right: {
      id: 'B1_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'B1_ID_LEFT',
      dest: 'TLS',
      WT: 0,
      size_code: 'A',
    },
  },
  C : {
    right: {
      id: 'C_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'C_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  D : {
    right: {
      id: 'D_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'D_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  E : {
    right: {
      id: 'E_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'E_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  F : {
    right: {
      id: 'F_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'F_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  G : {
    right: {
      id: 'G_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'G_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  H : {
    right: {
      id: 'H_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'H_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  J : {
    right: {
      id: 'J_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'J_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  K : {
    right: {
      id: 'K_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'K_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  L : {
    right: {
      id: 'L_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'L_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  M : {
    right: {
      id: 'M_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'M_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  P : {
    right: {
      id: 'P_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'P_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  Q : {
    right: {
      id: 'Q_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'Y',
    },
    left: {
      id: 'Q_ID_LEFT',
      dest: 'TLS',
      WT: 1000,
      size_code: 'Y',
    }
  },
  R : {
    right: {
      id: 'R_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'R_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  S : {
    right: {
      id: 'S_ID',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    },
    left: {
      id: 'S_ID_LEFT',
      dest: 'TLS',
      WT: 3200,
      size_code: 'A',
    }
  },
  T : {
    right: {
      id: 'T_ID',
      dest: 'TLS',
      WT: 1000,
      size_code: 'A',
    }
  },
  OPT11: {
    right: {
      id: '11R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '11L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT12: {
    right: {
      id: '12R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '12L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT13: {
    right: {
      id: '13R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '13L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT21: {
    right: {
      id: '21R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '21L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT22: {
    right: {
      id: '22R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '22L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT23: {
    right: {
      id: '23R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '23L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT24: {
    right: {
      id: '24R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '24L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT25: {
    right: {
      id: '25R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '25L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT31: {
    right: {
      id: '31R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '31L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT32: {
    right: {
      id: '32R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '32L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT33: {
    right: {
      id: '33R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '33L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT41: {
    right: {
      id: '41R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '41L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT42: {
    right: {
      id: '42R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '42L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT43: {
    right: {
      id: '43R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '43L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT44: {
    right: {
      id: '44R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '44L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT45: {
    right: {
      id: '45R',
      dest: 'TLS',
      WT: 0,
    },
    left: {
      id: '45L',
      dest: 'TLS',
      WT: 0,
    },
  },
  BISOPT11P: {
    id: '11P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT12P: {
    id: '12P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT21P: {
    id: '21P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT22P: {
    id: '22P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT23P: {
    id: '23P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT31P: {
    id: '31P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT32P: {
    id: '32P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT41P: {
    id: '41P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT42P: {
    id: '42P',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT51: {
    id: '51',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT52: {
    id: '52',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT53: {
    id: '53',
    dest:'TLS',
    WT: 3200,
  },
  BISOPT54: {
    id: '54',
    dest:'TLS',
    WT: 3200,
  },
}

export const DEFAULTVALUES = {
  A1: {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
  },
  A2 : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
  },
  B1 : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
  },
  C : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  D : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  E : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  F : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  G : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  H : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  J : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  K : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  L : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  M : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  P : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  Q : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'Y',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'Y',
    }
  },
  R : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  S : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    },
    left: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  T : {
    right: {
      id: '',
      dest : '',
      WT: 0,
      size_code: 'A',
    }
  },
  OPT11: {
    right: {
      id: '11R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '11L',
      dest: '',
      WT: 0,
    },
  },
  OPT12: {
    right: {
      id: '12R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '12L',
      dest: '',
      WT: 0,
    },
  },
  OPT13: {
    right: {
      id: '13R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '13L',
      dest: '',
      WT: 0,
    },
  },
  OPT21: {
    right: {
      id: '21R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '21L',
      dest: '',
      WT: 0,
    },
  },
  OPT22: {
    right: {
      id: '22R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '22L',
      dest: '',
      WT: 0,
    },
  },
  OPT23: {
    right: {
      id: '23R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '23L',
      dest: '',
      WT: 0,
    },
  },
  OPT24: {
    right: {
      id: '24R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '24L',
      dest: '',
      WT: 0,
    },
  },
  OPT25: {
    right: {
      id: '25R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '25L',
      dest: '',
      WT: 0,
    },
  },
  OPT31: {
    right: {
      id: '31R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '31L',
      dest: '',
      WT: 0,
    },
  },
  OPT32: {
    right: {
      id: '32R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '32L',
      dest: '',
      WT: 0,
    },
  },
  OPT33: {
    right: {
      id: '33R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '33L',
      dest: '',
      WT: 0,
    },
  },
  OPT41: {
    right: {
      id: '41R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '41L',
      dest: 'TLS',
      WT: 0,
    },
  },
  OPT42: {
    right: {
      id: '42R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '42L',
      dest: '',
      WT: 0,
    },
  },
  OPT43: {
    right: {
      id: '43R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '43L',
      dest: '',
      WT: 0,
    },
  },
  OPT44: {
    right: {
      id: '44R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '44L',
      dest: '',
      WT: 0,
    },
  },
  OPT45: {
    right: {
      id: '45R',
      dest: '',
      WT: 0,
    },
    left: {
      id: '45L',
      dest: '',
      WT: 0,
    },
  },
  BISOPT11P: {
    id: '11P',
    dest:'',
    WT: 0,
  },
  BISOPT12P: {
    id: '12P',
    dest:'',
    WT: 0,
  },
  BISOPT21P: {
    id: '21P',
    dest:'',
    WT: 0,
  },
  BISOPT22P: {
    id: '22P',
    dest:'',
    WT: 0,
  },
  BISOPT23P: {
    id: '23P',
    dest:'',
    WT: 0,
  },
  BISOPT31P: {
    id: '31P',
    dest:'',
    WT: 0,
  },
  BISOPT32P: {
    id: '32P',
    dest:'',
    WT: 0,
  },
  BISOPT41P: {
    id: '41P',
    dest:'',
    WT: 0,
  },
  BISOPT42P: {
    id: '42P',
    dest:'',
    WT: 0,
  },
  BISOPT51: {
    id: '51',
    dest:'',
    WT: 0,
  },
  BISOPT52: {
    id: '52',
    dest:'',
    WT: 0,
  },
  BISOPT53: {
    id: '53',
    dest:'',
    WT: 0,
  },
  BISOPT54: {
    id: '54',
    dest:'TLS',
    WT: 0,
  },
}

export const DATAS = {
  struct: {
    line1: [
      {id: 'A1', right: false, left: false, topDec: false, max: 3674, bg: false, angle: false, display: false, specialTitle: null, specialMax: null, nextDecal: false, half : false},
      {id: 'A2', right: false, left: false, topDec: false, max: 3674, bg: true, angle: false, display: true, specialTitle: 'A', specialMax: 4808, nextDecal : true, half : false},
      {id: 'B1', right: false, left: false, topDec: false, max: 3674, bg: true, angle: true, display: true, specialTitle: 'B', specialMax: 4808, nextDecal : false, half : false},
      {id: 'C', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'D', right: true, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'E', right: false, left: true, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'F', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'G', right: false, left: false, topDec: false, max: 8284, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'H', right: false, left: false, topDec: false, max: 8284, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'J', right: false, left: false, topDec: false, max: 8284, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'K', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'L', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'M', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'P', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'Q', right: false, left: false, topDec: false, max: 2120, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : true},
      {id: 'R', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'S', right: false, left: false, topDec: false, max: 4857, bg: false, angle: false, display: true, specialTitle: null, specialMax: null, nextDecal : false, half : false},
      {id: 'T', right: false, left: false, topDec: true, max: 2041, bg: false, angle: false, display: false, specialTitle: null, specialMax: null, nextDecal : false, half : false},
    ],
    line2: {
      part1: [
        {id: '11R', force: false, id2: '11L'},
        {id: '12R', force: false, id2: '12L'},
        {id: '13R', force: false, id2: '13L'},
        {id: '21R', force: false, id2: '21L'},
        {id: '22R', force: false, id2: '22L'},
        {id: '23R', force: false, id2: '23L'},
        {id: '24R', force: false, id2: '24L'},
        {id: '25R', force: false, id2: '25L'},
      ],
      part2: [
        {id: '31R', force: false, id2: '31L'},
        {id: '32R', force: false, id2: '32L'},
        {id: '33R', force: false, id2: '33L'},
        {id: '41R', force: false, id2: '41L'},
        {id: '42R', force: false, id2: '42L'},
        {id: '43R', force: false, id2: '43L'},
        {id: '44R', force: false, id2: '44L'},
        {id: '45R', force: false, id2: '45L'},
      ]
    },
    line3: {
      part1: [
        {id: '11P', force: false },
        {id: '12P', force: false },
        {id: '21P', force: false },
        {id: '22P', force: false },
        {id: '23P', force: false },
      ],
      part2: [
        {id: '31P', force: false, half: false, big:false },
        {id: '32P', force: false, half: false, big:false },
        {id: '41P', force: false, half: false, big:false },
        {id: '42P', force: false, half: false, big:false },
        {id: '51', force: false, half: true, big:false },
        {id: '52', force: false, half: true, big:true },
        {id: '53', force: false, half: true, big:true },
        {id: '54', force: false, half: false, big:true },
      ]
    },
    line4: [
      {id: 'A1', zone: 3674, cum: 3674, middle: false, cum2: null, half: false},
      {id: 'A2', zone: 3674, cum: 7691, middle: false, cum2: null, half: false},
      {id: 'B1', zone: 6918, cum: 13570, middle: false, cum2: null, half: false},
      {id: 'C', zone: 11428, cum: 23183, middle: false, cum2: null, half: false},
      {id: 'D', zone: 11428, cum: 32797, middle: false, cum2: null, half: false},
      {id: 'E', zone: 11428, cum: 42411, middle: false, cum2: null, half: false},
      {id: 'F', zone: 12611, cum: 52608, middle: false, cum2: null, half: false},
      {id: 'G', zone: 16569, cum: 64761, middle: false, cum2: null, half: false},
      {id: 'H', zone: 16569, cum: 0, middle: true, cum2: null, half: false},
      {id: 'J', zone: 16569, cum: 68489, middle: false, cum2: 77665, half: false},
      {id: 'K', zone: 14406, cum: 55360, middle: false, cum2: 64536, half: false},
      {id: 'L', zone: 11428, cum: 43667, middle: false, cum2: 52843, half: false},
      {id: 'M', zone: 11428, cum: 33951, middle: false, cum2: 43127, half: false},
      {id: 'P', zone: 11428, cum: 24235, middle: false, cum2: 33412, half: false},
      {id: 'Q', zone: 4988, cum: 15722, middle: false, cum2: 23696, half: true},
      {id: 'R', zone: 11428, cum: 12006, middle: false, cum2: 19455, half: false},
      {id: 'S', zone: 10639, cum: 7024, middle: false, cum2: 10688, half: false},
      {id: 'T', zone: 2041, cum: 2041, middle: false, cum2: 2041, half: false},
    ]
  },
  sizes_codes: {
    A: {
      A: 4808,
      M: 4808,
      K: null,
      X: null,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    },
    B: {
      A: 4808,
      M: 4808,
      K: null,
      X: null,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    },
    A1: {
      A: 3356,
      M: 3674,
      K: null,
      X: null,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    },
    A2: {
      A: 3356,
      M: 3674,
      K: null,
      X: null,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    },
    B1: {
      A: 3356,
      M: 3674,
      K: null,
      X: null,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    },
    C: {
      A: 6123,
      M: 6123,
      K: null,
      X: null,
      Y: null,
      R: 9570,
      RLat: 7393,
      G: 11657,
    },
    D: {
      A: 6123,
      M: 6123,
      K: null,
      X: null,
      Y: null,
      R: 9570,
      RLat: 7393,
      G: 11657,
    },
    E: {
      A: 7529,
      M: 8210,
      K: null,
      X: null,
      Y: null,
      R: 12882,
      RLat: 7393,
      G: 14152,
    },
    F: {
      A: 7529,
      M: 8210,
      K: null,
      X: null,
      Y: null,
      R: 12882,
      RLat: 7393,
      G: 14152,
    },
    G: {
      A: 7529,
      M: 8210,
      K: null,
      X: null,
      Y: null,
      R: 14152,
      RLat: 12609,
      G: 14152,
    },
    H: {
      A: 7529,
      M: 8210,
      K: null,
      X: null,
      Y: null,
      R: 14152,
      RLat: 12609,
      G: 14152,
    },
    J: {
      A: 7529,
      M: 8210,
      K: null,
      X: null,
      Y: null,
      R: 14152,
      RLat: 12609,
      G: 14152,
    },
    K: {
      A: 7529,
      M: 8164,
      K: null,
      X: null,
      Y: null,
      R: 12882,
      RLat: 7393,
      G: 13471,
    },
    L: {
      A: 7529,
      M: 8164,
      K: null,
      X: null,
      Y: null,
      R: 12882,
      RLat: 7393,
      G: 13471,
    },
    M: {
      A: 7529,
      M: 8164,
      K: null,
      X: null,
      Y: null,
      R: 12882,
      RLat: 7393,
      G: 13471,
    },
    P: {
      A: 6123,
      M: 6123,
      K: null,
      X: null,
      Y: null,
      R: 9570,
      RLat: 7393,
      G: 11657,
    },
    Q: {
      A: null,
      M: null,
      K: null,
      X: null,
      Y: 1214,
      R: null,
      RLat: null,
      G: null,
    },
    R: {
      A: 6123,
      M: 6123,
      K: null,
      X: null,
      Y: null,
      R: 9570,
      RLat: 7393,
      G: 11657,
    },
    S: {
      A: 6441,
      M: 7257,
      K: null,
      X: null,
      Y: null,
      R: 9570,
      RLat: 7393,
      G: 1657,
    },
    T: {
      A: 2041,
      M: 2041,
      K: null,
      X: null,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    },
    LH: {
      A: 4626,
      M: 5034,
      K: 1587,
      X: 3175,
      Y: null,
      R: null,
      RLat: null,
      G: null,
    }
  },
  indexRules: {
    A1 : [
      {value:0, index:0},
      {value:144, index:-1},
      {value:430, index:-2},
      {value:717, index:-3},
      {value:1004, index:-4},
      {value:1291, index:-5},
      {value:1578, index:-6},
      {value:1865, index:-7},
      {value:2152, index:-8},
      {value:2439, index:-9},
      {value:2725, index:-10},
      {value:3012, index:-11},
      {value:3299, index:-12},
      {value:3586, index:-13},
      {value:3675, index:-9999},
    ],
    A2 : [
      {value:0, index:0},
      {value:160, index:-1},
      {value:478, index:-2},
      {value:797, index:-3},
      {value:1116, index:-4},
      {value:1435, index:-5},
      {value:1754, index:-6},
      {value:2073, index:-7},
      {value:2392, index:-8},
      {value:2711, index:-9},
      {value:3030, index:-10},
      {value:3349, index:-11},
      {value:3668, index:-12},
      {value:3674, index:-9999},
    ],
    B1 : [
      {value:0, index:0},
      {value:177, index:-1},
      {value:528, index:-2},
      {value:880, index:-3},
      {value:1233, index:-4},
      {value:1585, index:-5},
      {value:1937, index:-6},
      {value:2290, index:-7},
      {value:2642, index:-8},
      {value:2994, index:-9},
      {value:3347, index:-10},
      {value:3699, index:-11},
      {value:4051, index:-12},
      {value:4403, index:-13},
      {value:4756, index:-14},
      {value:5108, index:-15},
      {value:5460, index:-16},
      {value:5813, index:-17},
      {value:6165, index:-18},
      {value:6517, index:-19},
      {value:6870, index:-20},
      {value:6918, index:-9999},
    ],
    C : [
      {value:0, index:0},
      {value:204, index:-1},
      {value:611, index:-2},
      {value:1019, index:-3},
      {value:1427, index:-4},
      {value:1835, index:-5},
      {value:2243, index:-6},
      {value:2650, index:-7},
      {value:3058, index:-8},
      {value:3466, index:-9},
      {value:3874, index:-10},
      {value:4282, index:-11},
      {value:4690, index:-12},
      {value:5097, index:-13},
      {value:5505, index:-14},
      {value:5913, index:-15},
      {value:6321, index:-16},
      {value:6729, index:-17},
      {value:7137, index:-18},
      {value:7544, index:-19},
      {value:7952, index:-20},
      {value:8360, index:-21},
      {value:8768, index:-22},
      {value:9176, index:-23},
      {value:9584, index:-24},
      {value:9991, index:-25},
      {value:10399, index:-26},
      {value:10807, index:-27},
      {value:11215, index:-28},
      {value:11428, index:-9999},
    ],
    D : [
      {value:0, index:0},
      {value:247, index:-1},
      {value:738, index:-2},
      {value:1230, index:-3},
      {value:1722, index:-4},
      {value:2214, index:-5},
      {value:2706, index:-6},
      {value:3198, index:-7},
      {value:3690, index:-8},
      {value:4183, index:-9},
      {value:4675, index:-10},
      {value:5167, index:-11},
      {value:5659, index:-12},
      {value:6151, index:-13},
      {value:6643, index:-14},
      {value:7135, index:-15},
      {value:7627, index:-16},
      {value:8120, index:-17},
      {value:8612, index:-18},
      {value:9104, index:-19},
      {value:9596, index:-20},
      {value:10088, index:-21},
      {value:10580, index:-22},
      {value:11072, index:-23},
      {value:11428, index:-9999},
    ],
    E : [
      {value:0, index:0},
      {value:311, index:-1},
      {value:930, index:-2},
      {value:1550, index:-3},
      {value:2171, index:-4},
      {value:2791, index:-5},
      {value:3411, index:-6},
      {value:4032, index:-7},
      {value:4652, index:-8},
      {value:5272, index:-9},
      {value:5893, index:-10},
      {value:6513, index:-11},
      {value:7133, index:-12},
      {value:7754, index:-13},
      {value:8374, index:-14},
      {value:8995, index:-15},
      {value:9615, index:-16},
      {value:10235, index:-17},
      {value:10856, index:-18},
      {value:11428, index:-9999},
    ],
    F : [
      {value:0, index:0},
      {value:420, index:-1},
      {value:1258, index:-2},
      {value:2097, index:-3},
      {value:2936, index:-4},
      {value:3775, index:-5},
      {value:4614, index:-6},
      {value:5453, index:-7},
      {value:6291, index:-8},
      {value:7130, index:-9},
      {value:7969, index:-10},
      {value:8808, index:-11},
      {value:9647, index:-12},
      {value:10486, index:-13},
      {value:11325, index:-14},
      {value:12164, index:-15},
      {value:12611, index:-9999},
    ],
    G : [
      {value:0, index:0},
      {value:648, index:-1},
      {value:1943, index:-2},
      {value:3238, index:-3},
      {value:4533, index:-4},
      {value:5829, index:-5},
      {value:7124, index:-6},
      {value:8419, index:-7},
      {value:9715, index:-8},
      {value:11010, index:-9},
      {value:12305, index:-10},
      {value:13601, index:-11},
      {value:14896, index:-12},
      {value:16191, index:-13},
      {value:16569, index:-9999},
    ],
    H : [
      {value:0, index:0},
      {value:1421, index:-1},
      {value:4261, index:-2},
      {value:7102, index:-3},
      {value:9943, index:-4},
      {value:12784, index:-5},
      {value:15625, index:-6},
      {value:16569, index:-9999},
    ],
    J : [
      {value:0, index:0},
      {value:7353, index:1},
      {value:16569, index:9999},
    ],
    K : [
      {value:0, index:0},
      {value:1025, index:1},
      {value:3073, index:2},
      {value:5122, index:3},
      {value:7172, index:4},
      {value:9221, index:5},
      {value:11270, index:6},
      {value:13319, index:7},
      {value:14406, index:9999},
    ],
    L : [
      {value:0, index:0},
      {value:551, index:1},
      {value:1651, index:2},
      {value:2753, index:3},
      {value:3854, index:4},
      {value:4955, index:5},
      {value:6057, index:6},
      {value:7158, index:7},
      {value:8259, index:8},
      {value:9361, index:9},
      {value:10462, index:10},
      {value:11428, index:9999},
    ],
    M : [
      {value:0, index:0},
      {value:377, index:1},
      {value:1129, index:2},
      {value:1882, index:3},
      {value:2635, index:4},
      {value:3388, index:5},
      {value:4141, index:6},
      {value:4894, index:7},
      {value:5647, index:8},
      {value:6400, index:9},
      {value:7153, index:10},
      {value:7906, index:11},
      {value:8659, index:12},
      {value:9412, index:13},
      {value:10165, index:14},
      {value:10918, index:15},
      {value:11428, index:9999},
    ],
    P : [
      {value:0, index:0},
      {value:287, index:1},
      {value:858, index:2},
      {value:1430, index:3},
      {value:2002, index:4},
      {value:2574, index:5},
      {value:3146, index:6},
      {value:3718, index:7},
      {value:4290, index:8},
      {value:4862, index:9},
      {value:5434, index:10},
      {value:6006, index:11},
      {value:6578, index:12},
      {value:7151, index:13},
      {value:7723, index:14},
      {value:8295, index:15},
      {value:8867, index:16},
      {value:9439, index:17},
      {value:10011, index:18},
      {value:10583, index:19},
      {value:11155, index:20},
      {value:11428, index:9999},
    ],
    Q : [
      {value:0, index:0},
      {value:244, index:1},
      {value:731, index:2},
      {value:1219, index:3},
      {value:1707, index:4},
      {value:2195, index:5},
      {value:2683, index:6},
      {value:3171, index:7},
      {value:3659, index:8},
      {value:4147, index:9},
      {value:4634, index:10},
      {value:4988, index:9999},
    ],
    R : [
      {value:0, index:0},
      {value:213, index:1},
      {value:637, index:2},
      {value:1063, index:3},
      {value:1488, index:4},
      {value:1913, index:5},
      {value:2339, index:6},
      {value:2764, index:7},
      {value:3189, index:8},
      {value:3614, index:9},
      {value:4040, index:10},
      {value:4465, index:11},
      {value:4890, index:12},
      {value:5316, index:13},
      {value:5741, index:14},
      {value:6166, index:15},
      {value:6592, index:16},
      {value:7017, index:17},
      {value:7442, index:18},
      {value:7867, index:19},
      {value:8293, index:20},
      {value:8718, index:21},
      {value:9143, index:22},
      {value:9569, index:23},
      {value:9994, index:24},
      {value:10419, index:25},
      {value:10844, index:26},
      {value:11270, index:27},
      {value:11428, index:9999},
    ],
    S : [
      {value:0, index:0},
      {value:181, index:1},
      {value:541, index:2},
      {value:902, index:3},
      {value:1262, index:4},
      {value:1623, index:5},
      {value:1984, index:6},
      {value:2345, index:7},
      {value:2706, index:8},
      {value:3067, index:9},
      {value:3427, index:10},
      {value:3788, index:11},
      {value:4149, index:12},
      {value:4510, index:13},
      {value:4871, index:14},
      {value:5232, index:15},
      {value:5592, index:16},
      {value:5953, index:17},
      {value:6314, index:18},
      {value:6675, index:19},
      {value:7036, index:20},
      {value:7397, index:21},
      {value:7757, index:22},
      {value:8118, index:23},
      {value:8479, index:24},
      {value:8840, index:25},
      {value:9201, index:26},
      {value:9562, index:27},
      {value:9923, index:28},
      {value:10283, index:29},
      {value:10639, index:9999},
    ],
    T : [
      {value:0, index:0},
      {value:155, index:1},
      {value:464, index:2},
      {value:776, index:3},
      {value:7083, index:4},
      {value:1392, index:5},
      {value:1702, index:6},
      {value:2011, index:7},
      {value:2041, index:9999},
    ],
  },
  fuel_densities: [
    0.76,
    0.78,
    0.80,
    0.82,
    0.85,
  ],
  fuel_index: [
    {value: 0, 0.76:0, 0.78:0, 0.80:0, 0.82:0, 0.85:0},
    {value: 2000, 0.76:0, 0.78:0, 0.80:0, 0.82:0, 0.85:0},
    {value: 4000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 6000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 8000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 10000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 12000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 14000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 16000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 18000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 20000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 22000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 24000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 26000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 28000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 30000, 0.76:-2, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 32000, 0.76:-1, 0.78:-2, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 34000, 0.76:-1, 0.78:-1, 0.80:-2, 0.82:-2, 0.85:-2},
    {value: 36000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-2, 0.85:-2},
    {value: 38000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-2},
    {value: 40000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 42000, 0.76:-1, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 44000, 0.76:0, 0.78:-1, 0.80:-1, 0.82:-1, 0.85:-1},
    {value: 46000, 0.76:0, 0.78:0, 0.80:0, 0.82:-1, 0.85:-1},
    {value: 48000, 0.76:0, 0.78:0, 0.80:0, 0.82:0, 0.85:-1},
    {value: 50000, 0.76:1, 0.78:0, 0.80:0, 0.82:0, 0.85:0},
    {value: 52000, 0.76:0, 0.78:1, 0.80:0, 0.82:0, 0.85:0},
    {value: 54000, 0.76:-1, 0.78:0, 0.80:0,0.82:1, 0.85:0},
    {value: 56000, 0.76:-1, 0.78:-1, 0.80:0,0.82:0, 0.85:1},
    {value: 58000, 0.76:-2, 0.78:-2, 0.80:-1,0.82:-1, 0.85:0},
    {value: 60000, 0.76:-3, 0.78:-2, 0.80:-2,0.82:-1, 0.85:-1},
    {value: 62000, 0.76:-4, 0.78:-3, 0.80:-3,0.82:-2, 0.85:-1},
    {value: 64000, 0.76:-2, 0.78:-4, 0.80:-3,0.82:-3, 0.85:-2},
    {value: 66000, 0.76:0, 0.78:-2, 0.80:-4,0.82:-3, 0.85:-3},
    {value: 68000, 0.76:3, 0.78:1, 0.80:-2,0.82:-4, 0.85:-3},
    {value: 70000, 0.76:6, 0.78:3, 0.80:1,0.82:-1, 0.85:-4},
    {value: 72000, 0.76:6, 0.78:6, 0.80:4,0.82:1, 0.85:-2},
    {value: 74000, 0.76:5, 0.78:6, 0.80:7,0.82:4, 0.85:1},
    {value: 76000, 0.76:5, 0.78:6, 0.80:6,0.82:7, 0.85:3},
    {value: 78000, 0.76:4, 0.78:5, 0.80:6,0.82:6, 0.85:6},
    {value: 80000, 0.76:4, 0.78:4, 0.80:5,0.82:6, 0.85:7},
    {value: 82000, 0.76:3, 0.78:4, 0.80:4,0.82:5, 0.85:6},
    {value: 84000, 0.76:3, 0.78:3, 0.80:4,0.82:5, 0.85:6},
    {value: 86000, 0.76:2, 0.78:3, 0.80:3,0.82:4, 0.85:5},
    {value: 88000, 0.76:1, 0.78:2, 0.80:3,0.82:4, 0.85:5},
    {value: 90000, 0.76:1, 0.78:2, 0.80:2,0.82:3, 0.85:4},
    {value: 92000, 0.76:0, 0.78:1, 0.80:2,0.82:2, 0.85:3},
    {value: 94000, 0.76:0, 0.78:1, 0.80:1,0.82:2, 0.85:3},
    {value: 96000, 0.76:-1, 0.78:0, 0.80:1,0.82:1, 0.85:2},
    {value: 98000, 0.76:-1, 0.78:-1, 0.80:0,0.82:1, 0.85:2},
    {value: 100000, 0.76:-2, 0.78:-1, 0.80:0,0.82:0, 0.85:1},
    {value: 102000, 0.76:-2, 0.78:-2, 0.80:-1, 0.82:0, 0.85:1},
    {value: 104000, 0.76:-2, 0.78:-2, 0.80:-1, 0.82:-1, 0.85:0},
    {value: 106000, 0.76:-3, 0.78:-2, 0.80:-2, 0.82:-1, 0.85:0},
    {value: 108000, 0.76:-5, 0.78:-3, 0.80:-2, 0.82:-2, 0.85:-1},
    {value: 110000, 0.76:-6, 0.78:-4, 0.80:-3, 0.82:-2, 0.85:-1},
    {value: 112000, 0.76:-8, 0.78:-6, 0.80:-4, 0.82:-3, 0.85:-2},
    {value: 114000, 0.76:-9, 0.78:-7, 0.80:-5, 0.82:-3, 0.85:-2},
    {value: 116000, 0.76:-10, 0.78:-9, 0.80:-7, 0.82:-5, 0.85:-3},
    {value: 118000, 0.76:-12, 0.78:-10, 0.80:-8, 0.82:-6, 0.85:-3},
    {value: 120000, 0.76:-13, 0.78:-11, 0.80:-10, 0.82:-8, 0.85:-5},
    {value: 122000, 0.76:-15, 0.78:-13, 0.80:-11, 0.82:-9, 0.85:-6},
    {value: 124000, 0.76:-16, 0.78:-14, 0.80:-12, 0.82:-10, 0.85:-8},
    {value: 126000, 0.76:-17, 0.78:-16, 0.80:-14, 0.82:-10, 0.85:-9},
    {value: 128000, 0.76:-19, 0.78:-17, 0.80:-15, 0.82:-13, 0.85:-10},
    {value: 130000, 0.76:-20, 0.78:-18, 0.80:-17, 0.82:-15, 0.85:-12},
    {value: 132000, 0.76:-22, 0.78:-20, 0.80:-18, 0.82:-16, 0.85:-13},
    {value: 134000, 0.76:-23, 0.78:-21, 0.80:-19, 0.82:-17, 0.85:-15},
    {value: 136000, 0.76:-24, 0.78:-23, 0.80:-21, 0.82:-19, 0.85:-16},
    {value: 138000, 0.76:-26, 0.78:-24, 0.80:-22, 0.82:-20, 0.85:-17},
    {value: 140000, 0.76:-27, 0.78:-25, 0.80:-23, 0.82:-22, 0.85:-19},
    {value: 142000, 0.76:-29, 0.78:-27, 0.80:-25, 0.82:-23, 0.85:-20},
    {value: 144000, 0.76:-30, 0.78:-28, 0.80:-26, 0.82:-24, 0.85:-22},
    {value: 146000, 0.76:-31, 0.78:-29, 0.80:-26, 0.82:-28, 0.85:-23},
    {value: 148000, 0.76:-33, 0.78:-31, 0.80:-29, 0.82:-27, 0.85:-24},
    {value: 150000, 0.76:-34, 0.78:-32, 0.80:-30, 0.82:-29, 0.85:-26},
    {value: 152000, 0.76:-36, 0.78:-34, 0.80:-32, 0.82:-30, 0.85:-27},
    {value: 154000, 0.76:-38, 0.78:-35, 0.80:-33, 0.82:-31, 0.85:-29},
    {value: 154677, 0.76:-38, 0.78:-36, 0.80:-34, 0.82:-32, 0.85:-29},
    {value: 156000, 0.76:-9999, 0.78:-37, 0.80:-35, 0.82:-33, 0.85:-30},
    {value: 158000, 0.76:-9999, 0.78:-39, 0.80:-36, 0.82:-34, 0.85:-31},
    {value: 158747, 0.76:-9999, 0.78:-39, 0.80:-37, 0.82:-35, 0.85:-32},
    {value: 160000, 0.76:-9999, 0.78:-9999, 0.80:-38, 0.82:-36, 0.85:-33},
    {value: 160783, 0.76:-9999, 0.78:-9999, 0.80:-39, 0.82:-36, 0.85:-33},
    {value: 162000, 0.76:-9999, 0.78:-9999, 0.80:-40, 0.82:-37, 0.85:-34},
    {value: 162818, 0.76:-9999, 0.78:-9999, 0.80:-40, 0.82:-38, 0.85:-35},
    {value: 164000, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-39, 0.85:-36},
    {value: 166000, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-41, 0.85:-37},
    {value: 166888, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-41, 0.85:-38},
    {value: 168000, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-9999, 0.85:-39},
    {value: 170000, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-9999, 0.85:-40},
    {value: 172000, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-9999, 0.85:-42},
    {value: 172994, 0.76:-9999, 0.78:-9999, 0.80:-9999, 0.82:-9999, 0.85:-42},
  ],
  MZFW: 277144,
  MTWW: 414129,
  MTOW: 412769,
  MLW: 296195,
  DOW: 158854,
  index_dow: 69,
  text: {
    a: 'Use of 2 PLT nose, Zone B includes 11P or Cans 11',
    b: 'MAX BAY WT',
    c: 'LOWER LOBE',
    d: 'K: 1587KG',
    e: 'L: 3175KG',
    f: 'ALL WEIGHTS IN KILOGRAMS',
    g: 'Bay Weight Limitations for size code "M" For other base dimensions see Maximum Unit Load Device Limits',
    h: '# Check with "MAXIMUM ULD LIMITS" table for additional tiedown reuqirements',
    i: 'Fwd Limit A =',
    j: 'Fwd Limit C =',
  },
  keysZone : {
    A1: [],
    A2: [],
    B1: [11],
    C: [12,13],
    D: [21,22],
    E: [23,24],
    F: [25],
    G: [],
    H: [],
    J: [],
    K: [31],
    L: [32,33],
    M: [41,42],
    P: [43,44],
    Q: [45],
    R: [],
    S: [],
    T: [],
  },
  keysZone2 : {
    A1: [],
    A2: [],
    B1: ['11P'],
    C: ['12P'],
    D: ['21P'],
    E: ['22P'],
    F: ['23P'],
    G: [],
    H: [],
    J: [],
    K: ['31P'],
    L: ['32P'],
    S: ['54'],
    M: ['41P'],
    P: ['42P'],
    Q: ['51'],
    R: ['52', '53'],
    T: [],
  },
  maxWeightLowers: 1587,
  correspIdCode: {
    '11P': 'B1',
    '12P': 'C',
    '21P': 'D',
    '22P': 'E',
    '23P': 'F',
    '31P': 'K',
    '32P': 'L',
    '41P': 'M',
    '42P': 'P',
    '51': 'Q',
    '52': 'R',
    '53': 'S',
    '54': 'T',
  }
}
