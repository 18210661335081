import React, {Component} from 'react';
import CreateBalanceView from './CreateBalanceView';
import { connect } from 'react-redux';
import { addBalance } from 'api/methods/balance';
import { getAircraftList } from 'api/methods/load';
import {DEFAULTVALUES} from 'utils/balanceChart/boeing-747-400ERF';
import {DEMOVALUES} from 'utils/balanceChart/boeing-747-400ERF';
import { DATAS } from 'utils/balanceChart/boeing-747-400ERF';

class CreateBalanceContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      aircraftList: []
    }
  }

  addBalance = (balance) => {
    const newBalance = {
      name: balance.loadId,
      aircraft_id: balance.aircraft,
      data: DEFAULTVALUES,
      dow: DATAS.DOW,
      index_dow: DATAS.index_dow,
    }

    addBalance(this.props.userReducer.token, newBalance).then((response) => {
      console.log('response', response)
      if(response.status === 200 && response.ok) {
        const newId = response.data.id
        window.location.href='/balanceChart/'+newId
      }
    })
  }

  componentDidMount() {
    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })
  }

  render() {
    const idLoad = this.props.match.params.id
    return(
      <>
        <CreateBalanceView
          aircraftList={this.state.aircraftList}
          isMainLoading={this.state.isLoading}
          addBalance={this.addBalance}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUldStock: (uld) => {
      dispatch({type: "ADDULDSTOCK", data: uld})
    },
    deleteUldStock: (id) => {
      dispatch({type: "DELETEULDSTOCK", data: {id: id}})
    },
    updateUldStock: (data) => {
      dispatch({type:"UPDATEULDSTOCK", data: data})
    },
    refreshUldStock: (data) => {
      dispatch({type:"REFRESHULDSTOCK", data: data})
    },
    updateLoads: (loadsList) => {
      dispatch({type: "UPDATELOADS", data: loadsList})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
    stockULDSReducer: state.stockULDSReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBalanceContainer)
