import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import UldViewer from './UldViewer';

/**
 * Wrapper for ULD Viewer - Get ULD & parcels from data.
 * @param {ViewerDataWrapperProps} props 
 * @returns {JSX.Element}
 */
export default function ViewerDataWrapper({style, className, data, config, selectedGroup, onSelect}){
    const [parcels, setParcels] = useState({});
    const [uld, setUld] = useState({});

    useEffect(() => {
        const newParcels = getParcels(data, parcels)
        const newULD = getULD(data, uld)
        setParcels({ ...newParcels });
        setUld({ ...newULD });
    },[data]);

    return <UldViewer 
        style={style}
        className={className} 
        config={config} 
        uld={uld} 
        parcels={parcels} 
        selectedGroup={selectedGroup}
        onSelect={onSelect} />
}
/**
 * Get new ULD object or return last ULD ref if ULD id did not change
 * @param {Object} data 
 * @param {ULD|null} uld 
 * @returns {ULD} uld
 */
function getULD( data, uld ){
    if( data?.uld_stock?.uld && data.uld_stock.uld.id !== uld?.id ){
        return {
            id: data.uld_stock.uld.id,
            size: data.uld_stock.uld.size
        };
    }
    return uld;
}
/**
 * Rebuild parcel object list.
 * @param {Object} data 
 * @param {null|Object.<string,PARCEL>} parcels 
 */
function getParcels(data, parcels) {
    let newParcels = {}
    if (data?.load_uld_parcels?.length) {
        data.load_uld_parcels.forEach((parcel) => {
            const newParcel = {
                id: parcel.id,
                position: parcel.parcel.position,
                width: parcel.parcel.width,
                height: parcel.parcel.height,
                depth: parcel.parcel.length,
                group: parcel.parcel.group_uid
            }
            newParcels[parcel.id] = {...newParcel}
        })
    }
    return newParcels
}

// --- Type Definitions ---

/**
 * ViewerDataWrapper props definition
 * @typedef {Object} ViewerDataWrapperProps
 * @property {Object} style - CSS Style to apply on viewer container
 * @property {string} className - Class to apply on viewer container
 * @property {Object} data - ULD data ( parcels/uld informations )
 * @property {Object} config - THREEJS configuration options (  )
 * @property {Function} onSelect - Function called when user select a parcel.
 */

/**
 * PARCEL type definition
 * @typedef {Object} PARCEL
 * @property {number} id - PARCEL
 * @property {string} group - PARCEL group's unique id
 * @property {Position} position - PARCEL 3D Position
 * @property {number} width - PARCEL size width
 * @property {number} height - PARCEL size height
 * @property {number} depth - PARCEL size depth
 */

/**
 * 3D Position
 * @typedef {Object} Position
 * @property {number} x - Position on x axis
 * @property {number} y - Position on y axis
 * @property {number} z - Position on z axis
 */

/**
 * ULD type definition
 * @typedef {Object} ULD
 * @property {number} id - ULD ID
 * @property {ULDSize} size - 3D ULD Definition ( "xy" shape to extrude on a "z" depth)
 */

/**
 * ULD Geometry Definition
 * @typedef {Object} ULDSize
 * @property {[number,number][]} xy - ULD shape on x/y axis
 * @property {number} z - ULD depth
 */