const commons = {
  "appName" : "ALEN",
  "ULD" : "ULD",
  "back" : "back",
  "Parcel" : "Parcel",
  "parcel" : "parcel",
  "searchLabel" : "Find anything",
  "Loads" : "Loads",
  "loadsList" : "List Load",
  "newLoads" : "New Load",
  "settings" : "Settings",
  "stockMenu" : "ULD Stock Management",
  "genericError" : "Internal Error... Try again or contact support",
  "logout" : "Logout",
  "status" : {
    "load" : {
      "0" : "NOT STARTED",
      "20" : "CHARGING...",
      "30" : "COMPLETED",
    },
    "uld" : {
      "5" : "NOT STARTED",
      "10" : "NOT STARTED",
      "20" : "CHARGING...",
      "30" : "COMPLETED",
    },
    "parcel" : {
      "0" : "WAITING",
      "10" : "READY TO LOAD",
      "20" : "LOADED",
      "99" : "ISSUE TO FIX",
    }
  },
  "loadingList" : "Loading list",
  "optimizedList" : "Optimized ULDs list",
  "newTiedown" : "New Tiedown",
  "tiedowns" : "Tiedowns",
  "tiedownsList" : "List Tiedown",
  "account" : "Manage account",
  "balance" : "Balance Chart",
  "newBalance" : "New Balance Chart",
  "balanceList" : "List Balances Charts",
}

export default commons
