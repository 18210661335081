import * as React from "react";

function SvgBalanceChart(props) {
  return (
    <svg width={60} height={46.77} viewBox="0 0 60 46.77" {...props}>
      <g fillRule="nonzero">
        <path d="M59.83 3.17a1 1 0 00-1.389-.271l-12.375 8.327L44.95 9.57a1 1 0 00-1.66 1.117l1.117 1.659-8.966 6.036a1 1 0 001.118 1.659l23-15.483a1 1 0 00.27-1.388zM.171 43.329a1 1 0 001.388.271l21.567-14.52 5.431-3.657a1 1 0 00-1.118-1.659l-4.6 3.1-1.117-1.659a1 1 0 00-1.659 1.117l1.117 1.659L.441 41.94a1 1 0 00-.27 1.389zM38.808.442A1 1 0 0037.42.171L14.191 15.807a1 1 0 00-.27 1.388l1.68 2.489a1 1 0 001.66-1.117l-1.118-1.659L37.706 2.389l1.116 1.658a1 1 0 101.66-1.116z" />
        <path d="M12.685 21.997l-.814 4.164a1 1 0 00.126.688c.016.03.033.059.052.087a1 1 0 00.231.232c.006 0 .01.01.016.014.1.063.21.108.326.133.013 0 .022.012.035.014l4.164.813a1 1 0 00.383-1.962l-1.794-.351 9.61-6.47a1 1 0 10-1.117-1.659l-9.61 6.47.35-1.794a1 1 0 00-1.962-.384zM35.25 11.275a1 1 0 001.388.271l9.611-6.47L45.9 6.87a1 1 0 001.963.384l.814-4.164v-.037a1 1 0 000-.353v-.015a1.011 1.011 0 00-.369-.56 1 1 0 00-.413-.198l-4.164-.814a1 1 0 10-.383 1.963l1.794.351-9.61 6.47a1 1 0 00-.282 1.378zM32 12.77a1 1 0 00-1 1v29.586l-2.293-2.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.087.217 1 1 0 00.294-.2c.01-.008.021-.011.03-.02l4-4a1 1 0 00-1.414-1.414L33 43.356V13.77a1 1 0 00-1-1z" />
      </g>
    </svg>
  );
}

export default SvgBalanceChart;
