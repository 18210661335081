export const FONTNORMAL=14
export const FONTNORMALSUP=16
export const FONTBIGSUP=25
export const FONTBIG=20
export const FONTVERYBIG=31
export const FONTMEGABIG=39
export const FONTLITTLE=10
export const FONTVERYLITTLE=9
export const FONTMINIBIG=18
export const FONTLITTLE2=12
export const FONTGIGABIG=50
