const createLoad = {
  "title" : "Create a new load",
  "buttonLabel" : "Create LOAD",
  "aircraftRegistration" : "Aircraft registration",

  "customReference" : "Custom reference",
  "pointOfLoading" : "Point of loading",
  "pointOfUnloading" : "Point of unloading",
  "flightNumber" : "Flight number",
  "flightAt" : "Flight at",
  "ownerOperator" : "Owner operator",
  "deConsolidator" : "DE consolidator",
  "consolidator" : "Consolidator",
  "aircraftRegistrationPlaceholder" : "Enter aircraft registration",
  "customReferencePlaceholder" : "Enter custom reference",
  "pointOfLoadingPlaceholder" : "Enter point of loading",
  "pointOfUnloadingPlaceholder" : "Enter point of unloading",
  "flightNumberPlaceholder" : "Enter flight number",
  "flightAtPlaceholder" : "Enter flight at",
  "ownerOperatorPlaceholder" : "Enter owner operator",
  "deConsolidatorPlaceholder" : "Enter DE consolidator",
  "consolidatorPlaceholder" : "Enter consolidator",

  "editTitle" : "Modify your load",
  "cancel" : "Cancel",
  "save" : "Save",
  "successEdit" : "Success ! Load has been updated !",
}

export default createLoad
