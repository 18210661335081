import * as React from "react";

function SvgDownload(props) {
  return (
    <svg width={24} height={24} viewbox={"0 0 24 24"} {...props}>
      <path
        d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8zm1-8h3l-4 4-4-4h3V8h2v4z"
      />
    </svg>
  );
}

export default SvgDownload;
