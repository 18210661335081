import React, {Component} from 'react';
import LoadsListView from './LoadsListView';
import { connect } from 'react-redux';
import ALENToast from 'components/ALENToast';
import { withTranslation } from 'react-i18next';
import { getAircraftList, addLoad, deleteLoad, getLoads } from 'api/methods/load';

class LoadsListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      aircraftList: [],
      isLoading: false,
      success: false,
      successMessage: "",
    }
  }

  componentDidMount() {
    /*get aircraft list APPEL API aircraft/getlist*/
    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })

    /*get loads List*/

  }

  deleteLoad = (load_id) => {
    this.setState({isLoading: true})
    deleteLoad(this.props.userReducer.token, load_id).then((response) => {
      this.setState({isLoading: false})
      if(response.status === 200 && response.ok) {
        getLoads(this.props.userReducer.token).then((response) => {
          if(response.status === 200 && response.ok) {
            const newLoads = []
            response.data.forEach((load) => {
              newLoads.push({
                ...load,
                nbParcels: load.parcels.length
              })
            })
            this.props.updateLoads(newLoads)
            this.setState({
              isLoading: false,
              success: true,
              successMessage: this.props.t('loads.deleteSuccess'),
            })

            setTimeout(() => {
              this.setState({success:false})
            }, 3000);
          }
        })
      }
    })
  }

  addLoad = (load) => {
    console.log('load', load)
    const newLoad = {
      name: load.load_id,
      aircraft_id: load.aircraft_id,
    }

    addLoad(this.props.userReducer.token, newLoad).then((response) => {
      console.log('response', response)
      if(response.status === 200 && response.ok) {
        const newLoads = []
        response.data.forEach((load) => {
          newLoads.push({
            ...load,
            nbParcels: load.parcels.length
          })
        })
        this.props.updateLoads(newLoads)
      }
    })

    /*Simu ID pour redux*/
    /*const newLoad = {
      ...load,
      id: Date.now(),
      status: 0,
      nbParcels: 0,
      nbULDs: 0,
      parcelsList: [],
    }*/
    //this.props.addLoad(newLoad)
  }

  render() {
    return(
      <>
        <LoadsListView
          aircraftList={this.state.aircraftList}
          addLoad={this.addLoad}
          loads={this.props.loadsReducer.loads}
          deleteLoad={this.deleteLoad}
          isLoading={this.state.isLoading}
        />
        <ALENToast
          message={this.state.successMessage}
          show={this.state.success}
          handleClose={() => this.setState({success: false})}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLoad: (load) => {
      dispatch({type: "ADDLOAD", data: load})
    },
    updateLoads: (loadsList) => {
      dispatch({type: "UPDATELOADS", data: loadsList})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoadsListContainer))
