import * as React from "react";

function SvgModule2(props) {
  return (
    <svg width={83.697} height={82.013} viewBox="0 0 22.145 21.699" {...props}>
      <path d="M1.076 13.16l6.482-.539.652 3.754-3.063 1.396c-.506.224-.274.972-.288 1.846a.527.527 0 00.566.555l3.712-.3c1.134 2.455 2.57 2.4 3.601.052l3.84.4a.528.528 0 00.607-.613l-.351-1.995a.529.529 0 00-.323-.398l-2.704-1.084.634-3.563 6.541.613a.519.519 0 00.367-.083c1.405-.93.632-3.444.086-3.69l-6.472-2.926c-.588-9.6-7.67-7.967-7.89.158L.607 9.944c-.483.23-.638 1.537-.602 1.93.063.72.577 1.327 1.07 1.285zm.157-2.352l6.593-3.262a.527.527 0 00.293-.497c.017-2.718.836-4.637 1.88-5.509.352-.295.722-.458 1.081-.48 1.747-.096 2.713 2.829 2.848 5.898a.523.523 0 00.31.455l6.595 2.98c.108.108.497 1.303.053 1.825l-6.83-.64a.525.525 0 00-.567.433h-.002l-.793 4.463c-.046.259.108.51.354.59l2.798 1.12.18 1.022-3.569-.367a.525.525 0 00-.552.353c-.77 2.114-1.198 1.73-1.948-.077a.528.528 0 00-.527-.326l-3.533.287-.008-.488 3.127-1.427a.525.525 0 00.3-.566h.003l-.806-4.628a.526.526 0 00-.56-.436l-6.794.563c-.265-.29.019-1.226.074-1.286z" />
    </svg>
  );
}

export default SvgModule2;
