import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import ALENText from 'components/ALENText';
import InputAdornment from '@material-ui/core/InputAdornment';
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENRED} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTLITTLE} from 'utils/fontSizes'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import SvgSearch from 'components/icons/Search';

const styles = theme => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: ALENBORDERINPUT,
      height:37,

    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: ALENBLUE,
      height:37,

    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: ALENBLUE,
      height:37,

    },
  },
});

class ALENInputBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    }

    this.inputRef = React.createRef()
  }

  focus = () => {
    this.inputRef.current.focus()
  }

  showPassword = () => {
    this.setState({showPassword: true})
  }

  hidePassword = () => {
    this.setState({showPassword: false})
  }

  render() {
    const { t, i18n } = this.props;
    const { classes } = this.props;

    return (
      <>
        <input
          value={this.props.value}
          placeholder={this.props.placeholder}
          type={this.props.type}
          style={{
            backgroundColor:'transparent',
            borderColor:'transparent',
            width:'98%',
          }}
          onChange={this.props.onChange}
        />
      </>
    );
  }
}

ALENInputBalance.propTypes = {
  type:PropTypes.string,
  label:PropTypes.string,
  maxWidth:PropTypes.number,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  height:PropTypes.string,
  disabled:PropTypes.bool,
  placeholder: PropTypes.string,
  dark: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onTab: PropTypes.func,
  autoFocus: PropTypes.bool,
  ref: PropTypes.object,
};

ALENInputBalance.defaultProps = {
  type:'number',
  maxWidth:300,
  maxHeight:5,
  rows:1,
  onChange: () => console.log('onChange'),
  height:37,
  disabled: false,
  placeholder: "",
  dark:false,
  error: false,
  errorMessage: 'error',
  onTab: null,
  autoFocus: false,
  ref: React.createRef(),
};

export default withStyles(styles)(ALENInputBalance)
