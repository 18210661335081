import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN, ALENGREY, ALENBLUE, ALENGREENDARK} from 'utils/colors';
import ALENText from 'components/ALENText';
import SvgRight from 'components/icons/Aft';
import SvgLeft from 'components/icons/Bef';
import ALENInputBalance from 'components/ALENInputBalance';
import ALENSelect from 'components/ALENSelect';

function getIndexOccupants(weight) {
  let index = 0

  if(weight > 100) {
    index = -1
  }

  if(weight > 500) {
    index = -2
  }

  return index
}

function ALENBalanceRecap(props) {
  const t = props.t

  const densitiesList = []
  props.densityList.forEach((density) => {
    densitiesList.push({value: density, libelle: `${density}KG/L`})
  })

  return(
    <div
      style={{
        flexDirection:'column',
        display:'flex'
      }}>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
      }}>
        <div
          style={{
            height:30,
            width:300,
            backgroundColor:'transparent',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            display:'flex',
            alignItems:'center',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.weight')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            display:'flex',
            alignItems:'center',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.index')}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            display:'flex',
            alignItems:'center',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.occupants')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            borderLeftWidth:0,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
          <ALENInputBalance
            value={props.nb_occupants}
            placeholder={'nb occupants'}
            type={'number'}
            max={3}
            height={22}
            onChange={(val) => {
              props.onChangeOccupants(val.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.nb_occupants*100} kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${getIndexOccupants(props.nb_occupants*100)}`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'transparent',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            display:'flex',
            alignItems:'center',
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {t('balance.payload')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.payload.weight + props.nb_occupants*100} kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.payload.index + getIndexOccupants(props.nb_occupants*100)}`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
      }}>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            display:'flex',
            alignItems:'center',
            backgroundColor:'white',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.struct_max')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderLeftWidth:0,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'space-between',
            display:'flex',
            alignItems:'center',
            padding:12,
            backgroundColor:'white',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.dow')}
          </ALENText>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {'+'}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            borderLeftWidth:0,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
          <ALENInputBalance
            value={props.DOW}
            placeholder={'DOW'}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeDow(val.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
          <ALENInputBalance
            value={props.indexDOW}
            placeholder={'Index'}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeIndexDow(val.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.underload')}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {`${props.MZFW}kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            justifyContent:'space-between',
            display:'flex',
            alignItems:'center',
            padding:12,
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {t('balance.zfw')}
          </ALENText>
          <ALENText
            bold
            center
            color={'white'}>
            {'='}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            backgroundColor:'white',
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.payload.weight + props.DOW + props.nb_occupants*100}kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.payload.index + props.indexDOW + getIndexOccupants(props.nb_occupants*100)}`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.MZFW - (props.payload.weight + props.DOW + props.nb_occupants*100)} kg`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREEN,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            justifyContent:'flex-end',
            display:'flex',
            alignItems:'center',
            padding:12,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid'
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.wt_chg')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {``}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREEN,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            flexDirection:'row',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:12,
            paddingRight:12,
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {t('balance.density')}
          </ALENText>
          <ALENSelect
            noTop
            datas={densitiesList}
            minWidth={100}
            height={30}
            value={props.densitySelected}
            onChange={(event) => {
              props.onSelectDensity(event.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:2,
            justifyContent:'space-between',
            display:'flex',
            alignItems:'center',
            padding:12,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.takeoff')}
          </ALENText>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {'+'}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
          <ALENInputBalance
            value={props.takeOffFuel}
            placeholder={''}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeTakeOffFuel(val.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.indexTakeOffFuel}`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {`${props.MTOW}kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            justifyContent:'space-between',
            display:'flex',
            alignItems:'center',
            padding:12,
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {t('balance.tow')}
          </ALENText>
          <ALENText
            bold
            center
            color={'white'}>
            {'='}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.nb_occupants*100 + props.payload.weight + props.DOW + parseInt(props.takeOffFuel)}kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.payload.index + props.indexDOW + props.indexTakeOffFuel + getIndexOccupants(props.nb_occupants*100)}`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.MTOW - (props.nb_occupants*100 + props.payload.weight + props.DOW + parseInt(props.takeOffFuel))}kg`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREEN,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            justifyContent:'flex-end',
            display:'flex',
            alignItems:'center',
            padding:12,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid'
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.wt_chg')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {``}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREEN,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            flexDirection:'row',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:12,
            paddingRight:12,
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {t('balance.density')}
          </ALENText>
          <ALENSelect
            noTop
            datas={densitiesList}
            minWidth={100}
            height={30}
            value={props.densitySelected}
            onChange={(event) => {
              props.onSelectDensity(event.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:2,
            justifyContent:'space-between',
            display:'flex',
            alignItems:'center',
            padding:12,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.tripFuel')}
          </ALENText>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {'-'}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
          <ALENInputBalance
            value={props.tripFuel}
            placeholder={''}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeTripFuel(val.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.indexTripFuel}`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {`${props.MLW}kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREENDARK,
            justifyContent:'space-between',
            display:'flex',
            alignItems:'center',
            padding:12,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
          <ALENText
            bold
            center
            color={'white'}>
            {t('balance.lw')}
          </ALENText>
          <ALENText
            bold
            center
            color={'white'}>
            {'='}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.nb_occupants*100 + props.payload.weight + props.DOW + parseInt(props.takeOffFuel) - parseInt(props.tripFuel)}kg`}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'transparent',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {`${props.MLW - (props.nb_occupants*100 + props.payload.weight + props.DOW + parseInt(props.takeOffFuel) - parseInt(props.tripFuel))}kg`}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          marginTop:4,
      }}>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:ALENGREEN,
            borderTopLeftRadius:3,
            borderBottomLeftRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            backgroundColor:'white',
            justifyContent:'flex-end',
            display:'flex',
            alignItems:'center',
            padding:12,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          }}>
          <ALENText
            bold
            center
            color={ALENBLUE}>
            {t('balance.wt_chg')}
          </ALENText>
        </div>
        <div
          style={{
            height:30,
            width:150,
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderLeftWidth:0,
            borderStyle:'solid',
            borderTopRightRadius:3,
            borderBottomRightRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            backgroundColor:'white',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:100,
            marginLeft:8,
            backgroundColor:'transparent',
          }}>
        </div>
        <div
          style={{
            height:30,
            width:150,
            marginLeft:8,
            backgroundColor:'white',
            borderColor:ALENGREENDARK,
            borderWidth:2,
            borderStyle:'solid',
            borderRadius:3,
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <ALENText
            color={ALENGREEN}
            bold>
            {``}
          </ALENText>
        </div>
      </div>
    </div>
  )
}

export default ALENBalanceRecap
