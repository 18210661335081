import React, { useEffect } from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {getLoads} from 'api/methods/load';
import {getBalances} from 'api/methods/balance';
import {getTiedownsList} from 'api/methods/tiedowns';

import {ALENBLUE, ALENWHITE, ALENDARKBLUE, ALENGREEN, ALENBORDER, ALENOPACITY} from "utils/colors";

import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import SvgRightFull from 'components/icons/RightFull';
import SvgLoad from 'components/icons/Load';
import SvgAdd from 'components/icons/Add';
import SvgSettings from 'components/icons/Settings';
import SvgBottom from 'components/icons/Bottom';
import SvgTiedown from 'components/icons/Tiedown';
import SvgBalanceChart from 'components/icons/BalanceChart';

import StockManagement from 'screens/StockManagement';
import LoadsList from 'screens/LoadsList';
import UldsList from 'screens/UldsList';
import ParcelsList from 'screens/ParcelsList';
import AddParcels from 'screens/AddParcels';
import OptimizedList from 'screens/OptimizedList';
import CreateLoad from 'screens/CreateLoad';
import Loading from 'screens/Loading';
import TiedownCalculator from 'screens/TiedownCalculator';
import CreateTiedown from 'screens/CreateTiedown';
import TiedownsList from 'screens/TiedownsList';
import ManageAccount from 'screens/ManageAccount';
import CreateBalance from 'screens/CreateBalance';
import BalanceChart from 'screens/BalanceChart';
import BalanceList from 'screens/BalanceList';

import logo from 'assets/logo/logoSymbolTypeWhite.png';

const VERSION = 1;

const drawerWidth = 235;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:ALENDARKBLUE,
    boxShadow: '1px 0px 1px '+ALENBORDER,
    overflowX:'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ALENDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [loadsOpen, setLoadsOpen] = React.useState(false);
  const [tiedownsOpen, setTiedownsOpen] = React.useState(false);
  const [balanceOpen, setBalanceOpen] = React.useState(false);

  useEffect(() => {
    if(props.userReducer.logged && props.userReducer.version === VERSION) {
      if(props.userReducer.company.modules.findIndex((module) => module.libelle === "OPTIMISATION_ULD") !== -1) {
        getLoads(props.userReducer.token).then((response) => {
          if(response.status === 200 && response.ok) {
            const newLoads = []
            response.data.forEach((load) => {
              newLoads.push({
                ...load,
                nbParcels: load.parcels.length
              })
            })
            props.updateLoads(newLoads)
          }
        })
      }

      if(props.userReducer.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1) {
        getTiedownsList(props.userReducer.token).then((response) => {
          if(response.status === 200 && response.ok) {
            props.updateTiedowns(response.data)
          }
        })
      }

      if(props.userReducer.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1) {
        getBalances(props.userReducer.token).then((response) => {
          if(response.status === 200 && response.ok) {
            props.updateBalances(response.data)
          }
        })
      }
    }
  }, []);

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLoadsToggle = () => {
    setLoadsOpen(!loadsOpen);
  }

  const handleTiedownToggle = () => {
    setTiedownsOpen(!tiedownsOpen);
  }

  const handleBalanceToggle = () => {
    setBalanceOpen(!balanceOpen)
  }

  const { t, i18n } = props;

  const drawer = (
    <>
      {(!props.userReducer.logged || props.userReducer.version !== VERSION) ?
        <Redirect to="/"/>
      :
        <div style={{display:'flex', flexDirection:'column', flex:1}}>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <img src={logo} style={{width:191, height:'auto', marginTop:26, marginBottom:8}}/>
          </div>
          {/*<div style={{margin:16}}>
            <ALENInput
              label={""}
              placeholder={t('commons.searchLabel')}
              dark
              type={"search"}
            />
          </div>*/}
          <div style={{height:24}}/>
          {props.userReducer.company.modules.findIndex((module) => module.libelle === "OPTIMISATION_ULD") !== -1 &&
          <>
            <div style={{height:8}}/>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center',
                  padding:4,
                  backgroundColor:(props.location.pathname.search("/load") !== -1 || props.location.pathname.search("/createLoad") !== -1) ? 'rgba(255,255,255,0.15)' : 'transparent',
                }}>
                <div style={{width:4, height:33, backgroundColor:'transparent', borderRadius:24}}/>
                <div
                  onClick={() => handleLoadsToggle()}
                  style={{display:'flex', flexDirection:'column', justifyContent:'center',  marginLeft:11, cursor:'pointer'}}>
                  {loadsOpen ?
                    <SvgBottom
                      style={{width:20, height:20, marginRight:6}}
                      fill={ALENOPACITY}
                    />
                    :
                    <SvgRightFull
                      style={{width:20, height:20, marginRight:6}}
                      fill={ALENOPACITY}
                    />
                  }
                </div>

                <div onClick={() => handleLoadsToggle()} style={{display:'flex', flexDirection:'row', flex:1, cursor:"pointer"}}>
                <SvgLoad
                  style={{width:22, height:22, marginRight:9.75, marginTop:-1}}
                  fill={ALENOPACITY}
                />
                <ALENText bold={(props.location.pathname.search("/load") !== -1 || props.location.pathname.search("/createLoad") !== -1)} color={ALENWHITE}>{t('commons.Loads')}</ALENText>
                </div>
              </div>
            {loadsOpen &&
              <>
              <Link to={"/createLoad"} style={{ textDecoration: 'none' }}>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    cursor:'pointer',
                    alignItems:'center',
                    padding:4,
                    backgroundColor:props.location.pathname === "/createLoad" ? 'rgba(255,255,255,0.10)' : 'transparent',
                  }}>
                  <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/createLoad" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                  <div
                    style={{
                      marginLeft:40,
                    }}>
                    <ALENText bold={props.location.pathname === "/createLoad"} color={ALENWHITE}>{t('commons.newLoads')}</ALENText>
                  </div>
                </div>
              </Link>
              <Link to={"/loads"} style={{ textDecoration: 'none' }}>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    cursor:'pointer',
                    alignItems:'center',
                    padding:4,
                    backgroundColor:props.location.pathname === "/loads" ? 'rgba(255,255,255,0.10)' : 'transparent',
                  }}>
                  <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/loads" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                  <div
                    style={{
                      marginLeft:40,
                    }}>
                    <ALENText bold={props.location.pathname === "/loads" } color={ALENWHITE}>{t('commons.loadsList')}</ALENText>
                  </div>
                </div>
              </Link>
              </>
            }
          </>}
          {props.userReducer.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1 &&
          <>
            <div style={{height:8}}/>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                padding:4,
                backgroundColor:props.location.pathname.search("/tiedowns") !== -1 ? 'rgba(255,255,255,0.15)' : 'transparent',
              }}>
              <div style={{width:4, height:33, backgroundColor:'transparent', borderRadius:24}}/>
              <div
                onClick={() => handleTiedownToggle()}
                style={{display:'flex', flexDirection:'column', justifyContent:'center',  marginLeft:11, cursor:'pointer'}}>
                {tiedownsOpen ?
                  <SvgBottom
                    style={{width:20, height:20, marginRight:6}}
                    fill={ALENOPACITY}
                  />
                  :
                  <SvgRightFull
                    style={{width:20, height:20, marginRight:6}}
                    fill={ALENOPACITY}
                  />
                }
              </div>
              <div onClick={() => handleTiedownToggle()} style={{display:'flex', flexDirection:'row', flex:1, cursor:"pointer"}}>
              <SvgTiedown
                style={{width:22, height:22, marginRight:9.75, marginTop:-1}}
                fill={ALENOPACITY}
              />
              <ALENText bold={props.location.pathname.search("/tiedowns") !== -1} color={ALENWHITE}>{t('commons.tiedowns')}</ALENText>
              </div>
            </div>
            {tiedownsOpen &&
              <>
            <Link to={"/tiedowns/add"} style={{ textDecoration: 'none' }}>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  cursor:'pointer',
                  alignItems:'center',
                  padding:4,
                  backgroundColor:props.location.pathname.search("/tiedowns/add") !== -1 ? 'rgba(255,255,255,0.10)' : 'transparent',
                }}>
                <div style={{width:4, height:33, backgroundColor:props.location.pathname.search("/tiedowns/add") !== -1  ? ALENGREEN : 'transparent', borderRadius:24}}/>
                <div
                  style={{
                    marginLeft:40,
                  }}>
                  <ALENText bold={props.location.pathname.search("/tiedowns/add") !== -1} color={ALENWHITE}>{t('commons.newTiedown')}</ALENText>
                </div>
              </div>
            </Link>
            <Link to={"/tiedowns"} style={{ textDecoration: 'none' }}>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  cursor:'pointer',
                  alignItems:'center',
                  padding:4,
                  backgroundColor:props.location.pathname === "/tiedowns" ? 'rgba(255,255,255,0.10)' : 'transparent',
                }}>
                <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/tiedowns" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                <div
                  style={{
                    marginLeft:40,
                  }}>
                  <ALENText bold={props.location.pathname === "/tiedowns" } color={ALENWHITE}>{t('commons.tiedownsList')}</ALENText>
                </div>
              </div>
            </Link>
            </>
          }
          </>}
          {props.userReducer.company.modules.findIndex((module) => module.libelle === "LOADSHEET") !== -1 &&
          <>
            <div style={{height:8}}/>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                padding:4,
                backgroundColor:props.location.pathname.search("/balance") !== -1 ? 'rgba(255,255,255,0.15)' : 'transparent',
              }}>
              <div style={{width:4, height:33, backgroundColor:'transparent', borderRadius:24}}/>
              <div
                onClick={() => handleBalanceToggle()}
                style={{display:'flex', flexDirection:'column', justifyContent:'center',  marginLeft:11, cursor:'pointer'}}>
                {balanceOpen ?
                  <SvgBottom
                    style={{width:20, height:20, marginRight:6}}
                    fill={ALENOPACITY}
                  />
                  :
                  <SvgRightFull
                    style={{width:20, height:20, marginRight:6}}
                    fill={ALENOPACITY}
                  />
                }
              </div>
              <div onClick={() => handleBalanceToggle()} style={{display:'flex', flexDirection:'row', flex:1, cursor:"pointer"}}>
              <SvgBalanceChart
                style={{width:22, height:22, marginRight:9.75, marginTop:-1}}
                fill={ALENOPACITY}
              />
              <ALENText bold={props.location.pathname.search("/balance") !== -1} color={ALENWHITE}>{t('commons.balance')}</ALENText>
              </div>
            </div>
            {balanceOpen &&
              <>
            <Link to={"/balance/add"} style={{ textDecoration: 'none' }}>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  cursor:'pointer',
                  alignItems:'center',
                  padding:4,
                  backgroundColor:props.location.pathname.search("/balance/add") !== -1 ? 'rgba(255,255,255,0.10)' : 'transparent',
                }}>
                <div style={{width:4, height:33, backgroundColor:props.location.pathname.search("/balance/add") !== -1  ? ALENGREEN : 'transparent', borderRadius:24}}/>
                <div
                  style={{
                    marginLeft:40,
                  }}>
                  <ALENText bold={props.location.pathname.search("/balance/add") !== -1} color={ALENWHITE}>{t('commons.newBalance')}</ALENText>
                </div>
              </div>
            </Link>
            <Link to={"/balance"} style={{ textDecoration: 'none' }}>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  cursor:'pointer',
                  alignItems:'center',
                  padding:4,
                  backgroundColor:props.location.pathname === "/balance" ? 'rgba(255,255,255,0.10)' : 'transparent',
                }}>
                <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/balance" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                <div
                  style={{
                    marginLeft:40,
                  }}>
                  <ALENText bold={props.location.pathname === "/balance" } color={ALENWHITE}>{t('commons.balanceList')}</ALENText>
                </div>
              </div>
            </Link>
            </>
          }
          </>}
          <div style={{display:'flex', flex:1}}/>
          <div style={{marginBottom:31.75}}>


            <div style={{height:8}}/>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center',
                  padding:4,
                  backgroundColor:(props.location.pathname.search("/account") !== -1 || props.location.pathname.search("/account") !== -1) ? 'rgba(255,255,255,0.15)' : 'transparent',
                }}>
                <div style={{width:4, height:33, backgroundColor:'transparent', borderRadius:24}}/>
                <div
                  onClick={() => handleSettingsToggle(!settingsOpen)}
                  style={{display:'flex', flexDirection:'column', justifyContent:'center',  marginLeft:11, cursor:'pointer'}}>
                  {settingsOpen ?
                    <SvgBottom
                      style={{width:20, height:20, marginRight:6}}
                      fill={ALENOPACITY}
                    />
                    :
                    <SvgRightFull
                      style={{width:20, height:20, marginRight:6}}
                      fill={ALENOPACITY}
                    />
                  }
                </div>

                <div onClick={() => handleSettingsToggle(!settingsOpen)} style={{display:'flex', flexDirection:'row', flex:1, cursor:"pointer"}}>
                <SvgSettings
                  style={{width:22, height:22, marginRight:9.75, marginTop:-1}}
                  fill={ALENOPACITY}
                />
                <ALENText bold={(props.location.pathname.search("/account") !== -1 || props.location.pathname.search("/account") !== -1)} color={ALENWHITE}>{t('commons.settings')}</ALENText>
                </div>
              </div>

            {settingsOpen &&
              <>
                {/*<Link to="/stocks" style={{ textDecoration: 'none' }}>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      cursor:'pointer',
                      backgroundColor:props.location.pathname === "/stocks" ? 'rgba(255,255,255,0.15)' : 'transparent',
                      padding:4,
                      marginTop:13.75,
                    }}>
                    <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/stocks" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                    <div style={{flexDirection:'column', display:'flex', justifyContent:'center', marginLeft:30}}>
                      <ALENText bold={props.location.pathname === "/stocks"} color={ALENWHITE}>{t('commons.stockMenu')}</ALENText>
                    </div>
                  </div>
                </Link>*/}
                <Link to="/account" style={{ textDecoration: 'none' }}>
                  <div
                    style={{
                      display:'flex',
                      flexDirection:'row',
                      cursor:'pointer',
                      alignItems:'center',
                      padding:4,
                      backgroundColor:props.location.pathname === "/account" ? 'rgba(255,255,255,0.10)' : 'transparent',
                    }}>
                    <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/account" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                    <div
                      style={{
                        marginLeft:40,
                      }}>
                      <ALENText bold={props.location.pathname === "/account" } color={ALENWHITE}>{t('commons.account')}</ALENText>
                    </div>
                  </div>
                </Link>

                <div
                  onClick={() => props.logout()}
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    cursor:'pointer',
                    alignItems:'center',
                    padding:4,
                    backgroundColor:props.location.pathname === "/logout" ? 'rgba(255,255,255,0.10)' : 'transparent',
                  }}>
                  <div style={{width:4, height:33, backgroundColor:props.location.pathname === "/logout" ? ALENGREEN : 'transparent', borderRadius:24}}/>
                  <div
                    style={{
                      marginLeft:40,
                    }}>
                    <ALENText bold={props.location.pathname === "/logout" } color={ALENWHITE}>{t('commons.logout')}</ALENText>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      }
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  if(props.location.pathname !== "/") {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <Route exact path="/account" component={ManageAccount}/>
        {props.userReducer.logged && props.userReducer.company.modules.findIndex((module) => module.libelle === "OPTIMISATION_ULD") !== -1 &&
          <>
            <Route exact path="/stocks" component={StockManagement}/>
            <Route exact path="/loads" component={LoadsList}/>
            <Route exact path="/loads/:id" component={ParcelsList}/>
            <Route exact path="/loads/:id/add" component={AddParcels}/>
            <Route exact path="/loads/optimizedList/:id" component={OptimizedList}/>
            <Route exact path="/createLoad" component={CreateLoad}/>
            <Route exact path="/loading/:load/:uld/:parcel/:uldStock" component={Loading}/>
            <Route exact path="/ulds/:id" component={UldsList}/>
          </>
        }
        {props.userReducer.logged && props.userReducer.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1 &&
        <>
          <Route exact path="/tiedowns/calculate/:id" component={TiedownCalculator}/>
          <Route exact path="/tiedowns/add" component={CreateTiedown}/>
          <Route exact path="/tiedowns" component={TiedownsList}/>
        </>
        }
        {props.userReducer.logged && props.userReducer.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1 &&
        <>
          <Route exact path="/balance/add" component={CreateBalance}/>
          <Route exact path="/balanceChart/:id" component={BalanceChart}/>
          <Route exact path="/balance" component={BalanceList}/>
        </>
        }
        {props.children}
      </div>
    );
  } else {
    return (
      <>
        {props.children}
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoads: (loadsList) => {
      dispatch({type: "UPDATELOADS", data: loadsList})
    },
    updateTiedowns: (tiedownsList) => {
      dispatch({type : "UPDATETIEDOWNS", data: tiedownsList})
    },
    updateBalances: (balanceList) => {
      dispatch({type: "UPDATEBALANCES", data: balanceList})
    },
    logout: () => {
      dispatch({type: "LOGOUT"})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
    tiedownsReducer: state.tiedownsReducer,
  }
}


ALENDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ALENDrawer))
