import React, {Component} from 'react';
import HomeView from './HomeView'

class HomeContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  render()
  {
    return(
      <HomeView
      />
    )
  }
}

export default HomeContainer
