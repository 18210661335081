import React, {Component} from 'react';
import BalanceChartView from './BalanceChartView';
import { connect } from 'react-redux';
import { addParcel } from 'api/methods/parcel';
import { getAircraftList, addLoad, addUlds } from 'api/methods/load';
import { getBalance, updateBalance } from 'api/methods/balance';
import {getUlds, addUldStock, getUldStock, updateUldStock} from 'api/methods/stock';
import {DATAS, DEFAULTVALUES} from 'utils/balanceChart/boeing-747-400ERF';

class BalanceChartContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: null,
      nb_occupants: 1,
      tripFuel: 0,
      indexTripFuel: 0,
      takeOffFuel: 0,
      indexTakeOffFuel: 0,
      densitySelected: DATAS.fuel_densities[0],
      DOW: 158854,
      indexDOW: 69,
      ZFW: 0,
      fuelDensity: 0.76,
      aircraftList: [],
      datas: {
        A1: {
          right: {
            id: 6,
            dest : '',
            WT: 0,
            size_code: 'A',
          },
        },
        A2 : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
        },
        B1 : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
        },
        C : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        D : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        E : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        F : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        G : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        H : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        J : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        K : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        L : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        M : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        P : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        Q : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'Y',
          },
          left: {
            id: '',
            dest : '',
            WT: 1000,
            size_code: 'Y',
          }
        },
        R : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        S : {
          right: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          },
          left: {
            id: '',
            dest : '',
            WT: 0,
            size_code: 'A',
          }
        },
        T : {
          right: {
            id: '',
            dest : '',
            WT: 1000,
            size_code: 'A',
          }
        },
        OPT11: {
          right: {
            id: '11R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '11L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT12: {
          right: {
            id: '12R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '12L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT13: {
          right: {
            id: '13R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '13L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT21: {
          right: {
            id: '21R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '21L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT22: {
          right: {
            id: '22R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '22L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT23: {
          right: {
            id: '23R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '23L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT24: {
          right: {
            id: '24R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '24L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT25: {
          right: {
            id: '25R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '25L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT31: {
          right: {
            id: '31R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '31L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT32: {
          right: {
            id: '32R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '32L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT33: {
          right: {
            id: '33R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '33L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT41: {
          right: {
            id: '41R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '41L',
            dest: 'TLS',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT42: {
          right: {
            id: '42R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '42L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT43: {
          right: {
            id: '43R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '43L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT44: {
          right: {
            id: '44R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '44L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        OPT45: {
          right: {
            id: '45R',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
          left: {
            id: '45L',
            dest: '',
            WT: 0,
            size_code: 'K',
          },
        },
        BISOPT11P: {
          id: '11P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT12P: {
          id: '12P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT21P: {
          id: '21P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT22P: {
          id: '22P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT23P: {
          id: '23P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT31P: {
          id: '31P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT32P: {
          id: '32P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT41P: {
          id: '41P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT42P: {
          id: '42P',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT51: {
          id: '51',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT52: {
          id: '52',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT53: {
          id: '53',
          dest:'',
          WT: 0,
          size_code: 'M',
        },
        BISOPT54: {
          id: '54',
          dest:'TLS',
          WT: 0,
          size_code: 'M',
        },
      }
    }
  }

  onChangeTakeOffFuel = (newValue) => {
    let newIndex = 0
    DATAS.fuel_index.forEach((indexValues) => {
      if(newValue >= indexValues.value) {
        newIndex = indexValues[`${this.state.densitySelected}`]
      }
      this.setState({takeOffFuel: parseInt(newValue), indexTakeOffFuel: parseInt(newIndex)})
    })
  }

  onChangeTripFuel = (newValue) => {
    let newIndex = 0
    DATAS.fuel_index.forEach((indexValues) => {
      if(newValue >= indexValues.value) {
        newIndex = indexValues[`${this.state.densitySelected}`]
      }
      this.setState({tripFuel: parseInt(newValue), indexTripFuel: parseInt(newIndex)})
    })
  }


  onChangeValueBox = (id, field, side, value) => {
    let newDatas = {
      ...this.state.datas,
    }

    if(side !== 'none') {
      newDatas[id][side][field] = (field === 'WT' ? parseInt(value, 10) : value)
    } else {
      newDatas[id][field] = (field === 'WT' ? parseInt(value, 10) : value)
    }

    this.setState({datas: newDatas})
  }

  onSelectDensity = (newValue) => {
    let newIndex = 0
    DATAS.fuel_index.forEach((indexValues) => {
      if(this.state.takeOffFuel >= indexValues.value) {
        newIndex = indexValues[`${newValue}`]
      }

      this.setState({indexTakeOffFuel: newIndex})
    })

    DATAS.fuel_index.forEach((indexValues) => {
      if(this.state.tripFuel >= indexValues.value) {
        newIndex = indexValues[`${newValue}`]
      }

      this.setState({indexTripFuel: newIndex})
    })
    this.setState({densitySelected: newValue})
  }

  addBalance = (balance) => {
    const newBalance = {
      name: balance.loadId,
      aircraft_id: balance.aircraft,
      aircraft_registration : balance.aircraftRegistration,
      custom_reference : balance.customReference,
      point_of_loading : balance.pointOfLoading,
      point_of_unloading : balance.pointOfUnloading,
      flight_number : balance.flightNumber,
      flight_at : balance.flightAt,
      owner_operator : balance.ownerOperator,
      de_consolidator : balance.deConsolidator,
      consolidator : balance.consolidator,
    }

    window.location.href='/balance/demo'
  }

  getPdf = () => {
    fetch(process.env.REACT_APP_API_URL+"balance/pdf/"+this.props.match.params.id, {
            method: 'GET',
            headers: new Headers({
                "X-token-api": this.props.userReducer.token
            })
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `loadsheet_${this.state.name}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        })

        /*getPdf(this.props.userReducer.token, this.state.load.id).then((response) => {
            if(response.status === 200 && response.ok) {
              var win = window.open(response.data.url, '_blank');
              win.focus();
            }
          })
        }*/
    }

  updateBalance = (picture) => {
    this.setState({name: null})
    const newBalance = {
      data: {
        ...this.state.datas
      },
      picture: picture,
      dow: this.state.DOW,
      index_dow : this.state.indexDOW,
      fuel_density : this.state.densitySelected,
      trip_fuel :  this.state.tripFuel,
      take_off_fuel : this.state.takeOffFuel,
      nb_occupants: this.state.nb_occupants,
    }

    updateBalance(this.props.userReducer.token, this.props.match.params.id, newBalance).then((response) => {
      let datasApi = {...response.data.data}
      getBalance(this.props.userReducer.token, this.props.match.params.id).then((response) => {
        let datasApi = {...response.data.data}
        for(let dataVal in datasApi) {
          if(dataVal.substr(0,3) !== 'BIS') {
            let newData = {
              ...datasApi[dataVal],
              right: {
                ...datasApi[dataVal].right,
                WT: parseInt(datasApi[dataVal].right.WT)
              }
            }

            if(datasApi[dataVal].left !== undefined) {
              newData = {
                ...newData,
                left: {
                  ...datasApi[dataVal].left,
                  WT: parseInt(datasApi[dataVal].left.WT)
                }
              }
            }

            if(datasApi[dataVal].WT !== undefined) {
              datasApi[dataVal] = {
                ...newData,
                WT: parseInt(datasApi[dataVal].WT)
              }
            }
          }
        }
        this.setState({
          DOW : parseInt(response.data.dow),
          indexDOW : parseInt(response.data.index_dow),
          densitySelected : response.data.fuel_density,
          tripFuel : parseInt(response.data.trip_fuel),
          takeOffFuel : parseInt(response.data.take_off_fuel),
          name: response.data.name,
          nb_occupants: response.data.nb_occupants !== undefined ? response.data.nb_occupants : 1,
          datas: {...DEFAULTVALUES, ...datasApi}
        })

        this.getPdf()
      })
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.id !== this.props.match.params.id){
      getBalance(this.props.userReducer.token, this.props.match.params.id).then((response) => {
        let datasApi = {...response.data.data}
        for(let dataVal in datasApi) {
          datasApi[dataVal] = {
            ...datasApi[dataVal],
            WT: parseInt(datasApi[dataVal].WT)
          }
        }
        this.setState({name: response.data.name, datas: datasApi, nb_occupants: response.data.nb_occupants !== undefined ? response.data.nb_occupants : 1})
      })
    }
  }

  componentDidMount() {
    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })

    getBalance(this.props.userReducer.token, this.props.match.params.id).then((response) => {
      let datasApi = {...response.data.data}
      for(let dataVal in datasApi) {
        if(dataVal.substr(0,3) !== 'BIS') {
          let newData = {
            ...datasApi[dataVal],
            right: {
              ...datasApi[dataVal].right,
              WT: parseInt(datasApi[dataVal].right.WT)
            }
          }

          if(datasApi[dataVal].left !== undefined) {
            newData = {
              ...newData,
              left: {
                ...datasApi[dataVal].left,
                WT: parseInt(datasApi[dataVal].left.WT)
              }
            }
          }

          if(datasApi[dataVal].WT !== undefined) {
            datasApi[dataVal] = {
              ...newData,
              WT: parseInt(datasApi[dataVal].WT)
            }
          }
        }
      }
      this.setState({
        DOW : parseInt(response.data.dow),
        indexDOW : parseInt(response.data.index_dow),
        densitySelected : response.data.fuel_density,
        tripFuel : parseInt(response.data.trip_fuel),
        takeOffFuel : parseInt(response.data.take_off_fuel),
        name: response.data.name, datas: {...DEFAULTVALUES, ...datasApi}},
        () => {
          let newIndex = 0
          DATAS.fuel_index.forEach((indexValues) => {
            if(parseInt(response.data.take_off_fuel) >= indexValues.value) {
              newIndex = indexValues[`${this.state.densitySelected}`]
            }
            this.setState({indexTakeOffFuel: parseInt(newIndex)})
          })
          newIndex = 0
          DATAS.fuel_index.forEach((indexValues) => {
            if(response.data.trip_fuel >= indexValues.value) {
              newIndex = indexValues[`${this.state.densitySelected}`]
            }
            this.setState({indexTripFuel: parseInt(newIndex)})
          })
        })
    })
  }

  getWeightOpt = (id) => {
    let weightOpt = 0
    DATAS.keysZone[id].forEach((idZone) => {
      weightOpt = weightOpt + parseInt(this.state.datas[`OPT${idZone}`].right.WT) + parseInt(this.state.datas[`OPT${idZone}`].left ? this.state.datas[`OPT${idZone}`].left.WT : 0)
    })

    DATAS.keysZone2[id].forEach((idZone) => {
      weightOpt = weightOpt + parseInt(this.state.datas[`BISOPT${idZone}`].WT)
    })


    return(parseInt(weightOpt))
  }

  swap1 = (uld1, uld2) => {
    let datas = this.state.datas
    const uld1Bkp = datas[uld1.uld.id]

    datas[uld1.uld.id] = {
      ...datas[uld1.uld.id],
      right: {
        id: datas[uld2.uld.id].right.id,
        dest : datas[uld2.uld.id].right.dest,
        WT: datas[uld2.uld.id].right.WT,
        size_code: datas[uld2.uld.id].right.size_code,
      },
    }

    datas[uld2.uld.id] = {
      ...datas[uld2.uld.id],
      right: {
        id: uld1Bkp.right.id,
        dest : uld1Bkp.right.dest,
        WT: uld1Bkp.right.WT,
        size_code: uld1Bkp.right.size_code,
      },
    }

    this.setState({datas: datas})
  }

  swap2 = (uld1, uld2) => {
    let datas = this.state.datas
    const uld1Bkp = datas[uld1.uld.id]

    datas[uld1.uld.id] = {
      ...datas[uld1.uld.id],
      left: {
        id: datas[uld2.uld.id].left.id,
        dest : datas[uld2.uld.id].left.dest,
        WT: datas[uld2.uld.id].left.WT,
        size_code: datas[uld2.uld.id].left.size_code,
      },
    }

    datas[uld2.uld.id] = {
      ...datas[uld2.uld.id],
      left: {
        id: uld1Bkp.left.id,
        dest : uld1Bkp.left.dest,
        WT: uld1Bkp.left.WT,
        size_code: uld1Bkp.left.size_code,
      },
    }

    this.setState({datas: datas})
  }

  swap3 = (uld1, uld2) => {
    let datas = this.state.datas
    const uld1Bkp = datas[`OPT${uld1.uld.id.substr(0,2)}`]

    datas[`OPT${uld1.uld.id.substr(0,2)}`] = {
      ...datas[`OPT${uld1.uld.id.substr(0,2)}`],
      right: {
        id: datas[`OPT${uld2.uld.id.substr(0,2)}`].right.id,
        dest : datas[`OPT${uld2.uld.id.substr(0,2)}`].right.dest,
        WT: datas[`OPT${uld2.uld.id.substr(0,2)}`].right.WT,
        size_code: datas[`OPT${uld2.uld.id.substr(0,2)}`].right.size_code,
      },
    }

    datas[`OPT${uld2.uld.id.substr(0,2)}`] = {
      ...datas[`OPT${uld2.uld.id.substr(0,2)}`],
      right: {
        id: uld1Bkp.right.id,
        dest : uld1Bkp.right.dest,
        WT: uld1Bkp.right.WT,
        size_code: uld1Bkp.right.size_code,
      },
    }

    this.setState({datas: datas})
  }

  swap4 = (uld1, uld2) => {
    let datas = this.state.datas
    const uld1Bkp = datas[`OPT${uld1.uld.id2.substr(0,2)}`]

    datas[`OPT${uld1.uld.id2.substr(0,2)}`] = {
      ...datas[`OPT${uld1.uld.id2.substr(0,2)}`],
      left: {
        id: datas[`OPT${uld2.uld.id2.substr(0,2)}`].left.id,
        dest : datas[`OPT${uld2.uld.id2.substr(0,2)}`].left.dest,
        WT: datas[`OPT${uld2.uld.id2.substr(0,2)}`].left.WT,
        size_code: datas[`OPT${uld2.uld.id2.substr(0,2)}`].left.size_code,
      },
    }

    datas[`OPT${uld2.uld.id2.substr(0,2)}`] = {
      ...datas[`OPT${uld2.uld.id2.substr(0,2)}`],
      left: {
        id: uld1Bkp.left.id,
        dest : uld1Bkp.left.dest,
        WT: uld1Bkp.left.WT,
        size_code: uld1Bkp.left.size_code,
      },
    }

    this.setState({datas: datas})
  }

  swap5 = (uld1, uld2) => {
    console.log('uld1', uld1)
    console.log('uld2', uld2)
    let datas = this.state.datas
    const uld1Bkp = datas[`BISOPT${uld1.uld.id}`]

    datas[`BISOPT${uld1.uld.id}`] = {
      ...datas[`BISOPT${uld1.uld.id}`],
      id: datas[`BISOPT${uld2.uld.id}`].id,
      dest : datas[`BISOPT${uld2.uld.id}`].dest,
      WT: datas[`BISOPT${uld2.uld.id}`].WT,
      size_code: datas[`BISOPT${uld2.uld.id}`].size_code,
    }

    datas[`BISOPT${uld2.uld.id}`] = {
      ...datas[`BISOPT${uld2.uld.id}`],
      id: uld1Bkp.id,
      dest : uld1Bkp.dest,
      WT: uld1Bkp.WT,
      size_code: uld1Bkp.size_code,
    }

    this.setState({datas: datas})
  }

  render() {
    const idLoad = this.props.match.params.id
    return(
      <>
        <BalanceChartView
          getWeightOpt={this.getWeightOpt}
          isLoading={(this.state.name === null)}
          name={this.state.name}
          datas={this.state.datas}
          onChangeValueBox={this.onChangeValueBox}
          indexValues={this.state.indexValues}
          DOW={this.state.DOW}
          onChangeDow={(newDow) => this.setState({DOW: parseInt(newDow)})}
          indexDOW={this.state.indexDOW}
          onChangeIndexDow={(newIndex) => this.setState({indexDOW: parseInt(newIndex)})}
          densitySelected={this.state.densitySelected}
          onSelectDensity={this.onSelectDensity}
          onChangeTakeOffFuel={this.onChangeTakeOffFuel}
          takeOffFuel={this.state.takeOffFuel}
          indexTakeOffFuel={this.state.indexTakeOffFuel}
          onChangeTripFuel={this.onChangeTripFuel}
          tripFuel={this.state.tripFuel}
          indexTripFuel={this.state.indexTripFuel}
          update={this.updateBalance}
          swap1={this.swap1}
          swap2={this.swap2}
          swap3={this.swap3}
          swap4={this.swap4}
          swap5={this.swap5}
          onChangeOccupants={(newNb_occupants) => this.setState({nb_occupants: parseInt(newNb_occupants)})}
          nb_occupants={this.state.nb_occupants}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUldStock: (uld) => {
      dispatch({type: "ADDULDSTOCK", data: uld})
    },
    deleteUldStock: (id) => {
      dispatch({type: "DELETEULDSTOCK", data: {id: id}})
    },
    updateUldStock: (data) => {
      dispatch({type:"UPDATEULDSTOCK", data: data})
    },
    refreshUldStock: (data) => {
      dispatch({type:"REFRESHULDSTOCK", data: data})
    },
    updateLoads: (loadsList) => {
      dispatch({type: "UPDATELOADS", data: loadsList})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
    stockULDSReducer: state.stockULDSReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceChartContainer)
