import React from 'react';
import {ALENDISABLEDDARK, BLUEINFO2, ALENGREEN, ALENRED, ALENWHITE, ALENOPACITY2} from 'utils/colors';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import SvgCheck from 'components/icons/Check';

function ALENParcelStatus(props) {
  const t = props.t

  let bg = ALENDISABLEDDARK

  switch(parseInt(props.status, 10)) {
    case 0 :
      bg = ALENDISABLEDDARK
    break;
    case 10 :
      bg = BLUEINFO2
      break;
    case 20 :
      bg = ALENGREEN
      break;
    case 99 :
      bg= ALENRED
      break;
    default:
    break;
  }

  return(
    <>
    <div
      style={{
        backgroundColor:bg,
        padding:8,
        borderRadius:8,
        flexDirection:'row',
        display:'flex',
      }}>
      {props.status === "20" &&
        <div
          style={{
            width:18,
            height:18,
            marginRight:8,
            borderRadius:5,
            backgroundColor:ALENOPACITY2,
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'row',
            display:'flex',
          }}>
          <SvgCheck
            style={{
              height:15,
              width:15,
            }}
            fill={ALENWHITE}
            />
        </div>
      }
      <ALENText
        color={ALENWHITE}>
        {t('commons.status.parcel.'+props.status.toString())}
      </ALENText>
    </div>
    </>
  )
}

export default withTranslation()(ALENParcelStatus)
