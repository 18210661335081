import * as React from "react";

function SvgIsCrushable(props) {
  return (
    <svg width={73.951} height={75.09} viewBox={"0 0 73.951 75.09"} {...props}>
      <switch transform="translate(-8.684 -9.146)">
        <g>
          <path d="M8.684 21.472l.144 51.838 40.884 10.926.324-55.126zm28.667 48.599a.39.39 0 01-.035.146L19.664 65.85c-.017-.057-.034-.11-.034-.163 0-1.053 3.531-1.043 8.039-.02V57.52C13.99 54.744 16.514 37.295 16.516 37.176l6.878 1.481-2.489 5.807 2.698.667-1.587 5.159 7.772-5.852-3.83-.945 2.059-3.692 13.412 2.547c.002.12.47 17.041-12.116 15.581v8.145c4.507 1.209 8.038 2.948 8.038 3.997zM69.566 36.202l-.603-2.119-.995 3.001-.717.396-.445.246-.101-15.31-.329-.057-15.286 6.673.324 55.126L82.49 67.104l.145-51.839-12.253 5.347v15.141zM41.002 9.146l-10.946 3.892 39.583 6.366 12.382-5.354zM50.155 27.826l13.652-5.902-39.787-6.741-14.885 5.291z" />
        </g>
      </switch>
    </svg>
  );
}

export default SvgIsCrushable;
