import React from 'react';
import ALENText from 'components/ALENText';
import SvgSettings from 'components/icons/Settings';
import {FONTMINIBIG} from 'utils/fontSizes';
import {ALENGREY, ALENBLACK, ALENGREEN, ALENBG} from 'utils/colors';

function getRomanNumber(nb) {
  switch(nb) {
    case 1 :
      return "I"
      break;
    case 2 :
      return "II"
      break;
    case 3 :
      return "III"
      break;
    case 4 :
      return "IV"
      break;
    case 5 :
      return "V"
      break;
    case 6 :
      return "VI"
      break;
    case 7 :
      return "VII"
      break;
    case 8 :
      return "VIII"
      break;
    default:
      return nb
      break;
  }
}

function ALENStepHeaders(props) {
  const [mouseOverId, setMouseOverId] = React.useState(-1);

  return(
    <div
      style={{
        flexDirection:'row',
        display:'flex',
        flex:1,
        borderTopWidth:0,
        borderLeftWidth:0,
        borderRightWidth:0,
        borderBottomWidth:4,
        borderStyle: 'solid',
        borderColor: ALENBG,
        boxShadow: '0px 8px 32px rgba(18, 5, 80, 0.2)'
      }}>
      {props.steps.menus.map((step, index) => {
        return(
          <div
            onClick={() => window.location.href = step.link}
            onMouseLeave={() => setMouseOverId(-1)}
            onMouseOver={() => setMouseOverId(index)}
            style={{
              boxShadow: mouseOverId === index ? '0px 8px 32px rgba(18, 5, 80, 0.07)' : '0px 8px 32px rgba(18, 5, 80, 0)',
              cursor:'pointer',
              padding:20,
              flexDirection:'column',
              display:'flex',
              borderTopWidth:0,
              borderLeftWidth:0,
              borderRightWidth:0,
              borderBottomWidth:4,
              borderStyle: 'solid',
              borderColor: props.steps.selected === index ? ALENGREEN : 'transparent',
              marginBottom:-4,
            }}>
            <ALENText
              bold
              color={index === props.steps.selected ? ALENBLACK : ALENGREY}
              size={FONTMINIBIG}>
              {`${getRomanNumber(index + 1)}. ${step.label}`}
            </ALENText>
          </div>
        )
      })}
      <SvgSettings
        onClick={props.onEdit}
        style={{
          marginTop:17,
          right:48,
          position:'absolute',
          cursor:'pointer',
          width:32,
          height:32,
        }}
        fill={ALENGREY}
      />
    </div>
  )
}

export default ALENStepHeaders
