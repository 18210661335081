export const ALENYELLOW = "#E8A80F"
export const ALENBLUE = "#120550"
export const ALENDARKBLUE = "#0E043E"
export const ALENOPACITY = "rgba(255, 255, 255, 0.5)"
export const ALENOPACITY2 = "rgba(255, 255, 255, 0.1)"
export const ALENWHITE = "#FFFFFF"
export const ALENDISABLED = "#120550"
export const ALENDISABLEDDARK = "#807F87"
export const ALENBG = "#F1F0F7"
export const ALENBGLIGHT = "rgba(241, 240, 247, 0.2)"
export const ALENGREEN = "#4AD18A"
export const ALENBLACK = "#2C2A37"
export const ALENULTRABLACK = "#000000"
export const ALENBORDERINPUT = "#BFBFC3"
export const ALENLINK = "#736BB2"
export const ALENBORDER = "#E7E6ED"
export const ALENGREY = "#9693A3"
export const ALENRED = "#D60D0D"
export const TABLEBORDER = "#CCCCCF"
export const ALENSUCCESS = "#4CAF50"
export const BLUEINFO="#CFDBF2"
export const BLUEINFO2 = "#0F4BC0"
export const ALENGREENDARK = "#4AA674"

export const STATUS = {
  load: [
    "#807F87",
  ],
  parcel: [
    "#E7E6ED",
    "#D60D0D",
    "#0F4BC0",
    "#E8A80F",
    "#4CAF50",
    "#9693A3",
  ],
}
