const tiedowns = {
  "title" : "Tiedowns list",
  "mainTitle" : "TIEDOWNS",
  "create" : "Create",
  "list" : {
    "title" : "List of tiedowns",
  },
  "empty" : {
    "title" : {
      "1" : "You have no ",
      "2" : "TIEDOWN ",
      "3" : "declared",
    },
    "text" : "Please create your first tiedown to start your tiedown optimization !",
    "buttonLabel" : "Add your first tiedown!",
  },
}

export default tiedowns
