import React, {Component} from 'react';
import StockManagementView from './StockManagementView';
import { connect } from 'react-redux';
import ALENToast from 'components/ALENToast';
import { withTranslation } from 'react-i18next';
import {getUlds, addUldStock, getUldStock, updateUldStock} from 'api/methods/stock';

class StockManagementContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uldsBank: [],

      success: false,
      successMessage: '',

      isMainLoading: false,
      isAsyncLoading: false,
      isAddLoading: false,
      isUpdating: null,
    }
  }

  refresh = (loading) => {
    this.setState({isMainLoading: loading})
    getUlds(this.props.userReducer.token).then((response) => {
      this.setState({isMainLoading: false})
      if(response.status === 200 && response.ok) {
        this.setState({uldsBank: response.data})
        this.setState({isAsyncLoading: true})
        getUldStock(this.props.userReducer.token).then((response) => {
          this.setState({isAsyncLoading: false})
          this.setState({isAsyncLoading: true})
          if(response.status === 200 && response.ok) {
            const uldList = response.data
            let uldListRedux = []
            uldList.forEach((uld) => {
              this.state.uldsBank.forEach((uldBank) => {
                if(uld.uld.id === uldBank.id) {
                  let newUld = {
                    id: uldBank.id,
                    stock: uld.nbr,
                    name: uldBank.name,
                    ref: uldBank.ref,
                    description: uldBank.description,
                    picture: uldBank.picture,
                  }
                  uldListRedux.push(newUld)
                }
              })
            })
            this.props.refreshUldStock(uldListRedux)
            this.setState({isAsyncLoading: false})
          }
        })
      }
    })
  }

  componentDidMount() {
    this.refresh(true)
  }

  addUldStock = (uld) => {
    const data = {
      ...uld
    }
    this.setState({isAddLoading: true})
    addUldStock(this.props.userReducer.token, data).then((response) => {
      if(response.status === 200 && response.ok) {
        let id = response.data.uld.id
        this.state.uldsBank.forEach((uldBank) => {
          if(uldBank.id === id) {
            const uldRedux = {
              id: id,
              stock: response.data.nbr,
              name: uldBank.name,
              ref: uldBank.ref,
              description: uldBank.description,
              picture: uldBank.picture,
            }

            this.props.addUldStock(uldRedux)
            this.setState({isAddLoading: false})
          }
        })
      }
    })
  }

  deleteUldStock = (id) => {
    /*TODO APPEL API deleteuldstock*/
    this.props.deleteUldStock(id)
  }

  updateUldStock = (id, stock) => {
    this.setState({isUpdating: id})

    const data = {
      uld_id: id,
      nbr: stock
    }

    updateUldStock(this.props.userReducer.token, data).then((response) => {
      this.setState({isUpdating: -1})
      if(response.status === 200 && response.ok) {
        this.setState({isUpdating: id})
        console.log('response', response)
        const newData = {
          id: response.data.uld.id,
          stock: response.data.nbr,
        }
        this.props.updateUldStock(newData)
        this.refresh(false)
        this.setState({
          isUpdating: -1,
          success: true,
          successMessage: this.props.t('stockManagement.successMessage')
        })
      }
    })
  }

  render() {
    return(
      <>
        <StockManagementView
          uldList={this.props.stockULDSReducer.uldList}
          uldsBank={this.state.uldsBank}
          addUldStock={this.addUldStock}
          deleteUldStock={this.deleteUldStock}
          updateUldStock={this.updateUldStock}
          isMainLoading={this.state.isMainLoading}
          isAsyncLoading={this.state.isAsyncLoading}
          isAddLoading={this.state.isAddLoading}
          isUpdating={this.state.isUpdating}
        />
        <ALENToast
          message={this.state.successMessage}
          show={this.state.success}
          handleClose={() => this.setState({success: false})}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
    return {
      addUldStock: (uld) => {
        dispatch({type: "ADDULDSTOCK", data: uld})
      },
      deleteUldStock: (id) => {
        dispatch({type: "DELETEULDSTOCK", data: {id: id}})
      },
      updateUldStock: (data) => {
        dispatch({type:"UPDATEULDSTOCK", data: data})
      },
      refreshUldStock: (data) => {
        dispatch({type:"REFRESHULDSTOCK", data: data})
      }
    }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    stockULDSReducer: state.stockULDSReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StockManagementContainer))
