import React, {Component} from 'react';
import loading from 'assets/images/loading.svg';

function ALENLoading(props) {
  return(
    <div style={{flexDirection:'column', display:'flex', justifyContent:'center', flex:1}}>
      <img
        src={loading}
        style={{
          width:props.little ? 30 : props.big ? 130 : 60,
          height:props.little ? 30 : props.big ? 130 : 60,
          alignSelf:'center',
        }}/>
    </div>
  )
}

export default ALENLoading
