import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN, ALENGREY, ALENBLUE, ALENGREENDARK} from 'utils/colors';
import ALENText from 'components/ALENText';
import SvgRight from 'components/icons/Aft';
import SvgLeft from 'components/icons/Bef';
import ALENInputBalance from 'components/ALENInputBalance';
import ALENSelect from 'components/ALENSelect';

function getRMain(datas) {
  const value = datas['C'].right.WT + datas['D'].right.WT + datas['E'].right.WT + datas['F'].right.WT + datas['G'].right.WT + datas['H'].right.WT + datas['J'].right.WT + datas['K'].right.WT + datas['L'].right.WT + datas['M'].right.WT + datas['P'].right.WT + datas['Q'].right.WT + datas['R'].right.WT + datas['S'].right.WT
  return parseInt(value)
}

function getRLower(datas) {
  const value = datas['OPT11'].right.WT + datas['OPT12'].right.WT + datas['OPT13'].right.WT + datas['OPT21'].right.WT + datas['OPT22'].right.WT + datas['OPT23'].right.WT + datas['OPT24'].right.WT + datas['OPT25'].right.WT + datas['OPT31'].right.WT + datas['OPT32'].right.WT + datas['OPT33'].right.WT + datas['OPT41'].right.WT + datas['OPT42'].right.WT + datas['OPT43'].right.WT + datas['OPT44'].right.WT + datas['OPT45'].right.WT
  return parseInt(value)
}

function getRTotal(datas) {
  const value = getRMain(datas) + getRLower(datas)
  return parseInt(value)
}

function getLMain(datas) {
  const value = datas['C'].left.WT + datas['D'].left.WT + datas['E'].left.WT + datas['F'].left.WT + datas['G'].left.WT + datas['H'].left.WT + datas['J'].left.WT + datas['K'].left.WT + datas['L'].left.WT + datas['M'].left.WT + datas['P'].left.WT + datas['Q'].left.WT + datas['R'].left.WT + datas['S'].left.WT
  return parseInt(value)
}

function getLLower(datas) {
  const value = datas['OPT11'].left.WT + datas['OPT12'].left.WT + datas['OPT13'].left.WT + datas['OPT21'].left.WT + datas['OPT22'].left.WT + datas['OPT23'].left.WT + datas['OPT24'].left.WT + datas['OPT25'].left.WT + datas['OPT31'].left.WT + datas['OPT32'].left.WT + datas['OPT33'].left.WT + datas['OPT41'].left.WT + datas['OPT42'].left.WT + datas['OPT43'].left.WT + datas['OPT44'].left.WT + datas['OPT45'].left.WT
  return parseInt(value)
}

function getLTotal(datas) {
  const value = getLMain(datas) + getLLower(datas)
  return parseInt(value)
}

function getDiff(datas) {
  const value = getLTotal(datas) - getRTotal(datas)
  return (value > 0 ? value : value*-1)
}

function ALENBalanceImbalance(props) {
  const t = props.t

  return(
    <div
      style={{
        backgroundColor:'white',
        display:'flex',
        flexDirection:'column',
        borderColor:ALENGREENDARK,
        borderWidth:2,
        borderStyle:'solid',
        borderRadius:3,
        justifyContent:'center',
        alignItems:'center',
        width:500
      }}>
      <ALENText
        bold
        size={20}
        color={ALENGREENDARK}>{'LATERAL IMBALANCE'}</ALENText>
      <ALENText
        color={ALENGREENDARK}
        size={10}>{'(Bays A, A1, A2, B, B1 & T excluded)'}</ALENText>
      <div
        style={{
          display:'flex',
          justifyContent:'center',
          flexDirection:'row',
          width:'90%',
          margin:16,
        }}>
        <ALENText>{`R`}</ALENText>
        <div
          style={{
            height:30,
            flex:1,
            marginLeft:8,
            borderWidth:0,
            borderColor:'black',
            borderStyle:'solid',
            borderLeftWidth:0,
            borderRightWidth:0,
            borderTopWidth:0,
            borderBottomWidth:2,
          }}>
          <ALENText>{`${getRMain(props.datas)}`}</ALENText>
        </div>
        <ALENText>{`+`}</ALENText>
        <div
          style={{
            height:30,
            flex:1,
            marginLeft:8,
            borderWidth:0,
            borderColor:'black',
            borderStyle:'solid',
            borderLeftWidth:0,
            borderRightWidth:0,
            borderTopWidth:0,
            borderBottomWidth:2,
          }}>
          <ALENText>{`${getRLower(props.datas)}`}</ALENText>
        </div>
        <ALENText>{`=`}</ALENText>
        <div
          style={{
            height:30,
            flex:1,
            marginLeft:8,
            borderWidth:0,
            borderColor:'black',
            borderStyle:'solid',
            borderLeftWidth:0,
            borderRightWidth:0,
            borderTopWidth:0,
            borderBottomWidth:2,
          }}>
          <ALENText>{`${getRTotal(props.datas)}`}</ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          justifyContent:'center',
          flexDirection:'row',
          width:'90%',
          margin:16,
        }}>
        <ALENText>{`R`}</ALENText>
        <div
          style={{
            height:30,
            flex:1,
            marginLeft:8,
            borderWidth:0,
            borderColor:'black',
            borderStyle:'solid',
            borderLeftWidth:0,
            borderRightWidth:0,
            borderTopWidth:0,
            borderBottomWidth:2,
          }}>
          <ALENText>{`${getLMain(props.datas)}`}</ALENText>
        </div>
        <ALENText>{`+`}</ALENText>
        <div
          style={{
            height:30,
            flex:1,
            marginLeft:8,
            borderWidth:0,
            borderColor:'black',
            borderStyle:'solid',
            borderLeftWidth:0,
            borderRightWidth:0,
            borderTopWidth:0,
            borderBottomWidth:2,
          }}>
          <ALENText>{`${getLLower(props.datas)}`}</ALENText>
        </div>
        <ALENText>{`=`}</ALENText>
        <div
          style={{
            height:30,
            flex:1,
            marginLeft:8,
            borderWidth:0,
            borderColor:'black',
            borderStyle:'solid',
            borderLeftWidth:0,
            borderRightWidth:0,
            borderTopWidth:0,
            borderBottomWidth:2,
          }}>
          <ALENText>{`${getLTotal(props.datas)}`}</ALENText>
        </div>
      </div>
      <div
        style={{
          display:'flex',
          justifyContent:'center',
          flexDirection:'row',
          width:'90%',
          margin:16,
          marginTop:-16,
        }}>
        <ALENText>{` `}</ALENText>
        <div
          style={{
            flex:1,
            marginLeft:8,
          }}>
          <ALENText center>{`MAIN`}</ALENText>
        </div>
        <ALENText>{` `}</ALENText>
        <div
          style={{
            flex:1,
            marginLeft:8,
          }}>
          <ALENText center>{`LOWER`}</ALENText>
        </div>
        <ALENText>{` `}</ALENText>
        <div
          style={{
            flex:1,
            marginLeft:8,
          }}>
          <ALENText center>{`TOTAL`}</ALENText>
        </div>
      </div>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
        }}>
        <div
          style={{
            width:315,
            paddingLeft:84,
            paddingRight:84,
          }}>
          <ALENText size={10} center>{'Allowed lateral imbalance check chart limit'}</ALENText>
        </div>
        <div
          style={{
            width:130
          }}>
          <ALENText>{`${getDiff(props.datas)}`}</ALENText>
          <div style={{height:2, backgroundColor:'black', width:'100%'}}/>
          <ALENText center>{'DIFFERENCE'}</ALENText>
        </div>
      </div>
    </div>
  )
}

export default ALENBalanceImbalance
