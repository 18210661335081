import React, {Component} from 'react';
import {ALENBLUE, ALENBORDER, ALENBLACK, ALENWHITE, ALENDISABLEDDARK, TABLEBORDER, ALENGREY, ALENSUCCESS, ALENBG, BLUEINFO2} from "utils/colors";
import ALENLoading from 'components/ALENLoading';
import ALENText from 'components/ALENText';
import {FONTNORMAL, FONTBIGSUP, FONTMINIBIG} from 'utils/fontSizes';
import SvgClose from 'components/icons/Close';
import SvgSuccess from 'components/icons/Success';
import { withTranslation } from 'react-i18next';
import ALENButton from 'components/ALENButton';
import ALENChargingPercent from 'components/ALENChargedPercent';

function ALENOptimizeResult(props) {
  return(
    <div
      style={{
        position:'fixed',
        top:0,
        left:0,
        zIndex:9999,
        width:'100%',
        height:'100%',
        backgroundColor:"rgba(0, 0, 0, 0.7)",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
      }}>
        <div
          style={{
            width:789,
            height:794,
            borderRadius: 8,
            backgroundColor:ALENWHITE,
          }}>
          <div
            style={{
              height:80,
              width:789,
              flexDirection:'row',
              display:'flex',
              borderWidth:0,
              borderBottomWidth:1,
              borderStyle:'solid',
              borderColor:"rgba(150, 147, 163, 0.7)",
            }}>
            <div
              style={{
                height:80,
                width:715,
                paddingLeft:32,
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
              }}>
              <ALENText
                size={FONTBIGSUP}
                bold>
                {props.t("optimize.title") + props.load.name}
              </ALENText>
            </div>
            <div
              style={{
                height:80,
                width:74,
                flexDirection:'row',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}>
                <SvgClose
                  onClick={props.back}
                  fill={ALENGREY}
                  style={{
                    cursor:'pointer',
                    width:30,
                    height:30,
                  }}
                />
            </div>
          </div>
          {props.loading ?
            <div
              style={{
                marginTop:61
              }}>
              <ALENLoading
                big
                />
            </div>
            :
            <>
              <div
                style={{
                  padding:32,
                  paddingTop:24,
                  paddingBottom:0,
                  display:'flex',
                  flexDirection:'column',
                }}>
                <div
                  style={{
                    backgroundColor:ALENSUCCESS,
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'center',
                    justifyContent:'center',
                    borderRadius:4,
                    width:725,
                    height:53,
                  }}>

                    <SvgSuccess
                      style={{
                        width:16,
                        height:16,
                        marginRight:9,
                      }}
                      fill={ALENWHITE}/>

                  <ALENText
                    bold
                    color={ALENWHITE}>
                    {props.t('optimize.success')}
                  </ALENText>
                </div>
                <div
                  style={{
                    marginTop:32,
                    display:'flex',
                    flexDirection:'row',
                  }}>
                  <ALENText
                    bold
                    size={FONTMINIBIG}
                    color={ALENGREY}>
                    {props.t('optimize.description')}
                    <span
                      style={{
                        color:ALENBLACK
                      }}>
                      {props.load.name}
                    </span>
                  </ALENText>
                </div>
              </div>
              <div
                style={{
                  minHeight:492,
                  maxHeight:492,
                  marginLeft:32,
                  marginTop:16,
                  overflow:'scroll',
                  overflowX: 'hidden',
                }}
              >
                {props.listUld.map((uld) => {
                  return(
                    <div
                      style={{
                        height:90,
                        width:725,
                        borderWidth:0,
                        borderTopWidth:1,
                        borderColor:ALENBG,
                        borderStyle:'solid',
                        flexDirection:'row',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                      }}>
                      <div
                        style={{
                          flexDirection:'row',
                          display:'flex',
                          alignItems:'center',
                        }}>
                        <img
                          style={{
                            height:53,
                            width:'auto',
                            marginRight:10,
                          }}
                          src={uld.uld_stock.uld.picture}/>
                        <ALENText
                          bold>
                           {uld.uld_stock.uld.name}
                        </ALENText>
                        <div style={{width:10}}/>
                        <ALENText
                          color={ALENDISABLEDDARK}>
                           {props.t('optimize.uldRef') + uld.uld_stock.uld.ref}
                        </ALENText>
                      </div>
                      <div
                        style={{
                          flexDirection:'row',
                          display:'flex',
                          alignItems:'center',
                        }}>
                        <ALENText
                          bold
                          color={uld.percent === 100 ? ALENSUCCESS : BLUEINFO2}>
                          {props.t('optimize.loadingRate') + uld.percent.toString() + "%"}
                        </ALENText>
                        <ALENChargingPercent
                          percent={uld.percent}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div
                style={{
                  height:85,
                  width:789,
                  backgroundColor:ALENBG,
                  borderBottomRightRadius: 8,
                  borderBottomLeftRadius: 8,
                  display:'flex',
                  flexDirection:'row',
                  justifyContent:'flex-end',
                  alignItems:'center',
                  paddingRight:32,
                }}>
                <ALENButton
                  label={props.t('optimize.seeResult')}
                  onPress={() => window.location.href="/loads/optimizedList/"+(props.load !== null ? props.load.id : "")}
                />
              </div>
            </>
          }
        </div>
    </div>
  )
}

export default withTranslation()(ALENOptimizeResult)
