import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {ALENBLUE, ALENBG, ALENRED} from 'utils/colors';
import ALENLayout from 'components/ALENLayout';
import ALENParcelsEmpty from 'components/ALENParcelsEmpty';
import ALENOptimizedListHeader from 'components/ALENOptimizedListHeader';
import ALENOptimizedUldsList from 'components/ALENOptimizedUldsList';
import ALENOptimizeResult from 'components/ALENOptimizeResult';
import ALENStepHeaders from 'components/ALENStepHeaders';

class OptimizedListView extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  render()
  {
    let t = this.props.t

    const ariane = [
      {
        label: t('loads.title'),
        link: '/loads',
      },
      {
        label: this.props.load !== null ? this.props.load.name : "",
        link: 'current',
      },
    ]

    const steps={
      selected: 2,
      menus: [
        {
          label: t('loads.header.step1'),
          link: '/ulds/'+(this.props.load !== null ? this.props.load.id : "")
        },
        {
          label: t('loads.header.step2'),
          link: "/loads/"+(this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t('loads.header.step3'),
          link: "/loads/optimizedList/"+(this.props.load !== null ? this.props.load.id : ""),
        },
      ]
    }

    let nbUlds = 0
    this.props.uldList.forEach((uld) => {
      if(uld.load_uld_parcels.length > 0 ) {
        nbUlds = nbUlds + 1
      }
    })

    return(
      <>
        <ALENLayout
          title={this.props.load !== null ? this.props.load.name : ""}
          ariane={ariane}
        >
          <ALENStepHeaders
            steps={steps}
          />
          {this.props.load !== null &&
            <>
              <ALENOptimizedListHeader
                onChangeFilter={this.onChangeFilter}
                selected={this.state.filter}
                nbUlds={nbUlds}
                getPdf={this.props.getPdf}
              />
              {(this.props.load.parcels.length > 0)?
                <ALENOptimizedUldsList
                  ulds={this.props.uldList}
                  load_id={this.props.load.id}
                  load={this.props.load}
                />
                :
                <ALENParcelsEmpty
                  loadId={this.props.load !== null ? this.props.load.load_id : ""}
                  onPressNew={() => window.location.href="/loads/"+this.props.load.id+"/add"}/>
              }
            </>
          }
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(OptimizedListView)
