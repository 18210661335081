import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { checkEmpty } from "utils/checkForm";

import ALENLayout from "components/ALENLayout";
import ALENAddParcelsTable from "components/ALENAddParcelsTable";
import { ALENWHITE, ALENBG, ALENBLACK, ALENRED } from "utils/colors";
import { FONTBIG } from "utils/fontSizes";
import ALENText from "components/ALENText";
import ALENInput from "components/ALENInput";
import ALENSelect from "components/ALENSelect";
import ALENULDStockTable from "components/ALENULDStockTable";
import ALENButton from "components/ALENButton";
import ALENDatePicker from "components/ALENDatePicker";
import origami from "assets/images/origami.png";

class CreateLoadView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadId: "",
      aircraft: "",

      errorLoadId: false,
      errorAircraft: false,

      valueSelected: "",
      stockSelected: 0,
      maxWeightSelected: 0,

      errorValue: false,
      errorStock: false,
      genericError: false,

      uldList: [],
      errorUldListLength: false,

      aircraftRegistration: "",

      customReference: "",
      pointOfLoading: "",
      pointOfUnloading: "",
      flightNumber: "",
      flightAt: "",
      ownerOperator: "",
      deConsolidator: "",
      consolidator: "",
    };
  }

  createLoadValidation = () => {
    let valid = true;
    this.setState({
      errorLoadId: false,
      errorAircraft: false,
      errorUldListLength: false,
      errorStock: false,
    });

    if (this.state.loadId.length === 0) {
      valid = false;
      this.setState({ errorLoadId: true });
    }

    if (this.state.aircraft === "") {
      valid = false;
      this.setState({ errorAircraft: true });
    }

    if (this.state.uldList.length < 1) {
      valid = false;
      this.setState({ errorUldListLength: true });
    }

    /*this.state.uldList.forEach((uld) => {
      console.log('uld', uld)
      if(uld.stock === '' || uld.stock < 1) {
        valid = false
        this.setState({errorStock: uld.id})
      }
    })*/

    if (valid === true) {
      const newUldList = [];
      console.log("LIST", this.state.uldList);
      this.state.uldList.forEach((uld) => {
        newUldList.push({
          uld_id: uld.uld_id,
          nbr: uld.nbr,
          max_weight: uld.max_weight,
        });
      });
      const newLoad = {
        loadId: this.state.loadId,
        aircraft: this.state.aircraft,
        uldList: newUldList,
        aircraftRegistration: this.state.aircraftRegistration,
        customReference: this.state.customReference,
        pointOfLoading: this.state.pointOfLoading,
        pointOfUnloading: this.state.pointOfUnloading,
        flightNumber: this.state.flightNumber,
        flightAt: this.state.flightAt,
        ownerOperator: this.state.ownerOperator,
        deConsolidator: this.state.deConsolidator,
        consolidator: this.state.consolidator,
      };

      this.props.addLoad(newLoad);
    }
  };

  onSelectValue = (value) => {
    this.setState({ valueSelected: value });
  };

  onSelectStock = (value) => {
    let newValue = value;
    if (value < 0) {
      newValue = 0;
    }
    this.setState({ stockSelected: newValue });
  };

  onSelectMaxWeight = (value) => {
    let newValue = value;
    if (value < 0) {
      newValue = 0;
    }
    this.setState({ maxWeightSelected: newValue });
  };

  addULD = () => {
    let exist = false;
    let valid = true;
    const id = this.state.valueSelected;
    const stock = this.state.stockSelected;
    this.setState({ errorValue: false, errorStock: false });

    if (id === undefined || id === null || id === "") {
      this.setState({ errorValue: true });
      valid = false;
    }

    if (stock < 0 || stock === undefined || stock === null || stock === "") {
      this.setState({ errorStock: true });
      valid = false;
    }

    this.props.uldsBank.forEach((uld) => {
      if (uld.id === id) {
        exist = true;
        const newULD = {
          ...uld,
          uld_id: uld.id,
          stock: parseInt(this.state.stockSelected, 10),
          nbr: parseInt(this.state.stockSelected, 10),
          max_weight: parseInt(this.state.maxWeightSelected, 10),
        };

        if (exist && valid) {
          this.setState({
            valueSelected: "",
            stockSelected: 0,
            maxWeightSelected: 0,
          });

          let newUldsList = [...this.state.uldList, newULD];

          this.setState({ uldList: newUldsList });
        }
      }
    });

    if (!exist && valid === true) {
      this.setState({ genericError: true });
    }
  };

  changeStock = (newStock, id) => {
    let newUldList = this.state.uldList;
    newUldList[newUldList.findIndex((uld) => uld.id === id)].stock = newStock;
    this.setState({ uldList: newUldList });
  };

  changeMaxWeight = (newMaxWeight, id) => {
    let newUldList = this.state.uldList;
    newUldList[
      newUldList.findIndex((uld) => uld.id === id)
    ].max_weight = newMaxWeight;
    this.setState({ uldList: newUldList });
  };

  deleteUldStock = (id) => {
    let newUldList = this.state.uldList;
    const indexToDelete = newUldList.findIndex((uld) => uld.id === id);
    newUldList.splice(indexToDelete, 1);
    this.setState({ uldList: newUldList });
  };

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG;
  }

  render() {
    const t = this.props.t;

    let values = [];

    this.props.aircraftList.forEach((aircraft) => {
      let newAircraft = {
        value: aircraft.id,
        libelle: aircraft.name,
      };
      values.push(newAircraft);
    });

    const ariane = [
      {
        label: t("loads.title"),
        link: "/loads",
      },
      {
        label: t("createLoad.title"),
        link: "current",
      },
    ];

    return (
      <ALENLayout title={t("createLoad.title")} ariane={ariane}>
        <div
          style={{
            padding: 32,
            paddingTop: 48,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                minWidth: 1077,
                width: 1077,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  paddingLeft: 32,
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <ALENInput
                  label={t("loads.list.idLabel")}
                  placeholder={t("loads.list.IdPlaceholder")}
                  value={this.state.loadId}
                  onChange={(event) => {
                    this.setState({ loadId: event.target.value });
                  }}
                  error={this.state.errorLoadId}
                  errorMessage={t("loads.list.errorID")}
                />
                <div style={{ height: 16 }} />
                <ALENSelect
                  datas={values}
                  minWidth={245}
                  value={this.state.aircraft}
                  label={t("loads.list.planeLabel")}
                  placeholder={t("loads.list.aircraftPlaceholder")}
                  onChange={(event) => {
                    this.setState({ aircraft: event.target.value });
                  }}
                  error={this.state.errorAircraft}
                  errorMessage={t("loads.list.errorValue")}
                />
                <div style={{ height: 16 }} />
                <ALENInput
                  label={t("createLoad.aircraftRegistration")}
                  placeholder={t("createLoad.aircraftRegistrationPlaceholder")}
                  value={this.state.aircraftRegistration}
                  onChange={(event) => {
                    this.setState({ aircraftRegistration: event.target.value });
                  }}
                />
                <div style={{ height: 16 }} />
                <ALENInput
                  label={t("createLoad.customReference")}
                  placeholder={t("createLoad.customReferencePlaceholder")}
                  value={this.state.customReference}
                  onChange={(event) => {
                    this.setState({ customReference: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  paddingLeft: 32,
                  flexDirection: "column",
                  display: "flex",
                  minWidth: 245,
                }}
              >
                <ALENInput
                  label={t("createLoad.pointOfLoading")}
                  placeholder={t("createLoad.pointOfLoadingPlaceholder")}
                  value={this.state.pointOfLoading}
                  onChange={(event) => {
                    this.setState({ pointOfLoading: event.target.value });
                  }}
                />
                <div style={{ height: 16 }} />
                <ALENInput
                  label={t("createLoad.pointOfUnloading")}
                  placeholder={t("createLoad.pointOfUnloadingPlaceholder")}
                  value={this.state.pointOfUnloading}
                  onChange={(event) => {
                    this.setState({ pointOfUnloading: event.target.value });
                  }}
                />
                <div style={{ height: 16 }} />
                <ALENInput
                  label={t("createLoad.flightNumber")}
                  placeholder={t("createLoad.flightNumberPlaceholder")}
                  value={this.state.flightNumber}
                  onChange={(event) => {
                    this.setState({ flightNumber: event.target.value });
                  }}
                />
                <div style={{ height: 16 }} />
                <div style={{ marginBottom: 8 }}>
                  <ALENText>{t("createLoad.flightAt")}</ALENText>
                </div>
                <ALENDatePicker
                  placeholder={t("createLoad.flightAtPlaceholder")}
                  value={this.state.flightAt}
                  onChange={(date) => this.setState({ flightAt: date })}
                />
              </div>
              <div
                style={{
                  paddingLeft: 32,
                  flexDirection: "column",
                  display: "flex",
                  minWidth: 245,
                }}
              >
                <ALENInput
                  label={t("createLoad.ownerOperator")}
                  placeholder={t("createLoad.ownerOperatorPlaceholder")}
                  value={this.state.ownerOperator}
                  onChange={(event) => {
                    this.setState({ ownerOperator: event.target.value });
                  }}
                />
                <div style={{ height: 16 }} />
                <ALENInput
                  label={t("createLoad.deConsolidator")}
                  placeholder={t("createLoad.deConsolidatorPlaceholder")}
                  value={this.state.deConsolidator}
                  onChange={(event) => {
                    this.setState({ deConsolidator: event.target.value });
                  }}
                />
                <div style={{ height: 16 }} />
                <ALENInput
                  label={t("createLoad.consolidator")}
                  placeholder={t("createLoad.consolidatorPlaceholder")}
                  value={this.state.consolidator}
                  onChange={(event) => {
                    this.setState({ consolidator: event.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <>
            <ALENULDStockTable
              uldList={this.state.uldList}
              uldsBank={this.props.uldsBank}
              valueSelected={this.state.valueSelected}
              onSelectValue={this.onSelectValue}
              stockSelected={this.state.stockSelected}
              onSelectStock={this.onSelectStock}
              maxWeightSelected={this.state.maxWeightSelected}
              onSelectMaxWeight={this.onSelectMaxWeight}
              addULD={this.addULD}
              errorStock={this.state.errorStock}
              errorValue={this.state.errorValue}
              deleteUldStock={this.deleteUldStock}
              updateUldStock={this.updateULD}
              isAddLoading={false}
              isUpdating={false}
              showUpdate={false}
              changeStock={this.changeStock}
              changeMaxWeight={this.changeMaxWeight}
            />
            {this.state.genericError && (
              <div style={{ textAlign: "center", margin: 32 }}>
                <ALENText bold color={ALENRED} size={FONTBIG}>
                  {t("commons.genericError")}
                </ALENText>
              </div>
            )}
          </>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ALENButton
              medium
              label={t("createLoad.buttonLabel")}
              onPress={this.createLoadValidation}
            />
          </div>
        </div>
      </ALENLayout>
    );
  }
}

export default withTranslation()(CreateLoadView);
