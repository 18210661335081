import React from 'react';
import {ALENGREY, BLUEINFO2, ALENSUCCESS, ALENGREEN, ALENRED, ALENWHITE} from 'utils/colors';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';

function ALENUldStatus(props) {
  const t = props.t

  let bg = ALENGREY

  switch(parseInt(props.status, 10)) {
    case 5 :
      bg = ALENGREY
      break;
    case 10 :
      bg = ALENGREY
      break;
    case 20 :
      bg = BLUEINFO2
      break;
    case 99 :
      bg= ALENSUCCESS
      break;
    default:
    break;
  }

  return(
    <>
    <div
      style={{
        backgroundColor:bg,
        padding:8,
        borderRadius:8,
      }}>
      <ALENText
        color={ALENWHITE}>
        {t('commons.status.uld.'+props.status.toString())}
      </ALENText>
    </div>
    </>
  )
}

export default withTranslation()(ALENUldStatus)
