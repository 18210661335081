import * as React from "react";

function SvgModule1(props) {
  return (
    <svg width={100} height={94.314} viewBox={"0 0 100 94.314"} {...props}>
      <path d="M64.72 4.577C64.6 4.555 12.48 0 12.48 0L0 42.623l20.417 51.69 49.78-14.785L100 47.64zM92.57 43.293c-.418.165-45.785 5.521-45.785 5.521L20.56 4.324l42.975 3.03z" />
    </svg>
  );
}

export default SvgModule1;
