const login = {
  "connexion" : "Connexion",
  "mail" : "Mail",
  "mailPlaceholder" : "john@example.com",
  "password" : "Password",
  "forgotPassword" : "Forgot password ?",
  "noAccount" : "You don't have account ?",
  "signUp" : "Sign up",
  "error" : {
    "api" : "Bad credentials",
    "mail" : {
      "badMail" : "Please enter a correct email address",
      "emptyMail" : "Please enter your email address",
    },
    "password" : "Please enter a password",
  }
}

export default login
