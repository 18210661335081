import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN, ALENGREY, ALENBLUE, ALENGREENDARK} from 'utils/colors';
import ALENText from 'components/ALENText';
import SvgRight from 'components/icons/Aft';
import SvgLeft from 'components/icons/Bef';

function ALENBalanceChartZoneBox(props) {
  let indexValue = 0

  props.indexRule.forEach((rule) => {
    if(props.zone >= rule.value) {
      indexValue = rule.index
    }
  })

  return(
    <div style={{flexDirection:'column', display:'flex'}}>
      <div
        style={{
          height:102,
          width:props.uld.half ? 60 : 120,
          borderRadius:3,
          borderColor: ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:2,
          borderBottomWidth:1,
          margin: 2,
          backgroundColor:'white',
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
        }}>
        <div
          style={{
            height:26,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:2,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            borderBottomWidth:2,
        }}>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              flex:1,
            }}>
            <ALENText bold color={ALENBLUE} size={12}>
              {props.uld.id}
            </ALENText>
          </div>
        </div>
        <div
          style={{
            height:24,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:2,
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
        }}>
          <ALENText center bold color={ALENGREEN} size={12}>
            {props.zone}
          </ALENText>
        </div>
        <div
          style={{
            height:12,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:1,
        }}>
          <ALENText center color={ALENBLUE} size={9}>
            {props.uld.zone}
          </ALENText>
        </div>
        <div
          style={{
            height:24,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
            borderBottomWidth:1,
        }}>
          <ALENText center bold color={ALENGREEN} size={12}>
            {props.cum}
          </ALENText>
        </div>
        <div
          style={{
            height:12,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
        }}>
          <ALENText center color={ALENBLUE} size={9}>
            {props.uld.cum}
          </ALENText>
        </div>
      </div>
      <div
        style={{
          height:24,
          width:props.uld.half ? 60 : 120,
          borderColor:ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:2,
          margin:2,
          backgroundColor:'white',
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          borderRadius:3,
          marginTop:16,
      }}>
        <ALENText center color={ALENGREEN} bold>
          {indexValue}
        </ALENText>
      </div>
    </div>
  )
}

export default ALENBalanceChartZoneBox
