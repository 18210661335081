import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ALENBLUE, ALENBG, ALENWHITE, ALENDISABLEDDARK, ALENGREEN, ALENBLACK, ALENGREY, BLUEINFO, BLUEINFO2} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTBIGSUP, FONTLITTLE2} from 'utils/fontSizes'
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText'
import ALENInput from 'components/ALENInput';
import ALENButton from 'components/ALENButton';
import SvgInfos from 'components/icons/Infos';
import SvgAdd from 'components/icons/Add';
import SvgIsCrushable from 'components/icons/IsCrushable';
import SvgIsHeavy from 'components/icons/IsHeavy';
import SvgIsNoStackable from 'components/icons/IsNoStackable';
import ALENCheckBox from 'components/ALENCheckBox';

class ALENAddParcelsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdding : true
    }

    this.refName = React.createRef();
  }

  renderHeader = (width, text) => {
    return(
      <div
        style={{
          width:width,
          display:'flex',
          flexDirection:'row',
          alignItems:'center',
          marginLeft:24,
        }}>
        <ALENText
          bold
          color={ALENDISABLEDDARK}
          size={FONTLITTLE2}>
          {text}
        </ALENText>
      </div>
    )
  }

  renderTable = () => {
    const t = this.props.t;

    return(
      <div
        style={{
          marginBottom:24,
          flexDirection:'column',
          display:'flex'
        }}>
        <div
          style={{
            marginTop:24,
            backgroundColor:ALENBG,
            height:50,
            width:1077,
            flexDirection:'row',
            display:'flex',
          }}>
          {this.renderHeader(232, t('addParcels.addedList.headers.1'))}
          {this.renderHeader(168, t('addParcels.addedList.headers.2'))}
          {this.renderHeader(168, t('addParcels.addedList.headers.3'))}
          {this.renderHeader(168, t('addParcels.addedList.headers.4'))}
          {this.renderHeader(168, t('addParcels.addedList.headers.5'))}
          {this.renderHeader(168, t('addParcels.addedList.headers.6'))}
        </div>
        <div
          style={{
            width:1077,
          }}>
        {this.props.list.map((parcel) => {
          return(
            <div
              style={{
                flexDirection:'row',
                display:'flex',
                borderColor:ALENBG,
                borderWidth:0,
                borderStyle:'solid',
                borderBottomWidth:1,
                alignItems:'center',
              }}>
              <div
                style={{
                  width:232,
                  height:53,
                  flexDirection:'row',
                  display:'flex',
                  alignItems:'center',
                  paddingLeft:24,
                }}>
                  <ALENText
                    color={ALENBLACK}>
                    {parcel.name}
                  </ALENText>
              </div>
              <div
                style={{
                  width:168,
                  height:53,
                  flexDirection:'row',
                  display:'flex',
                  alignItems:'center',
                  paddingLeft:24,
                }}>
                  <ALENText
                    color={ALENBLACK}>
                    {parcel.l/10}
                  </ALENText>
              </div>
              <div
                style={{
                  width:168,
                  height:53,
                  flexDirection:'row',
                  display:'flex',
                  alignItems:'center',
                  paddingLeft:24,
                }}>
                  <ALENText
                    color={ALENBLACK}>
                    {parcel.w/10}
                  </ALENText>
              </div>
              <div
                style={{
                  width:168,
                  height:53,
                  flexDirection:'row',
                  display:'flex',
                  alignItems:'center',
                  paddingLeft:24,
                }}>
                  <ALENText
                    color={ALENBLACK}>
                    {parcel.h/10}
                  </ALENText>
              </div>
              <div
                style={{
                  width:168,
                  height:53,
                  flexDirection:'row',
                  display:'flex',
                  alignItems:'center',
                  paddingLeft:24,
                }}>
                  <ALENText
                    color={ALENBLACK}>
                    {parcel.weight}
                  </ALENText>
              </div>
              <div
                style={{
                  width:168,
                  height:53,
                  flexDirection:'row',
                  display:'flex',
                  alignItems:'center',
                  paddingLeft:24,
                }}>
                  <ALENText
                    color={ALENBLACK}>
                    {parcel.qte}
                  </ALENText>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    )
  }

  renderAddForm = () => {
    const t = this.props.t;

    return(
      <div
        style={{
          marginBottom:20,
          backgroundColor:ALENBG,
          width:'100%',
          minWidth:1077,
          marginLeft:32,
          marginRight:32,
          height:400,
          borderRadius:8,
          padding:32,
          flexDirection:'column',
          display:'flex',
        }}>
        <div
          style={{
            flexDirection:'row',
            display:'flex',
          }}>
          <div
            style={{
              minWidth:170
            }}>
            <ALENInput
              ref={this.refName}
              label={t('addParcels.addingList.headersTop.1')}
              placeholder={""}
              autoFocus={true}
              value={this.props.values.top[0]}
              onChange={(event) => this.props.onChangeValues("top", 0, event.target.value)}
              error={this.props.errors.top[0]}
              errorMessage={t('addParcels.addingList.errors.1')}
            />
          </div>
          <div
            style={{
              marginLeft:16,
              minWidth:170,
            }}>
            <ALENInput
              label={t('addParcels.addingList.headersTop.2')}
              placeholder={""}
              value={this.props.values.top[1]}
              onChange={(event) => this.props.onChangeValues("top", 1, event.target.value)}
              error={this.props.errors.top[1]}
              errorMessage={t('addParcels.addingList.errors.2')}
            />
          </div>
          <div
            style={{
              marginLeft:16,
              minWidth:170,
            }}>
            <ALENInput
              label={t('addParcels.addingList.headersTop.3')}
              placeholder={""}
              value={this.props.values.top[2]}
              onChange={(event) => this.props.onChangeValues("top", 2, event.target.value)}
              error={this.props.errors.top[2]}
              errorMessage={t('addParcels.addingList.errors.3')}
            />
          </div>
          <div
            style={{
              marginLeft:16,
              minWidth:170,
            }}>
            <ALENInput
              label={t('addParcels.addingList.headersTop.4')}
              placeholder={""}
              value={this.props.values.top[3]}
              onChange={(event) => this.props.onChangeValues("top", 3, event.target.value)}
              error={this.props.errors.top[3]}
              errorMessage={t('addParcels.addingList.errors.4')}
            />
          </div>
          <div
            style={{
              marginLeft:16,
              minWidth:170,
            }}>
            <ALENInput
              label={t('addParcels.addingList.headersTop.5')}
              placeholder={""}
              value={this.props.values.top[4]}
              onChange={(event) => this.props.onChangeValues("top", 4, event.target.value)}
              error={this.props.errors.top[4]}
              errorMessage={t('addParcels.addingList.errors.5')}
            />
          </div>
          <div
            style={{
              marginLeft:16,
              minWidth:80,
            }}>
            <ALENInput
              type={"number"}
              label={t('addParcels.addingList.headersTop.6')}
              placeholder={""}
              value={this.props.values.top[5]}
              onChange={(event) => this.props.onChangeValues("top", 5, event.target.value)}
              error={this.props.errors.top[5]}
              errorMessage={t('addParcels.addingList.errors.6')}
            />
          </div>
        </div>
        <div
          style={{
            marginTop:16,
            flexDirection:'row',
            display:'flex',
          }}>
          <div
            style={{
              minWidth:180
            }}>
            <ALENInput
              type={"number"}
              label={t('addParcels.addingList.headersMid.1')}
              placeholder={""}
              value={this.props.values.mid[0]}
              onChange={(event) => this.props.onChangeValues("mid", 0, event.target.value)}
              error={this.props.errors.mid[0]}
              errorMessage={t('addParcels.addingList.errors.1')}
            />
          </div>
          <div
            style={{
              marginLeft:32,
              minWidth:108,
            }}>
            <ALENInput
              type={"number"}
              label={t('addParcels.addingList.headersMid.2')}
              placeholder={""}
              value={this.props.values.mid[1]}
              onChange={(event) => this.props.onChangeValues("mid", 1, event.target.value)}
              error={this.props.errors.mid[1]}
              errorMessage={t('addParcels.addingList.errors.2')}
            />
          </div>
          <div
            style={{
              marginLeft:32,
              minWidth:108,
            }}>
            <ALENInput
              type={"number"}
              label={t('addParcels.addingList.headersMid.3')}
              placeholder={""}
              value={this.props.values.mid[2]}
              onChange={(event) => this.props.onChangeValues("mid", 2, event.target.value)}
              error={this.props.errors.mid[2]}
              errorMessage={t('addParcels.addingList.errors.3')}
            />
          </div>
          <div
            style={{
              marginLeft:32,
              minWidth:108,
            }}>
            <ALENInput
              type={"number"}
              label={t('addParcels.addingList.headersMid.4')}
              placeholder={""}
              value={this.props.values.mid[3]}
              onChange={(event) => this.props.onChangeValues("mid", 3, event.target.value)}
              error={this.props.errors.mid[3]}
              errorMessage={t('addParcels.addingList.errors.4')}
            />
          </div>
          <div
            style={{
              marginLeft:32,
              minWidth:108,
            }}>
            <ALENInput
              type={"number"}
              label={t('addParcels.addingList.headersMid.5')}
              placeholder={""}
              value={this.props.values.mid[4]}
              onChange={(event) => this.props.onChangeValues("mid", 4, event.target.value)}
              error={this.props.errors.mid[4]}
              errorMessage={t('addParcels.addingList.errors.5')}
            />
          </div>
        </div>
        <div
          style={{
            flexDirection:'row',
            display:'flex',
            marginTop:16,
          }}>
          <div
            style={{
              minWidth:288
            }}>
            <ALENInput
              label={t('addParcels.addingList.headersBot.1')}
              placeholder={""}
              value={this.props.values.bot[0]}
              onChange={(event) => this.props.onChangeValues("bot", 0, event.target.value)}
              error={this.props.errors.bot[0]}
              errorMessage={t('addParcels.addingList.errors.1')}
            />
          </div>
          <div
            style={{
              marginLeft:32,
              minWidth:108,
            }}>
            <ALENInput
              label={t('addParcels.addingList.headersBot.2')}
              placeholder={""}
              value={this.props.values.bot[1]}
              onChange={(event) => this.props.onChangeValues("bot", 1, event.target.value)}
              error={this.props.errors.bot[1]}
              errorMessage={t('addParcels.addingList.errors.2')}
            />
          </div>
        </div>
        <div
          style={{
            flexDirection:'row',
            display:'flex',
            marginTop:16,
            alignItems:'center',
          }}>
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <ALENCheckBox
              checked={this.props.values.bot[2]}
              handleChange={(event) => {
                this.props.onChangeValues("bot", 2, event.target.checked)
              }}
            />
            <SvgIsHeavy
              fill={ALENBLACK}
              style={{marginTop:-8}}
            />
            <div style={{width:8}}/>
            <ALENText
              bold>
              {t('addParcels.addingList.headersBot.3')}
            </ALENText>
          </div>
          <div style={{marginLeft:48, flexDirection:'row', display:'flex', alignItems:'center'}}>
            <ALENCheckBox
              checked={this.props.values.bot[3]}
              handleChange={(event) => {
                this.props.onChangeValues("bot", 3, event.target.checked)
              }}
            />
            <SvgIsNoStackable
              height={40}
              width={40}
              style={{marginRight:8}}
            />
            <ALENText
              bold>
              {t('addParcels.addingList.headersBot.4')}
            </ALENText>
          </div>
          <div style={{marginLeft:48, flexDirection:'row', display:'flex', alignItems:'center'}}>
            <ALENCheckBox
              checked={this.props.values.bot[4]}
              handleChange={(event) => {
                this.props.onChangeValues("bot", 4, event.target.checked)
              }}
            />
            <SvgIsCrushable
              height={40}
              width={40}
              style={{marginRight:8}}
            />
            <ALENText
              bold>
              {t('addParcels.addingList.headersBot.5')}
            </ALENText>
          </div>
          <div style={{flex:1,}}/>
          <div
            style={{
              marginTop:16,
              flexDirection:'row',
              display:'flex',
            }}>
            <ALENButton
              label={t('addParcels.addingList.cancel')}
              variant
              medium
              onPress={this.props.cancel}
            />
            <div style={{width:16}}/>
            <ALENButton
              label={t('addParcels.addingList.add')}
              medium
              onPress={this.props.addParcel}
            />
          </div>
        </div>
        {/* <div
          style={{
            flexDirection:'row',
            display:'flex',
            marginTop:32,
            marginBottom:24,
          }}>

          <div
            style={{
              width:725,
              height:64,
              borderRadius:4,
              backgroundColor:BLUEINFO,
              flexDirection:'row',
              display:'flex',
              alignItems:'center',
            }}>

            <SvgInfos fill={BLUEINFO2} style={{marginLeft:18, marginRight:16, width:20, height:20}}/>
            <ALENText>
              {t('addParcels.addingList.info.1')}
            </ALENText>
            <div style={{width:5}}/>
            <div
              style={{
                backgroundColor:ALENGREY,
                borderRadius:8,
                padding:6,
              }}>
              <ALENText
                color={ALENWHITE}
                size={FONTLITTLE2}
                bold>
                {t('addParcels.addingList.info.2')}
              </ALENText>
            </div>
          </div>*
          <div style={{display:'flex', flex:1}}/>
          <ALENButton
            label={t('addParcels.addingList.cancel')}
            variant
            medium
            onPress={this.props.cancel}
          />
          <div style={{width:16}}/>
          <ALENButton
            label={t('addParcels.addingList.add')}
            medium
            onPress={this.props.addParcel}
          />
        </div>*/}
      </div>
    )
  }

  render() {
    return(
      <>
        {this.renderAddForm()}
      </>
    )
  }

  renderOld() {
    const { t, i18n } = this.props;

    return (
      <div
        style={{
          padding:32,
          flexDirection:'column',
          display:'flex',
          alignItems:'center'
        }}>
        <div
          style={{
            flexDirection:'row',
            justifyContent:'flex-start',
            display:'flex',
            width:1077}}>
          <ALENText
            bold
            size={FONTBIGSUP}>
            {t('addParcels.titleList')}
          </ALENText>
        </div>
        {this.renderTable()}
        {this.state.isAdding && this.renderAddForm()}
        {!this.state.isAdding &&
        <div
          style={{
            flexDirection:'row',
            justifyContent:'flex-start',
            display:'flex',
            width:1077,
          }}>
          <ALENButton
            startIcon=<SvgAdd fill={ALENDISABLEDDARK} style={{width:22, height:22}}/>
            label={t('addParcels.create')}
            variant
            big
            onPress={() => this.setState({isAdding: true})}
          />
        </div>
        }
      </div>
    );
  }
}

ALENAddParcelsTable.propTypes = {
  label:PropTypes.string,

};

ALENAddParcelsTable.defaultProps = {
  label:'ALEN',

};

export default withTranslation()(ALENAddParcelsTable)
