import * as React from "react";

function SvgRightFull(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M14 12l-4 4V8l4 4z" />
    </svg>
  );
}

export default SvgRightFull;
