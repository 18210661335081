import * as React from "react";

function SvgAft(props) {
  return (
    <svg viewBox="0 0 480 480" {...props}>
      <path d="M461.248 194.736l-128-128c-24.928-24.96-65.568-24.96-90.496 0C230.656 78.8 224 94.896 224 111.984s6.656 33.184 18.752 45.248l82.752 82.752-82.752 82.752C230.656 334.832 224 350.896 224 367.984s6.656 33.152 18.752 45.248c12.096 12.096 28.16 18.752 45.248 18.752s33.152-6.656 45.248-18.752l128-128C473.344 273.168 480 257.072 480 239.984s-6.656-33.184-18.752-45.248zm-22.624 67.872l-128 128c-12.128 12.096-33.12 12.096-45.248 0-12.48-12.48-12.48-32.768 0-45.248l105.376-105.376-105.376-105.376c-6.048-6.048-9.376-14.08-9.376-22.624s3.328-16.576 9.376-22.624A31.912 31.912 0 01288 79.984a32.013 32.013 0 0122.624 9.344l128 128c6.048 6.08 9.376 14.112 9.376 22.656s-3.328 16.576-9.376 22.624z" />
      <path d="M237.248 194.736l-128-128c-24.928-24.96-65.568-24.96-90.496 0C6.656 78.8 0 94.896 0 111.984s6.656 33.184 18.752 45.248l82.752 82.752-82.752 82.752C6.656 334.832 0 350.896 0 367.984s6.656 33.152 18.752 45.248c12.096 12.096 28.16 18.752 45.248 18.752s33.152-6.656 45.248-18.752l128-128C249.344 273.168 256 257.072 256 239.984s-6.656-33.184-18.752-45.248zm-22.624 67.872l-128 128c-12.128 12.096-33.12 12.096-45.248 0-12.48-12.48-12.48-32.768 0-45.248l105.376-105.376L41.376 134.608C35.328 128.56 32 120.528 32 111.984s3.328-16.576 9.376-22.624c6.24-6.24 14.432-9.376 22.624-9.376s16.384 3.136 22.624 9.344l128 128c6.048 6.08 9.376 14.112 9.376 22.656s-3.328 16.576-9.376 22.624z" />
    </svg>
  );
}

export default SvgAft;
