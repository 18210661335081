import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {ALENULTRABLACK, ALENWHITE, ALENBLACK, ALENBG, ALENSUCCESS, BLUEINFO2, ALENRED, ALENGREY, ALENBORDER, TABLEBORDER, ALENDISABLEDDARK, STATUS} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP, FONTMINIBIG, FONTLITTLE2} from 'utils/fontSizes'
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgDelete from 'components/icons/Delete';
import ALENCheckBox from 'components/ALENCheckBox';
import ALENParcelStatus from 'components/ALENParcelStatus';
import ALENUldStatus from 'components/ALENUldStatus';
import ALENSwitch from 'components/ALENSwitch';
import SvgRightFull from 'components/icons/RightFull';
import SvgBottom from 'components/icons/Bottom';
import ALENChargedPercent from 'components/ALENChargedPercent';
import SvgEnter from 'components/icons/Enter';

class ALENOptimizedUldsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockValues: [],
      isAdding: false,

      checkAll: false,
      selectedUld: [],
      over: -1,
    }
  }


  getPercent(parcels) {
    const total = parcels.length
    const parcelsCharged = parcels.filter((parcel) => {
      return(
        parcel.status === "20"
      )
    })

    const charged = parcelsCharged.length
    const percent = charged*100 / total

    return(percent)
  }

  toggleCheck = (id) => {
    let selectedUld = this.state.selectedUld
    let index = selectedUld.indexOf(id)

    if(index > -1) {
      selectedUld.splice(index, 1)
    } else {
      selectedUld.push(id)
    }

    this.setState({selectedParcels: selectedUld})
  }

  renderDetail = (uld) => {

  }

  renderLine = (uld, groups, index) => {
    const { t, i18n } = this.props;
    let selected = this.state.selectedUld.find(el => el === uld.id)

    const percent = parseInt(this.getPercent(uld.load_uld_parcels), 10)
    //const percent = 10

    console.log('uld.load_uld_parcels', uld.load_uld_parcels)
    console.log('this.props', this.props)
    let parcelsGrouped = {}
    uld.load_uld_parcels.forEach((parcel) => {
      const parcelFormatted1 = {
        parcel: parcel,
        qte: parcelsGrouped[parcel.parcel.group_uid] !== undefined ? parcelsGrouped[parcel.parcel.group_uid].qte + 1 : 1
      }
      parcelsGrouped[parcel.parcel.group_uid] = parcelFormatted1
    })

    console.log('parcelsGrouped', parcelsGrouped)

    let parcels = []
    for(const parcel in parcelsGrouped ) {
      console.log('parcel', parcelsGrouped[parcel])
      const parcelFormatted2 = {
        group: parcel,
        qte: parcelsGrouped[parcel].qte,
        weight: parcelsGrouped[parcel].qte * parcelsGrouped[parcel].parcel.parcel.weight,
        nature_of_good: parcelsGrouped[parcel].parcel.parcel.nature_of_good,
        shipper_name_address: parcelsGrouped[parcel].parcel.parcel.shipper_name_address,
        consignee_name_address: parcelsGrouped[parcel].parcel.parcel.consignee_name_address,
        use_by_owner_operator: parcelsGrouped[parcel].parcel.parcel.use_by_owner_operator,
        official_use: parcelsGrouped[parcel].parcel.parcel.official_use,
        total: groups[parcel],
        libelle: parcelsGrouped[parcel].parcel.parcel.airway_bill,
      }
      parcels.push(parcelFormatted2)
    }

    if(uld.load_uld_parcels.length > 0) {

      return(
        <>
          <div
            onMouseOver={() => this.setState({over: index})}
            onMouseLeave={() => this.setState({over: -1})}
            onClick={() => {
              this.toggleCheck(uld.id)
            }}
            style={{
              cursor:'pointer',
              flexDirection:'row',
              display:'flex',
              width:'100%',
              borderWidth: 0,
              borderBottomWidth:1,
              borderStyle:'solid',
              backgroundColor: ALENWHITE,
              borderColor:ALENBG,
              boxShadow: '0px 4px 16px rgba(18, 5, 80, 0.1)',
            }}>
            <div
              onClick={() => {
                this.toggleCheck(uld.id)
              }}
              style={{
                height:60,
                minWidth:50,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
              }}>
              {selected ?
                <SvgBottom
                  fill={ALENDISABLEDDARK}
                />
                :
                <SvgRightFull
                  fill={ALENDISABLEDDARK}
                />
              }
            </div>
            <div
              style={{
                height:60,
                minWidth:1027,
                flexDirection:'row',
                display:'flex',
                alignItems:'center',
              }}>
              <img
                style={{
                  height:53,
                  width:'auto',
                  margin:8,
                  marginRight:10,
                }}
                src={uld.uld_stock.uld.picture}
                />
              <ALENText>
                <span
                  style={{
                    fontWeight:'bold'
                  }}>
                  {uld.uld_stock.uld.name}
                </span>
                {" | "+t('optimize.uldRef') + uld.uld_stock.uld.ref}
              </ALENText>
            </div>
            {/*<div
              style={{
                height:60,
                minWidth:187,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}>
              <ALENUldStatus
                status={uld.status}
              />
            </div>
            <div
              style={{
                height:60,
                minWidth:269,
                paddingLeft:24,
                display:'flex',
                alignItems:'center',
              }}>
              {percent === 100 &&
                <ALENText
                  bold
                  color={ALENSUCCESS}>
                  {t('optimize.charging.full')}
                </ALENText>
              }
              {percent === 0 &&
                <ALENText
                  bold
                  color={ALENDISABLEDDARK}>
                  {percent.toString() + "% " + t('optimize.charging.charged')}
                </ALENText>
              }
              {percent < 100 && percent > 0 &&
                <ALENText
                  bold
                  color={BLUEINFO2}>
                  {percent.toString() + "% " + t('optimize.charging.charged')}
                </ALENText>
              }
              <ALENChargedPercent
                percent={percent}
                />
            </div>*/}
            <div
              style={{
                height:60,
                minWidth:188,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}>
                <ALENButton
                  label={t('optimize.seeLoading')}
                  onPress={() => {
                    console.log('uld', uld)
                    const parcelId = uld.load_uld_parcels.find((parcel_uld) => parcel_uld.parcel.status === "10")?.parcel.id
                    console.log('parcelId', uld.load_uld_parcels)
                    if(parcelId) {
                      window.location.href="/loading/"+this.props.load_id+"/"+uld.uld_stock_id+"/"+parcelId+"/"+uld.uld_stock.uld.id
                    } else {
                      window.location.href="/loading/"+this.props.load_id+"/"+uld.uld_stock_id+"/"+uld.load_uld_parcels[0].parcel.id+"/"+uld.uld_stock.uld.id
                    }
                  }}
                />
            </div>
          </div>
          {selected &&
            <div
              style={{
                flexDirection:'column',
                display:'flex',
                minWidth:'100%',
                backgroundColor: ALENBG,
                paddingTop:16,
                paddingBottom:16,
                paddingLeft:32,
                paddingRight:32,
              }}>
              <div
                style={{
                  flexDirection:'row',
                  display:'flex',
                  width:'100%',
                  minHeight:53,
                  borderWidth:0,
                  borderBottomWidth:1,
                  borderStyle:'solid',
                  backgroundColor: ALENWHITE,
                  borderColor:ALENBG,
                  alignItems:'center',
                  paddingLeft:0,
                  paddingRight:0,
                  boxShadow: '0px 4px 16px rgba(18, 5, 80, 0.1)',
                }}>
                <div
                  style={{
                    flex:1,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersTopClean.1').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:1,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersMidClean.1').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:1,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersMidClean.5').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:2,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersTopClean.3').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:2,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersBotClean.1').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:2,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersBotClean.2').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:1,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersTopClean.4').toUpperCase()}
                  </ALENText>
                </div>
                <div
                  style={{
                    flex:1,
                    display:'flex',
                    flexDirection:'row',
                    paddingLeft:16,
                    backgroundColor:ALENBG,
                    height:53,
                    alignItems:'center'
                  }}>
                  <ALENText
                    bold
                    color={ALENDISABLEDDARK}>
                    {t('addParcels.addingList.headersTopClean.5').toUpperCase()}
                  </ALENText>
                </div>
              </div>
              {parcels.map((parcel) => {
                return(
                  <div
                    style={{
                      flexDirection:'row',
                      display:'flex',
                      width:'100%',
                      minHeight:53,
                      borderWidth:0,
                      borderBottomWidth:1,
                      borderStyle:'solid',
                      backgroundColor: ALENWHITE,
                      borderColor:ALENBG,
                      alignItems:'center',
                      boxShadow: '0px 4px 16px rgba(18, 5, 80, 0.1)',
                    }}>
                    <div
                      style={{
                        flex:1,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        bold
                        color={ALENBLACK}>
                        {parcel.libelle}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:1,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.qte}/${parcel.total}`}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:1,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.weight} Kg`}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:2,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.nature_of_good}`}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:2,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.shipper_name_address}`}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:2,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.consignee_name_address}`}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:1,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.use_by_owner_operator}`}
                      </ALENText>
                    </div>
                    <div
                      style={{
                        flex:1,
                        paddingLeft:24,
                      }}>
                      <ALENText
                        color={ALENBLACK}>
                        {`${parcel.official_use}`}
                      </ALENText>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </>
      )
    }
  }

  renderHeaders = () => {
    const { t, i18n } = this.props;

    return (
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          marginTop:0,
          width:'100%',
          boxShadow: '0px 4px 16px rgba(18, 5, 80, 0.1)',
        }}>
        <div
          style={{
            height:50,
            minWidth:50,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
        </div>
        <div
          style={{
            height:50,
            flex:1,
            paddingLeft:24,
            backgroundColor:ALENBG,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',

          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('optimize.headers.0')}
          </ALENText>
        </div>
        {/* 644 <div
          style={{
            height:50,
            minWidth:187,
            backgroundColor:ALENBG,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('optimize.headers.1')}
          </ALENText>
        </div>
        <div
          style={{
            height:50,
            minWidth:269,
            paddingLeft:24,
            backgroundColor:ALENBG,
            display:'flex',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('optimize.headers.2')}
          </ALENText>
        </div>
        <div
          style={{
            height:50,
            minWidth:188,
            backgroundColor:ALENBG,
            display:'flex',
            alignItems:'center',
          }}>
        </div>*/}
      </div>
    )
  }

  renderPagination = () => {
    return (
      <p>TODO Pagination</p>
    )
  }

  render() {
    const { t, i18n } = this.props;

    const groups = {}
    this.props.ulds.forEach((uld) => {
      uld.load_uld_parcels.forEach((parcel) => {
        if(groups[parcel.parcel.group_uid] !== undefined) {
          groups[parcel.parcel.group_uid] = groups[parcel.parcel.group_uid] + 1
        } else {
          groups[parcel.parcel.group_uid] = 1
        }
      })
    })

    return(
      <div
        style={{
          paddingLeft:33,
          paddingRight:31,
          flexDirection:'column',
          display:'flex',
          alignItems:'center',
          marginBottom:48,
        }}>
        {this.renderHeaders()}
        {this.props.ulds.map((uld, index) => {
          return(
            <>
              {this.renderLine(uld, groups, index)}
            </>
          )
        })}
      </div>
    )
  }
}

ALENOptimizedUldsList.propTypes = {
  ulds: PropTypes.array,
};

ALENOptimizedUldsList.defaultProps = {
  ulds: [],
};

export default withTranslation()(ALENOptimizedUldsList)
