import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENDISABLEDDARK} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTNORMALSUP} from 'utils/fontSizes'
import origami from 'assets/images/origami.png';
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgAdd from 'components/icons/Add';

class ALENLoadsEmpty extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div
        style={{
          flexDirection:'column',
          display:'flex'}}>
        <div
          style={{
            flexDirection:'row',
            display:'flex',
            justifyContent:'center',
            marginTop:56}}>
          <img
            src={origami}
            style={{
              width:203,
              height:'auto'}}/>
        </div>
        <div
          style={{
            marginTop:24,
            alignSelf:'center',
            textAlign:'center',
          }}>
          <ALENText
            size={FONTVERYBIG}
            color={ALENBLACK}>
            {t('loads.empty.title.1')}
            <span
              style={{
                fontWeight:600
              }}>
              {t('loads.empty.title.2')}
            </span>
            {t('loads.empty.title.3')}
          </ALENText>
        </div>
        <div
          style={{
            alignSelf:'center',
            textAlign:'center',
            marginTop:6,
          }}>
          <ALENText
            size={FONTNORMALSUP}
            color={ALENDISABLEDDARK}>
            {t('loads.empty.text')}
          </ALENText>
        </div>
        <div
          style={{
            alignSelf:'center',
            marginTop:24,
            marginBottom:56
          }}>
          <ALENButton
            onPress={this.props.onPressNew}
            startIcon=<SvgAdd fill={ALENWHITE} style={{width:22, height:22}}/>
            big
            label={t('loads.empty.buttonLabel')}
          />
        </div>
      </div>
    );
  }
}

ALENLoadsEmpty.propTypes = {
  onPressNew: PropTypes.func,
};

ALENLoadsEmpty.defaultProps = {
  onPressNew: () => console.log('press new')
};

export default withTranslation()(ALENLoadsEmpty)
