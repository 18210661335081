import ApiConstants from './ApiConstants';

export default function api(path, params, method, token, moreHeaders = {}, doStringify = true) {
  let options;
      options = {
          headers: {
              ...{
                  'Cookie': 'XDEBUG_SESSION=PHPSTORM',
                  'Content-Type': 'application/json',
                  'X-token-api': token,
              },
              ...moreHeaders
          },
          method: method,
          ...(params && {body: doStringify ? JSON.stringify(params) : params})
      };

      if (ApiConstants.DEBUG_OUTPUT) {
          console.log('[DEBUG] Calling API url : ', ApiConstants.BASE_URL + path);
          console.log('[DEBUG] Call Params : ', options);
      }

      return fetch(ApiConstants.BASE_URL + path, options)
          .then((resp) => {
              console.log('resp', resp)
              if(resp.status === 200) {
                if (ApiConstants.DEBUG_OUTPUT) {
                    try {
                        const json = JSON.parse(resp._bodyText);
                        console.log("\t[DEBUG] Response content : ", json);
                    } catch (e) {
                        console.log("\t[DEBUG] Raw response content : ", resp);
                    }
                }
                return resp.json().then((json) => {
                  const response = {status: resp.status, ok: resp.ok, data: json}
                  return response
                });
              } else {
                return {status: resp.status, ok: resp.ok, data: null}
              }
          })
          .catch(error => {
              console.log('[ERROR] Api call failed `' + ApiConstants.BASE_URL + path + ' : ' + error);
              throw new Error(error);
          });
  }
