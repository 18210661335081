const initialState = {
    list: [],
}

function addParcelsReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'LOGOUT' : {
      return initialState
    }
    case 'RESET' :
      return initialState
    case 'INSERTPARCELS' :
      return initialState
    case 'ADDPARCEL' :
      nextState = {
        ...state,
      }

      let parcelsAdd = nextState.list
      parcelsAdd.push(action.data)
      return nextState
    case 'INIT' :
      nextState = {
        ...initialState,
      }
      return nextState
    default:
      return state
  }
}

export default addParcelsReducer
