import React, {Component} from 'react';
import * as THREE from "three";
import OrbitControls from './OrbitControls';
import OBJLoader from 'three-obj-loader';

class ULDScene extends Component {
  constructor(props) {
    super(props)

    var THREE = require('three');
    var OBJLoader = require('three-obj-loader');
    OBJLoader(THREE);

    const loader = new THREE.OBJLoader();

    let camera, scene, renderer;
    let geometry, material, mesh;


      camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
      camera.position.z = 1;

      scene = new THREE.Scene();

      geometry = new THREE.BoxGeometry( 1, 1, 1 );
      material = new THREE.MeshBasicMaterial( {
      	color: 'blue',
        wireframe: true,
      } );
      const cube = new THREE.Mesh( geometry, material );
      scene.add( cube );

      // load a resource
      material = new THREE.MeshBasicMaterial( {
      	color: 'white',
        wireframe: true,
      } );

      loader.load( './LD1.2.obj',
          function( obj ){
              obj.traverse( function( child ) {
                  if ( child instanceof THREE.Mesh ) {
                      child.material = material;
                  }
              } );
              console.log('loaded')
              scene.add( obj );
          },
          function( xhr ){
              console.log( (xhr.loaded / xhr.total * 100) + "% loaded")
          },
          function( err ){
              console.error( "Error loading 'ship.obj'")
          }
      );


      renderer = new THREE.WebGLRenderer( { antialias: false } );
      renderer.setSize( window.innerWidth, window.innerHeight );
      document.body.appendChild( renderer.domElement );
      const controls = new OrbitControls(camera, renderer.domElement);

      renderer.render( scene, camera );
      renderer.setClearColor ('black', 1);

      function animate() {

				requestAnimationFrame( animate );

				controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true

				renderer.render( scene, camera );

			}

      animate()


  }

  componentDidMount() {

  }

  render()
  {

    return(
      <div>
      </div>
    )
  }
}

export default ULDScene
