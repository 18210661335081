/* App config for apis
 */
const ApiConstants = {
    DEBUG_OUTPUT: true,
    //BASE_URL: 'https://api.loadcontrol.aero/',
    BASE_URL: process.env.REACT_APP_API_URL,

    LOGIN: 'user/login',

    GETULDS: 'uld/getList',
    ADDULDSTOCK: 'uldStock/set',
    UPDATEULDSTOCK: 'uldStock/set',
    GETULDSTOCK: 'uldStock/getList',

    GETAIRCRAFTLIST: 'aircraft/getList',
    ADDLOAD: 'load/add',
    DELETELOAD: 'load/delete',
    GETLOAD: 'load/get',
    GETLISTLOAD: 'load/getList',
    GETULDSLOAD: 'loadUld/getList',
    LOADUPDATE: 'load/update',
    LOADULDADD: 'loadUld/add',

    ADDPARCEL: 'parcel/add',
    GETPARCEL: 'parcel/get',
    PARCELGETLIST: 'parcel/getList',
    PARCELDELETE: '/parcel/delete',

    OPTIMIZE: 'optimize/start',
    GETOPTIMIZEDULDS: 'loadUld/getList',
    GETLOADING: 'parcel/getList/uld',
    CHARGE: 'parcel/isCharged',

    ADDULDS: 'loadUld/addMulti',
    LOADPDF: 'load/pdf',

    GETLISTTIEDOWNS: 'tiedown/getList',
    SAVETIEDOWNNS: 'tiedown/save',
    GETTIEDOWNMODEL: 'tiedown/model',

    ADDBALANCE: 'balance/add',
    GETBALANCELIST:'balance/getList',
    GETBALANCE: 'balance/get',
    UPDATEBALANCE: 'balance/update',
};

export default ApiConstants;
