import React, {Component} from 'react';
import {ALENBG, ALENRED} from "utils/colors";
import {FONTLITTLE} from "utils/fontSizes";
import SvgBackgroundLogin from "components/icons/BackgroundLogin";
import ALENFormBox from "components/ALENFormBox";
import ALENInput from "components/ALENInput";
import ALENText from "components/ALENText";
import logo from 'assets/logo/logoSymbolTypeWhite.png';
import { withTranslation } from 'react-i18next';
import {ALENBLUE} from 'utils/colors';
import {checkEmail, checkEmpty} from 'utils/checkForm.js';

class LoginView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobile:false,

      mail: "",
      password: "",

      errorMailEmpty: false,
      errorBadMail: false,
      errorPassword: false,
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG

    window.addEventListener("resize", this.resize);
    this.resize()
  }

  resize = () => {
    if( window.innerWidth <= 960) {
      this.setState({mobile: true})
    } else {
      this.setState({mobile:false})
    }
  }

  login = () => {
    this.setState({errorBadMail: false, errorPassword: false, errorMailEmpty: false})
    let valid = true
    const data = {
      email: this.state.mail,
      password: this.state.password,
    }

    if(!checkEmpty(data.email)) {
      valid = false
      this.setState({errorMailEmpty: true})
    } else {
      if(!checkEmail(data.email)) {
        this.setState({errorBadMail: true})
      }
    }

    if(!checkEmpty(data.password)) {
      valid = false
      this.setState({errorPassword: true})
    }

    if(valid) {
      this.props.login(data)
    }
  }

  render()
  {
    let t = this.props.t

    return(
      <>
        {!this.state.mobile &&
          <div style={{position:'absolute', top:0, width:'100%', zIndex:-9999}}>
            <SvgBackgroundLogin/>
          </div>
        }

        <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', padding:50, backgroundColor: !this.state.mobile ? "transparent" : ALENBLUE}}>
              <img src={logo} style={{width:219, height:70}}/>
            </div>
            <ALENFormBox
              title={t("login.connexion")}
              buttonLabel={t("login.connexion")}
              fullScreen={this.state.mobile}
              onValid={() => this.login()}
              isLoading={this.props.isLoading}
            >
              <ALENInput
                label={t("login.mail")}
                placeholder={t("john@example.com")}
                value={this.state.mail}
                onChange={(event) => this.setState({mail: event.target.value})}
                error={this.state.errorBadMail || this.state.errorMailEmpty}
                errorMessage={this.state.errorBadMail ? t('login.error.mail.badMail') : t('login.error.mail.emptyMail')}
              />
              <div style={{height:16}}/>
              <ALENInput
                type="password"
                label={t("login.password")}
                placeholder={t("********")}
                value={this.state.password}
                onChange={(event) => this.setState({password: event.target.value})}
                error={this.state.errorPassword}
                errorMessage={t('login.error.password')}
              />
              <div style={{height:8}}/>
              <div style={{cursor:"pointer", marginLeft:3.24}}>
                {/*<ALENText link>{t('login.forgotPassword')}</ALENText>*/}
              </div>
              {this.props.isApiError &&
                <div
                  style={{alignSelf:'center', marginTop:8, marginBottom:-8}}>
                  <ALENText
                    size={FONTLITTLE}
                    bold
                    color={ALENRED}>
                    {t('login.error.api')}
                  </ALENText>
                </div>
              }
            </ALENFormBox>
            <div style={{height:24}}/>
            {/*<div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
              <ALENText>{t('login.noAccount')}</ALENText>
            </div>
              <div style={{cursor:"pointer", height:8}}/>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
              <ALENText link>{t('login.signUp')}</ALENText>
            </div>*/}
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(LoginView)
