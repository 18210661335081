const loads = {
  "header" : {
    "step1" : "ULDs List",
    "step2" : "Parcels List",
    "step3" : "Optimized ULDs",
    "step4" : "Loading",
  },
  "title" : "Loads list",
  "empty" : {
    "title" : {
      "1" : "You have no ",
      "2" : "LOAD ",
      "3" : "declared",
    },
    "text" : "Please create your first selection of ULD to start your load optimization !",
    "buttonLabel" : "Add your first load!",
  },
  "list" : {
    "title" : "List of Loads",
    "idLabel" : "Load ID *",
    "planeLabel" : "Select aircraft *",
    "cancel" : "Cancel",
    "add" : "Add Load",
    "createLabel" : "Create a new load",
    "errorID" : "Load ID cannot be null",
    "errorValue" : "Select correct aircraft value",
    "aircraftPlaceholder" : "Select aircraft in the list",
    "IdPlaceholder" : "Enter load ID",
    "enterButton" : "Enter",
    "moreButton" : "...",
  },
  "delete" : {
    "title" : "Are you sure ?",
    "message" : {
      "1" : "The load",
      "2" : "will be deleted, are sure ?",
    },
    "yes" : "Yes",
    "no" : "No",
  },
  "deleteSuccess" : "Success : load has been deleted !",
}

export default loads
