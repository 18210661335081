import React, {Component} from 'react';
import AddParcelsView from './AddParcelsView';
import { connect } from 'react-redux';
import { addParcel } from 'api/methods/parcel';

class AddParcelsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      load: null
    }
  }

  componentDidMount() {
    this.loadDatas()
  }

  loadDatas = () => {
    /*CALL API GET LOAD*/
    const idLoad = this.props.match.params.id

    this.props.loadsReducer.loads.forEach((load) => {
      if(load.id.toString() === idLoad) {
        this.setState({load: load})
      }
    })
  }

  addParcel = (values) => {
    const newParcel = {
      id: Date.now(),
      name: values.top[0],
      qte: values.mid[0],
      l: values.mid[1]*10,
      w: values.mid[2]*10,
      h: values.mid[3]*10,
      weight: values.mid[4],
      status: 0,
      airway_bill_type: values.top[1],
      nature_of_good: values.top[2],
      use_by_owner_operator: values.top[3],
      official_use: values.top[4],
      number_of_hawb: values.top[5],
      shipper_name_address: values.bot[0],
      consignee_name_address: values.bot[1],
    }

    this.props.addParcel(newParcel)
  }

  insertParcels = () => {
    /*API CALL insertParcels*/

    const parcelsList = this.props.addParcelsReducer.list

    let cpt = 0

    parcelsList.forEach((parcel, index) => {

      const addingParcel = {
        airway_bill: parcel.name,
        quantity: parcel.qte,
        length: parcel.l,
        width: parcel.w,
        height: parcel.h,
        weight: parcel.weight,
        load_id: this.state.load.id,
        airway_bill_type: parcel.airway_bill_type,
        nature_of_good: parcel.nature_of_good,
        use_by_owner_operator: parcel.use_by_owner_operator,
        official_use: parcel.official_use,
        number_of_hawb: parcel.number_of_hawb,
        shipper_name_address: parcel.shipper_name_address,
        consignee_name_address: parcel.consignee_name_address,
      }

      console.log('parcel', parcel)

      addParcel(this.props.userReducer.token, addingParcel).then((response) => {
        console.log('parcel added' + cpt, response)
        if(response.status === 200 && response.ok) {
          const insertParcel = response.data

          this.props.insertParcels(this.state.load.id, insertParcel)

          cpt = cpt + 1

          if(cpt === parcelsList.length) {
            window.location.href = '/loads/'+this.state.load.id
          }
        }
      })
    })
    //this.props.insertParcels(id, parcelsList)
  }

  render() {
    const idLoad = this.props.match.params.id
    return(
      <>
        <AddParcelsView
          load={this.state.load}
          addParcel={this.addParcel}
          parcelsList={this.props.addParcelsReducer.list}
          insertParcels={this.insertParcels}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addParcel: (newParcel) => {
      dispatch({type: "ADDPARCEL", data: newParcel})
    },
    insertParcels: (id, parcelsList) => {
      dispatch({type: "INSERTPARCELS", data: {id: id, parcelsList: parcelsList}})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    addParcelsReducer : state.addParcelsReducer,
    loadsReducer: state.loadsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddParcelsContainer)
