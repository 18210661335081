import React, { Component } from "react";
import CreateLoadView from "./CreateLoadView";
import { connect } from "react-redux";
import { addParcel } from "api/methods/parcel";
import { getAircraftList, addLoad, addUlds } from "api/methods/load";
import {
  getUlds,
  addUldStock,
  getUldStock,
  updateUldStock,
} from "api/methods/stock";

class CreateLoadContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aircraftList: [],
    };
  }

  addLoad = (load) => {
    console.log("load.uldList", load.uldList);
    const newLoad = {
      name: load.loadId,
      aircraft_id: load.aircraft,
      load_uld: load.uldList,
      aircraft_registration: load.aircraftRegistration,
      custom_reference: load.customReference,
      point_of_loading: load.pointOfLoading,
      point_of_unloading: load.pointOfUnloading,
      flight_number: load.flightNumber,
      flight_at: load.flightAt,
      owner_operator: load.ownerOperator,
      de_consolidator: load.deConsolidator,
      consolidator: load.consolidator,
    };

    addLoad(this.props.userReducer.token, newLoad).then((response) => {
      console.log("response", response);
      if (response.status === 200 && response.ok) {
        const newLoads = [];
        response.data.forEach((load) => {
          newLoads.push({
            ...load,
            nbParcels: load.parcels.length,
          });
        });
        this.props.updateLoads(newLoads);
        const newId = response.data[0].id;
        window.location.href = "/loads/" + newId;
        //this.props.stockULDSReducer.uldList.forEach
      }
    });
  };

  refresh = (loading) => {
    this.setState({ isMainLoading: loading });
    getUlds(this.props.userReducer.token).then((response) => {
      this.setState({ isMainLoading: false });
      if (response.status === 200 && response.ok) {
        this.setState({ uldsBank: response.data });
        this.setState({ isAsyncLoading: true });
        getUldStock(this.props.userReducer.token).then((response) => {
          this.setState({ isAsyncLoading: false });
          this.setState({ isAsyncLoading: true });
          if (response.status === 200 && response.ok) {
            const uldList = response.data;
            let uldListRedux = [];
            uldList.forEach((uld) => {
              this.state.uldsBank.forEach((uldBank) => {
                if (uld.uld.id === uldBank.id) {
                  let newUld = {
                    id: uldBank.id,
                    stock: uld.nbr,
                    name: uldBank.name,
                    ref: uldBank.ref,
                    description: uldBank.description,
                    picture: uldBank.picture,
                  };
                  uldListRedux.push(newUld);
                }
              });
            });
            this.props.refreshUldStock(uldListRedux);
            this.setState({ isAsyncLoading: false });
          }
        });
      }
    });
  };

  componentDidMount() {
    this.refresh(true);
    getAircraftList(this.props.userReducer.token).then((response) => {
      if (response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data,
        });
      }
    });
  }

  render() {
    const idLoad = this.props.match.params.id;
    return (
      <>
        <CreateLoadView
          aircraftList={this.state.aircraftList}
          uldList={this.props.stockULDSReducer.uldList}
          uldsBank={this.state.uldsBank}
          addUldStock={this.addUldStock}
          deleteUldStock={this.deleteUldStock}
          updateUldStock={this.updateUldStock}
          isMainLoading={this.state.isMainLoading}
          isAsyncLoading={this.state.isAsyncLoading}
          isAddLoading={this.state.isAddLoading}
          isUpdating={this.state.isUpdating}
          addLoad={this.addLoad}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUldStock: (uld) => {
      dispatch({ type: "ADDULDSTOCK", data: uld });
    },
    deleteUldStock: (id) => {
      dispatch({ type: "DELETEULDSTOCK", data: { id: id } });
    },
    updateUldStock: (data) => {
      dispatch({ type: "UPDATEULDSTOCK", data: data });
    },
    refreshUldStock: (data) => {
      dispatch({ type: "REFRESHULDSTOCK", data: data });
    },
    updateLoads: (loadsList) => {
      dispatch({ type: "UPDATELOADS", data: loadsList });
    },
  };
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
    stockULDSReducer: state.stockULDSReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLoadContainer);
