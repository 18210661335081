import React, { Component } from "react";
import LoadingView from "./LoadingView";
import { connect } from "react-redux";
import ALENToast from "components/ALENToast";
import { withTranslation } from "react-i18next";
import { getLoading, charge, getOptimizedUlds } from "api/methods/parcel";

class LoadingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: [],
      load: null,
      uld: null,
    };
  }

  loadDatas = () => {
    const idLoad = this.props.match.params.load;
    const idUld = this.props.match.params.uld;
    const parcelId = this.props.match.params.parcel;

    getLoading(this.props.userReducer.token, idUld).then((response) => {
      console.log("responseLOADING", response);
      this.setState({ loading: response.data });
    });

    getOptimizedUlds(this.props.userReducer.token, idLoad).then((response) => {
      if (response.status === 200 && response.ok) {
        //this.props.updateParcels(response.data, idLoad);
        console.log("RESPONSE", response);
        response.data.forEach((uld) => {
          if (uld.uld_stock_id.toString() === this.props.match.params.uld) {
            let finish = false;
            let uldClean = uld;
            let load_uld_parcels = [];
            uldClean.load_uld_parcels.forEach((parcel, index) => {
              if (!finish) {
                const parcelClean = {
                  ...parcel,
                  parcel: {
                    ...parcel.parcel,
                    position: JSON.parse(parcel.parcel.position),
                  },
                };

                if (parcel.parcel_id.toString() === parcelId) {
                  finish = true;
                }
                load_uld_parcels.push(parcelClean);
              }
            });

            uldClean.load_uld_parcels = [...load_uld_parcels];
            this.setState({ uld: uldClean });
          }
        });
      }
    });

    this.props.loadsReducer.loads.forEach((load) => {
      if (load.id.toString() === idLoad) {
        this.setState({ load: load });
      }
    });
  };

  componentDidMount() {
    this.loadDatas();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.load !== this.props.match.params.load ||
      prevProps.match.params.uld !== this.props.match.params.uld ||
      prevProps.match.params.parcel !== this.props.match.params.parcel
    ) {
      this.loadDatas();
    }
  }

  previous = (index) => {
    if (index >= 0 && index < this.state.loading.length) {
      this.props.history.push(
        "/loading/" +
          this.props.match.params.load +
          "/" +
          this.props.match.params.uld +
          "/" +
          this.state.loading[index].id +
          "/" +
          this.props.match.params.uldStock
      );
    }
  };

  next = (index) => {
    if (index >= 0 && index < this.state.loading.length) {
      this.props.history.push(
        "/loading/" +
          this.props.match.params.load +
          "/" +
          this.props.match.params.uld +
          "/" +
          this.state.loading[index].id +
          "/" +
          this.props.match.params.uldStock
      );
    }
    /*const parcel_id = this.state.loading[index-1].id
    charge(this.props.userReducer.token, parcel_id).then((response) => {
      console.log('response', response)
      if(index >= 0 && index < this.state.loading.length) {
        this.props.history.push("/loading/"+this.props.match.params.load+"/"+this.props.match.params.uld+"/"+this.state.loading[index].id+"/"+this.props.match.params.uldStock)
      }
    })*/
  };

  render() {
    return (
      <>
        {this.state.uld !== null && (
          <LoadingView
            load={this.state.load}
            loading={this.state.loading}
            idLoad={this.props.match.params.load}
            idUld={this.props.match.params.uld}
            parcelId={this.props.match.params.parcel}
            uldStock={this.props.match.params.uldStock}
            next={this.next}
            previous={this.previous}
            uld={this.state.uld}
          />
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLoad: (load) => {
      dispatch({ type: "ADDLOAD", data: load });
    },
    updateParcels: (parcels, load_id) => {
      dispatch({
        type: "UPDATEPARCELS",
        data: { parcels: parcels, id: load_id },
      });
    },
  };
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingContainer);
