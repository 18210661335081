import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  HashRouter,
  Route,
  Switch,
  BrowserRouter
} from 'react-router-dom';
import { createBrowserHistory } from "history";

import { Provider } from 'react-redux'
import { store, persistor } from 'store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import translations from 'translations';
import ALENDrawer from 'components/ALENDrawer';

import Test3D from 'screens/Test3D';
import Login from 'screens/Login';
import Home from 'screens/Home';
import StockManagement from 'screens/StockManagement';
import LoadsList from 'screens/LoadsList';
import ParcelsList from 'screens/ParcelsList';
import AddParcels from 'screens/AddParcels';
import OptimizedList from 'screens/OptimizedList';
import CreateLoad from 'screens/CreateLoad';
import Loading from 'screens/Loading';
import TiedownCalculator from 'screens/TiedownCalculator';
import CreateTiedown from 'screens/CreateTiedown';
import TiedownsList from 'screens/TiedownsList';

const hist = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={ store }>
        <BrowserRouter history={hist}>
          <Switch>
            <ALENDrawer
              history={hist}>
              <Route exact path="/" component={Login}/>
            </ALENDrawer>
          </Switch>
        </BrowserRouter>
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
