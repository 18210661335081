import React from 'react';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {ALENGREEN} from 'utils/colors';
import moment from "moment";

function ALENDatePicker(props) {
  require.resolve('react-dates/lib/css/_datepicker.css')
  moment.locale("en");

  const [focused, setFocused] = React.useState(false);

  if(props.value === "") {
    let date = moment(new Date(), "YYYY-DD-MM")
    props.onChange(date.format('YYYY-MM-DD'))
  }

  return(
    <SingleDatePicker
      placeholder={props.placeholder}
      date={new moment(props.value)}
      numberOfMonths={1}
      onDateChange={date =>
      {
        if(date !== null) {
          props.onChange(date.format('YYYY-MM-DD'))
        }
      }}
      focused={focused} // PropTypes.bool
      onFocusChange={() => setFocused(!focused)}
      id="your_unique_id"
      style={{minWidth:245, cursor:'pointer'}}
      customInputIcon={<CalendarTodayIcon style={{color:ALENGREEN}}/>}
      inputIconPosition={'after'}
    />
  )
}

export default ALENDatePicker
