import React, {Component} from 'react';
import TiedownsListView from './TiedownsListView';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getAircraftList } from 'api/methods/load';

class TiedownsListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      aircraftList: [],
    }
  }

  componentDidMount() {
    /*get aircraft list APPEL API aircraft/getlist*/
    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })
  }

  render() {
    return(
      <>
        <TiedownsListView
          aircraftList={this.state.aircraftList}
          tiedowns={this.props.tiedownsReducer.tiedowns}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLoad: (load) => {
      dispatch({type: "ADDLOAD", data: load})
    },
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    tiedownsReducer: state.tiedownsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TiedownsListContainer)
