import * as React from "react";

function SvgLoad(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M4 3h16l2 4v13a1 1 0 01-1 1H3a1 1 0 01-1-1V7.004L4 3zm16 6H4v10h16V9zm-.236-2l-1-2H5.237l-1 2h15.527zM13 14h3l-4 4-4-4h3v-4h2v4z"
      />
    </svg>
  );
}

export default SvgLoad;
