import * as React from "react";

function SvgBef(props) {
  return (
    <svg width={512} height={512} viewBox="0 0 480 480" {...props}>
      <path
        d="M18.752 285.264l128 128c24.928 24.96 65.568 24.96 90.496 0C249.344 401.2 256 385.104 256 368.016s-6.656-33.184-18.752-45.248l-82.752-82.752 82.752-82.752C249.344 145.168 256 129.104 256 112.016s-6.656-33.152-18.752-45.248C225.152 54.672 209.088 48.016 192 48.016s-33.152 6.656-45.248 18.752l-128 128C6.656 206.832 0 222.928 0 240.016s6.656 33.184 18.752 45.248zm22.624-67.872l128-128c12.128-12.096 33.12-12.096 45.248 0 12.48 12.48 12.48 32.768 0 45.248L109.248 240.016l105.376 105.376c6.048 6.048 9.376 14.08 9.376 22.624s-3.328 16.576-9.376 22.624A31.912 31.912 0 01192 400.016a32.013 32.013 0 01-22.624-9.344l-128-128C35.328 256.592 32 248.56 32 240.016s3.328-16.576 9.376-22.624z"
        data-original="#000000"
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d="M242.752 285.264l128 128c24.928 24.96 65.568 24.96 90.496 0C473.344 401.2 480 385.104 480 368.016s-6.656-33.184-18.752-45.248l-82.752-82.752 82.752-82.752C473.344 145.168 480 129.104 480 112.016s-6.656-33.152-18.752-45.248C449.152 54.672 433.088 48.016 416 48.016s-33.152 6.656-45.248 18.752l-128 128C230.656 206.832 224 222.928 224 240.016s6.656 33.184 18.752 45.248zm22.624-67.872l128-128c12.128-12.096 33.12-12.096 45.248 0 12.48 12.48 12.48 32.768 0 45.248L333.248 240.016l105.376 105.376c6.048 6.048 9.376 14.08 9.376 22.624s-3.328 16.576-9.376 22.624c-6.24 6.24-14.432 9.376-22.624 9.376s-16.384-3.136-22.624-9.344l-128-128c-6.048-6.08-9.376-14.112-9.376-22.656s3.328-16.576 9.376-22.624z"
        data-original="#000000"
        xmlns="http://www.w3.org/2000/svg"
      />
    </svg>
  );
}

export default SvgBef;
