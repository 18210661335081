import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {ALENULTRABLACK, ALENWHITE, ALENOPACITY2, ALENBLACK, ALENBG,ALENGREEN, ALENRED, ALENGREY, ALENBORDERINPUT, ALENBLUE, ALENBORDER, TABLEBORDER, ALENDISABLEDDARK, STATUS} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP, FONTMINIBIG, FONTLITTLE2} from 'utils/fontSizes'
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgDelete from 'components/icons/Delete';
import ALENCheckBox from 'components/ALENCheckBox';
import ALENParcelStatus from 'components/ALENParcelStatus';
import ALENSwitch from 'components/ALENSwitch';
import SvgSuccess from 'components/icons/Success';
import SvgMore from 'components/icons/More';
import SvgIsCrushable from 'components/icons/IsCrushable';
import SvgIsHeavy from 'components/icons/IsHeavy';
import SvgIsNoStackable from 'components/icons/IsNoStackable';

class ALENParcelsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockValues: [],
      isAdding: false,

      checkAll: false,
      selectedParcels: [],
    }
  }

  toggleCheck = (id) => {
    let selectedParcels = this.state.selectedParcels
    let index = selectedParcels.indexOf(id)

    if(index > -1) {
      selectedParcels.splice(index, 1)
    } else {
      selectedParcels.push(id)
    }
    this.setState({selectedParcels: selectedParcels})
  }

  renderLine = (parcel) => {
    const { t, i18n } = this.props;
    //let selected = this.state.selectedParcels.find(el => el === parcel.id)
    let selected = (parcel.status === "20")

    return(
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          flex:1,
          width:'100%',
          borderWidth:0,
          borderBottomWidth:1,
          borderStyle:'solid',
          backgroundColor:selected ? ALENBORDER : ALENWHITE,
          borderColor:ALENBG,
        }}>
        <div
          style={{
            height:52,
            flex:1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          {(parcel.status !== "0") ?
            <SvgSuccess
              fill={ALENGREEN}
            />
          :
            <SvgMore
              fill={ALENGREEN}
            />
          }
        </div>
        <div
          style={{
            height:52,
            flex:3,
            flexDirection:'row',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            lineThrough={selected}
            color={selected ? ALENGREY : ALENBLACK}
          >
            {parcel.airway_bill}
          </ALENText>
        </div>
        <div
          style={{
            height:52,
            flex:1,
            flexDirection:'row',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <SvgIsHeavy
            height={30}
            width={30}
            fill={parcel.is_heavy === 1 ? ALENGREEN : "rgba(0, 0, 0, 0.1)"}
            />
          <SvgIsNoStackable
            height={30}
            width={30}
            style={{marginLeft:16}}
            fill={parcel.is_no_stackable === 1 ? ALENGREEN : "rgba(0, 0, 0, 0.1)"}
            />
          <SvgIsCrushable
            height={30}
            width={30}
            style={{marginLeft:16}}
            fill={parcel.is_crushable === 1 ? ALENGREEN : "rgba(0, 0, 0, 0.1)"}
            />
        </div>
        <div
          style={{
            height:52,
            flex:1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            lineThrough={selected}
            color={selected ? ALENGREY : ALENBLACK}
          >
            {parcel.length/10}
          </ALENText>
        </div>
        <div
          style={{
            height:52,
            flex:1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            lineThrough={selected}
            color={selected ? ALENGREY : ALENBLACK}
          >
            {parcel.width/10}
          </ALENText>
        </div>
        <div
          style={{
            height:52,
            flex:1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            lineThrough={selected}
            color={selected ? ALENGREY : ALENBLACK}
          >
            {parcel.height/10}
          </ALENText>
        </div>
        <div
          style={{
            height:52,
            flex:1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            lineThrough={selected}
            color={selected ? ALENGREY : ALENBLACK}
          >
            {parcel.weight}
          </ALENText>
        </div>
        {/*<div
          style={{
            height:52,
            width:141,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={selected ? ALENGREY : ALENBLACK}
          >
            <ALENParcelStatus
              status={parcel.status}
            />
          </ALENText>
        </div>*/}
        <div
          style={{
            height:52,
            flex:0.5,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <SvgDelete
            style={{
              cursor:'pointer'
            }}
            onClick={() => this.props.onDelete(parcel)}
            fill={ALENDISABLEDDARK}
            width={17.5}
            height={17.5}
          />
        </div>
      </div>
    )
  }

  renderHeaders = () => {
    const { t, i18n } = this.props;

    return (
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          width:'100%',
        }}>
        <div
          style={{
            height:56,
            flex:1,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.6')}
          </ALENText>
        </div>
        <div
          style={{
            height:56,
            flex:3,
            backgroundColor:ALENBG,
            flexDirection:'row',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.0')}
          </ALENText>
        </div>
        <div
          style={{
            height:56,
            flex:1,
            backgroundColor:ALENBG,
            flexDirection:'row',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.7')}
          </ALENText>
        </div>
        <div
          style={{
            height:56,
            flex:1,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.1')}
          </ALENText>
        </div>
        <div
          style={{
            height:56,
            flex:1,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.2')}
          </ALENText>
        </div>
        <div
          style={{
            height:56,
            flex:1,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.3')}
          </ALENText>
        </div>
        <div
          style={{
            height:56,
            flex:1,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.4')}
          </ALENText>
        </div>
        {/*<div
          style={{
            height:56,
            width:173,
            backgroundColor:ALENBG,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}>
          <ALENText
            color={ALENDISABLEDDARK}
            bold
          >
            {t('parcelsList.table.headers.6')}
          </ALENText>
        </div>*/}
        <div
          style={{
            height:56,
            flex:0.5,
            backgroundColor:ALENBG,
          }}>
        </div>
      </div>
    )
  }

  renderPagination = () => {
    const t = this.props.t

    let renderPages = []

    for(let i = 1; i<=this.props.totalPage; i++) {
      renderPages.push(
        <div
          onClick={() => this.props.changePage(i)}
          style={{
            cursor:'pointer',
            borderColor:ALENBORDERINPUT,
            borderRadius:4,
            borderStyle:'solid',
            borderWidth:1,
            minWidth:37,
            minHeight:37,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:8,
            backgroundColor:i===this.props.page ? ALENBLUE : ALENWHITE,
          }}>
          <ALENText
            bold={i===this.props.page}
            color={i===this.props.page ? ALENWHITE : ALENGREY}>
            {i}
          </ALENText>
        </div>
      )
    }

    return (
      <div
        style={{
          marginTop:48,
          marginBottom:35,
          display:'flex',
          flexDirection:'row',
        }}>
        {/*
        <ALENText>
          {t('parcelsList.pagination.showEntries')}
        </ALENText>
        {this.props.showEntries}
        */}
        <div
          onClick={() => {
            if(this.props.page > 1) {
              let newPage = this.props.page -1
              this.props.changePage(newPage)
            }
          }}
          style={{
            cursor:"pointer",
            borderColor:ALENBORDERINPUT,
            borderRadius:4,
            borderStyle:'solid',
            borderWidth:1,
            minWidth:37,
            minHeight:37,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:8,
          }}>
          <ALENText
            bold
            color={ALENGREY}>
            {"<"}
          </ALENText>
        </div>
        {renderPages}
        <div
          onClick={() => {
            if(this.props.page < this.props.totalPage) {
              let newPage = this.props.page +1
              this.props.changePage(newPage)
            }
          }}
          style={{
            cursor:"pointer",
            borderColor:ALENBORDERINPUT,
            borderRadius:4,
            borderStyle:'solid',
            borderWidth:1,
            minWidth:37,
            minHeight:37,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginLeft:8,
          }}>
          <ALENText
            bold
            color={ALENGREY}>
            {">"}
          </ALENText>
        </div>
      </div>
    )
  }

  renderSwitch = () => {
    const t = this.props.t
    return (
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          marginTop:24,
          justifyContent:'flex-start',
          alignItems:'center',
          width:1077,
          flex:1,
        }}>
        <ALENSwitch
        />
        <ALENText>
          {t('parcelsList.showLoadedParcels')}
        </ALENText>

      </div>
    )
  }

  render() {
    const { t, i18n } = this.props;

    return(
      <div
        style={{
          paddingLeft:33,
          paddingRight:31,
          flexDirection:'column',
          display:'flex',
          alignItems:'center',
        }}>
        {this.renderHeaders()}
        {this.props.parcels.map((parcel) => {
          return(
            <>
              {this.renderLine(parcel)}
            </>
          )
        })}
        {this.renderPagination()}
      </div>
    )
  }
}

ALENParcelsList.propTypes = {
  parcels: PropTypes.array,
};

ALENParcelsList.defaultProps = {
  parcels: [],
};

export default withTranslation()(ALENParcelsList)
