import React from 'react';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgAdd from 'components/icons/Add';
import SvgDownload from 'components/icons/Download';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP} from 'utils/fontSizes'
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENGREEN, ALENGREY, ALENDISABLEDDARK, ALENBORDER, STATUS} from "utils/colors";

function ALENOptimizedListHeader(props) {
  const t = props.t

  return(
    <>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center',
        }}>
        <div
          style={{
            width:'100%',
            padding:32,
            marginRight:16,
            marginLeft:16,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
          }}>
            <div>
              <ALENText
                size={FONTBIGSUP}
                color={ALENBLACK}
                bold>
                {props.nbUlds.toString() + " " + t('commons.ULD')+ ((props.nbUlds > 1) ? "s" : "")}
              </ALENText>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
              }}>
              <ALENButton
                startIcon={<SvgDownload fill={ALENWHITE} style={{width:22, height:22}}/>}
                onPress={props.getPdf}
                label={t('optimize.downloadPdf')}
                medium/>
            </div>
        </div>
      </div>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center',
        }}>
      </div>
    </>
  )
}

export default withTranslation()(ALENOptimizedListHeader)
