import Api from 'api';
import ApiConstants from '../ApiConstants';

export function addParcel(token, data) {
  return Api(
    ApiConstants.ADDPARCEL,
    data,
    'POST',
    token,
  )
}

export function parcelGetList(token, load_id, page) {
    return Api(
        ApiConstants.PARCELGETLIST+"/"+load_id+"/"+page+"/20",
        null,
        'GET',
        token,
    )
}

export function optimize(token, load_id, case_id) {
  return Api(
    ApiConstants.OPTIMIZE+"/"+load_id+"/case/"+case_id,
    null,
    'GET',
    token,
  )
}

export function getOptimizedUlds(token, load_id) {
  return Api(
    ApiConstants.GETOPTIMIZEDULDS+"/"+load_id,
    null,
    'GET',
    token,
  )
}

export function getLoading(token, uld_id) {
  return Api(
    ApiConstants.GETLOADING+"/"+uld_id,
    null,
    'GET',
    token,
  )
}

export function charge(token, parcel_id) {
  return Api(
    ApiConstants.CHARGE+"/"+parcel_id,
    null,
    'PUT',
    token,
  )
}

export function deleteParcel(token, parcel_id) {
  return Api(
    ApiConstants.PARCELDELETE + '/' + parcel_id,
    null,
    'DELETE',
    token,
  )
}
