import Api from 'api';
import ApiConstants from '../ApiConstants';

export function addBalance(token, data) {
  return Api(
    ApiConstants.ADDBALANCE,
    data,
    'POST',
    token,
  )
}

export function getBalances(token) {
  return Api(
    ApiConstants.GETBALANCELIST,
    null,
    'GET',
    token,
  )
}

export function getBalance(token, id) {
  return Api(
    ApiConstants.GETBALANCE + '/' + id,
    null,
    'GET',
    token,
  )
}

export function updateBalance(token, id, data) {
  return Api(
    ApiConstants.UPDATEBALANCE + '/' + id,
    data,
    'PUT',
    token,
  )
}
