import React, {Component} from 'react';
import SvgBackgroundLogin from "components/icons/BackgroundLogin";
import { withTranslation } from 'react-i18next';
import {ALENBLUE, ALENBG, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import ALENLayout from 'components/ALENLayout';
import ALENLoadsEmpty from 'components/ALENLoadsEmpty';
import ALENLoadsTable from 'components/ALENLoadsTable';
import ALENText from 'components/ALENText';
import ALENModalDeleteLoad from 'components/ALENModalDeleteLoad';

class LoadsListView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstAdd: true,

      aircraftList: [],

      loadId: "",
      valueSelected: "",

      errorLoadId: false,
      errorAircraft: false,

      delete: null,
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  onChangeLoadId = (loadId) => {
    this.setState({loadId: loadId})
  }

  onSelectAircraft = (aircraftId) => {
    this.setState({valueSelected: aircraftId})
  }

  onDelete = (load) => {
    this.setState({delete: load})
  }

  addLoad = () => {
    let valid = true
    this.setState({
      errorLoadId: false,
      errorAircraft: false,
    })

    const load = {
      load_id : this.state.loadId,
      aircraft_id : this.state.valueSelected,
    }

    if( load.load_id === undefined || load.load_id.length < 1 || load.load_id === "") {
      valid = false
      this.setState({errorLoadId: true})
    }

    if( load.aircraft_id === undefined || load.aircraft_id === "" || load.aircraft_id.length < 1) {
      valid = false
      this.setState({errorAircraft : true})
    }

    if(valid) {
      this.props.addLoad(load)
    }
  }

  render()
  {
    let t = this.props.t

    const ariane = [
      {
        label: t('loads.title'),
        link: 'current',
      },
    ]

    return(
      <>
        <ALENLayout
          title={t('loads.title')}
          ariane={ariane}
          isAsyncLoading={this.props.isLoading}
        >
          {(this.props.loads.length > 0 || !this.state.firstAdd)?
            <>
              <ALENLoadsTable
                aircraftList={this.props.aircraftList}
                valueSelected={this.state.valueSelected}
                loadId={this.state.loadId}
                onChangeLoadId={this.onChangeLoadId}
                onSelectAircraft={this.onSelectAircraft}
                addLoad={this.addLoad}
                errorLoadId={this.state.errorLoadId}
                errorAircraft={this.state.errorAircraft}
                loads={this.props.loads}
                onDelete={this.onDelete}
              />
              {this.state.delete !== null &&
                <ALENModalDeleteLoad
                  load={this.state.delete}
                  yes={() => {
                    const load_idToDelete = this.state.delete.id
                    this.props.deleteLoad(load_idToDelete)
                    this.setState({delete: null})
                  }}
                  no={() => this.setState({delete: null})}
                  back={() => this.setState({delete: null})}
                />
              }
            </>
          :
            <ALENLoadsEmpty
              onPressNew={() => this.setState({firstAdd: false})}/>
          }
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(LoadsListView)
