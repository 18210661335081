import React, {Component} from 'react';
import LoginView from './LoginView'
import {login} from 'api/methods/user'
import { connect } from 'react-redux';

class LoginContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,

      isApiError: false,
    }
  }

  componentDidMount() {
  }

  login = (data) => {

    this.setState({isLoading:true})
    login(data).then((response) => {
      this.setState({isLoading:false})
      if(response.status === 200 && response.ok) {
        const user = {
          ...response.data,
        }
        this.props.resetRedux()
        this.props.login(user)
        window.location.href="/tiedowns"
      } else {
        this.setState({isApiError: true})
        setTimeout(() => {
          this.setState({isApiError:false})
        }, 3000);
      }
    })
  }

  render() {
    return(
      <LoginView
        login={this.login}
        isLoading={this.state.isLoading}
        isApiError={this.state.isApiError}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (user) => {
      dispatch({type: "LOGIN", data: user})
    },
    resetRedux: () => {
      dispatch({type:"reset", data: null})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginContainer)
