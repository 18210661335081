const initialState = {
    loads: [],
}

function loadsReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'LOGOUT' : {
      return initialState
    }
    case 'RESET' :
      return initialState
    case 'UPDATEPARCELS' :
      nextState = {
        ...state,
      }

      const id = action.data.id
      const parcels = action.data.parcels

      let loadsUpdate = nextState.loads
      for(let i = 0; i<loadsUpdate.length; i++) {
        if(parseInt(loadsUpdate[i].id, 10) === parseInt(id, 10)) {
          loadsUpdate[i].parcels = parcels
        }
      }

      nextState = {
        ...state,
        loads: loadsUpdate,
      }

      return nextState
    case 'INSERTPARCELS' :
      nextState = {
        ...state,
      }

      let loadsInsert = nextState.loads
      let indexToChange = null
      let newLoad = null

      loadsInsert.forEach((load, index) => {
        if(parseInt(load.id, 10) === parseInt(action.data.id, 10)) {
          let parcelsList = (load.parcels !== undefined) ? load.parcels : []
          action.data.parcelsList.forEach((parcel) => {
            parcelsList.push(parcel)
          })
          newLoad = {
            ...load,
            parcels: parcelsList,
          }
          indexToChange = index
        }
      })

      if((indexToChange !== null)&&(newLoad !== null)) {
        loadsInsert[indexToChange] = newLoad
      }

      nextState = {
        ...state,
        loads: loadsInsert
      }
      return nextState
    case 'UPDATELOADS':
      nextState = {
        ...state,
        loads: action.data
      }
      return nextState
    case 'ADDLOAD' :
      nextState = {
        ...state,
      }

      let loadsAdd = nextState.loads
      loadsAdd.push(action.data)
      nextState = {
        ...state,
        loads: loadsAdd
      }
      return nextState
    default:
      return state
  }
}

export default loadsReducer
