import React, {Component} from 'react';
import ALENLayout from 'components/ALENLayout';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENButton from 'components/ALENButton';
import alenLogo from 'assets/images/alen.png';
import {ALENWHITE, ALENLINK, ALENGREEN, ALENBORDER,ALENGREENDARK, ALENBLACK, ALENBLUE, ALENDISABLEDDARK} from 'utils/colors';
import {FONTMEGABIG, FONTMINIBIG, FONTVERYBIG, FONTBIGSUP, FONTNORMALSUP, FONTGIGABIG} from 'utils/fontSizes';
import SvgModule1 from 'components/icons/Module1';
import SvgModule2 from 'components/icons/Module2';
import SvgTiedown from 'components/icons/Tiedown';

class ManageAccountView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  render() {

    let t = this.props.t

    const ariane = [
      {
        label: t('commons.account'),
        link: 'current',
      },
    ]

    console.log('this.props', this.props)

    return(
      <>
        <ALENLayout
          title={t('commons.account')}
          ariane={ariane}
          isAsyncLoading={this.props.isLoading}
        >
          <div
            style={{
              flexDirection:'column',
              display:'flex',
              padding:48,
            }}>
            <div
              style={{
                flexDirection:'row',
                display:'flex',
                marginLeft:24,
              }}>
              <div
                style={{
                  flexDirection:'column',
                  display:'flex',
                  marginRight:24,
                  justifyContent:'center',
                }}>
                {this.props.user.company.avatar !== null ?
                  <div
                    style={{
                      marginTop:-5,
                      flexDirection:'row',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      borderRadius:'50%',
                      backgroundColor:'white',
                      boxShadow: '-2px 3px 9px #0000003b',
                      width:70,
                      height:70,
                    }}>
                    <img
                      src={this.props.user.company.avatar}
                      style={{
                        width:70,
                        borderRadius:'50%',
                        height:'auto'}}/>
                  </div>
                  :
                  <div
                    style={{
                      marginTop:-5,
                      flexDirection:'row',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      borderRadius:'50%',
                      backgroundColor:'white',
                      width:70,
                      height:70,
                    }}>
                    <img
                      src={alenLogo}
                      style={{
                        width:70,
                        borderRadius:'50%',
                        boxShadow: '-2px 3px 9px #0000003b',
                        height:'auto'}}/>
                    </div>
                  }
              </div>
              <div
                style={{
                  flexDirection:'column',
                  display:'flex',
                  flex:1,
                }}>
                <ALENText
                  size={FONTBIGSUP}>
                  {this.props.user.company.name}
                </ALENText>
                <span style={{marginTop:-8}}>
                  <ALENText
                    size={FONTMINIBIG}
                    bold
                    color={ALENGREENDARK}>
                    {`${this.props.user.firstname} ${this.props.user.lastname}`}
                  </ALENText>
                </span>
                <span style={{marginTop:-3}}>
                  <ALENText
                    underline
                    bold>
                    {this.props.user.email}
                  </ALENText>
                </span>
              </div>
            </div>

            <div
              style={{
                margin:24,
                marginTop:64,
                flexDirection:'column',
                display:'flex',
                alignItems:'center',
              }}>
              <ALENText
                bold
                color={ALENBLUE}
                size={FONTBIGSUP}
                >
                {t('manageAccount.listModules')}
              </ALENText>
              <div
                style={{
                  flexDirection:'row',
                  display:'flex',
                  marginTop:24,
                  justifyContent:'center',
                }}>
                <div style={{
                  flexDirection:'column',
                  display:'flex',
                  margin:16,
                  alignItems:'center',
                }}>
                  <SvgModule1
                    width={80}
                    height={80}
                    fill={(this.props.user.company.modules.findIndex((module) => module.libelle === "OPTIMISATION_ULD") !== -1) ? ALENBLUE : "rgba(0, 0, 0, 0.1)"}
                    style={{
                      marginBottom:16
                    }}
                  />
                  <ALENText
                    bold
                    size={FONTMINIBIG}
                    color={(this.props.user.company.modules.findIndex((module) => module.libelle === "OPTIMISATION_ULD") !== -1) ? ALENBLUE : "rgba(0, 0, 0, 0.1)"}
                    >
                    {t('manageAccount.OPTIMISATION_ULD')}
                  </ALENText>
                </div>
                <div style={{
                  flexDirection:'column',
                  display:'flex',
                  margin:16,
                  alignItems:'center',
                }}>
                  <SvgModule2
                    width={80}
                    height={80}
                    fill={(this.props.user.company.modules.findIndex((module) => module.libelle === "LOADSHEET") !== -1) ? ALENBLUE : "rgba(0, 0, 0, 0.1)"}
                    style={{
                      marginBottom:16
                    }}
                    />
                  <ALENText
                    bold
                    size={FONTMINIBIG}
                    color={(this.props.user.company.modules.findIndex((module) => module.libelle === "LOADSHEET") !== -1) ? ALENBLUE : "rgba(0, 0, 0, 0.1)"}
                    >
                    {t('manageAccount.LOADSHEET')}
                  </ALENText>
                </div>
                <div style={{
                  flexDirection:'column',
                  display:'flex',
                  margin:16,
                  alignItems:'center',
                }}>
                  <SvgTiedown
                    width={80}
                    height={80}
                    style={{
                      marginBottom:16
                    }}
                    fill={(this.props.user.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1) ? ALENBLUE : "rgba(0, 0, 0, 0.1)"}/>
                  <ALENText
                    bold
                    size={FONTMINIBIG}
                    color={(this.props.user.company.modules.findIndex((module) => module.libelle === "STRAPPING_PLAN") !== -1) ? ALENBLUE : "rgba(0, 0, 0, 0.1)"}
                    >
                    {t('manageAccount.STRAPPING_PLAN')}
                  </ALENText>
                </div>
              </div>
            </div>
            <div style={{
              margin:24,
              alignSelf:'center',
            }}>
              <ALENButton
                big
                label={t('manageAccount.changePassword.change')}
                onPress={this.props.showChangePassword}
              />
            </div>
          </div>
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(ManageAccountView);
