const initialState = {
    balances: [],
}

function balanceReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'LOGOUT' : {
      return initialState
    }
    case 'RESET' :
      return initialState
    case 'UPDATEBALANCES':
      nextState = {
        ...state,
        balances: action.data
      }
      return nextState
    default:
      return state
  }
}

export default balanceReducer
