import React, {Component} from 'react';
import ParcelsListView from './ParcelsListView';
import { connect } from 'react-redux';
import ALENToast from 'components/ALENToast';
import { withTranslation } from 'react-i18next';
import {parcelGetList, optimize} from 'api/methods/parcel';
import { getAircraftList, loadUpdate, getLoads } from 'api/methods/load';
import { addParcel, deleteParcel } from 'api/methods/parcel';

class ParcelsListContainer extends Component {
  constructor(props) {
    super(props)

    this.state={
      load: null,
      showOptimize: false,
      optimize: false,
      resultOptimize: [],
      case_id: 30,
      showEntries: 20,
      page: 1,
      totalPage: 1,
      aircraftList: [],
      isLoading: false,
      showConfirm: false,
      isMainLoading: false,
      showConfirmParcel: false,
    }
  }

  deleteParcel = (parcel_id) => {
    this.setState({isLoading: true})
    deleteParcel(this.props.userReducer.token, parcel_id).then((response) => {
      this.setState({isLoading: false})
      if(response.status === 200 && response.ok) {
        this.setState({showConfirmDeleteParcel: true})
        this.loadDatas(this.state.page)
        setTimeout(() => {
          this.setState({showConfirmDeleteParcel:false})
        }, 3000);
      }
    })
  }

  loadDatas = (page) => {
    const idLoad = this.props.match.params.id

    this.setState({isMainLoading: true})

    parcelGetList(this.props.userReducer.token, idLoad, page).then((response) => {
      this.setState({isMainLoading: false})
      if(response.status === 200 && response.ok) {
        this.setState({showEntries: response.data.per_page, totalPage: response.data.last_page, page: response.data.current_page})
        this.props.updateParcels(response.data.data, idLoad)
      }
    })

    this.props.loadsReducer.loads.forEach((load) => {
      if(load.id.toString() === idLoad) {
        this.setState({load: load})
      }
    })
  }

  componentDidMount() {
    this.loadDatas(this.state.page)
    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.id !== this.props.match.params.id){
      this.loadDatas(this.state.page)
    }
  }

  optimizeUld = () => {
    this.setState({showOptimize: true, optimize: true})
    const idLoad = this.props.match.params.id
    const case_id = this.state.case_id
    optimize(this.props.userReducer.token, idLoad, case_id).then((response) => {
      console.log('response', response)
      if(response.status === 200 && response.ok) {
        this.setState({resultOptimize: response.data, optimize: false})
      }
    })
  }

  changePage = (page) => {
    this.setState({page: page})
    this.loadDatas(page)
  }

  updateLoad = (newLoad) => {
    this.setState({isLoading: true})
    loadUpdate(this.props.userReducer.token, newLoad, this.state.load.id).then((response) => {
      this.setState({isLoading: false})
      if(response.status === 200 && response.ok) {
        this.setState({isLoading: true})
        getLoads(this.props.userReducer.token).then((response) => {
          this.setState({isLoading: false})
          if(response.status === 200 && response.ok) {
            const newLoads = []
            response.data.forEach((load) => {
              newLoads.push({
                ...load,
                nbParcels: load.parcels.length
              })
            })
            this.props.updateLoads(newLoads)
            this.loadDatas(this.state.page)
            this.setState({
              isLoading: false,
              showConfirm: true,
            })

            setTimeout(() => {
              this.setState({showConfirm:false})
            }, 3000);
          }
        })
      }
    })
  }

  addParcel = (values) => {
    this.setState({isLoading: true})
    const parcel = {
      id: Date.now(),
      name: values.top[0],
      qte: values.mid[0],
      l: values.mid[1]*10,
      w: values.mid[2]*10,
      h: values.mid[3]*10,
      weight: values.mid[4],
      status: 0,
      airway_bill_type: values.top[1],
      nature_of_good: values.top[2],
      use_by_owner_operator: values.top[3],
      official_use: values.top[4],
      number_of_hawb: values.top[5],
      shipper_name_address: values.bot[0],
      consignee_name_address: values.bot[1],
      is_heavy: values.bot[2],
      is_no_stackable: values.bot[3],
      is_crushable: values.bot[4],
    }

    const addingParcel = {
      airway_bill: parcel.name,
      quantity: parcel.qte,
      length: parcel.l,
      width: parcel.w,
      height: parcel.h,
      weight: parcel.weight,
      load_id: this.state.load.id,
      airway_bill_type: parcel.airway_bill_type,
      nature_of_good: parcel.nature_of_good,
      use_by_owner_operator: parcel.use_by_owner_operator,
      official_use: parcel.official_use,
      number_of_hawb: parcel.number_of_hawb,
      shipper_name_address: parcel.shipper_name_address,
      consignee_name_address: parcel.consignee_name_address,
      is_heavy: parcel.is_heavy, 
      is_no_stackable: parcel.is_no_stackable, 
      is_crushable: parcel.is_crushable, 
    }

    addParcel(this.props.userReducer.token, addingParcel).then((response) => {
      this.setState({isLoading: false})
      if(response.status === 200 && response.ok) {
        const insertParcel = response.data
        this.loadDatas(this.state.page)
        this.setState({
          isLoading: false,
          showConfirmParcel: true,
        })

        setTimeout(() => {
          this.setState({showConfirmParcel:false})
        }, 3000);
      }
    })
  }

  render() {
    return(
      <>
        <ParcelsListView
          addParcel={this.addParcel}
          caseId={this.state.case_id}
          changeCase={(case_id) => this.setState({case_id: case_id})}
          load={this.state.load}
          optimizeUld={this.optimizeUld}
          showOptimize = {this.state.showOptimize}
          optimize={this.state.optimize}
          resultOptimize={this.state.resultOptimize}
          closeOptimize={() => this.setState({showOptimize: false, optimize: false, resultOptimize: []})}
          showEntries = {this.state.showEntries}
          page = {this.state.page}
          totalPage = {this.state.totalPage}
          changePage={this.changePage}
          aircraftList={this.state.aircraftList}
          updateLoad={this.updateLoad}
          isLoading={this.state.isLoading}
          isMainLoading={this.state.isMainLoading}
          deleteParcel={this.deleteParcel}
        />
        <ALENToast
          message={this.props.t('createLoad.successEdit')}
          show={this.state.showConfirm}
          handleClose={() => this.setState({showConfirm: false})}
        />
        <ALENToast
          message={this.props.t('addParcels.success')}
          show={this.state.showConfirmParcel}
          handleClose={() => this.setState({showConfirmParcel: false})}
        />
        <ALENToast
          message={this.props.t('addParcels.successDelete')}
          show={this.state.showConfirmDeleteParcel}
          handleClose={() => this.setState({showConfirmDeleteParcel: false})}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLoad: (load) => {
      dispatch({type: "ADDLOAD", data: load})
    },
    updateParcels: (parcels, load_id) => {
      dispatch({type: "UPDATEPARCELS", data: {parcels: parcels, id: load_id}})
    },
    updateLoads: (loadsList) => {
      dispatch({type: "UPDATELOADS", data: loadsList})
    },
    insertParcels: (id, parcelsList) => {
      dispatch({type: "INSERTPARCELS", data: {id: id, parcelsList: parcelsList}})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ParcelsListContainer))
