import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import ALENText from 'components/ALENText';
import InputAdornment from '@material-ui/core/InputAdornment';
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENRED} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTLITTLE} from 'utils/fontSizes'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import SvgSearch from 'components/icons/Search';

const styles = theme => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: ALENBORDERINPUT,
      height:37,

    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: ALENBLUE,
      height:37,

    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: ALENBLUE,
      height:37,

    },
  },
});

class ALENInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    }

    this.inputRef = React.createRef()
  }

  focus = () => {
    this.inputRef.current.focus()
  }

  showPassword = () => {
    this.setState({showPassword: true})
  }

  hidePassword = () => {
    this.setState({showPassword: false})
  }

  render() {
    const { t, i18n } = this.props;
    const { classes } = this.props;

    let icon = null
    if(this.props.type === "password") {
      icon = <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.showPassword}
                onMouseDown={this.hidePassword}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
    }

    if(this.props.type === "search") {
      icon = <InputAdornment position="end">
              <div style={{cursor:'pointer'}}>
                <SvgSearch
                  size={14}
                />
              </div>
             </InputAdornment>
    }

    return (
      <>
        <div style={{marginBottom:8}}>
          <ALENText>{this.props.label}</ALENText>
        </div>
        <TextField
          type={this.state.showPassword ? "text" : this.props.type}
          placeholder={this.props.placeholder}
          variant="outlined"
          disabled={this.props.disabled}
          classes={classes.root}
          InputLabelProps={{
            style: { fontFamily:'ALENFont', color: this.props.dark ? ALENWHITE : ALENBLACK, fontSize: FONTNORMAL },
          }}
          inputProps={{
            ref:this.inputRef,
            onBlur: this.props.onTab
          }}
          InputProps={{
            autoFocus:this.props.autoFocus,
            style: { fontFamily:'ALENFont', color: this.props.dark ? ALENWHITE : ALENBLACK, fontSize: FONTNORMAL, height: this.props.height},
            endAdornment: icon,
          }}
          style={{
            backgroundColor:this.props.dark ? ALENOPACITY2 : ALENWHITE,
            width:'100%',
            borderRadius:4,
          }}
          multiline={this.props.type==='textarea'}
          rows={this.props.rows}
          onChange={this.props.onChange}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
        />
        {this.props.error &&
          <div style={{marginLeft:8}}>
            <ALENText bold size={FONTLITTLE} color={ALENRED}>{this.props.errorMessage}</ALENText>
          </div>
        }
      </>
    );
  }
}

ALENInput.propTypes = {
  type:PropTypes.string,
  label:PropTypes.string,
  maxWidth:PropTypes.number,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  height:PropTypes.string,
  disabled:PropTypes.bool,
  placeholder: PropTypes.string,
  dark: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onTab: PropTypes.func,
  autoFocus: PropTypes.bool,
  ref: PropTypes.object,
};

ALENInput.defaultProps = {
  type:'text',
  label:'ALEN',
  maxWidth:300,
  maxHeight:5,
  rows:1,
  onChange: () => console.log('onChange'),
  height:37,
  disabled: false,
  placeholder: "ALEN",
  dark:false,
  error: false,
  errorMessage: 'error',
  onTab: null,
  autoFocus: false,
  ref: React.createRef(),
};

export default withStyles(styles)(ALENInput)
