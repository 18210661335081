import * as React from "react";

function SvgIsNoStackable(props) {
  return (
    <svg width={89.7} height={90} viewBox={"0 0 89.7 90"} {...props}>
      <path d="M44.1 87.5V63.4l-7.9-4.1-15.4 15.4v.7zM20.8 51.3v12.5l8.2-8.2zM68.9 63.8V51.3l-8.2 4.3zM68.9 75.4v-.7L53.5 59.3l-7.9 4.1v24.1zM59.5 54.4L68 50l-9.8-5.1-5.4 2.8zM36.9 47.7l-5.4-2.8-9.8 5.1 8.5 4.4zM45.6 51.4l-.4.2c-.1.1-.2.1-.4.1-.1 0-.2 0-.4-.1l-.4-.2-6.7 6.7 7.5 3.9 7.5-3.9zM20.8 37.5l17.3 9 1.3-1.3-18.6-18.6zM20.8 15.7L44.1 39V25.6L20.8 13.4zM68.9 13.4L45.6 25.6V39l23.3-23.3zM51.6 46.5l17.3-9V26.6L50.3 45.2zM68 12.1L44.9 0 21.7 12.1l23.2 12.1z" />
      <path d="M86.4.4L44.8 42 3.3.4 0 3.6l41.6 41.6L0 86.8 3.3 90l41.5-41.6L86.4 90l3.3-3.2-41.6-41.6L89.7 3.6z" />
    </svg>
  );
}

export default SvgIsNoStackable;
