import React from 'react';
import { ALENBG, ALENDISABLEDDARK } from 'utils/colors';
import { FONTLITTLE2 } from 'utils/fontSizes';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';

function ALENTiedownHeader(props) {
  const t = props.t
  const headers = [
    {
      width:263,
      labelTop:t('tiedowncalculator.table.headers.0'),
      labelBot:t('tiedowncalculator.table.headers.01'),
    },
    {
      width:161,
      labelTop:t('tiedowncalculator.table.headers.1'),
      labelBot:t('tiedowncalculator.table.headers.11'),
    },
    {
      width:161,
      labelTop:t('tiedowncalculator.table.headers.2'),
      labelBot:t('tiedowncalculator.table.headers.21'),
    },
    {
      width:168,
      labelTop:t('tiedowncalculator.table.headers.3'),
      labelBot:t('tiedowncalculator.table.headers.31'),
    },
    {
      width:156,
      labelTop:t('tiedowncalculator.table.headers.4'),
      labelBot:t('tiedowncalculator.table.headers.41'),
    },
    {
      width:168,
      labelTop:t('tiedowncalculator.table.headers.5'),
      labelBot:t('tiedowncalculator.table.headers.51'),
    }
  ]

  return(
    <>
      <div
        style={{
          flexDirection:'row',
          justifyContent:'center',
          display:'flex',
          marginTop:34,
          marginBottom:10,
        }}>
        <div
          style={{
            maxWidth:1077,
            width:1077,
            backgroundColor:ALENBG,
            height:68,
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
          }}>
          {headers.map((header) => {
            return(
              <div
                style={{
                  minWidth:header.width,
                  maxWidth:header.width,
                  width:header.width,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:24,
                  paddingTop:16,
                  paddingBottom:16,
                }}>
                <ALENText
                  bold
                  color={ALENDISABLEDDARK}
                  size={FONTLITTLE2}
                  >
                  {header.labelTop}
                </ALENText>
                <ALENText
                  bold
                  color={ALENDISABLEDDARK}
                  size={FONTLITTLE2}
                  >
                  {header.labelBot}
                </ALENText>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ALENTiedownHeader);
