import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { FONTLITTLE2, FONTVERYBIG } from "utils/fontSizes";
import {
  ALENBLUE,
  ALENGREEN,
  ALENBG,
  ALENRED,
  ALENLINK,
  ALENBLACK,
  ALENGREENDARK,
} from "utils/colors";
import ALENLayout from "components/ALENLayout";
import ALENParcelsEmpty from "components/ALENParcelsEmpty";
import ALENOptimizedListHeader from "components/ALENOptimizedListHeader";
import ALENOptimizedUldsList from "components/ALENOptimizedUldsList";
import ALENOptimizeResult from "components/ALENOptimizeResult";
import ALENText from "components/ALENText";
import ALENButton from "components/ALENButton";
import ALENStepHeaders from "components/ALENStepHeaders";
import { ulds } from "utils/ulds";
import ViewerDataWrapper from "./View3d/ViewerDataWrapper";

class LoadingView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG;
  }

  render() {
    let t = this.props.t;

    const ariane = [
      {
        label: t("loads.title"),
        link: "/loads",
      },
      {
        label: this.props.load !== null ? this.props.load.name : "",
        link: "current",
      },
    ];

    const steps = {
      selected: 3,
      menus: [
        {
          label: t("loads.header.step1"),
          link: "/ulds/" + (this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t("loads.header.step2"),
          link:
            "/loads/" + (this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t("loads.header.step3"),
          link:
            "/loads/optimizedList/" +
            (this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t("loads.header.step4"),
          link: window.location.href,
        },
      ],
    };

    return (
      <>
        <ALENLayout
          title={this.props.load !== null ? this.props.load.name : ""}
          ariane={ariane}
        >
          <ALENStepHeaders
            steps={steps}
            onEdit={() => this.setState({ showEdit: true })}
          />
          {this.props.loading.length > 0 && (
            <div
              style={{
                margin: 24,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 300,
                    maxWidth: 300,
                    marginLeft: 48,
                  }}
                >
                  <ALENText size={FONTLITTLE2} bold color={ALENLINK}>
                    {t("optimize.loading") +
                      " " +
                      (
                        this.props.loading.findIndex(
                          (el) => el.id === parseInt(this.props.parcelId, 10)
                        ) + 1
                      ).toString() +
                      "/" +
                      this.props.loading.length}
                  </ALENText>
                  <ALENText size={FONTVERYBIG} color={ALENBLACK}>
                    {
                      this.props.loading.find(
                        (el) => el.id === parseInt(this.props.parcelId, 10)
                      ).airway_bill
                    }
                  </ALENText>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: 32,
                    }}
                  >
                    <div
                      style={{
                        width: 198,
                      }}
                    >
                      <ALENButton
                        little
                        label={t("optimize.previousPalet")}
                        onPress={() => {
                          this.props.previous(
                            this.props.loading.findIndex(
                              (el) =>
                                el.id === parseInt(this.props.parcelId, 10)
                            ) - 1
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: 198,
                      }}
                    >
                      <ALENButton
                        little
                        label={t("optimize.nextPalet")}
                        onPress={() => {
                          this.props.next(
                            this.props.loading.findIndex(
                              (el) =>
                                el.id === parseInt(this.props.parcelId, 10)
                            ) + 1
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                {console.log("uld", this.props.uld)}
                <ViewerDataWrapper
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                  className={""}
                  data={this.props.uld}
                  selectedGroup={null}
                  onSelect={(parcelID) => console.log("select", parcelID)}
                  config={{
                    textures: {
                      parcel: "/textures/cardboard3.jpg",
                      uld: "/textures/rivets4.jpg",
                    },
                  }}
                />
              </div>
            </div>
          )}
        </ALENLayout>
      </>
    );
  }
}

export default withTranslation()(LoadingView);
