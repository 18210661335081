import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ALENGREEN, ALENBLACK, ALENULTRABLACK, ALENWHITE, ALENBORDER} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTVERYBIG} from 'utils/fontSizes'
import ALENText from "components/ALENText"
import ALENButton from "components/ALENButton"
import ALENLoading from "components/ALENLoading"

class ALENFormBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <>
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            width:this.props.fullScreen ? "100%" : 438,
            height:414}}>
          <div
            style={{
              backgroundColor: ALENGREEN,
              borderTopRightRadius: this.props.fullScreen ? 0 : 8,
              borderTopLeftRadius: this.props.fullScreen ? 0 : 8,
              height:8}}/>
          <div
            style={{
              backgroundColor:ALENWHITE,
              display:'flex',
              flexDirection:'column',
              height:406,
              padding:32,
              borderStyle:'solid',
              borderColor:ALENBORDER,
              boxSizing:'border-box',
              boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.2)',
              borderWidth: 1,
              borderTopWidth:0,
              borderBottomLeftRadius: this.props.fullScreen ? 0 : 8,
              borderBottomRightRadius: this.props.fullScreen ? 0 : 8}}>
              <div style={{padding:8}}>
                <ALENText
                  bold
                  color={ALENULTRABLACK}
                  size={FONTVERYBIG}>
                  {this.props.title}
                </ALENText>
              </div>
              {this.props.children}
              <div style={{height:32}}/>
              {this.props.isLoading ?
                  <ALENLoading/>
                :
                <ALENButton
                  big
                  label={this.props.buttonLabel}
                  onPress={this.props.onValid}
                />
              }

          </div>
        </div>
      </>
    );
  }
}

ALENFormBox.propTypes = {
  title:PropTypes.string,
  buttonLabel: PropTypes.string,
  fullScreen: PropTypes.bool,
  onValid: PropTypes.func,
  isLoading: PropTypes.bool,
};

ALENFormBox.defaultProps = {
  title: '',
  buttonLabel: "ALEN",
  fullScreen: false,
  onValid: () => console.log('valid'),
  isLoading: false,
};

export default ALENFormBox
