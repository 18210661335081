import React from 'react';
import { ALENBG, ALENDISABLEDDARK, ALENWHITE, ALENRED } from 'utils/colors';
import { FONTLITTLE2 } from 'utils/fontSizes';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENSelect from 'components/ALENSelect';
import ALENButton from 'components/ALENButton';
import SvgAdd from 'components/icons/Add';
import SvgDelete from 'components/icons/Delete';

function ALENTiedownCalculatorLines(props) {
  const t = props.t
  const cells = [
    {
      width:263,
      labelTop:t('tiedowncalculator.table.headers.0'),
      labelBot:t('tiedowncalculator.table.headers.01'),
    },
    {
      width:161,
      labelTop:t('tiedowncalculator.table.headers.1'),
      labelBot:t('tiedowncalculator.table.headers.11'),
    },
    {
      width:161,
      labelTop:t('tiedowncalculator.table.headers.2'),
      labelBot:t('tiedowncalculator.table.headers.21'),
    },
    {
      width:168,
      labelTop:t('tiedowncalculator.table.headers.3'),
      labelBot:t('tiedowncalculator.table.headers.31'),
    },
    {
      width:156,
      labelTop:t('tiedowncalculator.table.headers.4'),
      labelBot:t('tiedowncalculator.table.headers.41'),
    },
    {
      width:168,
      labelTop:t('tiedowncalculator.table.headers.5'),
      labelBot:t('tiedowncalculator.table.headers.51'),
    }
  ]

  let values = []

  props.lines.model.tiedown_location.forEach(location => {
    let newLocation = {
      value: location.id,
      libelle: location.libelle
    };
    values.push(newLocation);
  })

  let angleValues = [
    {
      value: 0,
      libelle: '0'
    },
    {
      value: 30,
      libelle: '30'
    },
    {
      value: 60,
      libelle: '60'
    },
    {
      value: 90,
      libelle: '90'
    },
  ]

  console.log('this.props.extraData', props.extraData)

  return(
    <>
      <div
        style={{
          flexDirection:'column',
          alignItems:'center',
          display:'flex',
          marginBottom:34,
        }}>

        {props.lines.lines.map((line, index) => {
          return(
            <div
              style={{
                maxWidth:1077,
                width:1077,
                backgroundColor:ALENBG,
                height:68,
                flexDirection:'row',
                display:'flex',
                alignItems:'center',
              }}>
              <div
                style={{
                  minWidth:263,
                  maxWidth:263,
                  width:263,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                }}>
                <ALENSelect
                  datas={values}
                  value={line.tiedown_location}
                  onChange={(event) => {
                    console.log('event', event.target.value)
                    const newLine = {
                      ...line,
                      tiedown_location: event.target.value
                    }
                    props.onChange(newLine, index)
                  }}
                />
              </div>
              <div
                style={{
                  minWidth:161,
                  maxWidth:161,
                  width:161,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                }}>
                <ALENSelect
                  datas={angleValues}
                  value={line.floor_angle}
                  onChange={(event) => {
                    const newLine = {
                      ...line,
                      floor_angle: event.target.value
                    }
                    props.onChange(newLine, index)
                  }}
                />
              </div>
              <div
                style={{
                  minWidth:161,
                  maxWidth:161,
                  width:161,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                }}>
                <ALENSelect
                  datas={angleValues}
                  value={line.centerline_angle}
                  onChange={(event) => {
                    const newLine = {
                      ...line,
                      centerline_angle: event.target.value
                    }
                    props.onChange(newLine, index)
                  }}
                />
              </div>
              <div
                style={{
                  minWidth:168,
                  maxWidth:168,
                  width:168,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                }}>
                <ALENInput
                  label={""}
                  type={"number"}
                  value={line.value}
                  onChange={(event) => {
                  }}
                  />
              </div>
              <div
                style={{
                  minWidth:156,
                  maxWidth:156,
                  width:156,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                }}>
                <ALENInput
                  label={""}
                  type={"number"}
                  value={line.nb_fittings}
                  onChange={(event) => {
                    if(event.target.value >=0) {
                      const newLine = {
                        ...line,
                        nb_fittings: event.target.value
                      }
                      props.onChange(newLine, index)
                    }
                  }}
                  />
              </div>
              <div
                style={{
                  minWidth:118,
                  maxWidth:118,
                  width:118,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                }}>
                <ALENInput
                  onTab={() => {
                    if(index === props.lines.lines.length - 1) {
                      props.newLine()
                    }
                  }}
                  label={""}
                  type={"number"}
                  value={line.til_value}
                  onChange={(event) => {
                  }}
                  />
              </div>
              <div
                style={{
                  minWidth:50,
                  maxWidth:50,
                  width:50,
                  flexDirection:'column',
                  display:'flex',
                  minHeight:68,
                  padding:5,
                  paddingTop:5,
                  alignItems:'center',
                  justifyContent:'center',
                }}>
                {props.lines.lines.length > 1 &&
                <SvgDelete
                  onClick={() => props.deleteLine(index)}
                  style={{
                    cursor:'pointer',
                  }}
                  fill={ALENRED}/>
                }
              </div>
            </div>
          )
        })}
        <div
          style={{
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            backgroundColor:ALENBG,
            maxWidth:1077,
            width:1077,
            padding:10,
          }}>
          <ALENButton
            startIcon={<SvgAdd fill={ALENWHITE} style={{width:22, height:22}}/>}
            onPress={() => props.newLine()}
            label={t('tiedowncalculator.newline')}
            little
            />
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ALENTiedownCalculatorLines);
