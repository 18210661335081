import React, {Component} from 'react';
import {ALENBG} from "utils/colors";
import SvgBackgroundLogin from "components/icons/BackgroundLogin";
import { withTranslation } from 'react-i18next';
import {ALENBLUE} from 'utils/colors';
import ALENLayout from 'components/ALENLayout';
import ALENFirstConnect from 'components/ALENFirstConnect'

class HomeView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobile:false,
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG

    window.addEventListener("resize", this.resize);
    this.resize()
  }

  resize = () => {
    if( window.innerWidth <= 600) {
      this.setState({mobile: true})
    } else {
      this.setState({mobile:false})
    }
  }

  render()
  {
    let t = this.props.t

    return(
      <>

        <ALENLayout>
          <ALENFirstConnect/>
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(HomeView)
