import React from 'react';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgAdd from 'components/icons/Add';
import SvgDownload from 'components/icons/Download';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP} from 'utils/fontSizes'
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, BLUEINFO, ALENOPACITY, ALENGREEN, ALENBGLIGHT, ALENGREY, ALENBG, ALENDISABLEDDARK, ALENRED, ALENBORDER, STATUS} from "utils/colors";

function ALENTiedownHeader(props) {
  const t = props.t

  const [mouseOverId, setMouseOverId] = React.useState(0);

  return(
    <>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center',
          marginTop:24,
        }}
      >
        {props.subMenus.map((menu, index) => {
          return(
            <div
              onMouseOver={() => setMouseOverId(menu.id)}
              onMouseLeave={() => setMouseOverId(0)}
              style={{
                flexDirection:'row',
                display:'flex',
                cursor:'pointer',
                borderRadius:5,
                marginRight:15,
                marginLeft:15,
                boxShadow:mouseOverId === menu.id ? '0px 8px 12px rgba(18, 5, 80, 0.1)' : '0px 8px 32px rgba(18, 5, 80, 0.1)',
                backgroundColor:mouseOverId === menu.id ? ALENBGLIGHT : "transparent"
              }}
              onClick={() => props.onSelect(menu.id)}
            >
              <div
                style={{
                  display:'flex',
                  flexDirection:'column',
                  margin:10,
                }}>
                <div
                  style={{
                    flexDirection:'row',
                    display:'flex',
                    alignItems:'center',
                  }}
                >
                  <ALENText
                    size={FONTBIG}
                    bold
                    color={menu.id === props.selected ? ALENBLACK : ALENGREY}>
                    {menu.title}
                  </ALENText>
                  <div
                    style={{
                      height:38,
                      width:100,
                      borderRadius:5,
                      justifyContent:'center',
                      alignItems:'center',
                      flexDirection:'row',
                      display:'flex',
                      marginLeft:10,
                      backgroundColor:menu.done ? ALENGREEN : ALENRED
                    }}>
                    <ALENText
                      size={FONTBIG}
                      bold
                      color={menu.done ? ALENBLACK : ALENBG}>
                      {menu.value}
                    </ALENText>
                  </div>
                </div>

                <div
                  style={{
                    width:"100%",
                    height:menu.id === props.selected ? 4 : 1,
                    backgroundColor:menu.id === props.selected ? ALENGREEN : ALENBORDER,
                    borderRadius:16,
                    marginTop:menu.id === props.selected ? 10 : 12,

                  }}/>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default withTranslation()(ALENTiedownHeader)
