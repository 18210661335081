import * as React from "react";

function SvgBackgroundLogin(props) {
  return (
    <svg viewBox="0 0 1440 561" width={'100%'} height={'561'} fill="none" preserveAspectRatio="none" {...props}>
      <path
        d="M1073.22 301.123c-419.136 0-984.673 423.455-1186.22 187.201 20.389-155.5 66.58-471.907 88.24-493.53C2.313-32.237 1443.23-65.272 1443.23-.202s49.13 301.324-370.01 301.324z"
        fill="#120550"
        stroke="#000"
      />
    </svg>
  );
}

export default SvgBackgroundLogin;
