import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {checkEmpty} from 'utils/checkForm'

import ALENLayout from 'components/ALENLayout';
import ALENAddParcelsTable from 'components/ALENAddParcelsTable';
import {ALENWHITE, ALENBG, ALENBLACK, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENSelect from 'components/ALENSelect';
import ALENULDStockTable from 'components/ALENULDStockTable';
import ALENButton from 'components/ALENButton';
import origami from 'assets/images/origami.png';
import SvgDownload from 'components/icons/Download';
import ALENTiedownHeader from 'components/ALENTiedownHeader';
import ALENTiedownCalculatorHeader from 'components/ALENTiedownCalculatorHeader';
import ALENTiedownCalculatorLines from 'components/ALENTiedownCalculatorLines';
import ALENLoading from 'components/ALENLoading';
import ALENModalTiedown from 'components/ALENModalTiedown';
import ALENModalTiedownPdf from 'components/ALENModalTiedownPdf';

class TiedownCalculatorView extends Component {
  constructor(props) {
    super(props)

    this.state={
      pane: 1,
      errorWeight: this.props.tiedown.weight.length < 1,
      showModal : false,
      showPdf: false,
      nextAircraft: null,
      weight: this.props.tiedown.weight,
      height: this.props.tiedown.height,
      width: this.props.tiedown.width,
      length: this.props.tiedown.length,
      custom_reference: this.props.tiedown.custom_reference,
      flight_number: this.props.tiedown.flight_number,
      description: this.props.tiedown.description,
      ac_registration: this.props.tiedown.ac_registration,
      slr_number: this.props.tiedown.slr_number,
      position_loaded: this.props.tiedown.position_loaded,
      floating_pallet: this.props.tiedown.floating_pallet,
      frangible_cargo: this.props.tiedown.frangible_cargo,
      FWD: {
        model: {
          tiedown_location: this.props.model.FWD.location,
        },
        value: 0,
        lines : [
          {
            tiedown_location: this.props.model.FWD.location[0].id,
            floor_angle: 0,
            centerline_angle:0,
            nb_fittings:0,
            value:0,
            til_value:0,
          }
        ],
      },
      SIDE_LEFT: {
        model: {
          tiedown_location: this.props.model.SIDE_LEFT.location,
        },
        value: 0,
        lines : [
          {
            tiedown_location: this.props.model.SIDE_LEFT.location[0].id,
            floor_angle: 0,
            centerline_angle:0,
            nb_fittings:0,
            value:0,
            til_value:0,
          }
        ],
      },
      SIDE_RIGHT: {
        model: {
          tiedown_location: this.props.model.SIDE_RIGHT.location,
        },
        value: 0,
        lines : [
          {
            tiedown_location: this.props.model.SIDE_RIGHT.location[0].id,
            floor_angle: 0,
            centerline_angle:0,
            nb_fittings:0,
            value:0,
            til_value:0,
          }
        ],
      },
      UP: {
        model: {
          tiedown_location: this.props.model.UP.location,
        },
        value: 0,
        lines : [
          {
            tiedown_location: this.props.model.UP.location[0].id,
            floor_angle: 0,
            centerline_angle:0,
            nb_fittings:0,
            value:0,
            til_value:0,
          }
        ],
      },
      AFT: {
        model: {
          tiedown_location: this.props.model.AFT.location,
        },
        value: 0,
        lines : [
          {
            tiedown_location: this.props.model.AFT.location[0].id,
            floor_angle: 0,
            centerline_angle:0,
            nb_fittings:0,
            value:0,
            til_value:0,
          }
        ],
      },
    }
  }

  getPane = () => {
    switch(this.state.pane) {
      case 1 :
        return this.state.FWD;
        break;
      case 2 :
        return this.state.SIDE_LEFT;
        break;
      case 3 :
        return this.state.SIDE_RIGHT;
        break;
      case 4 :
        return this.state.UP;
        break;
      case 5 :
        return this.state.AFT;
        break;
      default :
        return [];
        break;
    }
  }

  getPaneKey = () => {
    switch(this.state.pane) {
      case 1 :
        return "FWD";
        break;
      case 2 :
        return "SIDE_LEFT";
        break;
      case 3:
        return "SIDE_RIGHT";
        break;
      case 4 :
        return "UP";
        break;
      case 5 :
        return "AFT";
        break;
      default :
        return null;
        break;
    }
  }

  save = (withPdf: false) => {
    let tiedown_configs= []

    const FWD = this.state.FWD
    const SIDE_LEFT = this.state.SIDE_LEFT
    const SIDE_RIGHT = this.state.SIDE_RIGHT
    const UP = this.state.UP
    const AFT = this.state.AFT

    FWD.lines.forEach((line) => {
      if(line.tiedown_id !== undefined && line.nb_fittings > 0) {
        let lineFormatted = {
          tiedown_model_id : line.tiedown_id,
          number_fittings: line.nb_fittings,
        }
        tiedown_configs.push(lineFormatted)
      }
    })

    SIDE_LEFT.lines.forEach((line) => {
      if(line.tiedown_id !== undefined && line.nb_fittings > 0) {
        let lineFormatted = {
          tiedown_model_id : line.tiedown_id,
          number_fittings: line.nb_fittings,
        }

        tiedown_configs.push(lineFormatted)
      }
    })

    SIDE_RIGHT.lines.forEach((line) => {
      if(line.tiedown_id !== undefined && line.nb_fittings > 0) {
        let lineFormatted = {
          tiedown_model_id : line.tiedown_id,
          number_fittings: line.nb_fittings,
        }

        tiedown_configs.push(lineFormatted)
      }
    })

    UP.lines.forEach((line) => {
      if(line.tiedown_id !== undefined && line.nb_fittings > 0) {
        let lineFormatted = {
          tiedown_model_id : line.tiedown_id,
          number_fittings: line.nb_fittings,
        }
        tiedown_configs.push(lineFormatted)
      }
    })

    AFT.lines.forEach((line) => {
      if(line.tiedown_id !== undefined && line.nb_fittings > 0) {
        let lineFormatted = {
          tiedown_model_id : line.tiedown_id,
          number_fittings: line.nb_fittings,
        }
        tiedown_configs.push(lineFormatted)
      }
    })

    console.log('tiedown', this.props.tiedown)
    const datas = {
      id: this.props.tiedown.id,
      weight: this.props.tiedown.weight,
      aircraft_id: this.props.tiedown.aircraft_id,
      tiedown_configs: tiedown_configs,
      name: this.props.tiedown.name,
      weight: this.state.weight,
      height: this.state.height,
      width: this.state.width,
      length: this.state.length,
      custom_reference: this.state.custom_reference,
      flight_number: this.state.flight_number,
      description: this.state.description,
      ac_registration: this.state.ac_registration,
      slr_number: this.state.slr_number,
      position_loaded: this.state.position_loaded,
      floating_pallet: this.state.floating_pallet,
      frangible_cargo: this.state.frangible_cargo,
    }

    this.props.save(datas, withPdf)
  }

  calculateAllLines = () => {
    const FWD = this.state.FWD.lines
    const SIDE_LEFT = this.state.SIDE_LEFT.lines
    const SIDE_RIGHT = this.state.SIDE_RIGHT.lines
    const UP = this.state.UP.lines
    const AFT = this.state.AFT.lines

    FWD.forEach((line, index) => {
      this.calculateLine(line, "FWD", index)
    })

    SIDE_LEFT.forEach((line, index) => {
      this.calculateLine(line, "SIDE_LEFT", index)
    })

    SIDE_RIGHT.forEach((line, index) => {
      this.calculateLine(line, "SIDE_RIGHT", index)
    })

    UP.forEach((line, index) => {
      this.calculateLine(line, "UP", index)
    })

    AFT.forEach((line, index) => {
      this.calculateLine(line, "AFT", index)
    })
  }

  calculateLine = (line, key, index) => {
    let calculatedLine = line
    const model = this.props.model[key].model
    model.forEach((modelLine) => {
      if(modelLine.tiedown_location_id === line.tiedown_location && modelLine.centerline_angle === line.centerline_angle && modelLine.floor_angle === line.floor_angle) {
        calculatedLine.value = modelLine.allowable_load
        calculatedLine.til_value = modelLine.allowable_load * line.nb_fittings
        calculatedLine.tiedown_id = modelLine.id
      }
    })

    let newKey = this.state[key]

    newKey.lines[index] = calculatedLine

    let newValue = 0
    newKey.lines.forEach((lineValue) => {
      newValue = newValue + lineValue.til_value
    })

    newKey.value = newValue

    this.setState({[key] : newKey})
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG

    if(this.props.tiedown.tiedown_configs.length > 0) {
      this.props.tiedown.tiedown_configs.forEach((config) => {
        this.props.model.AFT.model.forEach((modelLine) => {
          if(modelLine.id === config.tiedown_model_id) {
            let newAFTLines = this.state.AFT.lines
            newAFTLines.unshift(
              {
                tiedown_location: modelLine.tiedown_location_id,
                floor_angle: modelLine.floor_angle,
                centerline_angle: modelLine.centerline_angle,
                nb_fittings:config.number_fittings,
                value:modelLine.allowable_load,
                til_value:modelLine.allowable_load * config.number_fittings,
              }
            )
            const newAFT = {
              ...this.state.AFT,
              lines: newAFTLines,
            }
            this.setState({AFT: newAFT})
          }
        })

        this.props.model.SIDE_LEFT.model.forEach((modelLine) => {
          if(modelLine.id === config.tiedown_model_id) {
            let newSIDE_LEFTLines = this.state.SIDE_LEFT.lines
            newSIDE_LEFTLines.unshift(
              {
                tiedown_location: modelLine.tiedown_location_id,
                floor_angle: modelLine.floor_angle,
                centerline_angle: modelLine.centerline_angle,
                nb_fittings:config.number_fittings,
                value:modelLine.allowable_load,
                til_value:modelLine.allowable_load * config.number_fittings,
              }
            )
            const newSIDE_LEFT = {
              ...this.state.SIDE,
              lines: newSIDE_LEFTLines,
            }
            this.setState({SIDE_LEFT: newSIDE_LEFT})
          }
        })

        this.props.model.SIDE_RIGHT.model.forEach((modelLine) => {
          if(modelLine.id === config.tiedown_model_id) {
            let newSIDE_RIGHTLines = this.state.SIDE_RIGHT.lines
            newSIDE_RIGHTLines.unshift(
              {
                tiedown_location: modelLine.tiedown_location_id,
                floor_angle: modelLine.floor_angle,
                centerline_angle: modelLine.centerline_angle,
                nb_fittings:config.number_fittings,
                value:modelLine.allowable_load,
                til_value:modelLine.allowable_load * config.number_fittings,
              }
            )
            const newSIDE_RIGHT = {
              ...this.state.SIDE,
              lines: newSIDE_RIGHTLines,
            }
            this.setState({SIDE_RIGHT: newSIDE_RIGHT})
          }
        })

        this.props.model.FWD.model.forEach((modelLine) => {
          if(modelLine.id === config.tiedown_model_id) {
            let newFWDLines = this.state.FWD.lines
            newFWDLines.unshift(
              {
                tiedown_location: modelLine.tiedown_location_id,
                floor_angle: modelLine.floor_angle,
                centerline_angle: modelLine.centerline_angle,
                nb_fittings:config.number_fittings,
                value:modelLine.allowable_load,
                til_value:modelLine.allowable_load * config.number_fittings,
              }
            )
            const newFWD = {
              ...this.state.FWD,
              lines: newFWDLines,
            }
            this.setState({FWD: newFWD})
          }
        })

        this.props.model.UP.model.forEach((modelLine) => {
          if(modelLine.id === config.tiedown_model_id) {
            let newUPLines = this.state.UP.lines
            newUPLines.unshift(
              {
                tiedown_location: modelLine.tiedown_location_id,
                floor_angle: modelLine.floor_angle,
                centerline_angle: modelLine.centerline_angle,
                nb_fittings:config.number_fittings,
                value:modelLine.allowable_load,
                til_value:modelLine.allowable_load * config.number_fittings,
              }
            )
            const newUP = {
              ...this.state.UP,
              lines: newUPLines,
            }
            this.setState({UP: newUP})
          }
        })
      })
    }

    this.calculateAllLines()
  }

  render() {
    const t = this.props.t

    let values = []

    this.props.aircraftList.forEach(aircraft => {
      let newAircraft = {
        value: aircraft.id,
        libelle: aircraft.name
      };
      values.push(newAircraft);
    })

    const ariane = [
      {
        label: t('tiedowns.title'),
        link: '/tiedowns',
      },
      {
        label: t('tiedowncalculator.title'),
        link: 'current',
      }
    ]

    const subMenus=[
      {
        id:1,
        title: t('tiedowncalculator.panes.FWD'),
        value: this.state.FWD.value,
        done: this.state.FWD.value >= this.props.tiedown.weight,
      },
      {
        id:2,
        title: t('tiedowncalculator.panes.SIDE_LEFT'),
        value: this.state.SIDE_LEFT.value,
        done: this.state.SIDE_LEFT.value >= this.props.tiedown.weight,
      },
      {
        id:3,
        title: t('tiedowncalculator.panes.SIDE_RIGHT'),
        value: this.state.SIDE_RIGHT.value,
        done: this.state.SIDE_RIGHT.value >= this.props.tiedown.weight,
      },
      {
        id:4,
        title: t('tiedowncalculator.panes.UP'),
        value: this.state.UP.value,
        done: this.state.UP.value >= this.props.tiedown.weight,
      },
      {
        id:5,
        title: t('tiedowncalculator.panes.AFT'),
        value: this.state.AFT.value,
        done: this.state.AFT.value >= this.props.tiedown.weight,
      }
    ]

    return(
      <ALENLayout
        isAsyncLoading={this.props.isAsyncLoading}
        title={this.props.tiedown.name}
        ariane = {ariane}
      >
        <div
          style={{
            padding:32,
          }}>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
            }}>
            <div
              style={{
                minWidth:1077,
                width:1077,
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center',
              }}>
              <div style={{width:16}}/>
              <div
                style={{
                  flexDirection:'column',
                  display:'flex'
                }}>
                <ALENInput
                  type={"number"}
                  label={t('tiedowncalculator.weight')}
                  placeholder={t('tiedowncalculator.weightPlaceholder')}
                  value={this.props.tiedown.weight}
                  onChange={(event) => {
                    this.props.changeWeight(event.target.value)
                  }}
                  error={this.props.tiedown.weight < 1 || this.props.tiedown.weight === null}
                  errorMessage={t('tiedowncalculator.errorWeight')}
                />
                {this.props.tiedown.weight > 0 &&
                <div
                  style={{
                    height:16
                  }}/>
                }
              </div>
              <div style={{width:16}}/>
              <div
                style={{
                  flexDirection:'column',
                  display:'flex',
                  marginTop:-16
                }}>
                <ALENSelect
                  datas={values}
                  minWidth={304}
                  value={this.props.tiedown.aircraft_id}
                  label={t('loads.list.planeLabel')}
                  placeholder={t('loads.list.aircraftPlaceholder')}
                  onChange={(event) => {
                    if(event.target.value > 0) {
                      this.setState({showModal: true, nextAircraft: event.target.value})
                    }
                  }}
                  error={this.state.errorAircraft}
                  errorMessage={t('loads.list.errorValue')}
                />
              </div>
              <div style={{width:32}}/>
              <div>
                <ALENButton
                  label={t('tiedowncalculator.save')}
                  onPress={this.save}
                  medium/>
              </div>
              <div style={{width:16}}/>
              <div style={{width:210}}>
              <ALENButton
                startIcon={<SvgDownload fill={ALENWHITE} style={{width:22, height:22}}/>}
                label={t('tiedowncalculator.pdf')}
                onPress={() => {
                  this.setState({showPdf: true})
                }}
                medium/>
              </div>
            </div>
          </div>
          <>
            {this.state.genericError &&
            <div style={{textAlign:'center', margin:32}}>
              <ALENText bold color={ALENRED} size={FONTBIG}>{t('commons.genericError')}</ALENText>
            </div>
            }
          </>
          {this.props.tiedown.weight > 0 && this.props.model !== null &&
          <>
            {!this.props.isLoading ?
              <>
                {this.state.showPdf &&
                  <ALENModalTiedownPdf
                    t={t}
                    back={() => this.setState({showPdf: false})}
                    confirm={() => {
                      this.setState({showPdf: false})
                      this.save(true)
                    }}
                    cancel={() => this.setState({showPdf: false})}
                    weight = {this.state.weight}
                    onChangeweight = {(value) => this.setState({weight: value})}
                    height = {this.state.height}
                    onChangeheight = {(value) => this.setState({height: value})}
                    width = {this.state.width}
                    onChangewidth = {(value) => this.setState({width: value})}
                    length = {this.state.length}
                    onChangelength = {(value) => this.setState({length: value})}
                    custom_reference = {this.state.custom_reference}
                    onChangecustom_reference = {(value) => this.setState({custom_reference: value})}
                    flight_number = {this.state.flight_number}
                    onChangeflight_number = {(value) => this.setState({flight_number: value})}
                    description = {this.state.description}
                    onChangedescription = {(value) => this.setState({description: value})}
                    ac_registration = {this.state.ac_registration}
                    onChangeac_registration = {(value) => this.setState({ac_registration: value})}
                    slr_number = {this.state.slr_number}
                    onChangeslr_number = {(value) => this.setState({slr_number: value})}
                    position_loaded = {this.state.position_loaded}
                    onChangeposition_loaded = {(value) => this.setState({position_loaded: value})}
                    floating_pallet = {this.state.floating_pallet}
                    onChangefloating_pallet = {(value) => this.setState({floating_pallet: value})}
                    frangible_cargo = {this.state.frangible_cargo}
                    onChangefrangible_cargo = {(value) => this.setState({frangible_cargo: value})}
                  />
                }
                {this.state.showModal &&
                  <ALENModalTiedown
                    back={() => this.setState({showModal: false})}
                    yes={() => {
                      this.setState({
                        pane: 1,
                        errorWeight: this.props.tiedown.weight.length < 1,
                        showModal : false,
                        nextAircraft: null,
                        FWD: {
                          model: {
                            tiedown_location: this.props.model.FWD.location,
                          },
                          value: 0,
                          lines : [
                            {
                              tiedown_location: this.props.model.FWD.location[0].id,
                              floor_angle: 0,
                              centerline_angle:0,
                              nb_fittings:0,
                              value:0,
                              til_value:0,
                            }
                          ],
                        },
                        SIDE_LEFT: {
                          model: {
                            tiedown_location: this.props.model.SIDE_LEFT.location,
                          },
                          value: 0,
                          lines : [
                            {
                              tiedown_location: this.props.model.SIDE_LEFT.location[0].id,
                              floor_angle: 0,
                              centerline_angle:0,
                              nb_fittings:0,
                              value:0,
                              til_value:0,
                            }
                          ],
                        },
                        SIDE_RIGHT: {
                          model: {
                            tiedown_location: this.props.model.SIDE_RIGHT.location,
                          },
                          value: 0,
                          lines : [
                            {
                              tiedown_location: this.props.model.SIDE_RIGHT.location[0].id,
                              floor_angle: 0,
                              centerline_angle:0,
                              nb_fittings:0,
                              value:0,
                              til_value:0,
                            }
                          ],
                        },
                        UP: {
                          model: {
                            tiedown_location: this.props.model.UP.location,
                          },
                          value: 0,
                          lines : [
                            {
                              tiedown_location: this.props.model.UP.location[0].id,
                              floor_angle: 0,
                              centerline_angle:0,
                              nb_fittings:0,
                              value:0,
                              til_value:0,
                            }
                          ],
                        },
                        AFT: {
                          model: {
                            tiedown_location: this.props.model.AFT.location,
                          },
                          value: 0,
                          lines : [
                            {
                              tiedown_location: this.props.model.AFT.location[0].id,
                              floor_angle: 0,
                              centerline_angle:0,
                              nb_fittings:0,
                              value:0,
                              til_value:0,
                            }
                          ],
                        },
                      })

                      this.props.changeAircraft(this.state.nextAircraft)
                    }}
                    no={() => this.setState({showModal: false})}
                  />
                }
                <ALENTiedownHeader
                  subMenus={subMenus}
                  onSelect={(id) => this.setState({pane: id})}
                  selected={this.state.pane}
                />
                <ALENTiedownCalculatorHeader
                />
                <ALENTiedownCalculatorLines
                  deleteLine={(index) => {
                    let newVal = this.state[this.getPaneKey()]
                    if(newVal !== null) {
                      newVal.lines.splice(index, 1)
                      this.setState({[this.getPaneKey()] : newVal})
                      this.calculateAllLines()
                    }
                  }}
                  lines={this.getPane()}
                  onChange={(line, index) => {
                    this.calculateLine(line, this.getPaneKey(), index)
                  }}
                  newLine={() => {
                    let newVal = this.state[this.getPaneKey()]
                    if(newVal !== null) {
                      const newLine = {
                        tiedown_id: null,
                        tiedown_location: newVal.model.tiedown_location[0].id,
                        floor_angle: 0,
                        centerline_angle:0,
                        nb_fittings:0,
                        value:0,
                        til_value:0,
                      }
                      newVal.lines.push(newLine)

                      this.setState({[this.getPaneKey()] : newVal})
                    }
                  }}
                />
              </>
            :
              <div
                style={{
                marginTop:50,
              }}>
                <ALENLoading
                  big
                />
              </div>
            }
          </>
        }
        </div>
      </ALENLayout>
    )
  }
}

export default withTranslation()(TiedownCalculatorView)
