import React, { Component } from "react";
import UldsListView from "./UldsListView";
import { connect } from "react-redux";
import ALENToast from "components/ALENToast";
import { withTranslation } from "react-i18next";
import {
  getAircraftList,
  addLoad,
  addUlds,
  getUldsLoad,
  loadUpdate,
} from "api/methods/load";
import {
  getUlds,
  addUldStock,
  getUldStock,
  updateUldStock,
} from "api/methods/stock";

class UldsListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      load: null,
      uldsLoadList: [],
      loadingDatas: false,
    };
  }

  loadUpdate = (load) => {
    console.log("load TO API first", load);
    let cleanLoad = [];
    load.load_uld.forEach((uld) => {
      cleanLoad.push({
        uld_id: uld.uld_id,
        nbr: parseInt(uld.nbr),
        max_weight: parseInt(uld.max_weight),
      });
    });
    loadUpdate(
      this.props.userReducer.token,
      { load_uld: [...cleanLoad] },
      this.state.load.id
    ).then((response) => {
      this.loadDatas();
    });
  };

  loadDatas = (loading) => {
    const idLoad = this.props.match.params.id;
    this.props.loadsReducer.loads.forEach((load) => {
      if (load.id.toString() === idLoad) {
        this.setState({ load: load });
      }
    });
    this.setState({ isMainLoading: loading });
    getUldsLoad(this.props.userReducer.token, idLoad).then((response) => {
      if (response.status === 200 && response.ok) {
        let uldsLoad = [];
        response.data.forEach((uld) => {
          const newUldLoad = {
            id: uld.id,
            description: uld.uld_stock.uld.description,
            name: uld.uld_stock.uld.name,
            picture: uld.uld_stock.uld.picture,
            ref: uld.uld_stock.uld.ref,
            size: uld.uld_stock.uld.size,
            stock: uld.uld_stock.nbr,
            uld_id: uld.uld_stock.uld.id,
            max_weight: uld.max_weight,
            nbr: uld.uld_stock.nbr,
          };

          uldsLoad.push(newUldLoad);
        });

        uldsLoad.sort((a, b) => {
          if (a.uld_id < b.uld_id) {
            return -1;
          }
          if (a.uld_id > b.uld_id) {
            return 1;
          }
          return 0;
        });

        let uldToAdd = { uld_id: -1 };
        let cleanedUldsList = [];
        uldsLoad.forEach((uld) => {
          if (uldToAdd?.uld_id === uld.uld_id) {
            uldToAdd.nbr = uldToAdd.nbr + 1;
            uldToAdd.stock = uldToAdd.stock + 1;
          } else {
            if (uldToAdd.uld_id > 0) {
              cleanedUldsList.push(uldToAdd);
            }
            uldToAdd = { ...uld };
          }
        });
        console.log("cleanedUldsList", cleanedUldsList);
        this.setState({ uldsLoadList: cleanedUldsList });
        this.setState({ loadingDatas: true });
      }
    });
    getUlds(this.props.userReducer.token).then((response) => {
      this.setState({ isMainLoading: false });
      if (response.status === 200 && response.ok) {
        this.setState({ uldsBank: response.data });
        this.setState({ isAsyncLoading: true });
        getUldStock(this.props.userReducer.token).then((response) => {
          this.setState({ isAsyncLoading: false });
          this.setState({ isAsyncLoading: true });
          if (response.status === 200 && response.ok) {
            const uldList = response.data;
            let uldListRedux = [];
            uldList.forEach((uld) => {
              this.state.uldsBank.forEach((uldBank) => {
                if (uld.uld.id === uldBank.id) {
                  let newUld = {
                    id: uldBank.id,
                    stock: uld.nbr,
                    name: uldBank.name,
                    ref: uldBank.ref,
                    description: uldBank.description,
                    picture: uldBank.picture,
                  };
                  uldListRedux.push(newUld);
                }
              });
            });
            this.props.refreshUldStock(uldListRedux);
            this.setState({ isAsyncLoading: false });
          }
        });
      }
    });
  };

  componentDidMount() {
    this.loadDatas();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadDatas();
    }
  }

  render() {
    return (
      <>
        {this.state.loadingDatas && (
          <UldsListView
            load={this.state.load}
            uldList={this.props.stockULDSReducer.uldList}
            uldsBank={this.state.uldsBank}
            addUldStock={this.addUldStock}
            deleteUldStock={this.deleteUldStock}
            updateUldStock={this.updateUldStock}
            uldsLoadList={this.state.uldsLoadList}
            loadUpdate={this.loadUpdate}
          />
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUldStock: (uld) => {
      dispatch({ type: "ADDULDSTOCK", data: uld });
    },
    deleteUldStock: (id) => {
      dispatch({ type: "DELETEULDSTOCK", data: { id: id } });
    },
    updateUldStock: (data) => {
      dispatch({ type: "UPDATEULDSTOCK", data: data });
    },
    refreshUldStock: (data) => {
      dispatch({ type: "REFRESHULDSTOCK", data: data });
    },
    updateLoads: (loadsList) => {
      dispatch({ type: "UPDATELOADS", data: loadsList });
    },
  };
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
    stockULDSReducer: state.stockULDSReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UldsListContainer);
