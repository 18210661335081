import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import {ALENGREENDARK, ALENWHITE} from "utils/colors";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '& + $track': {
      backgroundColor: ALENWHITE,
      opacity: 1,
      border: 'none',
      borderColor:ALENGREENDARK,
      borderWidth:1,
      borderStyle:'solid',
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: ALENGREENDARK,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: ALENGREENDARK,
      border: '6px solid '+ALENGREENDARK,
    },
  },
  thumb: {
    width: 24,
    height: 24,
    backgroundColor:ALENGREENDARK,
    '&$checked': {
      backgroundColor:ALENWHITE,
    }
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid `+ALENGREENDARK,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class ALENSwitch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;


    return (
      <>
        <IOSSwitch
          onChange={this.props.onChange}
          checked={this.props.checked}
        />
      </>
    );
  }
}

ALENSwitch.propTypes = {
  label:PropTypes.string,
};

ALENSwitch.defaultProps = {
  label:"Cocoon",
};

export default withTranslation()(ALENSwitch)
