import * as React from "react";

function SvgMore(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path
        d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
      />
    </svg>
  );
}

export default SvgMore;
