import React, {Component} from 'react';
import SvgBackgroundLogin from "components/icons/BackgroundLogin";
import { withTranslation } from 'react-i18next';
import {ALENBLUE, ALENBG, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import ALENLayout from 'components/ALENLayout';
import ALENTiedownsEmpty from 'components/ALENTiedownsEmpty';
import ALENTiedownsTable from 'components/ALENTiedownsTable';
import ALENText from 'components/ALENText';

class TiedownsListView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  render()
  {
    let t = this.props.t

    const ariane = [
      {
        label: t('tiedowns.title'),
        link: 'current',
      },
    ]

    return(
      <>
        <ALENLayout
          title={t('tiedowns.mainTitle')}
          ariane={ariane}
        >
          {this.props.tiedowns.length > 0 ?
            <ALENTiedownsTable
              addTiedown={() => window.location.href="tiedowns/add"}
              tiedowns={this.props.tiedowns}
              aircraftList={this.props.aircraftList}
            />
          :
            <ALENTiedownsEmpty
              onPressNew={() => window.location.href="tiedowns/add"}/>
          }
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(TiedownsListView)
