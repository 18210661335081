import React from 'react';
import {ALENBLUE, ALENBORDER, ALENBLACK, ALENWHITE, ALENDISABLEDDARK, TABLEBORDER, ALENGREY, ALENSUCCESS, ALENBG, BLUEINFO2} from "utils/colors";
import SvgSuccess from 'components/icons/Success';

function ALENChargedPercent(props) {
  return(
    <div style={{
      marginLeft:9,
      flexDirection:'row',
      display:'flex',
      maxWidth:102,
      height:21,
      borderRadius:2,
      borderWidth:1,
      borderColor: TABLEBORDER,
      borderStyle:'solid',
    }}>
      {props.percent !== 100 ?
        <>
          <div
            style={{
              height:19,
              width:props.percent,
              backgroundColor: BLUEINFO2,
              borderRadius:1,
          }}/>
          <div
            style={{
              height:19,
              width:100-props.percent,
              backgroundColor: ALENBORDER,
              borderTopRightRadius:1,
              borderBottomRightRadius:1,
          }}/>
        </>
        :
        <div
          style={{
            height:19,
            width:100,
            backgroundColor: ALENSUCCESS,
            borderRadius:1,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center'
        }}>
          <SvgSuccess
            fill={ALENWHITE}
            style={{
              width:15,
              height:15,
            }}
          />
        </div>
      }
    </div>
  )
}

export default (ALENChargedPercent)
