const initialState = {
    logged: false,
    version: 1,
}

function userReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'LOGOUT' : {
      return initialState
    }
    case 'LOGIN' :
      nextState = {
        ...state,
        ...action.data,
        logged: true,
        version: 1,
      }
      return nextState
    default:
      return state
  }
}

export default userReducer
