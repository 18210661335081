import React, {Component} from 'react';
import TiedownCalculatorView from './TiedownCalculatorView';
import ALENLoading from 'components/ALENLoading';
import { connect } from 'react-redux';
import { getAircraftList } from 'api/methods/load';
import { getTiedownModel, saveTiedown, getTiedownsList } from 'api/methods/tiedowns';
import ALENToast from 'components/ALENToast';
import { withTranslation } from 'react-i18next';

class TiedownCalculatorContainer extends Component {
  constructor(props) {
    super(props)

    const idTiedown = this.props.match.params.id

    this.state = {
      aircraftList: [],
      tiedown: this.props.tiedownsReducer.tiedowns[this.props.tiedownsReducer.tiedowns.findIndex((el) => el.id === parseInt(idTiedown, 10))],
      model: null,
      isAsyncLoading: false,
      isLoading: false,
      success: false,
      successMessage: "",
    }
  }

  getPdf = () => {
    const url = process.env.REACT_APP_API_URL+"tiedown/pdf/"+this.state.tiedown.id
    //const url = "https://firebasestorage.googleapis.com/v0/b/reliable-return-291618.appspot.com/o/PRINTOUT%20747-400F.pdf?alt=media"
    fetch(url, {
            method: 'GET',
            headers: new Headers({
                "X-token-api": this.props.userReducer.token
            })
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "tiedown.pdf";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        })

        /*var link = document.createElement("a");
      link.download = "tiedown.pdf";
      link.target="_bank";
      link.href = "https://storage.googleapis.com/agregbou/tiedown.pdf";
      link.click();*/
    }

  save = (datas, withPdf: false) => {
    this.setState({isAsyncLoading: true})
    saveTiedown(this.props.userReducer.token, datas).then((response) => {
      if(response.status === 200 && response.ok) {
        getTiedownsList(this.props.userReducer.token).then((response) => {
          if(response.status === 200 && response.ok) {
            this.props.updateTiedowns(response.data)
            this.setState({
              isAsyncLoading: false,
              success: true,
              successMessage: this.props.t('tiedowncalculator.success'),
            })

            if(withPdf) {
              this.getPdf()
            }
            setTimeout(() => {
              this.setState({success:false})
            }, 3000);
          }
        })
      }
    })
  }

  getModel = (aircraft_id) => {
    this.setState({isLoading: true})
    getTiedownModel(this.props.userReducer.token, aircraft_id).then((response) => {
      this.setState({isLoading: false})
      if(response.status === 200 && response.ok) {
        this.setState({model: response.data})
      }
    })
  }

  changeWeight = (weight) => {
    const newTiedown = {
      ...this.state.tiedown,
      weight: weight,
    }

    this.props.updateTiedown(newTiedown)
    this.setState({tiedown: newTiedown})
  }

  changeAircraft = (aircraft_id) => {
    const newTiedown = {
      ...this.state.tiedown,
      aircraft_id: aircraft_id,
    }

    this.props.updateTiedown(newTiedown)
    this.setState({tiedown: newTiedown})
    this.getModel(aircraft_id)
  }

  componentDidUpdate(prevProps){

    if(prevProps.match.params.id !== this.props.match.params.id){
      const idTiedown = this.props.match.params.id
      this.setState({
        aircraftList: [],
        tiedown: this.props.tiedownsReducer.tiedowns[this.props.tiedownsReducer.tiedowns.findIndex((el) => el.id === parseInt(idTiedown, 10))],
        model: null,
      })
      getAircraftList(this.props.userReducer.token).then((response) => {
        if(response.status === 200 && response.ok) {
          this.setState({
            aircraftList: response.data
          })

          this.setState({tiedown : this.props.tiedownsReducer.tiedowns[this.props.tiedownsReducer.tiedowns.findIndex((el) => el.id === parseInt(idTiedown, 10))]})

          this.getModel(this.props.tiedownsReducer.tiedowns[this.props.tiedownsReducer.tiedowns.findIndex((el) => el.id === parseInt(idTiedown, 10))].aircraft_id)
        }
      })
    }
  }

  componentDidMount() {
    const idTiedown = this.props.match.params.id

    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })

    this.getModel(this.props.tiedownsReducer.tiedowns[this.props.tiedownsReducer.tiedowns.findIndex((el) => el.id === parseInt(idTiedown, 10))].aircraft_id)
  }

  render() {
    console.log('this.state.tiedown', this.state.tiedown)
    return(
      <>
        {this.state.model !== null ?
        <TiedownCalculatorView
          aircraftList={this.state.aircraftList}
          tiedown={this.state.tiedown}
          model={this.state.model}
          changeAircraft={this.changeAircraft}
          changeWeight={this.changeWeight}
          save={this.save}
          getPdf={this.getPdf}
          isAsyncLoading={this.state.isAsyncLoading}
          isLoading={this.state.isLoading}
        />
        :
        <div
          style={{
            flexDirection:'column',
            alignItems:'center',
            flex:1,
            marginTop:100,
          }}>
          <ALENLoading
            big
            />
        </div>
        }
        <ALENToast
          message={this.state.successMessage}
          show={this.state.success}
          handleClose={() => this.setState({success: false})}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateTiedown: (tiedown) => {
      dispatch({type: "UPDATETIEDOWN", data: tiedown})
    },
    updateTiedowns: (tiedownsList) => {
      dispatch({type : "UPDATETIEDOWNS", data: tiedownsList})
    },
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    tiedownsReducer: state.tiedownsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TiedownCalculatorContainer))
