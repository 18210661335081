const tiedowncalculator = {
  "create" : {
    "title" : "Create new tiedown",
    "name" : "Identifiant",
    "weight" : "Weight (kg)",
    "weightPlaceholder" : "Enter weight",
    "length" : "Length (cm)",
    "lengthPlaceholder" : "Enter length",
    "width" : "Width (cm)",
    "widthPlaceholder" : "Enter width",
    "height" : "Height (cm)",
    "heightPlaceholder" : "Enter height",
    "custom_reference": "Custom reference",
    "flight_number": "Flight number",
    "description": "Description",
    "ac_registration": "AC registration",
    "slr_number": "SLR number",
    "position_loaded": "Position loaded",
    "floating_pallet": "Floating pallet",
    "frangible_cargo": "Frangible cargo",
    "custom_referencePlaceholder": "Enter custom reference",
    "flight_numberPlaceholder": "Enter flight number",
    "descriptionPlaceholder": "Enter description",
    "ac_registrationPlaceholder": "Enter AC registration",
    "slr_numberPlaceholder": "Enter SLR number",
    "position_loadedPlaceholder": "Enter position loaded",
    "floating_palletPlaceholder": "Enter floating pallet",
    "frangible_cargoPlaceholder": "Enter frangible cargo",
  },
  "errorID" : "Identifiant cannot be null",
  "title" : "Tiedown calculator",
  "identifiant" : "Identifiant",
  "idPlaceholder" : "Enter identifiant",
  "weight" : "Cargo weight (kg)",
  "weightPlaceholder" : "Enter weight",
  "selectAircraft" : "Select type of plane",
  "save" : "Save",
  "pdf" : "Download pdf",
  "panes" : {
    "FWD" : "FWD",
    "SIDE_LEFT" : "SIDE-L",
    "SIDE_RIGHT" : "SIDE-R",
    "UP" : "UP",
    "AFT" : "AFT",
  },
  "table" : {
    "headers" : {
      "0" : "TIEDOWN LOCATION",
      "01" : "",
      "1" : "FLOOR ANGLE",
      "11" : "(DEGREES)",
      "2" : "CENTERLINE ANGLE",
      "21" : "(DEGREES)",
      "3" : "TIEDOWN VALUE",
      "31" : "(KGS)",
      "4" : "NB OF FITTINGS",
      "41" : "",
      "5" : "TIL TIEDOWN VALUE",
      "51" : "(KGS)",
    }
  },
  "newline" : "New line",
  "success" : "Success ! Tiedown has been saved !",
  "modal" : {
    "title" : "Are you sure ? ",
    "message" : "Change aircraft type will reset all tiedown calculateds datas, are you sure ?",
    "yes" : "Yes",
    "no" : "No",
  },
  "modalPdf" : {
    "title" : "Generate Pdf",
    "confirm" : "Get Pdf",
    "cancel" : "Cancel",
  }
}

export default tiedowncalculator
