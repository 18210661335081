// Imports: Dependencies
import { combineReducers } from 'redux';// Imports: Reducers
import userReducer from './userReducer';
import stockULDSReducer from './stockULDSReducer';
import loadsReducer from './loadsReducer';
import addParcelsReducer from './addParcelsReducer';
import tiedownsReducer from './tiedownsReducer';
import balanceReducer from './balanceReducer';

// Redux: Root Reducer
const rootReducer = combineReducers({
  userReducer: userReducer,
  stockULDSReducer: stockULDSReducer,
  loadsReducer: loadsReducer,
  addParcelsReducer: addParcelsReducer,
  tiedownsReducer: tiedownsReducer,
  balanceReducer: balanceReducer,
});// Exports
export default rootReducer;
