import React, {Component} from 'react';
import ULDScene from "components/ULDScene";

class Test3DView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  render()
  {
    return(
      <>
        <p>Salut</p>
        <div>
          <ULDScene
          />
        </div>
      </>
    )
  }
}

export default Test3DView
