const initialState = {
    tiedowns: [],
}

function tiedownsReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'LOGOUT' : {
      return initialState
    }
    case 'RESET' :
      return initialState
    case 'UPDATETIEDOWN' :
      nextState = {
        ...state,
      }

      let tiedownUpdate = nextState.tiedowns
      tiedownUpdate[tiedownUpdate.findIndex((el) => el.id === action.data.id)] = action.data
      nextState = {
        ...state,
        tiedowns: tiedownUpdate
      }
      return nextState
    case 'UPDATETIEDOWNS':
      nextState = {
        ...state,
        tiedowns: action.data
      }
      return nextState
    case 'ADDTIEDOWN' :
      nextState = {
        ...state,
      }

      let tiedownsAdd = nextState.tiedowns
      tiedownsAdd.push(action.data)
      nextState = {
        ...state,
        tiedowns: tiedownsAdd
      }
      return nextState
    default:
      return state
  }
}

export default tiedownsReducer
