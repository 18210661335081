import firebase from 'firebase';

const firebaseConfig = {
    apiKey: 'AIzaSyC-tB8NVSP0NDZy9NBfowrjzSJRT7a_iMo',
    authDomain: '<your-auth-domain>',
    databaseURL: '<your-database-url>',
    storageBucket: 'gs://reliable-return-291618.appspot.com'
  };

firebase.initializeApp(firebaseConfig);

export var storage = firebase.storage();

export const uploadSvg = async(file) => {
  const uploadToFirebase = (blob) => {
    return new Promise((resolve, reject)=>{
      const storageRef = storage.ref();
      storageRef.child(`${new Date().getTime() + ".svg"}`).put(blob, {

      })
      .then((snapshot)=>{
        resolve(snapshot);
      }).catch((error)=>{
          console.log('error',error)
          reject(error);
      });
    });
  }

  try {
      return new Promise((resolve,reject) => {
          uploadToFirebase(file)
          .then((result) =>{
              resolve('https://firebasestorage.googleapis.com/v0/b/reliable-return-291618.appspot.com/o/' + result.metadata.fullPath + "?alt=media")
          })
          .catch((error) =>{
              console.log('error', error)
              reject(error)
          })

      })
  } catch (error) {
      console.log(error);
  }
}

export const uploadFile = async(file) => {
  const uploadToFirebase = (blob) => {
      return new Promise((resolve, reject)=>{
        const storageRef = storage.ref();
        storageRef.child(`${new Date().getTime()}`).put(blob, {

        })
        .then((snapshot)=>{
          resolve(snapshot);
        }).catch((error)=>{
            console.log('error',error)
            reject(error);
        });
      });
    }

  try {
      return new Promise((resolve,reject) => {
          uploadToFirebase(file)
          .then((result) =>{
              resolve('https://firebasestorage.googleapis.com/v0/b/reliable-return-291618.appspot.com/o/' + result.metadata.fullPath + "?alt=media")
          })
          .catch((error) =>{
              console.log('error', error)
              reject(error)
          })

      })
  } catch (error) {
      console.log(error);
  }
}

export const uploadImage = async (image) => {
  const uploadToFirebase = (blob) => {
      return new Promise((resolve, reject)=>{
        const storageRef = storage.ref();
        storageRef.child(`${new Date().getTime()}.jpg`).put(blob, {
            contentType: 'image/jpeg'
        })
        .then((snapshot)=>{
          resolve(snapshot);
        }).catch((error)=>{
            console.log('error',error)
            reject(error);
        });
      });
    }

  try {
      return new Promise((resolve,reject) => {
          uploadToFirebase(image)
          .then((result) =>{
              resolve('https://firebasestorage.googleapis.com/v0/b/reliable-return-291618.appspot.com/o/' + result.metadata.fullPath + "?alt=media")
          })
          .catch((error) =>{
              console.log('error', error)
              reject(error)
          })

      })
  } catch (error) {
      console.log(error);
  }
}
