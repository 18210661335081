import React, {Component, createRef} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {checkEmpty} from 'utils/checkForm'

import ALENLayout from 'components/ALENLayout';
import ALENAddParcelsTable from 'components/ALENAddParcelsTable';
import {ALENWHITE, ALENGREENDARK, ALENGREEN, ALENBLUE, ALENBG, ALENBLACK, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import {uploadSvg} from 'utils/firebaseConfig';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENSelect from 'components/ALENSelect';
import ALENULDStockTable from 'components/ALENULDStockTable';
import ALENButton from 'components/ALENButton';
import ALENDatePicker from 'components/ALENDatePicker';
import origami from 'assets/images/origami.png';
import {DATAS} from 'utils/balanceChart/boeing-747-400ERF';
import ALENBalanceChartBox from 'components/ALENBalanceChartBox';
import ALENBalanceChartSimpleBox from 'components/ALENBalanceChartSimpleBox';
import ALENBalanceChartMiniBox from 'components/ALENBalanceChartMiniBox';
import ALENBalanceChartZoneBox from 'components/ALENBalanceChartZoneBox';
import ALENFuelPlan from 'components/ALENFuelPlan';
import ALENBalanceRecap from 'components/ALENBalanceRecap';
import ALENLoading from 'components/ALENLoading';
import ALENBalanceImbalance from 'components/ALENBalanceImbalance';

class BalanceChartView extends Component {
  constructor(props) {
    super(props)

    this.svgRef = createRef();

    this.state={
      isDragging1: null,
      over1: null,
      isDragging2: null,
      over2: null,
      isDragging3: null,
      over3: null,
      isDragging4: null,
      over4: null,
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  getIndexOccupants = (weight) => {
    let index = 0

    if(weight > 100) {
      index = -1
    }

    if(weight > 500) {
      index = -2
    }

    return index
  }

  getSizesCodesList = (uld) => {
    let sizesCodes = []
    for(let sizeValue in DATAS.sizes_codes[uld.id]) {
      if(DATAS.sizes_codes[uld.id][sizeValue] !== null) {
        sizesCodes.push({value: sizeValue, libelle: sizeValue})
      }
    }

    return(sizesCodes)
  }

  getPayloadIndex = () => {
    let payloadIndex = 0
    DATAS.struct.line4.forEach((uld) => {
      let indexValue = 0
      const zone = (this.props.datas[uld.id].right.WT) + (this.props.datas[uld.id].left ? this.props.datas[uld.id].left.WT : 0) + this.props.getWeightOpt(uld.id)
      DATAS.indexRules[uld.id].forEach((rule) => {
        if(zone >= rule.value) {
          indexValue = rule.index
        }
      })
      payloadIndex = payloadIndex + indexValue
    })

    return payloadIndex
  }

  getPayloadValues = () => {
    let weight = 0
    for(let data in this.props.datas) {
      if((data.substr(0,3) !== 'BIS')) {
        for(let side in this.props.datas[data]) {
            weight = weight + this.props.datas[data][side].WT
        }
      } else {
        weight = weight + this.props.datas[data].WT
      }
    }

    const payloadValues = {
      weight: weight,
      index: this.getPayloadIndex()
    }

    return(payloadValues)
  }

  render() {
    const t = this.props.t

    const ariane = [
      {
        label: t('balance.title'),
        link: '/balance',
      },
      {
        label: this.props.name,
        link: 'current',
      }
    ]

    return(
      <ALENLayout
        title={this.props.name}
        ariane = {ariane}
      >
      {this.props.isLoading ?
        <>
          <div
            style={{
              padding:100
            }}>
            <ALENLoading
              big/>
          </div>
        </>
      :
        <>
          <div
            onMouseMove={(e) => {
              this.setState({
                x : e.clientX,
                y : e.clientY
              })
            }}
            style={{
              transform: "scale(1)",
            }}>
            <div
              style={{
                padding:32,
                paddingTop:48,
              }}>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                }}>
                {DATAS.struct.line1.map((uld, index) => {
                  return(
                    <ALENBalanceChartBox
                      onDragEndTop={() => {
                        this.props.swap1(this.state.over1, this.state.isDragging1)
                      }}
                      onMouseOverTop={() => this.setState({over1: {uld: uld, index: index}})}
                      onDragStartTop={() => this.setState({isDragging1: {uld: uld, index: index}})}
                      onDragEndBot={() => {
                        this.props.swap2(this.state.over2, this.state.isDragging2)
                      }}
                      onMouseOverBot={() => this.setState({over2: {uld: uld, index: index}})}
                      onDragStartBot={() => this.setState({isDragging2: {uld: uld, index: index}})}
                      onChangeValueBox={this.props.onChangeValueBox}
                      displayRL={((index>2) && (index < (DATAS.struct.line1.length -1)))}
                      uld={uld}
                      datas={this.props.datas[uld.id]}
                      maxValueRight={this.props.datas[uld.id].right.size_code !== null ? DATAS.sizes_codes[uld.id][this.props.datas[uld.id].right.size_code] : 0}
                      maxValueLeft={this.props.datas[uld.id].left !== undefined ? this.props.datas[uld.id].left.size_code !== null ? DATAS.sizes_codes[uld.id][this.props.datas[uld.id].left.size_code] : 0 : 0}
                      sizeCodesList={this.getSizesCodesList(uld)}
                    />
                  )
                })}
              </div>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center',
                }}>
                <div
                  style={{
                    borderRadius:3,
                    borderColor:ALENGREENDARK,
                    borderWidth:2,
                    borderStyle:'solid',
                    marginTop:15,
                    maxWidth:200,
                    padding:8,
                  }}>
                  <ALENText
                    color={ALENBLUE} bold center>
                    {DATAS.text.a}
                  </ALENText>
                </div>
                <div
                  style={{
                    flexDirection:'column',
                    display:'flex',
                    marginLeft:8,
                    marginRight:4,
                    marginTop:1,
                  }}>
                  <div
                    style={{
                      borderRadius:3,
                      borderColor:ALENGREENDARK,
                      borderWidth:2,
                      borderStyle:'solid',
                      marginTop:15,
                      maxWidth:200,
                      padding:8,
                      paddingLeft:2,
                      paddingRight:2,
                      borderBottomRightRadius:0,
                      borderBottomLeftRadius:0,
                    }}>
                    <ALENText
                      color={ALENBLUE} bold center>
                      {DATAS.text.b}
                    </ALENText>
                    <ALENText
                      color={ALENBLUE} bold center>
                      {DATAS.text.c}
                    </ALENText>
                  </div>
                  <div
                    style={{
                      borderRadius:3,
                      borderTopRightRadius: 0,
                      borderTopLeftRadius:0,
                      borderColor:ALENGREENDARK,
                      borderWidth:2,
                      borderTopWidth:0,
                      borderStyle:'solid',
                      maxWidth:200,
                      padding:8,
                      paddingLeft:2,
                      paddingRight:2,
                    }}>
                    <ALENText
                      color={ALENBLUE} bold center>
                      {DATAS.text.d}
                    </ALENText>
                    <ALENText
                      color={ALENBLUE} bold center>
                      {DATAS.text.e}
                    </ALENText>
                  </div>
                </div>
                {DATAS.struct.line2.part1.map((uld, index) => {
                  return(
                    <ALENBalanceChartMiniBox
                      maxValue={DATAS.maxWeightLowers}
                      onDragEndTop={() => {
                        this.props.swap3(this.state.over3, this.state.isDragging3)
                      }}
                      onMouseOverTop={() => this.setState({over3: {uld: uld, index: index}})}
                      onDragStartTop={() => this.setState({isDragging3: {uld: uld, index: index}})}
                      onDragEndBot={() => {
                        this.props.swap4(this.state.over4, this.state.isDragging4)
                      }}
                      onMouseOverBot={() => this.setState({over4: {uld: uld, index: index}})}
                      onDragStartBot={() => this.setState({isDragging4: {uld: uld, index: index}})}
                      datas={this.props.datas[`OPT${uld.id.substr(0,2)}`]}
                      onChangeValueBox={this.props.onChangeValueBox}
                      index={index}
                      uld={uld}/>
                  )
                })}
                <div
                  style={{
                    flexDirection:'column',
                    display:'flex',
                    justifyContent:'center',
                  }}>
                  <ALENText
                    color={ALENBLUE} center>
                    {DATAS.text.f}
                  </ALENText>
                  <div
                    style={{
                      borderRadius:3,
                      borderColor:ALENGREENDARK,
                      borderWidth:2,
                      borderStyle:'solid',
                      maxWidth:432,
                      padding:8,
                      margin:32,
                      marginTop:7,
                      marginBottom:0,
                    }}>
                    <ALENText
                      color={ALENBLUE} bold center>
                      {DATAS.text.g}
                    </ALENText>
                  </div>
                </div>
                {DATAS.struct.line2.part2.map((uld, index) => {
                  return(
                    <ALENBalanceChartMiniBox
                      maxValue={DATAS.maxWeightLowers}
                      onDragEndTop={() => {
                        this.props.swap3(this.state.over3, this.state.isDragging3)
                      }}
                      onMouseOverTop={() => this.setState({over3: {uld: uld, index: index}})}
                      onDragStartTop={() => this.setState({isDragging3: {uld: uld, index: index}})}
                      onDragEndBot={() => {
                        this.props.swap4(this.state.over4, this.state.isDragging4)
                      }}
                      onMouseOverBot={() => this.setState({over4: {uld: uld, index: index}})}
                      onDragStartBot={() => this.setState({isDragging4: {uld: uld, index: index}})}
                      datas={this.props.datas[`OPT${uld.id.substr(0,2)}`]}
                      onChangeValueBox={this.props.onChangeValueBox}
                      index={index}
                      uld={uld}/>
                  )
                })}
              </div>
              <div
                style={{
                  display:'flex',
                  flexDirection:'row',
                  marginLeft:246
                }}>
                {DATAS.struct.line3.part1.map((uld, index) => {
                  return(
                    <ALENBalanceChartSimpleBox
                      maxValue={this.props.datas[`BISOPT${uld.id}`].size_code !== null ? DATAS.sizes_codes[DATAS.correspIdCode[uld.id]][this.props.datas[`BISOPT${uld.id}`].size_code] : 0}
                      sizeCodesList={[{value: 'A', libelle: 'A'}, {value: 'M', libelle: 'M'}]}
                      onDragEnd={() => {
                        this.props.swap5(this.state.over5, this.state.isDragging5)
                      }}
                      onMouseOver={() => this.setState({over5: {uld: uld, index: index}})}
                      onDragStart={() => this.setState({isDragging5: {uld: uld, index: index}})}
                      datas={this.props.datas[`BISOPT${uld.id}`]}
                      onChangeValueBox={this.props.onChangeValueBox}
                      uld={uld}
                      />
                  )
                })}
                <div
                  style={{
                    borderRadius:3,
                    borderColor:ALENGREENDARK,
                    borderWidth:2,
                    borderStyle:'solid',
                    marginTop:32,
                    maxWidth:310,
                    padding:8,
                    margin:32,
                    marginTop:0,
                    marginBottom:0,
                  }}>
                  <ALENText
                    color={ALENBLUE} bold center>
                    {DATAS.text.h}
                  </ALENText>
                </div>
                {DATAS.struct.line3.part2.map((uld, index) => {
                  return(
                    <ALENBalanceChartSimpleBox
                      maxValue={this.props.datas[`BISOPT${uld.id}`].size_code !== null ? DATAS.sizes_codes[DATAS.correspIdCode[uld.id]][this.props.datas[`BISOPT${uld.id}`].size_code] : 0}
                      sizeCodesList={[{value: 'A', libelle: 'A'}, {value: 'M', libelle: 'M'}]}
                      onDragEnd={() => {
                        this.props.swap5(this.state.over5, this.state.isDragging5)
                      }}
                      onMouseOver={() => this.setState({over5: {uld: uld, index: index}})}
                      onDragStart={() => this.setState({isDragging5: {uld: uld, index: index}})}
                      datas={this.props.datas[`BISOPT${uld.id}`]}
                      onChangeValueBox={this.props.onChangeValueBox}
                      uld={uld}
                      />
                  )
                })}
              </div>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                padding:32
              }}>
              {DATAS.struct.line4.map((uld) => {
                let cum = 0
                let finish = false

                for(var dataUld in this.props.datas) {
                  if((dataUld.substr(0,3) !== 'OPT') && (dataUld.substr(0,3) !== 'BIS')) {
                    if(!finish) {
                      if(dataUld === uld.id) {
                        finish = true
                      }
                      cum = cum + this.props.datas[dataUld].right.WT + (this.props.datas[dataUld].left ? this.props.datas[dataUld].left.WT : 0) + this.props.getWeightOpt(dataUld)
                    }

                    if(uld.id === 'H') {
                      cum = 0
                    }

                    if(dataUld === 'H') {
                      break;
                    }
                  }
                }

                if(!finish) {
                  cum = 0
                  let begin = false
                  for(let dataUld in this.props.datas) {
                    if((dataUld.substr(0,3) !== 'OPT') && (dataUld.substr(0,3) !== 'BIS')) {
                      if(dataUld === uld.id) {
                        begin = true
                      }

                      if(begin) {
                        cum = cum + this.props.datas[dataUld].right.WT + (this.props.datas[dataUld].left ? this.props.datas[dataUld].left.WT : 0) + this.props.getWeightOpt(dataUld)
                      }
                    }
                  }
                }

                return(
                  <ALENBalanceChartZoneBox
                    cum={cum}
                    zone={(this.props.datas[uld.id].right.WT) + (this.props.datas[uld.id].left ? this.props.datas[uld.id].left.WT : 0) + this.props.getWeightOpt(uld.id)}
                    uld={uld}
                    indexRule={DATAS.indexRules[uld.id]}
                    />
                )
              })}
            </div>
          </div>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              marginLeft:35,
              justifyContent:'center'
            }}>
            <div style={{
              display:'flex',
              flexDirection:'colum',
              justifyContent:'center',
              alignItems:'center',
              margin:32,
            }}>
              <ALENBalanceRecap
                nb_occupants={this.props.nb_occupants}
                onChangeOccupants={this.props.onChangeOccupants}
                onChangeTripFuel={this.props.onChangeTripFuel}
                tripFuel={this.props.tripFuel}
                indexTripFuel={this.props.indexTripFuel}
                onChangeTakeOffFuel={this.props.onChangeTakeOffFuel}
                takeOffFuel={this.props.takeOffFuel}
                indexTakeOffFuel={this.props.indexTakeOffFuel}
                MZFW={DATAS.MZFW}
                MTOW={DATAS.MTOW}
                MLW={DATAS.MLW}
                densityList={DATAS.fuel_densities}
                densitySelected={this.props.densitySelected}
                onSelectDensity={this.props.onSelectDensity}
                DOW={this.props.DOW}
                onChangeDow={this.props.onChangeDow}
                indexDOW={this.props.indexDOW}
                onChangeIndexDow={this.props.onChangeIndexDow}
                payload={this.getPayloadValues()}
                t={t}/>
            </div>
            <div>
            <ALENFuelPlan
              ref={this.svgRef}
              ZFW={this.getPayloadValues().weight + this.props.DOW + this.props.nb_occupants*100}
              indexZFW={this.getPayloadValues().index + this.props.indexDOW + this.getIndexOccupants(this.props.nb_occupants*100)}
              TOW={this.getPayloadValues().weight + this.props.DOW+this.props.takeOffFuel + this.props.nb_occupants*100}
              indexTOW={this.getPayloadValues().index + this.props.indexDOW+this.props.indexTakeOffFuel + this.getIndexOccupants(this.props.nb_occupants*100)}
              MZFW={DATAS.MZFW}
              MTOW={DATAS.MTOW}
            />
            <div
              style={{display:'flex', flexDirection:'row', justifyContent:'center', flex:1, margin:32,}}>
              <ALENButton
                label={t('balance.save')}
                onPress={() => {
                  let svg = '<svg xmlns="http://www.w3.org/2000/svg" width="660" height="700">' + this.svgRef.current.svg.current.outerHTML + '</svg>'
                  const blob = new Blob([svg], {type: 'image/svg+xml'})
                  uploadSvg(blob).then((result) => {
                    this.props.update(result)
                  })
                }}
              />
            </div>
            </div>
            <div style={{
              display:'flex',
              flexDirection:'colum',
              justifyContent:'center',
              alignItems:'center',
              margin:32,
            }}>
              <ALENBalanceImbalance
                datas={this.props.datas}

              />
            </div>
          </div>
        </>
      }
      {this.state.isMoving1 !==  null && false &&
        <div
          style={{
            position:'absolute',
            top: this.state.y - 50,
            left: this.state.x - 50
          }}>
          <ALENBalanceChartBox
            dragging={true}
            displayOpt={'top'}
            onMouseOver={() => console.log('nothing')}
            onMouseDown={() => console.log('nothing')}
            onMouseUp={() => {

              if(this.state.isOver1 !== null) {
                console.log('this.state.isOver1', this.state.isOver1)
                console.log('this.state.isMoving1', this.state.isMoving1)

                this.props.swap1(this.state.isOver1, this.state.isMoving1)
              }

              this.setState({isOver1: null, isMoving1: null})

            }}
            onChangeValueBox={() => console.log('nothing')}
            displayRL={((this.state.isMoving1.index>2) && (this.state.isMoving1.index < (DATAS.struct.line1.length -1)))}
            uld={this.state.isMoving1.uld}
            datas={this.props.datas[this.state.isMoving1.uld.id]}
            maxValueRight={this.props.datas[this.state.isMoving1.uld.id].right.size_code !== null ? DATAS.sizes_codes[this.state.isMoving1.uld.id][this.props.datas[this.state.isMoving1.uld.id].right.size_code] : 0}
            maxValueLeft={this.props.datas[this.state.isMoving1.uld.id].left !== undefined ? this.props.datas[this.state.isMoving1.uld.id].left.size_code !== null ? DATAS.sizes_codes[this.state.isMoving1.uld.id][this.props.datas[this.state.isMoving1.uld.id].left.size_code] : 0 : 0}
            sizeCodesList={this.getSizesCodesList(this.state.isMoving1.uld)}
          />
        </div>
      }
      </ALENLayout>


    )
  }
}

export default withTranslation()(BalanceChartView)
