const parcelsList = {
  "pagination" : {
    "showEntries" : "Show entries",
  },
  "empty" : {
    "title" : {
      "1" : "You have no ",
      "2" : "PARCEL ",
      "3" : "declared",
    },
    "text" : "Please create your first parcel and start charging ",
    "buttonLabel" : "Add your first Parcel!",
  },
  "header" : {
    "add" : "Add parcels",
    "optimize" : "Optimize ULD",
  },
  "filter" : {
    "all" : "All parcels",
    "loaded" : "Loaded",
    "loading" : "In loading",
    "ready" : "Ready to load",
    "waiting" : "Waiting",
    "notLoaded" : "Not loaded",
  },
  "table" : {
    "headers" : {
      "0" : "AIRWAY BILL",
      "1" : "LENGTH (CM)",
      "2" : "WIDTH (CM)",
      "3" : "HEIGHT (CM)",
      "4" : "WEIGHT (KG)",
      "5" : "QUANTITY",
      "6" : "STATUS",
      "7" : "SPECIAL",
    }
  },
  "showLoadedParcels" : "Show Loaded parcels",
}

export default parcelsList
