const balance = {
  "title" : "Balances Charts list",
  "weight" : "WEIGHT",
  "index" : "INDEX +/-",
  "payload" : "PAYLOAD",
  "struct_max" : "STRUCT MAX",
  "dow" : "DOW",
  "zfw" : "ZFW",
  "wt_chg" : "Wt Chg",
  "takeoff" : "Take off Fuel",
  "tow" : "TOW",
  "tripFuel": "Trip Fuel",
  "lw": "LW",
  "density" : "D :",
  "save" : "SAVE AND GET PDF",
  "underload" : "UNDERLOAD",
  "occupants" : "NB OCCUPANTS",
}

export default balance
