import React, {Component, useState} from 'react';
import {ALENBLUE, ALENBORDER, ALENBLACK, ALENWHITE, ALENDISABLEDDARK, TABLEBORDER, ALENGREY, ALENSUCCESS, ALENBG, BLUEINFO2} from "utils/colors";
import ALENLoading from 'components/ALENLoading';
import ALENText from 'components/ALENText';
import {FONTNORMAL, FONTBIGSUP, FONTMINIBIG} from 'utils/fontSizes';
import SvgClose from 'components/icons/Close';
import SvgSuccess from 'components/icons/Success';
import { withTranslation } from 'react-i18next';
import ALENButton from 'components/ALENButton';
import ALENChargingPercent from 'components/ALENChargedPercent';
import ALENInput from 'components/ALENInput';

function ALENModalChangePassword(props) {
  const [password, setPassword] = useState('')

  const t = props.t

  return(
    <div
      style={{
        position:'fixed',
        top:0,
        left:0,
        zIndex:9999,
        width:'100%',
        height:'100%',
        backgroundColor:"rgba(0, 0, 0, 0.7)",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
      }}>
        <div
          style={{
            width:789,
            height:330,
            borderRadius: 8,
            backgroundColor:ALENWHITE,
          }}>
          <div
            style={{
              height:80,
              width:789,
              flexDirection:'row',
              display:'flex',
              borderWidth:0,
              borderBottomWidth:1,
              borderStyle:'solid',
              borderColor:"rgba(150, 147, 163, 0.7)",
            }}>
            <div
              style={{
                height:80,
                width:715,
                paddingLeft:32,
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
              }}>
              <ALENText
                size={FONTBIGSUP}
                bold>
                {props.t("manageAccount.changePassword.title")}
              </ALENText>
            </div>
            <div
              style={{
                height:80,
                width:74,
                flexDirection:'row',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}>
                <SvgClose
                  onClick={props.back}
                  fill={ALENGREY}
                  style={{
                    cursor:'pointer',
                    width:30,
                    height:30,
                  }}
                />
            </div>
          </div>
          <div
            style={{margin:32}}>
            <ALENText
              size={FONTMINIBIG}
              bold
              >
              {t('manageAccount.changePassword.text')}
            </ALENText>
            <ALENInput
              type={'password'}
              label={""}
              placeholder={t('manageAccount.changePassword.password')}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
          </div>
          <div
            style={{
              flexDirection:'row',
              justifyContent:'center',
              display:'flex',
            }}>
            <ALENButton
              medium
              variant
              onPress={props.back}
              label={props.t('manageAccount.changePassword.back')}
              />
            <div style={{width:32}}/>
            <ALENButton
              medium
              onPress={() => props.onChange(password)}
              label={props.t('manageAccount.changePassword.change')}
              />
          </div>
        </div>
    </div>
  )
}

export default withTranslation()(ALENModalChangePassword)
