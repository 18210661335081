import React, {Component, useState, useEffect} from 'react';
import {ALENBLUE, ALENBORDER, ALENBLACK, ALENWHITE, ALENDISABLEDDARK, TABLEBORDER, ALENGREY, ALENSUCCESS, ALENBG, BLUEINFO2} from "utils/colors";
import ALENLoading from 'components/ALENLoading';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENCheckBox from 'components/ALENCheckBox';
import {FONTNORMAL, FONTBIGSUP, FONTMINIBIG} from 'utils/fontSizes';
import SvgClose from 'components/icons/Close';
import SvgSuccess from 'components/icons/Success';
import { withTranslation } from 'react-i18next';
import ALENButton from 'components/ALENButton';
import ALENSelect from 'components/ALENSelect';
import ALENChargingPercent from 'components/ALENChargedPercent';
import ALENDatePicker from 'components/ALENDatePicker';
import SvgDownload from 'components/icons/Download';

function ALENModalEditLoad(props) {
  const t = props.t

  const [aircraftValues, setAircraftValues] = useState([])
  const [loadId, setLoadId] = useState(props.load.name)
  const [errorLoadId, setErrorLoadId] = useState(false)
  const [aircraft, setAircraft] = useState(props.load.aircraft_id)
  const [errorAircraft, setErrorAircraft] = useState(false)
  const [aircraftRegistration, setAircraftRegistration] = useState(props.load.aircraft_registration)
  const [errorAircraftRegistration, setErrorAircraftRegistration] = useState(false)
  const [customReference, setCustomReference] = useState(props.load.custom_reference)
  const [errorCustomReference, setErrorCustomReference] = useState(false)
  const [pointOfLoading, setPointOfLoading] = useState(props.load.point_of_loading)
  const [errorPointOfLoading, setErrorPointOfLoading] = useState(false)
  const [pointOfUnloading, setPointOfUnloading] = useState(props.load.point_of_unloading)
  const [errorPointOfUnloading, setErrorPointOfUnloading] = useState(false)
  const [flightNumber, setFlightNumber] = useState(props.load.flight_number)
  const [errorFlightNumber, setErrorFlightNumber] = useState(false)
  const [flightAt, setFlightAt] = useState(props.load.flight_at)
  const [errorFlightAt, setErrorFlightAt] = useState(false)
  const [ownerOperator, setOwnerOperator] = useState(props.load.owner_operator)
  const [errorOwnerOperator, setErrorOwnerOperator] = useState(false)
  const [deConsolidator, setDeConsolidator] = useState(props.load.de_consolidator)
  const [errorDeConsolidator, setErrorDeConsolidator] = useState(false)
  const [consolidator, setConsolidator] = useState(props.load.consolidator)
  const [errorConsolidator, setErrorConsolidator] = useState(false)

  const createLoadValidation = () => {
    let valid = true

    setErrorLoadId(false);
    setErrorAircraft(false);

    if(loadId.length === 0) {
      valid = false
      setErrorLoadId(true)
    }

    if(aircraft === '') {
      valid = false
      setErrorAircraft(true)
    }


    if(valid === true) {
      const newLoad = {
        name: loadId,
        aircraft: aircraft,
        aircraft_registration : aircraftRegistration,
        custom_reference : customReference,
        point_of_loading : pointOfLoading,
        point_of_unloading : pointOfUnloading,
        flight_number : flightNumber,
        flight_at : flightAt,
        owner_operator : ownerOperator,
        de_consolidator : deConsolidator,
        consolidator : consolidator,
      }

      props.onSave(newLoad)
    }
  }

  useEffect(() => {
    const newAircraftValues = []
    props.aircraftList.forEach(aircraft => {
      let newAircraft = {
        value: aircraft.id,
        libelle: aircraft.name
      };
      newAircraftValues.push(newAircraft);
    })

    setAircraftValues(newAircraftValues)
  }, []);

  return(
    <div
      style={{
        position:'fixed',
        top:0,
        left:0,
        zIndex:1200,
        width:'100%',
        height:'100%',
        backgroundColor:"rgba(0, 0, 0, 0.7)",
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
      }}>
        <div
          style={{
            width:789,
            height:550,
            borderRadius: 8,
            backgroundColor:ALENWHITE,
          }}>
          <div
            style={{
              height:80,
              width:789,
              flexDirection:'row',
              display:'flex',
              borderWidth:0,
              borderBottomWidth:1,
              borderStyle:'solid',
              borderColor:"rgba(150, 147, 163, 0.7)",
            }}>
            <div
              style={{
                height:80,
                width:715,
                paddingLeft:32,
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
              }}>
              <ALENText
                size={FONTBIGSUP}
                bold>
                {props.t("createLoad.editTitle")}
              </ALENText>
            </div>
            <div
              style={{
                height:80,
                width:74,
                flexDirection:'row',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}>
                <SvgClose
                  onClick={props.cancel}
                  fill={ALENGREY}
                  style={{
                    cursor:'pointer',
                    width:30,
                    height:30,
                  }}
                />
            </div>
          </div>
          <div style={{
            flexDirection:'row',
            display:'flex',
            marginTop:16,
          }}>
            <div
              style={{
                paddingLeft:32,
                flexDirection:'column',
                display:'flex',
              }}>
              <ALENInput
                label={t('loads.list.idLabel')}
                placeholder={t('loads.list.IdPlaceholder')}
                value={loadId}
                onChange={(event) => {
                  setLoadId(event.target.value)
                }}
                error={errorLoadId}
                errorMessage={t('loads.list.errorID')}
              />
              <div style={{height:16}}/>
              <ALENSelect
                datas={aircraftValues}
                minWidth={245}
                value={aircraft}
                label={t('loads.list.planeLabel')}
                placeholder={t('loads.list.aircraftPlaceholder')}
                onChange={(event) => {
                  setAircraft(event.target.value)
                }}
                error={errorAircraft}
                errorMessage={t('loads.list.errorValue')}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('createLoad.aircraftRegistration')}
                placeholder={t('createLoad.aircraftRegistrationPlaceholder')}
                value={aircraftRegistration}
                onChange={(event) => {
                  setAircraftRegistration(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('createLoad.customReference')}
                placeholder={t('createLoad.customReferencePlaceholder')}
                value={customReference}
                onChange={(event) => {
                  setCustomReference(event.target.value)
                }}
              />
            </div>
            <div
              style={{
                paddingLeft:32,
                flexDirection:'column',
                display:'flex',
                minWidth:245
              }}>
              <ALENInput
                label={t('createLoad.pointOfLoading')}
                placeholder={t('createLoad.pointOfLoadingPlaceholder')}
                value={pointOfLoading}
                onChange={(event) => {
                  setPointOfLoading(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('createLoad.pointOfUnloading')}
                placeholder={t('createLoad.pointOfUnloadingPlaceholder')}
                value={pointOfUnloading}
                onChange={(event) => {
                  setPointOfUnloading(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('createLoad.flightNumber')}
                placeholder={t('createLoad.flightNumberPlaceholder')}
                value={flightNumber}
                onChange={(event) => {
                  setFlightNumber(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <div style={{marginBottom:8}}>
                <ALENText>{t('createLoad.flightAt')}</ALENText>
              </div>
              <ALENDatePicker
                placeholder={t('createLoad.flightAtPlaceholder')}
                value={flightAt}
                onChange={(date) => setFlightAt(date)}
              />
            </div>
            <div
              style={{
                paddingLeft:32,
                paddingRight:32,
                flexDirection:'column',
                display:'flex',
                minWidth:245
              }}>
              <ALENInput
                label={t('createLoad.ownerOperator')}
                placeholder={t('createLoad.ownerOperatorPlaceholder')}
                value={ownerOperator}
                onChange={(event) => {
                  setOwnerOperator(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('createLoad.deConsolidator')}
                placeholder={t('createLoad.deConsolidatorPlaceholder')}
                value={deConsolidator}
                onChange={(event) => {
                  setDeConsolidator(event.target.value)
                }}
              />
              <div style={{height:16}}/>
              <ALENInput
                label={t('createLoad.consolidator')}
                placeholder={t('createLoad.consolidatorPlaceholder')}
                value={consolidator}
                onChange={(event) => {
                  setConsolidator(event.target.value)
                }}
              />
            </div>
          </div>
          <div
            style={{
              flexDirection:'row',
              justifyContent:'center',
              display:'flex',
              marginTop:32,
            }}>
            <ALENButton
              medium
              variant
              onPress={props.cancel}
              label={props.t('createLoad.cancel')}
              />
            <div style={{width:32}}/>
            <ALENButton
              medium
              onPress={() => createLoadValidation()}
              label={props.t('createLoad.save')}
              />
          </div>
        </div>
    </div>
  )
}

export default withTranslation()(ALENModalEditLoad)
