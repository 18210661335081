import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

/**
 * ULD Viewer - Display ULD & parcels in 3D.
 * @param {UldViewerProps} props
 * @returns {JSX.Element}
 */
export default function UldViewer({style, className, uld, parcels, config, selectedGroup, onSelect }){

    // Define ref pointing on container of 3D view.
    const rendererContainer = useRef();

    const canvasRef = useRef();
    // Define states
    /** @type {[{parcels:Object.<string,Parcel3D>,groups:Object.<string,number>}, Function]} */
    const [parcel3DObjects, setParcels3D ] = useState({});

    /** @type {[Uld3D, Function]} */
    const [uld3DObject, setUld3D ] = useState(null);

    /** @type {[Env3D, Function]} */
    const [env, setEnv] = useState(null);
    /** @type {[Parcel3D|null, Function]}  */
    const [selection, setSelection] = useState(null);

    useEffect(() => {
        if( env ){
            // Create / Update / Remove 3D Parcel objects depending on "parcels" changes
            let toRemove = Object.keys( parcel3DObjects?.parcels??{} );
            const newList = {}, groups = {};
            Object.keys(parcels??{}).forEach( (id)=>{
                if( !parcel3DObjects?.parcels?.[id] ){
                    newList[id] = buildParcel3D( env, parcels[id]);
                }else{
                    newList[id] = parcel3DObjects.parcels[id];
                    toRemove.splice( toRemove.indexOf(id), 1);
                }
                groups[parcels[id].group] = true;
            });
            // Remove deleted parcels
            toRemove.forEach(id => env.scene.remove(parcel3DObjects.parcels[id].mesh));
            // Compute groups colors
            const orderedGroups = Object.keys(groups).sort(), nbGroups = orderedGroups.length+1;
            orderedGroups.forEach( (group,index) => groups[group] = index/nbGroups );
            // Set groups color.
            setParcelColorsAndOpacity( newList, groups, selectedGroup, selection );
            // Update parcels3D state.
            setParcels3D({parcels:newList, groups: groups});
        }
    },[env, parcels, uld]);

    useEffect(() => {
        if( env && uld3DObject?.id !== uld?.id ){
            if( !uld ){
                env.scene.remove( uld3DObject.solidMesh );
                env.scene.remove( uld3DObject.wireframeMesh );
                setUld3D( null );
            }else{
                setUld3D( buildUld3D( env, uld) );
            }
        }
    },[env, uld]);

    useEffect(()=>{
        if( env && parcel3DObjects ){
            setParcelColorsAndOpacity( parcel3DObjects.parcels??{}, parcel3DObjects.groups??{}, selectedGroup, selection );
        }
    },[selectedGroup]);

    useLayoutEffect(()=>{
        const containerRect = canvasRef.current.getBoundingClientRect();
        // Create/config/append renderer
        const renderer = new THREE.WebGLRenderer({antialias:true,alpha:true,canvas:canvasRef.current });
        renderer.setSize( containerRect.width, containerRect.height, false );

        // Create scene, camera, controls, lights
        const scene = new THREE.Scene(),
            camera = new THREE.PerspectiveCamera( 100, containerRect.width/containerRect.height, 0.1, 10000 ),
            controls = new OrbitControls(camera, canvasRef.current),
            ambientLight = new THREE.AmbientLight( config?.lights?.ambient?.color ?? 0xFFFFFF, config?.lights?.ambient?.intensity ?? .5),
            cameraLight = new THREE.DirectionalLight( config?.lights?.camera?.color ?? 0xFFFFFF, config?.lights?.camera?.intensity ?? 1 ),
            raycaster = new THREE.Raycaster();

        scene.add(ambientLight);
        scene.add(cameraLight);

        if( config?.textures?.uld || config?.textures?.parcel ){
            // Ask base textures loading ( ULD & parcel )
            const loadManager = new THREE.LoadingManager(),
                loader = new THREE.TextureLoader(loadManager),
                baseUldTexture = config?.textures?.uld ? loader.load( config.textures.uld ) : null,
                baseParcelTexture = config?.textures?.parcel ? loader.load( config.textures.parcel ) : null;
            // Configure textures
            baseUldTexture.wrapS = baseUldTexture.wrapT = THREE.MirroredRepeatWrapping;
            baseUldTexture.offset.set( 0, 0 );
            baseParcelTexture.wrapS = baseParcelTexture.wrapT = THREE.MirroredRepeatWrapping;
            baseParcelTexture.offset.set( 0, 0 );
            // On load: Register env
            loadManager.onLoad = () => {
                setEnv({renderer, scene, camera, controls, baseUldTexture, baseParcelTexture, ambientLight, cameraLight, raycaster});
            };
        }else{
            // Register env
            setEnv({renderer, scene, camera, controls, baseUldTexture:null, baseParcelTexture:null, ambientLight, cameraLight, raycaster});
        }
    },[]);

    useLayoutEffect(()=>{
        if( env ){
            let rfaUID;
            var animate = function () {
                rfaUID = requestAnimationFrame( animate );
                // Update camera aspect && canvas size ( if view dimensions changed )
                const canvas = env.renderer.domElement;
                if( canvas.width !== canvas.clientWidth || canvas.height !== canvas.clientHeight ){
                    env.renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
                }
                env.camera.aspect = canvas.clientWidth / canvas.clientHeight;
                env.camera.updateProjectionMatrix();
                // Update controls
                env.controls.update(4);
                // Update camera light to point on same direction as camera does.
                env.cameraLight.position.set( env.camera.position.x, env.camera.position.y, env.camera.position.y);
                env.cameraLight.lookAt( env.camera.getWorldDirection( new Vector3() ) );
                // Render scene
                env.renderer.render( env.scene, env.camera );
            };

            animate();

            return ()=>{
                // Cancel animation loop to avoid memory leak.
                cancelAnimationFrame( rfaUID );
            }
        }
    },[env]);

    function pickParcel( event ){
        if( onSelect && env ){
            const position = getCanvasNormalizedPosition( event, canvasRef.current),
                {raycaster, camera, scene} = env;
            // Cast a ray to the camera frustrum
            raycaster.setFromCamera( position, camera);
            // Get list of intersections
            const intersections = raycaster.intersectObjects(scene.children);

            let selectedParcel;
            if( intersections.length ){
                intersections.some( intersection => {
                    let parcelId = intersection.object._parcelUID;
                    if( parcelId && (!selectedGroup || parcel3DObjects.parcels[parcelId].group == selectedGroup ) ){
                        selectedParcel = parcel3DObjects.parcels[parcelId];
                        return true;
                    }
                });
            }
            if( selection?.mesh?._parcelUID != selectedParcel?.id ){
                if( selection ){
                    resetParcelColor();
                }
                if( selectedParcel ){
                    selectedParcel.mesh.material.forEach( material => material.color.setHSL(0,0,1) );
                    setSelection(selectedParcel);
                }else{
                    setSelection( null );
                }
                onSelect( selectedParcel?.id ?? null );
            }
        }
    }

    function resetParcelColor(){
        selection.mesh.material.forEach( ( /** @type {THREE.Material} material */ material )=> {
            material.color.setHSL( parcel3DObjects.groups[selection.group], 1, .7 );
        });
    }

    function clearSelectedParcel(){
        if( onSelect &&  selection ){
            resetParcelColor();
            setSelection(null);
            onSelect( null );
        }
    }

    return <div ref={ rendererContainer } style={style} className={className}>
        <canvas
            ref={ canvasRef }
            style={{width:'100%',height:'100%'}}
            onMouseMove={ pickParcel }
            onMouseLeave={ clearSelectedParcel }
            onMouseEnter={ clearSelectedParcel } />
    </div>
}
/**
 * Return canvase normalized position (x: -1 to 1, y: -1 to 1)
 * @param {MouseEvent} event
 * @param {Element} canvas
 * @returns
 */
function getCanvasNormalizedPosition(event, canvas) {
    const rect = canvas.getBoundingClientRect();
    return {
        x: 2*((event.clientX - rect.left) / rect.width) - 1,
        y: 1 - 2*((event.clientY - rect.top ) / rect.height),
    };
}
/**
 * Build parcel 3D representation
 * @param {Env3D} env
 * @param {PARCEL} parcel
 * @returns {Parcel3D}
 */
function buildParcel3D( env, parcel ){
    const XZtexture = env.baseParcelTexture.clone(),
        XYtexture = env.baseParcelTexture.clone(),
        YZtexture = env.baseParcelTexture.clone();

    XYtexture.needsUpdate = YZtexture.needsUpdate = XZtexture.needsUpdate = true;
    XYtexture.repeat.set( parcel.width/XZtexture.image.naturalWidth, parcel.height/XZtexture.image.naturalHeight );
    YZtexture.repeat.set( parcel.depth/XZtexture.image.naturalHeight, parcel.height/XZtexture.image.naturalWidth );
    XZtexture.repeat.set( parcel.width/XZtexture.image.naturalWidth, parcel.depth/XZtexture.image.naturalHeight );

    const materials = [
        new THREE.MeshPhongMaterial({map: YZtexture}),
        new THREE.MeshPhongMaterial({map: YZtexture}),
        new THREE.MeshPhongMaterial({map: XZtexture}),
        new THREE.MeshPhongMaterial({map: XZtexture}),
        new THREE.MeshPhongMaterial({map: XYtexture}),
        new THREE.MeshPhongMaterial({map: XYtexture}),
    ];

    const geometry = new THREE.BoxGeometry( parcel.width, parcel.height, parcel.depth );
    const parcelMesh = new THREE.Mesh( geometry, materials );
    // Set parcel UID into mesh object /!\ Used to get Parcel3D object when picking parcel.
    parcelMesh._parcelUID = parcel.id;
    // Set parcel position ( 3D position is from box center whereas given position is from x0/y0/z0 )
    parcelMesh.position.x = parcel.position.x + (parcel.width/2);
    parcelMesh.position.y = parcel.position.y + (parcel.height/2);
    parcelMesh.position.z = parcel.position.z + (parcel.depth/2);
    // Add mesh to the scene
    env.scene.add( parcelMesh );
    return {...parcel, mesh: parcelMesh };
}
/**
 * Build parcel 3D representation
 * @param {Env3D} env
 * @param {ULD} uld
 * @returns {Uld3D}
 */
function buildUld3D( env, uld, config ){
    // Build wireframe & solid mesh
    const max = {x:0,y:0},
        z = uld.size.z,
        shapeLength = uld.size.xy.length - 1,
        linePoints = [],
        shape = new THREE.Shape();

    uld.size.xy.forEach( (point,index) =>{
        const [x,y] = point;
        const [px,py] = uld.size.xy[ index?index-1:shapeLength ];
        // Build lines for wireframe mesh
        linePoints.push( new THREE.Vector3( x, y, 0 ) );
        linePoints.push( new THREE.Vector3( x, y, z ) );
        linePoints.push( new THREE.Vector3( px, py, z ) );
        linePoints.push( new THREE.Vector3( px, py, 0 ) );
        linePoints.push( new THREE.Vector3( x, y, 0 ) );
        // Build shape for solid mesh
        shape[index?'lineTo':'moveTo'](x,y);
        // Compute max
        max.x = Math.max(max.x,x);
        max.y = Math.max(max.y,y);
    });

    const wireframeMesh = new THREE.Line(
        new THREE.BufferGeometry().setFromPoints( linePoints ),
        new THREE.LineBasicMaterial({ color: config?.uld?.color ?? 0x000000 })
    );

    const solidMesh = new THREE.Mesh(
        new THREE.ExtrudeGeometry( shape,{depth:z} ),
        new THREE.MeshPhongMaterial({ map: env.baseUldTexture,side: THREE.BackSide })
    );
    // Configure texture repetition.
    solidMesh.material.map.repeat.set( 1/solidMesh.material.map.image.naturalWidth, 1/solidMesh.material.map.image.naturalHeight );

    // Add both mesh to scene
    env.scene.add(wireframeMesh);
    env.scene.add(solidMesh);

    // Reset camera position/target && controls target.
    env.controls.target = new THREE.Vector3( max.x/2, max.y/2, z/2 );
    env.camera.position.z = - z;
    env.camera.position.x = max.x/2;
    env.camera.position.y = max.y/2;
    env.camera.lookAt( env.controls.target );

    return { ...uld, solidMesh, wireframeMesh};
}
/**
 *
 * @param {Object.<string,Parcel3D>} parcels
 * @param {Object.<string,number>} groups
 * @param {null|string} selectedGroup
 */
function setParcelColorsAndOpacity( parcels, groups, selectedGroup, selection, opacity=.3 ){
    // Set groups color.
    Object.keys(parcels??{}).forEach( id => {
        const group = parcels[id].group;
        if( parcels[id] != selection ){
            parcels[id].mesh.material.forEach(
                material => {
                    //material.needsUpdate = true;
                    material.color.setHSL(groups[group], 1, .7 );
                    if( !selectedGroup || group == selectedGroup){
                        material.opacity = 1;
                    }else{
                        material.transparent = true;
                        material.opacity = opacity;
                    }
                }
            );
        }
    });
}

// --- Type Definitions ---

/**
 * PARCEL type definition
 * @typedef {Object} PARCEL
 * @property {number} id - PARCEL
 * @property {string} group - PARCEL group's unique id
 * @property {Position} position - PARCEL 3D Position
 * @property {number} width - PARCEL size width
 * @property {number} height - PARCEL size height
 * @property {number} depth - PARCEL size depth
 */

/**
 * 3D Position
 * @typedef {Object} Position
 * @property {number} x - Position on x axis
 * @property {number} y - Position on y axis
 * @property {number} z - Position on z axis
 */

/**
 * ULD type definition
 * @typedef {Object} ULD
 * @property {number} id - ULD ID
 * @property {ULDSize} size - 3D ULD Definition ( "xy" shape to extrude on a "z" depth)
 */

/**
 * ULD Geometry Definition
 * @typedef {Object} ULDSize
 * @property {[number,number][]} xy - ULD shape on x/y axis
 * @property {number} z - ULD depth
 */

/**
 * 3D ENV Definition
 * @typedef {Object} Env3D
 * @property {THREE.WebGLRenderer} renderer
 * @property {THREE.Scene} scene
 * @property {THREE.PerspectiveCamera} camera
 * @property {THREE.Texture} baseUldTexture
 * @property {THREE.Texture} baseParcelTexture
 * @property {THREE.AmbientLight} ambientLight
 * @property {THREE.DirectionalLight} cameraLight
 * @property {THREE.Raycaster} raycaster
 * @property {OrbitControls} controls
 */

/**
 * @typedef {Object} Parcel3D
 * @property {number} id - PARCEL
 * @property {string} group - PARCEL group's unique id
 * @property {Position} position - PARCEL 3D Position
 * @property {number} width - PARCEL size width
 * @property {number} height - PARCEL size height
 * @property {number} depth - PARCEL size depth
 * @property {THREE.Mesh} mesh - PARCEL size depth
 */

/**
 * UldViewer props definition
 * @typedef {Object} UldViewerProps
 * @property {Object} style - CSS Style to apply on viewer container
 * @property {string} className - Class to apply on viewer container
 * @property {ULD} uld - ULD informations
 * @property {Object.<string,PARCEL>} parcels - Parcels informations
 * @property {Object} config - THREEJS configuration options (  )
 * @property {Function} onSelect - Function called when user select a parcel.
 * @property {null|string} selectedGroup - Selected group uid
 */
