const initialState = {
    uldList: [],
}

function stockULDSReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case 'LOGOUT' : {
      return initialState
    }
    case 'RESET' :
      return initialState
    case 'REFRESHULDSTOCK' :
      nextState = {
        ...state,
        uldList: action.data,
      }
      return nextState
    case 'ADDULDSTOCK' :
      nextState = {
        ...state,
      }

      let uldListAdd = nextState.uldList
      uldListAdd.push(action.data)
      return nextState
    case 'UPDATEULDSTOCK' :
      nextState = {
        ...state,
      }
      let uldListUpdate = nextState.uldList
      for(let i =0; i < uldListUpdate.length; i++) {
        if(uldListUpdate[i].id === action.data.id) {
          uldListUpdate[i].stock = action.data.stock
          break;
        }
      }

      nextState = {
        ...state,
        uldList: uldListUpdate,
      }
      return nextState
    case 'DELETEULDSTOCK' :
      nextState = {
        ...state,
      }
      let uldListDelete = nextState.uldList
      let uldToDelete = -1

      for(let i =0; i < uldListDelete.length; i++) {
        if(uldListDelete[i].id === action.data.id) {
          uldToDelete = i
          break;
        }
      }

      if(uldToDelete > -1) {
        uldListDelete.splice(uldToDelete, 1)
        nextState = {
          ...state,
          uldList: uldListDelete
        }
      }

      return nextState
    default:
      return state
  }
}

export default stockULDSReducer
