import commons from "./commons"
import login from "./login"
import firstconnect from './firstconnect'
import stockManagement from './stockManagement'
import loads from './loads'
import parcelsList from './parcelsList'
import addParcels from './addParcels'
import optimize from './optimize'
import createLoad from './createLoad'
import loading from './loading'
import tiedowns from './tiedowns'
import tiedowncalculator from './tiedowncalculator'
import manageAccount from './manageAccount'
import balance from './balance'
import createBalance from './createBalance'

const en = {
  "commons": commons,
  "login" : login,
  "firstconnect" : firstconnect,
  "stockManagement" : stockManagement,
  "loads" : loads,
  "parcelsList" : parcelsList,
  "addParcels" : addParcels,
  "optimize" : optimize,
  "createLoad" : createLoad,
  "loading": loading,
  "tiedowncalculator" : tiedowncalculator,
  "tiedowns" : tiedowns,
  "manageAccount" : manageAccount,
  "balance" : balance,
  "createBalance": createBalance,
}

export default en;
