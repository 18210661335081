import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN, ALENGREY, ALENBLUE, ALENGREENDARK} from 'utils/colors';
import ALENText from 'components/ALENText';
import SvgRight from 'components/icons/Aft';
import SvgLeft from 'components/icons/Bef';
import ALENInputBalance from 'components/ALENInputBalance';
import ALENSelect from 'components/ALENSelect';

function ALENBalanceChartSimpleBox(props) {
  const [over, setOver] = useState(false);
  const error = props.datas?.WT > props.maxValue

  return(
    <div style={{flexDirection:'column', display:'flex', marginTop:32}}>
      <div
        onDragStart={props.onDragStart}
        onDragOver={() => {
          setOver(true)
          props.onMouseOver()
        }}
        onDragLeave={() => setOver(false)}
        onDragEnd={props.onDragEnd}
        draggable="true"
        onMouseOver={() => {
          props.onMouseOver()
          setOver(true)
        }}
        onMouseLeave={() => setOver(false)}
        style={{
          height:props.uld.big ? 296 : 98,
          width:props.uld.half ? 60 : 120,
          borderRadius:3,
          borderColor: over ? ALENBLUE : ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:2,
          margin: 2,
          backgroundColor:error ? 'rgba(214, 13, 13, 0.2)' : 'white',
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          marginTop: props.uld.big ? -198 : 0,
        }}>
        <div
          style={{
            height:26,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:2,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            borderBottomWidth:1,
        }}>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              flex:1,
            }}>
            <ALENText color={ALENBLUE} size={12}>
              {props.uld.id}
            </ALENText>
          </div>
        </div>
        {props.uld.big &&
          <div
            style={{
              height:198,
              width:props.uld.half ? 58 : 118,
              borderColor:ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:0,
              borderBottomWidth:1,
          }}>
          </div>
        }
        <div
        style={{
          height:24,
          width:props.uld.half ? 58 : 118,
          borderColor:ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:0,
        }}>
          <ALENSelect
            transparent
            noTop
            datas={props.sizeCodesList}
            minWidth={props.uld.half ? 56 : 116}
            height={22}
            value={props.datas?.size_code}
            onChange={(event) => {
              props.onChangeValueBox(`BISOPT${props.uld.id}`, 'size_code', 'none', event.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:24,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:2,
        }}>
        <ALENInputBalance
          value={props.datas?.dest}
          placeholder={'Dest'}
          type={'text'}
          height={22}
          onChange={(val) => {
            props.onChangeValueBox(`BISOPT${props.uld.id}`, 'dest', 'none', val.target.value)
          }}
        />
        </div>
        <div
          style={{
            height:24,
            width:props.uld.half ? 58 : 118,
        }}>
        <ALENInputBalance
          value={props.datas?.WT}
          placeholder={'WT'}
          type={'number'}
          height={22}
          onChange={(val) => {
            props.onChangeValueBox(`BISOPT${props.uld.id}`, 'WT', 'none', val.target.value)
          }}
        />
        </div>
      </div>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center'
        }}>
        <ALENText color={ALENBLUE} size={12}>
          {props.maxValue}
        </ALENText>
      </div>
    </div>
  )
}

export default ALENBalanceChartSimpleBox
