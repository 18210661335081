import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import {ALENBLUE, ALENGREEN, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENRED, ALENGREY} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTLITTLE} from 'utils/fontSizes'

class ALENFuelPlan extends Component {
  constructor(props) {
    super(props);

    this.svg = createRef();

    this.horizontalMeasures = []
    for(let i = 0; i<=110; i=i+5) {
      this.horizontalMeasures.push(i)
    }

    this.horizontalLittleMeasures = []
    for(let i=0; i<=110; i++) {
      if((i % 5) !== 0) {
        this.horizontalLittleMeasures.push(i)
      }
    }

    this.verticalMeasures = []
    for(let i=160; i<=420; i=i+10){
      this.verticalMeasures.push(i)
    }

    this.verti = 2
    this.hori = 5

    this.polyOne = `${38*this.hori},${this.convertVerti(160)}
                    ${31*this.hori},${this.convertVerti(265)}
                    ${24*this.hori},${this.convertVerti(265)}
                    ${15*this.hori},${this.convertVerti(350)}
                    ${21*this.hori},${this.convertVerti(408)}
                    ${50*this.hori},${this.convertVerti(412)}
                    ${68*this.hori},${this.convertVerti(412)}
                    ${102*this.hori},${this.convertVerti(378)}
                    ${71*this.hori},${this.convertVerti(160)}`

    this.polyTwo = `${21*this.hori},${this.convertVerti(297)}
                    ${90.5*this.hori},${this.convertVerti(297)}`

    this.polyThree = `${45*this.hori},${this.convertVerti(411)}
                      ${34*this.hori},${this.convertVerti(360)}
                      ${42*this.hori},${this.convertVerti(185)}
                      ${58*this.hori},${this.convertVerti(160)}`

    this.polyFour = `${37.5*this.hori},${this.convertVerti(276)}
                     ${87.5*this.hori},${this.convertVerti(276)}`

    this.polyFive = `${88.6*this.hori},${this.convertVerti(284)}
                    ${54*this.hori},${this.convertVerti(160)}`

    this.polySix = `${79.5*this.hori},${this.convertVerti(220)}
                    ${64*this.hori},${this.convertVerti(160)}`

    this.polys = [this.polyOne, this.polyTwo, this.polyThree, this.polyFour, this.polyFive, this.polySix]

    this.topMeasuresLines = [4,13,22.5,31.5,40.8,50,59,68,77.5,86.5,96,105, 114]
    this.bottomMeasuresLines = [32.5,36,39.5,43,46.5,50,53.5,57,60.2,64,67.5,71,74.5]
  }

  convertVerti = (y) => {
    return (520 - ((y-160)*this.verti))
  }

  renderLetter = (letter, x, y) => {
    return(
      <>
        <circle
          cx={x+3.5}
          cy={y-3.5}
          r="8"
          fill={ALENBLUE}
        />
        <circle
          cx={x+3.5}
          cy={y-3.5}
          r="6"
          fill={'white'}
        />
        <text
          x={x}
          y={y}
          fontWeight={'bold'}
          fill={ALENBLUE}
          fontFamily={'Arial'}
          fontSize={10}>
            {letter}
        </text>
      </>
    )
  }

  renderNumber = (number, x, y) => {
    return(
      <>
        <circle
          cx={x+3.5}
          cy={y-3.5}
          r="10"
          fill={ALENBLUE}
        />
        <circle
          cx={x+3.5}
          cy={y-3.5}
          r="9"
          fill={'white'}
        />
        <text
          x={x-2}
          y={y}
          fill={ALENBLUE}
          fontFamily={'Arial'}
          fontSize={10}>
            {number}
        </text>
      </>
    )
  }

  render() {
    return (
      <>
        <svg
          ref={this.svg} width={660} height={700} viewBox={'-50 -50 610 550'}>
          <polyline
            fill={'none'}
            stroke={ALENGREY}
            points={'0,0 0,520 550,520 550,0 0,0'}
            />
          {this.horizontalMeasures.map((mes) => {
            const x = mes*5
            return(
              <>
                <text
                  x={x-6}
                  y={-18}
                  fill={'black'}
                  fontFamily={'Arial'}
                  fontSize={10}>
                    {mes}
                </text>
                <polyline
                  fill={'none'}
                  stroke={ALENGREY}
                  points={`${x},0 ${x},-15`}
                  />
              </>
            )
          })}
          {this.horizontalLittleMeasures.map((mes) => {
            const x = mes*this.hori
            return(
              <polyline
                fill={'none'}
                stroke={ALENGREY}
                points={`${x},0 ${x},-7`}
                />
            )
          })}
          {this.verticalMeasures.map((mes) => {
            const y = (mes - 160) * this.verti
            return(
              <>
                <text
                  x={-25}
                  y={523-y}
                  fill={'black'}
                  fontFamily={'Arial'}
                  fontSize={10}>
                    {mes}
                </text>
                <polyline
                  fill={'none'}
                  stroke={ALENGREY}
                  points={`0,${y} -5,${y}`}
                  />
              </>
            )
          })}
          {this.topMeasuresLines.map((xTop,index) => {
            let points = `${xTop*this.hori},0 ${this.bottomMeasuresLines[index]*this.hori},520`
            if(index === this.topMeasuresLines.length-1) {
              points = `${110*this.hori},56 ${this.bottomMeasuresLines[index]*this.hori},520`
            }
            return(
              <polyline
                fill={'none'}
                stroke={ALENGREEN}
                stroke-width={'1'}
                points={points}
                />
            )
          })}
          {this.polys.map((poly) => {
            return(
              <polyline
                fill={'none'}
                stroke={ALENBLUE}
                stroke-width={'3'}
                points={poly}
                />
            )
          })}
          {/*ZFW*/}
          <polyline
            fill={'none'}
            stroke={ALENGREEN}
            stroke-width={'3'}
            points={`0,${this.convertVerti(this.props.MZFW/1000)} ${110 * this.hori},${this.convertVerti(this.props.MZFW/1000)}`}
            />
          <text
            x={`${(110 * this.hori) -45}`}
            y={`${this.convertVerti(this.props.MZFW/1000) -5}`}
            fill={ALENGREEN}
            fontFamily={'Arial'}
            fontWeight={'bold'}
            fontSize={12}>
              {'MZFW'}
            </text>
          <polyline
            fill={'none'}
            stroke={ALENGREEN}
            stroke-width={'1'}
            strokeDasharray="5"
            points={`0,${this.convertVerti(this.props.ZFW/1000)} ${this.props.indexZFW * this.hori},${this.convertVerti(this.props.ZFW/1000)}`}
            />
          <polyline
            fill={'none'}
            stroke={ALENGREEN}
            stroke-width={'1'}
            strokeDasharray="5"
            points={`${this.props.indexZFW * this.hori},${this.convertVerti(160)} ${this.props.indexZFW * this.hori},${this.convertVerti(420)}`}
            />
          <circle
            cx={`${this.props.indexZFW * this.hori}`}
            cy={`${this.convertVerti(this.props.ZFW/1000)}`}
            r="5"
            fill={ALENBLUE}
          />
          <text
            x={`${(this.props.indexZFW * this.hori) +8}`}
            y={`${this.convertVerti(this.props.ZFW/1000)}`}
            fill={ALENBLUE}
            fontFamily={'Arial'}
            fontWeight={'bold'}
            fontSize={12}>
              {'ZFW'}
            </text>

          {/*TOW*/}
          <polyline
            fill={'none'}
            stroke={ALENGREEN}
            stroke-width={'3'}
            points={`0,${this.convertVerti(this.props.MTOW/1000)} ${110 * this.hori},${this.convertVerti(this.props.MTOW/1000)}`}
            />
          <text
            x={`${(110 * this.hori) -45}`}
            y={`${this.convertVerti(this.props.MTOW/1000) -5}`}
            fill={ALENGREEN}
            fontFamily={'Arial'}
            fontWeight={'bold'}
            fontSize={12}>
              {'MTOW'}
            </text>
          <polyline
            fill={'none'}
            stroke={ALENGREEN}
            stroke-width={'1'}
            strokeDasharray="5"
            points={`0,${this.convertVerti(this.props.TOW/1000)} ${this.props.indexTOW * this.hori},${this.convertVerti(this.props.TOW/1000)}`}
            />
          <polyline
            fill={'none'}
            stroke={ALENGREEN}
            stroke-width={'1'}
            strokeDasharray="5"
            points={`${this.props.indexTOW * this.hori},${this.convertVerti(160)} ${this.props.indexTOW * this.hori},${this.convertVerti(420)}`}
            />
          <circle
            cx={`${this.props.indexTOW * this.hori}`}
            cy={`${this.convertVerti(this.props.TOW/1000)}`}
            r="5"
            fill={ALENBLUE}
          />
          <text
            x={`${(this.props.indexTOW * this.hori) +8}`}
            y={`${this.convertVerti(this.props.TOW/1000)}`}
            fontFamily={'Arial'}
            fontWeight={'bold'}
            fill={ALENBLUE}
            fontSize={12}>
              {'TOW'}
          </text>
          {this.renderLetter('A', 78, 120)}
          {this.renderLetter('B', 199, 410)}
          {this.renderLetter('C', 185, 90)}
          {this.renderLetter('D', 345, 410)}
          {this.renderLetter('E', 339, 490)}
          {this.renderLetter('F', 363, 490)}
          {this.renderNumber('10', 60, 165)}
          {this.renderNumber('12', 97, 165)}
          {this.renderNumber('14', 136, 165)}
          {this.renderNumber('16', 171, 165)}
          {this.renderNumber('18', 209, 165)}
          {this.renderNumber('20', 246, 165)}
          {this.renderNumber('22', 282, 165)}
          {this.renderNumber('24', 320, 165)}
          {this.renderNumber('26', 356, 165)}
          {this.renderNumber('28', 395, 165)}
          {this.renderNumber('30', 432, 165)}
          {this.renderNumber('32', 468, 165)}
          {this.renderNumber('34', 506, 165)}
        </svg>
      </>
    );
  }
}

ALENFuelPlan.propTypes = {
  value:PropTypes.number,
};

ALENFuelPlan.defaultProps = {
  value:0,
};

export default ALENFuelPlan
