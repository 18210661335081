import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {ALENBLUE, ALENGREEN, ALENDISABLEDDARK} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTMEGABIG, FONTNORMALSUP} from 'utils/fontSizes'
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgImgFirstConnect from 'components/icons/ImgFirstConnect';
import {isMobile} from 'react-device-detect';

class ALENFirstConnect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      full: false
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize()
  }

  resize = () => {
    if( window.innerWidth <= 1700) {
      this.setState({full: false})
    } else {
      this.setState({full:true})
    }
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <>
        <div
          style={{
            flexDirection:'row',
            display:'flex',
            marginTop:isMobile ? 0 : 107,
            marginBottom:185,
            marginLeft:isMobile ? 24 : 56,
            marginRight:isMobile ? 24 : 0,
            justifyContent:isMobile ? 'center' : 'space-between',
            flex:1,
          }}
        >
          <div
            style={{
              flexDirection:'row',
              display:'flex',
            }}
          >
            {!isMobile &&
            <div style={{marginTop:94, marginRight:13}}>
              <div
                style={{
                  width:10,
                  height:10,
                  backgroundColor:ALENGREEN,
                  borderRadius:20,
                }}
              />
              <div
                style={{
                  width:2,
                  height:119,
                  marginLeft:4,
                  marginTop:4,
                  marginBottom:4,
                  backgroundColor:ALENBLUE,
                }}
              />
              <div
                style={{
                  width:10,
                  height:10,
                  backgroundColor:ALENGREEN,
                  borderRadius:20,
                }}
              />

            </div>
            }
            <div style={{marginTop:isMobile ? 24 : 94, maxWidth:436, minWidth:isMobile ? 0 : 436}}>
              <ALENText size={FONTMEGABIG} verybold color={ALENBLUE}>
                {t('firstconnect.slogan.1')}
                <span style={{color:ALENGREEN}}>
                  {t('firstconnect.slogan.2')}
                </span>
                {t('firstconnect.slogan.3')}
                <span style={{color:ALENGREEN}}>
                  {t('firstconnect.slogan.4')}
                </span>
                {t('firstconnect.slogan.5')}
              </ALENText>
              <div style={{height:24}}/>
              <ALENText size={FONTNORMALSUP} color={ALENDISABLEDDARK}>
                {t('firstconnect.mainText')}
              </ALENText>
              <div style={{height:24}}/>
              <ALENButton
                onPress={() => window.location.href="/stocks"}
                big
                label={t('firstconnect.buttonLabel')}
              />
            </div>
          </div>
          {!isMobile &&
          <>
            <div style={{minWidth:69}}/>
            {this.state.full ?
            <div style={{flex:1, maxWidth:800}}>
              <SvgImgFirstConnect full={this.state.full}/>
            </div>
            :
            <div style={{}}>
              <SvgImgFirstConnect full={this.state.full}/>
            </div>
            }
          </>
          }
        </div>
      </>
    );
  }
}

ALENFirstConnect.propTypes = {

};

ALENFirstConnect.defaultProps = {

};

export default withTranslation()(ALENFirstConnect)
