import Api from 'api';
import ApiConstants from '../ApiConstants';

export function getAircraftList(token) {
  return Api(
    ApiConstants.GETAIRCRAFTLIST,
    null,
    'GET',
    token,
  )
}

export function addLoad(token, data) {
  return Api(
    ApiConstants.ADDLOAD,
    data,
    'POST',
    token,
  )
}

export function getLoads(token) {
  return Api(
    ApiConstants.GETLISTLOAD,
    null,
    'GET',
    token,
  )
}

export function deleteLoad(token, load_id) {
  return Api(
    ApiConstants.DELETELOAD + '/' + load_id,
    null,
    'DELETE',
    token,
  )
}

export function addUlds(token, datas) {
  return Api(
    ApiConstants.ADDULDS,
    datas,
    'POST',
    token,
  )
}

export function getPdf(token, load_id) {
  return Api(
    ApiConstants.LOADPDF+'/'+load_id,
    null,
    'get',
    token,
  )
}

export function getUldsLoad(token, load_id) {
  return Api(
    ApiConstants.GETULDSLOAD + '/' + load_id,
    null,
    'get',
    token,
  )
}

export function loadUpdate(token, datas, load_id) {
  return Api(
    ApiConstants.LOADUPDATE + '/' + load_id,
    datas,
    'PUT',
    token,
  )
}
