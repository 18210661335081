import React, {Component} from 'react';
import {ALENSUCCESS, ALENWHITE} from "utils/colors";
import {FONTNORMAL} from 'utils/fontSizes'
import ALENText from "components/ALENText";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SvgSuccess from "components/icons/Success";
import SvgClose from "components/icons/Close";

function ALENToast(props) {
  return (
      <Snackbar
        autoHideDuration={6000}
        open={props.show}
        TransitionComponent={Slide}
      >
        <div
          style={{
            backgroundColor:ALENSUCCESS,
            height:53,
            minWidth:600,
            padding:16,
            paddingLeft:26,
            paddingRight:26,
            borderRadius:4,
            display:'flex',
            flexDirection:'row',
            flex:1,
            alignItems:'center',
            cursor:'pointer',
          }}
          onClick={props.handleClose}
        >
          <SvgSuccess
            fill={ALENWHITE}
            style={{
              width:18,
              height:18,
              marginRight:9.33,
            }}/>
          <ALENText
            bold
            color={ALENWHITE}>
            {props.message}
          </ALENText>
        </div>
      </Snackbar>
  )
}


export default ALENToast
