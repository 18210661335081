import React, {Component} from 'react';
import Test3DView from './Test3DView'

class Test3DContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  render()
  {
    return(
      <Test3DView
      />
    )
  }
}

export default Test3DContainer
