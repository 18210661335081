const manageAccount = {
  "listModules" : "My subscriptions",
  "OPTIMISATION_ULD" : "Optimisation Uld",
  "LOADSHEET" : "Loadsheet & balance chart",
  "STRAPPING_PLAN" : "Tiedowns",
  "password" : "New password",
  "changePassword" : {
    "title" : "Change your password",
    "text" : "Type new password",
    "password" : "New password",
    "back" : "Back",
    "change" : "Change password",
  }
}

export default manageAccount
