import React, {Component} from 'react';
import OptimizedListView from './OptimizedListView';
import { connect } from 'react-redux';
import ALENToast from 'components/ALENToast';
import { withTranslation } from 'react-i18next';
import {getOptimizedUlds} from 'api/methods/parcel';
import {getPdf} from 'api/methods/load';

class OptimizedListContainer extends Component {
  constructor(props) {
    super(props)

    this.state={
      load: null,
      uldList: [],
    }
  }

  getPdf = () => {
    fetch(process.env.REACT_APP_API_URL+"load/pdf/"+this.state.load.id, {
            method: 'GET',
            headers: new Headers({
                "X-token-api": this.props.userReducer.token
            })
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "filename.pdf";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        })

        /*getPdf(this.props.userReducer.token, this.state.load.id).then((response) => {
            if(response.status === 200 && response.ok) {
              var win = window.open(response.data.url, '_blank');
              win.focus();
            }
          })
        }*/
    }


  loadDatas = () => {
    const idLoad = this.props.match.params.id

    getOptimizedUlds(this.props.userReducer.token, idLoad).then((response) => {
      console.log('response GETLIST', response)
      if(response.status === 200 && response.ok) {
        //this.props.updateParcels(response.data, idLoad)
        this.setState({uldList: response.data})
      }
    })

    this.props.loadsReducer.loads.forEach((load) => {
      if(load.id.toString() === idLoad) {
        this.setState({load: load})
      }
    })
  }

  componentDidMount() {
    this.loadDatas()
  }

  componentDidUpdate(prevProps){
    if(prevProps.match.params.id !== this.props.match.params.id){
      this.loadDatas()
    }
  }

  render() {
    console.log('RENDER PARCEL', this.props)
    return(
      <>
        <OptimizedListView
          load={this.state.load}
          uldList={this.state.uldList}
          getPdf={this.getPdf}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLoad: (load) => {
      dispatch({type: "ADDLOAD", data: load})
    },
    updateParcels: (parcels, load_id) => {
      dispatch({type: "UPDATEPARCELS", data: {parcels: parcels, id: load_id}})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptimizedListContainer)
