import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN, ALENGREY, ALENBLUE, ALENGREENDARK, ALENRED} from 'utils/colors';
import ALENText from 'components/ALENText';
import SvgRight from 'components/icons/Aft';
import SvgLeft from 'components/icons/Bef';
import ALENInputBalance from 'components/ALENInputBalance';
import ALENSelect from 'components/ALENSelect';

function ALENBalanceChartBox(props) {
  const [overTop, setOverTop] = useState(false);
  const [overBot, setOverBot] = useState(false);
  const errorTop = props.datas?.right?.WT > props.maxValueRight
  const errorBottom = props.datas?.left?.WT > props.maxValueLeft

  return(
    <div style={{flexDirection:'column', display:'flex'}}>
      <div
        onDragStart={props.onDragStartTop}
        onDragOver={() => {
          setOverTop(true)
          props.onMouseOverTop()
        }}
        onDragLeave={() => setOverTop(false)}
        onDragEnd={props.onDragEndTop}
        draggable="true"
        onMouseOver={() => {
          props.onMouseOverTop()
          setOverTop(true)
        }}
        onMouseLeave={() => setOverTop(false)}
        style={{
          height:124,
          width:props.uld.half ? 60 : 120,
          borderRadius:3,
          borderColor: overTop ? ALENBLUE : ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:2,
          margin:2,
          backgroundColor:errorTop ? 'rgba(214, 13, 13, 0.2)' : 'white',
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
          marginTop: props.uld.topDec ? 26 : 0,
        }}>
        <div
          style={{
            height:26,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:2,
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            cursor:'pointer',
        }}>
          {props.uld.left &&
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'flex-start',
                marginLeft:5,
              }}>
              <SvgLeft
                style={{
                  height:20,
                  width:20,
                }}
                fill={ALENBLUE}/>
            </div>
          }
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              flex:1,
              marginLeft:props.uld.right ? 28 : 0,
              marginRight:props.uld.left ? 28 : 0,
            }}>
            <ALENText color={ALENBLUE} bold>
              {props.uld.id}
            </ALENText>
          </div>
          {props.uld.right &&
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'flex-end',
                marginRight:8,
              }}>
              <SvgRight
                style={{
                  height:20,
                  width:20,
                }}
                fill={ALENBLUE}/>
            </div>
          }
        </div>
        <div
          style={{
            height:24,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:1,
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            display:'flex',
        }}>
          {(props.displayRL) &&
          <div style={{marginLeft:8, marginRight:8}}>
            <ALENText>
              {'R'}
            </ALENText>
          </div>
          }
          <ALENInputBalance
            value={props.datas?.right?.id}
            placeholder={'ID'}
            type={'text'}
            height={22}
            onChange={(val) => {
              props.onChangeValueBox(props.uld.id, 'id', 'right', val.target.value)
            }}
          />
        </div>
        <div
        style={{
          height:24,
          width:props.uld.half ? 58 : 118,
          borderColor:ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:0,
        }}>
          <ALENSelect
            transparent
            noTop
            datas={props.sizeCodesList}
            minWidth={props.uld.half ? 56 : 116}
            height={22}
            value={props.datas?.right?.size_code}
            onChange={(event) => {
              props.onChangeValueBox(props.uld.id, 'size_code', 'right', event.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:24,
            width:props.uld.half ? 58 : 118,
            borderColor:ALENGREENDARK,
            borderStyle:'solid',
            borderWidth:0,
            borderBottomWidth:2,
        }}>
          <ALENInputBalance
            value={props.datas?.right?.dest}
            placeholder={'Dest'}
            type={'text'}
            height={22}
            onChange={(val) => {
              props.onChangeValueBox(props.uld.id, 'dest', 'right', val.target.value)
            }}
          />
        </div>
        <div
          style={{
            height:24,
            width:118,
        }}>
          <ALENInputBalance
            value={props.datas?.right?.WT}
            placeholder={'WT'}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeValueBox(props.uld.id, 'WT', 'right', val.target.value)
            }}
          />
        </div>
      </div>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center'
        }}>
        <ALENText color={ALENBLUE} size={12}>
          {props.maxValueRight}
        </ALENText>
      </div>
      {props.uld.display && props.displayOpt !== 'top' &&
        <div
          style={{
            marginTop: props.uld.angle ? 0 : 10,
            transform: props.uld.angle ? 'rotate(-8deg)' : 'rotate(0deg)',
            marginLeft: props.uld.nextDecal ? -20 : props.uld.angle ? -10 : 0,
          }}>
          <div
            onDragStart={props.onDragStartBot}
            onDragOver={() => {
              setOverBot(true)
              props.onMouseOverBot()
            }}
            onDragLeave={() => setOverBot(false)}
            onDragEnd={props.onDragEndBot}
            draggable="true"
            onMouseOver={() => {
              props.onMouseOverBot()
              setOverBot(true)
            }}
            onMouseLeave={() => setOverBot(false)}
            style={{
              height:props.uld.specialTitle ? 124 : 99,
              width:props.uld.half ? 60 : 120,
              borderRadius:3,
              borderColor: overBot ? ALENBLUE : ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:2,
              backgroundColor:'white',
              backgroundColor:errorBottom ? 'rgba(214, 13, 13, 0.2)' : 'white',
              margin:2,
              boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
              marginTop: props.uld.topDec ? 26 : 0,
            }}>
            {props.uld.specialTitle &&
              <div
                style={{
                  height:26,
                  width:props.uld.half ? 58 : 118,
                  borderColor:ALENGREENDARK,
                  borderStyle:'solid',
                  borderWidth:0,
                  borderBottomWidth:2,
                  display:'flex',
                  flexDirection:'row',
                  justifyContent:'center',
                  alignItems:'center',
              }}>
                <div
                  style={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    flex:1,
                    marginLeft:props.uld.right ? 28 : 0,
                    marginRight:props.uld.left ? 28 : 0,
                  }}>
                  <ALENText color={ALENBLUE} bold>
                    {props.uld.specialTitle}
                  </ALENText>
                </div>
              </div>
            }
            <div
              style={{
                height:24,
                width:props.uld.half ? 58 : 118,
                borderColor:ALENGREENDARK,
                borderStyle:'solid',
                borderWidth:0,
                borderBottomWidth:1,
                flexDirection:'row',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}>
              {(props.displayRL) &&
              <div style={{marginLeft:8, marginRight:8}}>
                <ALENText>
                  {'L'}
                </ALENText>
              </div>
              }
              <ALENInputBalance
                value={props.datas?.left?.id}
                placeholder={'ID'}
                placeholder={'ID'}
                type={'text'}
                height={22}
                onChange={(val) => {
                  props.onChangeValueBox(props.uld.id, 'id', 'left', val.target.value)
                }}
              />
            </div>
            <ALENSelect
              transparent
              noTop
              datas={props.sizeCodesList}
              minWidth={props.uld.half ? 56 : 116}
              height={22}
              value={props.datas?.left?.size_code}
              onChange={(event) => {
                props.onChangeValueBox(props.uld.id, 'size_code', 'left', event.target.value)
              }}
            />
            <div
              style={{
                height:24,
                width:props.uld.half ? 58 : 118,
                borderColor:ALENGREENDARK,
                borderStyle:'solid',
                borderWidth:0,
                borderBottomWidth:2,
            }}>
              <ALENInputBalance
                value={props.datas?.left?.dest}
                placeholder={'Dest'}
                type={'text'}
                height={22}
                onChange={(val) => {
                  props.onChangeValueBox(props.uld.id, 'dest', 'left', val.target.value)
                }}
              />
            </div>
            <div
              style={{
                height:24,
                width:props.uld.half ? 58 : 118,
            }}>
              <ALENInputBalance
                value={props.datas?.left?.WT}
                placeholder={'WT'}
                type={'number'}
                height={22}
                onChange={(val) => {
                  props.onChangeValueBox(props.uld.id, 'WT', 'left', val.target.value)
                }}
              />
            </div>
            <div
              style={{
                flexDirection:'row',
                display:'flex',
                justifyContent:'center'
              }}>
              <ALENText color={ALENBLUE} size={12}>
                {props.maxValueLeft}
              </ALENText>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ALENBalanceChartBox
