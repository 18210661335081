import Api from 'api';
import ApiConstants from '../ApiConstants';

export function getUlds(token) {
  return Api(
    ApiConstants.GETULDS,
    null,
    'GET',
    token,
  )
}

export function addUldStock(token, data) {
  return Api(
    ApiConstants.ADDULDSTOCK,
    data,
    'POST',
    token,
  )
}

export function updateUldStock(token, data) {
  return Api(
    ApiConstants.UPDATEULDSTOCK,
    data,
    'POST',
    token,
  )
}

export function getUldStock(token) {
  return Api(
    ApiConstants.GETULDSTOCK,
    null,
    'GET',
    token,
  )
}

export function loadUldAdd(token, uld) {
  return Api(
    ApiConstants.LOADULDADD,
    uld,
    'POST',
    token,
  )
}
