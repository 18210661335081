import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {ALENBLUE, ALENBG, ALENRED, ALENDISABLEDDARK} from 'utils/colors';
import ALENLayout from 'components/ALENLayout';
import ALENParcelsEmpty from 'components/ALENParcelsEmpty';
import ALENParcelsListHeader from 'components/ALENParcelsListHeader';
import ALENParcelsList from 'components/ALENParcelsList';
import ALENOptimizeResult from 'components/ALENOptimizeResult';
import ALENStepHeaders from 'components/ALENStepHeaders';
import ALENModalEditLoad from 'components/ALENModalEditLoad';
import ALENLoading from 'components/ALENLoading';
import ALENAddParcelsTable from 'components/ALENAddParcelsTable';
import {checkEmpty} from 'utils/checkForm'
import SvgAdd from 'components/icons/Add';
import SvgClose from 'components/icons/Close';
import ALENModalDeleteParcel from 'components/ALENModalDeleteParcel';

class ParcelsListView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      delete: null,
      filter: 0,
      showEdit: false,
      addParcel: false,
      values: {
        top: [
          "",
          "",
          "",
          "",
          "",
          ""
        ],
        mid: [
          0,
          0,
          0,
          0,
          0,
        ],
        bot: [
          "",
          "",
          false,
          false,
          false,
        ]
      },
      errors: {
        top: [
          false,
          false,
          false,
          false,
          false,
          false
        ],
        mid: [
          false,
          false,
          false,
          false,
          false,
        ],
        bot: [
          false,
          false,
        ]
      }
    }
  }

  onChangeFilter = (filter) => {
    this.setState({filter: filter})
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  getParcelFilter = (parcels) => {
    if(this.state.filter === 4) {
      parcels = parcels.filter((parcel) => {return(parcel.status === (20).toString())})
    }

    if(this.state.filter === 3) {
      parcels = parcels.filter((parcel) => {return(parcel.status === (15).toString())})
    }

    if(this.state.filter === 2) {
      parcels = parcels.filter((parcel) => {return(parcel.status === (10).toString())})
    }

    if(this.state.filter === 1) {
      parcels = parcels.filter((parcel) => {return(parcel.status === (99).toString())})
    }

    if(this.state.filter === 5) {
      parcels = parcels.filter((parcel) => {return(parcel.status === (0).toString())})
    }

    return parcels
  }

  saveLoad = (newLoad) => {
    this.setState({showEdit: false});
    this.props.updateLoad(newLoad);
  }

  onChangeValues = (position, index, value) => {
    let values = this.state.values
    let newValue = value
    if(position === "top") {
      if(index === 5 && value < 0) {
        newValue = 0
      }
    }

    if(position === "mid" ) {
      if(value < 0) {
        newValue = 0
      }
    }

    values[position][index] = newValue
    this.setState({values: values})
  }

  addParcel = () => {
    let valid = true

    this.setState({
      errors: {
        top: [
          false,
          false,
          false,
          false,
          false,
          false
        ],
        mid: [
          false,
          false,
          false,
          false,
          false,
        ],
        bot: [
          false,
          false,
        ]
      }
    })

    let errors = {
      top: [
        false,
        false,
        false,
        false,
        false,
        false
      ],
      mid: [
        false,
        false,
        false,
        false,
        false,
      ],
      bot: [
        false,
        false,
      ]
    }

    const values = this.state.values
    values.mid.forEach((value, index) => {
      if(!checkEmpty(value)) {
        errors.mid[index] = true
        valid = false
      }
    })

    this.setState({errors: errors})

    if(valid) {
      this.props.addParcel(values)
      this.setState({
        errors: {
          top: [
            false,
            false,
            false,
            false,
            false,
            false
          ],
          mid: [
            false,
            false,
            false,
            false,
            false,
          ],
          bot: [
            false,
            false,
          ]
        },
        values: {
          top: [
            "",
            "",
            "",
            "",
            "",
            ""
          ],
          mid: [
            0,
            0,
            0,
            0,
            0,
          ],
          bot: [
            "",
            "",
            false,
            false,
            false,
          ]
        },
      })
    }
  }

  render()
  {
    let t = this.props.t

    const ariane = [
      {
        label: t('loads.title'),
        link: '/loads',
      },
      {
        label: this.props.load !== null ? this.props.load.name : "",
        link: 'current',
      },
    ]

    const steps={
      selected: 1,
      menus: [
        {
          label: t('loads.header.step1'),
          link: '/ulds/'+(this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t('loads.header.step2'),
          link: "/loads/"+(this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t('loads.header.step3'),
          link: "/loads/optimizedList/"+(this.props.load !== null ? this.props.load.id : ""),
        },
      ]
    }

    return(
      <>
        <ALENLayout
          title={this.props.load !== null ? this.props.load.name : ""}
          ariane={ariane}
          editIcon={true}
          isAsyncLoading={this.props.isLoading}
        >
          <ALENStepHeaders
            steps={steps}
            onEdit={() => this.setState({showEdit: true})}
          />
          {this.props.load !== null &&
            <>
              <ALENParcelsListHeader
                onChangeFilter={this.onChangeFilter}
                selected={this.state.filter}
                nbParcels={this.props.load.nbParcels}
                parcels={this.props.load.parcels}
                changeCase={this.props.changeCase}
                caseId={this.props.caseId}
                optimizeUld={() => this.props.optimizeUld()}
                onPressAdd={() => this.setState({addParcel: !this.state.addParcel})}
                iconAdd={this.state.addParcel ? <SvgClose fill={ALENDISABLEDDARK} style={{width:22, height:22}}/> : <SvgAdd fill={ALENDISABLEDDARK} style={{width:22, height:22}}/>}
              />
              {this.state.addParcel &&
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                paddingTop:24,
                paddingBottom:24,
              }}>
                <ALENAddParcelsTable
                  values={this.state.values}
                  onChangeValues={this.onChangeValues}
                  addParcel={this.addParcel}
                  errors={this.state.errors}
                  cancel={() => this.setState({addParcel: false})}
                />
              </div>
              }
              {(this.props.load.parcels.length > 0)?
                <>
                  {this.props.isMainLoading ?
                    <div
                      style={{
                        marginTop:100,
                        marginBottom:100,
                      }}>
                      <ALENLoading
                        big
                        />
                    </div>
                    :
                    <ALENParcelsList
                      parcels={this.props.load.parcels}
                      showEntries = {this.props.showEntries}
                      page = {this.props.page}
                      totalPage = {this.props.totalPage}
                      changePage = {this.props.changePage}
                      onDelete = {(parcel) => this.setState({delete: parcel})}
                    />
                  }
                </>
                :
                <ALENParcelsEmpty
                  loadId={this.props.load !== null ? this.props.load.load_id : ""}
                  onPressNew={() => this.setState({addParcel: true})}
                />
              }
              {this.props.showOptimize &&
                <ALENOptimizeResult
                  loading={this.props.optimize}
                  load={this.props.load}
                  listUld={this.props.resultOptimize}
                  back={this.props.closeOptimize}
                />
              }
              {this.state.showEdit &&
                <ALENModalEditLoad
                  cancel={() => this.setState({showEdit: false})}
                  onSave={this.saveLoad}
                  aircraftList={this.props.aircraftList}
                  load={this.props.load}
                />
              }
              {this.state.delete !== null &&
                <ALENModalDeleteParcel
                  parcel={this.state.delete}
                  yes={() => {
                    const parcel_idToDelete = this.state.delete.id
                    this.props.deleteParcel(parcel_idToDelete)
                    this.setState({delete: null})
                  }}
                  no={() => this.setState({delete: null})}
                  back={() => this.setState({delete: null})}
                />
              }
            </>
          }
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(ParcelsListView)
