import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {checkEmpty} from 'utils/checkForm'

import ALENLayout from 'components/ALENLayout';
import ALENAddParcelsTable from 'components/ALENAddParcelsTable';
import {ALENWHITE, ALENBG, ALENBLACK} from 'utils/colors';

class AddParcelsView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      values: {
        top: [
          "",
          "",
          "",
          "",
          "",
          ""
        ],
        mid: [
          0,
          0,
          0,
          0,
          0,
        ],
        bot: [
          "",
          "",
        ]
      },
      errors: {
        top: [
          false,
          false,
          false,
          false,
          false,
          false
        ],
        mid: [
          false,
          false,
          false,
          false,
          false,
        ],
        bot: [
          false,
          false,
        ]
      }
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  onChangeValues = (position, index, value) => {
    let values = this.state.values
    let newValue = value
    if(position === "top") {
      if(index === 5 && value < 0) {
        newValue = 0
      }
    }

    if(position === "mid" ) {
      if(value < 0) {
        newValue = 0
      }
    }

    values[position][index] = newValue
    this.setState({values: values})
  }

  addParcel = () => {
    let valid = true

    this.setState({
      errors: {
        top: [
          false,
          false,
          false,
          false,
          false,
          false
        ],
        mid: [
          false,
          false,
          false,
          false,
          false,
        ],
        bot: [
          false,
          false,
        ]
      }
    })

    let errors = {
      top: [
        false,
        false,
        false,
        false,
        false,
        false
      ],
      mid: [
        false,
        false,
        false,
        false,
        false,
      ],
      bot: [
        false,
        false,
      ]
    }

    const values = this.state.values
    values.mid.forEach((value, index) => {
      if(!checkEmpty(value)) {
        errors.mid[index] = true
        valid = false
      }
    })

    this.setState({errors: errors})

    if(valid) {
      this.props.addParcel(values)
      this.setState({
        errors: {
          top: [
            false,
            false,
            false,
            false,
            false,
            false
          ],
          mid: [
            false,
            false,
            false,
            false,
            false,
          ],
          bot: [
            false,
            false,
          ]
        },
        values: {
          top: [
            "",
            "",
            "",
            "",
            "",
            ""
          ],
          mid: [
            0,
            0,
            0,
            0,
            0,
          ],
          bot: [
            "",
            "",
          ]
        },
      })
    }
  }

  render() {
    const t = this.props.t

    const ariane = [
      {
        label: t('loads.title'),
        link: '/loads',
      },
      {
        label: this.props.load !== null ? this.props.load.name : "",
        link: this.props.load !== null ? '/loads/'+this.props.load.id : "",
      },
      {
        label: t('addParcels.title'),
        link: 'current',
      }
    ]

    return(
      <ALENLayout
        ariane={ariane}
        buttonLabel={t('addParcels.titleButton')}
        onPressButton={() => this.props.insertParcels()}
        title={this.props.load !== null ? t('addParcels.title') + this.props.load.name : ""}
      >
        <ALENAddParcelsTable
          values={this.state.values}
          onChangeValues={this.onChangeValues}
          addParcel={this.addParcel}
          errors={this.state.errors}
          list={this.props.parcelsList}
        />
      </ALENLayout>
    )
  }
}

export default withTranslation()(AddParcelsView)
