const stockManagement = {
  title: "ULDs stock management",
  empty: {
    title: {
      1: "You have no ",
      2: "ULD ",
      3: "declared",
    },
    text:
      "Please create your first selection of ULD to start your load optimization !",
    buttonLabel: "Add your first ULD!",
  },
  ULDName: "ULD type",
  ULDRef: "ULD Reference",
  AvailableStock: "Number",
  placeholderName: "Select ULD in the list",
  placeholderRef: "Select ULD in the list",
  placeholderStock: "Stock",
  cancel: "Cancel",
  AddULD: "Add ULD",
  createNew: "Create a new ULD",
  errorStock: "Select correct stock value",
  errorValue: "Select correct ULD value",
  ref: "Ref",
  successMessage: "Success ! Stock has been updated !",
  update: "Update",
  maxWeight: "Max weight (kg)",
};

export default stockManagement;
