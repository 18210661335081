import Api from 'api';
import ApiConstants from '../ApiConstants';

export function getTiedownsList(token) {
  return Api(
    ApiConstants.GETLISTTIEDOWNS,
    null,
    'GET',
    token,
  )
}

export function saveTiedown(token, tiedown) {
  return Api(
    ApiConstants.SAVETIEDOWNNS,
    tiedown,
    'POST',
    token,
  )
}

export function getTiedownModel(token, aircraft_id) {
  return Api(
    ApiConstants.GETTIEDOWNMODEL+"/"+aircraft_id,
    null,
    'GET',
    token,
  )
}
