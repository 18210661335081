const addParcel = {
  "title" : "Add parcels",
  "titleButton" : "Insert in the list",
  "titleList" : "List of new parcels",
  "addedList" : {
    "headers" : {
      "1" : "AIRWAY BILL",
      "2" : "LENGTH (CM)",
      "3" : "WIDTH (CM)",
      "4" : "HEIGHT (CM)",
      "5" : "WEIGHT (KG)",
      "6" : "QUANTITY",
    }
  },
  "addingList" : {
    "errors" : {
      "1" : "Cannot be empty",
      "2" : "Cannot be empty",
      "3" : "Cannot be empty",
      "4" : "Cannot be empty",
      "5" : "Cannot be empty",
      "6" : "Cannot be empty",
    },
    "headersTop" : {
      "1" : "Airway Bill *",
      "2" : "Airway Bill Type",
      "3" : "Nature Of Good",
      "4" : "Use by owner operator",
      "5" : "Official use",
      "6" : "Nb of hawb",
    },
    "headersMid" : {
      "1" : "Quantity *",
      "2" : "Length (cm) *",
      "3" : "Width (cm) *",
      "4" : "Height (cm) *",
      "5" : "Weight (kg) *",
    },
    "headersBot" : {
      "1" : "Shipper name address",
      "2" : "Consignee name address",
      "3" : "Heavy",
      "4" : "No stackable",
      "5" : "Crushable",
    },
    "headersTopClean" : {
      "1" : "Airway Bill",
      "2" : "Airway Bill Type",
      "3" : "Nature Of Good",
      "4" : "Use by owner operator",
      "5" : "Official use",
      "6" : "Nb of hawb",
    },
    "headersMidClean" : {
      "1" : "Quantity",
      "2" : "Length",
      "3" : "Width",
      "4" : "Height",
      "5" : "Weight",
    },
    "headersBotClean" : {
      "1" : "Shipper name address",
      "2" : "Consignee name address",
      "3" : "Heavy",
      "4" : "No stackable",
      "5" : "Crushable",
    },
    "info" : {
      "1" : "The newly parcel created will be in status ",
      "2" : "WAITING",
    },
    "cancel" : "Cancel",
    "add" : "Add parcel",
  },
  "create" : "Create a new parcel",
  "success" : "Success ! Parcel list has been updated !",
  "successDelete" : "Success ! Parcel has been removed !",
  "delete" : {
    "message" : {
      "1" : "Parcel",
      "2" : "will be deleted, are sure ?",
    },
  },
}

export default addParcel
