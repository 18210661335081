import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ALENBLUE, ALENBG, ALENRED } from "utils/colors";
import { FONTBIG } from "utils/fontSizes";
import ALENLayout from "components/ALENLayout";
import ALENStepHeaders from "components/ALENStepHeaders";
import ALENULDStockTable from "components/ALENULDStockTable";
import ALENText from "components/ALENText";

class UldsListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uldList: this.props.uldsLoadList,

      valueSelected: "",
      stockSelected: 1,
      maxWeightSelected: 0,

      errorValue: false,
      errorStock: false,
      genericError: false,

      uldList: [],
      errorUldListLength: false,
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG;
    this.setState({ uldList: this.props.uldsLoadList });
  }

  onSelectValue = (value) => {
    this.setState({ valueSelected: value });
  };

  onSelectStock = (value) => {
    let newValue = value;
    if (value < 0) {
      newValue = 0;
    }
    this.setState({ stockSelected: newValue });
  };

  addULD = () => {
    let exist = false;
    let valid = true;
    const id = this.state.valueSelected;
    const stock = this.state.stockSelected;
    this.setState({ errorValue: false, errorStock: false });

    if (id === undefined || id === null || id === "") {
      this.setState({ errorValue: true });
      valid = false;
    }

    if (stock < 0 || stock === undefined || stock === null || stock === "") {
      this.setState({ errorStock: true });
      valid = false;
    }

    this.props.uldsBank.forEach((uld) => {
      if (uld.id === id) {
        exist = true;
        const newULD = {
          ...uld,
          uld_id: uld.id,
          stock: parseInt(this.state.stockSelected, 10),
          nbr: parseInt(this.state.stockSelected, 10),
          max_weight: parseInt(this.state.maxWeightSelected, 10),
        };

        if (exist && valid) {
          this.setState({
            valueSelected: "",
            stockSelected: 0,
          });

          let newUldsList = [...this.state.uldList, newULD];

          this.setState({ uldList: newUldsList });
          const updatedLoad = {
            load_uld: newUldsList,
          };

          this.props.loadUpdate(updatedLoad);
        }
      }
    });

    if (!exist && valid === true) {
      this.setState({ genericError: true });
    }
  };

  changeStock = (newStock, id) => {
    let newUldList = this.state.uldList;
    newUldList[newUldList.findIndex((uld) => uld.id === id)].stock = newStock;
    newUldList[newUldList.findIndex((uld) => uld.id === id)].nbr = newStock;
    this.setState({ uldList: newUldList });
    const updatedLoad = {
      load_uld: newUldList,
    };

    this.props.loadUpdate(updatedLoad);
  };

  deleteUldStock = (id) => {
    let newUldList = this.state.uldList;
    const indexToDelete = newUldList.findIndex((uld) => uld.id === id);
    newUldList.splice(indexToDelete, 1);
    this.setState({ uldList: newUldList });
    const updatedLoad = {
      load_uld: newUldList,
    };
    this.props.loadUpdate(updatedLoad);
  };

  onSelectMaxWeight = (value) => {
    let newValue = value;
    if (value < 0) {
      newValue = 0;
    }
    this.setState({ maxWeightSelected: newValue });
  };

  changeMaxWeight = (newMaxWeight, id) => {
    let newUldList = this.state.uldList;
    newUldList[
      newUldList.findIndex((uld) => uld.id === id)
    ].max_weight = parseInt(newMaxWeight);
    this.setState({ uldList: newUldList });

    const updatedLoad = {
      load_uld: newUldList,
    };

    this.props.loadUpdate(updatedLoad);
  };

  render() {
    let t = this.props.t;

    const ariane = [
      {
        label: t("loads.title"),
        link: "/loads",
      },
      {
        label: this.props.load !== null ? this.props.load.name : "",
        link: "current",
      },
    ];

    const steps = {
      selected: 0,
      menus: [
        {
          label: t("loads.header.step1"),
          link: "/ulds/" + (this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t("loads.header.step2"),
          link:
            "/loads/" + (this.props.load !== null ? this.props.load.id : ""),
        },
        {
          label: t("loads.header.step3"),
          link:
            "/loads/optimizedList/" +
            (this.props.load !== null ? this.props.load.id : ""),
        },
      ],
    };

    return (
      <>
        <ALENLayout
          title={this.props.load !== null ? this.props.load.name : ""}
          ariane={ariane}
        >
          <ALENStepHeaders steps={steps} />
          {this.props.load !== null && (
            <>
              <ALENULDStockTable
                uldList={this.state.uldList}
                uldsBank={this.props.uldsBank}
                valueSelected={this.state.valueSelected}
                onSelectValue={this.onSelectValue}
                stockSelected={this.state.stockSelected}
                onSelectStock={this.onSelectStock}
                addULD={this.addULD}
                errorStock={this.state.errorStock}
                errorValue={this.state.errorValue}
                deleteUldStock={this.deleteUldStock}
                updateUldStock={this.updateULD}
                isAddLoading={false}
                isUpdating={false}
                showUpdate={false}
                changeStock={this.changeStock}
                onSave={() => {
                  console.log("save");
                }}
                maxWeightSelected={this.state.maxWeightSelected}
                onSelectMaxWeight={this.onSelectMaxWeight}
                changeMaxWeight={this.changeMaxWeight}
              />
              {this.state.genericError && (
                <div style={{ textAlign: "center", margin: 32 }}>
                  <ALENText bold color={ALENRED} size={FONTBIG}>
                    {t("commons.genericError")}
                  </ALENText>
                </div>
              )}
            </>
          )}
        </ALENLayout>
      </>
    );
  }
}

export default withTranslation()(UldsListView);
