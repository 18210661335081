import logo from './logo.svg';
import './App.css';


function App() {
  return (
    <>
    <p>App</p>
    </>
  );
}

export default App;
