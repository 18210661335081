import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENRED} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTLITTLE} from 'utils/fontSizes'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ALENText from 'components/ALENText';

const styles = theme => ({
    select: {
        '&:before': {
            borderColor: ALENBORDERINPUT,
            borderWidth:0,

        },
        '&:after': {
            borderColor: ALENBORDERINPUT,
            borderWidth:0,
        },
    },
    icon: {
        fill: ALENBORDERINPUT,
    },
});

class ALENSelect extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const { classes } = this.props;

    return (
      <>
        {!this.props.noTop &&
        <div style={{marginBottom:8}}>
          <ALENText>{this.props.label}</ALENText>
        </div>}
        <Select
          className={classes.select}
          value={this.props.value}
          onChange={this.props.onChange}
          displayEmpty
          style={{
            backgroundColor:this.props.transparent ? 'transparent' : ALENWHITE,
            borderColor:ALENBORDERINPUT,
            borderWidth:1,
            borderStyle:'solid',
            borderRadius:4,
            minWidth:this.props.minWidth,
            height:this.props.height,
          }}
          SelectDisplayProps={{
            style: { fontFamily:'ALENFont', color: ALENBLACK, fontSize: FONTNORMAL, marginLeft:8, marginRight:8},
          }}
        >
          <MenuItem value={""}><ALENText>{this.props.placeholder}</ALENText></MenuItem>
          {this.props.datas.map((data) => {
            return(
              <MenuItem value={data.value}>{data.libelle}</MenuItem>
            )
          })}
        </Select>
        {this.props.error &&
          <div style={{marginLeft:8}}>
            <ALENText bold size={FONTLITTLE} color={ALENRED}>{this.props.errorMessage}</ALENText>
          </div>
        }
      </>
    );
  }
}

ALENSelect.propTypes = {
  value:PropTypes.number,
  onChange:PropTypes.func,
  data:PropTypes.array,
  label: PropTypes.string,
  minWidth:PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  height: PropTypes.number,
  noTop: PropTypes.bool,
  transparent: PropTypes.bool,
};

ALENSelect.defaultProps = {
  value:"",
  onChange:() => console.log('change'),
  datas: [],
  label: null,
  minWidth: 100,
  error: false,
  errorMessage: 'error',
  height:37,
  noTop:false,
  transparent: false,
};

export default withStyles(styles)(withTranslation()(ALENSelect))
