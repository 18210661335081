import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  ALENULTRABLACK,
  ALENBLACK,
  ALENBG,
  ALENRED,
  ALENGREY,
  TABLEBORDER,
  ALENDISABLEDDARK,
} from "utils/colors";
import { FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP } from "utils/fontSizes";
import ALENText from "components/ALENText";
import ALENInput from "components/ALENInput";
import ALENButton from "components/ALENButton";
import SvgAdd from "components/icons/Add";
import SvgEdit from "components/icons/Edit";
import SvgDelete from "components/icons/Delete";
import ALENSelect from "components/ALENSelect";
import ALENLoading from "components/ALENLoading";

function UldLine(props) {
  const [stock, setStock] = React.useState(props.uld.stock);
  const { t } = props;
  const uld = props.uld;
  return (
    <>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          minWidth: "100%",
        }}
      >
        <div style={{ width: 200 }}>
          <img
            src={uld.picture}
            style={{
              margin: 4,
              marginRight: 16,
              padding: 5,
              height: 90,
              width: "auto",
            }}
          />
        </div>
        <ALENText color={ALENULTRABLACK} bold>
          {uld.name}
          &nbsp;
        </ALENText>
        <ALENText color={ALENULTRABLACK}>{"- " + uld.description}</ALENText>
        <div style={{ width: 8 }} />
        <ALENText color={ALENDISABLEDDARK}>
          {t("stockManagement.ref") + " : " + uld.ref}
        </ALENText>
        <div style={{ display: "flex", flex: 1 }} />

        <div
          style={{
            flex: 1,
            marginLeft: 8,
            maxWidth: 150,
          }}
        >
          <ALENInput
            label={t("stockManagement.AvailableStock")}
            placeholder={t("stockManagement.placeholderStock")}
            type={"number"}
            value={uld.stock}
            onChange={(event) => {
              if (event.target.value >= 0) {
                props.changeStock(event.target.value, uld.id);
              }
            }}
            error={props.error}
            errorMessage={t("stockManagement.errorStock")}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: 32,
            maxWidth: 150,
            marginRight: 32,
          }}
        >
          <ALENInput
            label={t("stockManagement.maxWeight")}
            placeholder={t("stockManagement.maxWeight")}
            type={"number"}
            value={uld.max_weight}
            onChange={(event) => {
              if (event.target.value >= 0) {
                props.changeMaxWeight(event.target.value, uld.id);
              }
            }}
            error={props.error}
            errorMessage={t("stockManagement.errorStock")}
          />
        </div>
      </div>
    </>
  );
}

class ALENULDStockTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockValues: [],
      isAdding: true,
    };
  }

  renderNewLine = () => {
    const { t, i18n } = this.props;

    let values = [];

    this.props.uldsBank.forEach((uld) => {
      let newUld = {
        value: uld.id,
        libelle: uld.name + " - " + uld.ref,
      };

      values.push(newUld);
      /*const index = this.props.uldList.findIndex((_uld) =>  uld.id === _uld.id)
      if(index === -1) {
        values.push(newUld);
      }*/
    });

    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
            marginRight: 32,
          }}
        >
          <ALENSelect
            datas={values}
            value={this.props.valueSelected}
            minWidth={288}
            label={t("stockManagement.ULDName")}
            placeholder={t("stockManagement.placeholderName")}
            onChange={(event) => {
              this.props.onSelectValue(event.target.value);
            }}
            error={this.props.errorValue}
            errorMessage={t("stockManagement.errorValue")}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginRight: 32,
          }}
        >
          <ALENInput
            label={t("stockManagement.AvailableStock")}
            placeholder={t("stockManagement.placeholderStock")}
            type={"number"}
            value={this.props.stockSelected}
            onChange={(event) => {
              this.props.onSelectStock(event.target.value);
            }}
            error={false}
            errorMessage={t("stockManagement.errorStock")}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <ALENInput
            label={t("stockManagement.maxWeight")}
            placeholder={t("stockManagement.maxWeight")}
            type={"number"}
            value={this.props.maxWeightSelected}
            onChange={(event) => {
              this.props.onSelectMaxWeight(event.target.value);
            }}
            error={false}
            errorMessage={t("stockManagement.errorStock")}
          />
        </div>
        {this.props.isAddLoading ? (
          <ALENLoading />
        ) : (
          <>
            {/*<div
            style={{
              marginLeft:165,
              flexDirection:'column',
              display:'flex',
              justifyContent:'center',
            }}>
            <ALENButton
              medium
              variant
              label={t('stockManagement.cancel')}
              onPress={() => this.setState({isAdding: false})}
            />
          </div>*/}
            <div style={{ flex: 1 }} />
            <div
              style={{
                marginRight: 56,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ALENButton
                medium
                label={t("stockManagement.AddULD")}
                onPress={() => this.props.addULD()}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  render() {
    const { t, i18n } = this.props;

    return (
      <>
        <div
          style={{
            margin: 32,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              alignSelf: "center",
            }}
          >
            <ALENText bold color={ALENBLACK} size={FONTBIGSUP}>
              {this.props.uldList.length.toString() + " " + t("commons.ULD")}
            </ALENText>
          </div>
          {(this.state.isAdding || this.props.uldList.length === 0) && (
            <div
              style={{
                backgroundColor: ALENBG,
                padding: 32,
                width: "100%",
                alignSelf: "center",
                marginTop: 0,
                borderRadius: 8,
              }}
            >
              {this.renderNewLine()}
            </div>
          )}
          <div
            style={{
              alignSelf: "center",
              marginTop: 24,
              width: "100%",
            }}
          >
            {this.props.uldList.map((uld) => {
              return (
                <div
                  style={{
                    borderColor: TABLEBORDER,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderRadius: 8,
                    width: "100%",
                    marginBottom: 16,
                  }}
                >
                  <UldLine
                    isLoading={this.props.isUpdating === uld.id}
                    t={t}
                    uld={uld}
                    onDelete={this.props.deleteUldStock}
                    onEdit={this.props.updateUldStock}
                    showUpdate={this.props.showUpdate}
                    error={this.props.errorStock === uld.id}
                    changeStock={this.props.changeStock}
                    changeMaxWeight={this.props.changeMaxWeight}
                  />
                </div>
              );
            })}
          </div>
          {/*
          <div
            style={{
              width:1077,
              alignSelf:'center',
              marginTop:24,
            }}>
            {(!this.state.isAdding && this.props.uldList.length !== 0) &&
              <ALENButton
                big
                variant
                onPress={() => this.setState({isAdding : true})}
                label={t('stockManagement.createNew')}
                startIcon=<SvgAdd fill={ALENGREY} style={{width:22, height:22}}/>
              />
            }
          </div>*/}
        </div>
      </>
    );
  }
}

ALENULDStockTable.propTypes = {
  uldList: PropTypes.array,
  uldsBank: PropTypes.array,
  valueSelected: PropTypes.string,
  stockSelected: PropTypes.number,
  onSelectValue: PropTypes.func,
  onSelectStock: PropTypes.func,
  addULD: PropTypes.func,
  errorStock: PropTypes.bool,
  errorValue: PropTypes.bool,
  deleteUldStock: PropTypes.func,
  updateUldStock: PropTypes.func,
  showUpdate: PropTypes.bool,
};

ALENULDStockTable.defaultProps = {
  uldList: [],
  uldsBank: [],
  valueSelected: "",
  stockSelected: 0,
  onSelectValue: () => console.log("no handle for onSelectValue"),
  onSelectStock: () => console.log("no handle for onSelectStock"),
  addULD: () => console.log("no handle for addULD"),
  errorStock: false,
  errorValue: false,
  deleteUldStock: () => console.log("no handle for deleteUldStock"),
  updateUldStock: () => console.log("no handle for editUldStock"),
  showUpdate: false,
};

export default withTranslation()(ALENULDStockTable);
