import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {checkEmpty} from 'utils/checkForm'

import ALENLayout from 'components/ALENLayout';
import ALENAddParcelsTable from 'components/ALENAddParcelsTable';
import {ALENWHITE, ALENBG, ALENBLACK, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENSelect from 'components/ALENSelect';
import ALENULDStockTable from 'components/ALENULDStockTable';
import ALENButton from 'components/ALENButton';
import ALENDatePicker from 'components/ALENDatePicker';
import origami from 'assets/images/origami.png';

class CreateBalanceView extends Component {
  constructor(props) {
    super(props)

    this.state={
      loadId: '',
      aircraft: '',

      errorLoadId: false,
      errorAircraft: false,

      valueSelected: "",
      stockSelected: 0,
      maxWeightSelected: 0,

      genericError: false,
    }
  }

  createBalanceValidation = () => {
    let valid = true
    this.setState({
      errorLoadId: false,
      errorAircraft: false,
    })

    if(this.state.loadId.length === 0) {
      valid = false
      this.setState({errorLoadId : true})
    }

    if(this.state.aircraft === '') {
      valid = false
      this.setState({errorAircraft: true})
    }

    if(valid === true) {
      const newBalance = {
        loadId: this.state.loadId,
        aircraft: this.state.aircraft,
      }

      this.props.addBalance(newBalance)
    }
  }

  onSelectValue = (value) => {
    this.setState({valueSelected: value})
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG
  }

  render() {
    const t = this.props.t

    let values = []

    this.props.aircraftList.forEach(aircraft => {
      let newAircraft = {
        value: aircraft.id,
        libelle: aircraft.name
      };
      values.push(newAircraft);
    })

    const ariane = [
      {
        label: t('balance.title'),
        link: '/balance',
      },
      {
        label: t('createBalance.title'),
        link: 'current',
      }
    ]

    return(
      <ALENLayout
        title={t("createBalance.title")}
        ariane = {ariane}
      >
        <div
          style={{
            padding:32,
            paddingTop:48,
          }}>
          <div
            style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
            }}>
            <div
              style={{
                minWidth:1077,
                width:1077,
                display:'flex',
                flexDirection:'row',
              }}>
              <div
                style={{
                  paddingLeft:32,
                  flexDirection:'column',
                  display:'flex',
                }}>
                <ALENInput
                  label={t('loads.list.idLabel')}
                  placeholder={t('loads.list.IdPlaceholder')}
                  value={this.state.loadId}
                  onChange={(event) => {
                    this.setState({loadId: event.target.value})
                  }}
                  error={this.state.errorLoadId}
                  errorMessage={t('loads.list.errorID')}
                />
                <div style={{height:16}}/>
                <ALENSelect
                  datas={values}
                  minWidth={245}
                  value={this.state.aircraft}
                  label={t('loads.list.planeLabel')}
                  placeholder={t('loads.list.aircraftPlaceholder')}
                  onChange={(event) => {
                    this.setState({aircraft: event.target.value})
                  }}
                  error={this.state.errorAircraft}
                  errorMessage={t('loads.list.errorValue')}
                />
                <div style={{height:16}}/>

              </div>
            </div>
          </div>
          <>
            {this.state.genericError &&
            <div style={{textAlign:'center', margin:32}}>
              <ALENText bold color={ALENRED} size={FONTBIG}>{t('commons.genericError')}</ALENText>
            </div>
            }
          </>
          <div
            style={{
              flexDirection:'row',
              display:'flex',
              justifyContent:'center',
              marginTop:32,
            }}>
            <ALENButton
              medium
              label={t('createBalance.buttonLabel')}
              onPress={this.createBalanceValidation}
              />
          </div>
        </div>
      </ALENLayout>
    )
  }
}

export default withTranslation()(CreateBalanceView)
