const optimize = {
  "title" : "Optimize ULDs for ",
  "success" : "Opération succeed!",
  "description" : "Here's your ULDs list to optimize ",
  "back": "Back",
  "loadingRate" : "Loading rate : ",
  "uldRef" : "Ref : ",
  "downloadPdf" : "Download list in .pdf format",
  "headers" : {
    "0" : "ULD REFERENCES",
    "1" : "STATUS",
    "2" : "% CHARGING",
  },
  "startLoading": "Start loading",
  "continueLoading" : "Continue loading",
  "charging" : {
    "full" : "Full charged!",
    "charged" : "charged",
  },
  "Enter" : "Enter",
  "nextPalet" : "Next Palet",
  "previousPalet" : "Previous Palet",
  "loading" : "LOADING",
  "top" : "Top view",
  "front" : "Front view",
  "seeResult" : "See result",
  "seeLoading" : "See Loading",
}

export default optimize
