import * as React from "react";

function SvgBottom(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
      <path d="M12 14l-4-4h8l-4 4z" />
    </svg>
  );
}

export default SvgBottom;
