import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {ALENWHITE, ALENLINK, ALENGREEN, ALENBORDER,ALENGREENDARK, ALENBLACK, ALENBLUE, ALENDISABLEDDARK} from 'utils/colors';
import {FONTMEGABIG, FONTMINIBIG, FONTVERYBIG, FONTBIGSUP, FONTNORMALSUP, FONTGIGABIG} from 'utils/fontSizes';
import {isMobile} from 'react-device-detect';
import { connect } from 'react-redux';
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgLeft from 'components/icons/Left';
import SvgEdit from 'components/icons/Edit';
import ALENLoading from 'components/ALENLoading';
import alenLogo from 'assets/images/alen.png';

class ALENLayout extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  render()
  {
    let t = this.props.t

    return(
      <div
        style={{
          flexDirection:'column',
          display:'flex',
          flex:1}}>
        {this.props.title !== null &&
          <>
            <div
              style={{
                marginTop:8,
                marginLeft:37,
                flexDirection:'row',
                display:'flex',
                alignItems:'center'
              }}>
              <ALENText
                size={FONTGIGABIG}
                color={ALENBLACK}>
                {this.props.title}
              </ALENText>
              <div onClick={() => window.location.href = '/account'} style={{cursor:'pointer', position:'absolute', height:100, width:400, right:37, top:16, display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
                <div style={{flexDirection:'column', justifyContent:'center', display:'flex'}}>
                  <div style={{flexDirection: 'row', display:'flex', justifyContent:'flex-end'}}>
                    <ALENText
                      size={FONTBIGSUP}
                    >
                      {this.props.userReducer.company.name}
                    </ALENText>
                  </div>
                  <div style={{marginTop:-8, flexDirection: 'row', display:'flex', justifyContent:'flex-end'}}>
                    <ALENText
                      bold
                      size={FONTMINIBIG}
                      color={ALENGREENDARK}>
                      {`${this.props.userReducer.firstname ? this.props.userReducer.firstname : ''} ${this.props.userReducer.lastname ? this.props.userReducer.lastname : ''}`}
                    </ALENText>
                  </div>
                </div>
                <div
                  style={{marginLeft:20,height:70, width:70}}>
                {this.props.userReducer.company.avatar !== null ?
                  <div
                    style={{
                      marginTop:-5,
                      flexDirection:'row',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      borderRadius:'50%',
                      backgroundColor:'white',
                      boxShadow: '-2px 3px 9px #0000003b',
                      width:70,
                      height:70,
                    }}>
                    <img
                      src={this.props.userReducer.company.avatar}
                      style={{
                        width:70,
                        borderRadius:'50%',
                        height:'auto'}}/>
                  </div>
                  :
                  <div
                    style={{
                      marginTop:-5,
                      flexDirection:'row',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      borderRadius:'50%',
                      backgroundColor:'white',
                      width:70,
                      height:70,
                    }}>
                    <img
                      src={alenLogo}
                      style={{
                        width:70,
                        borderRadius:'50%',
                        boxShadow: '-2px 3px 9px #0000003b',
                        height:'auto'}}/>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div
              style={{
                flexDirection:'row',
                display:'flex',
                flex:1,
                justifyContent:'space-between'}}>
              <div
                style={{
                  marginTop:-20,
                  marginLeft:39,
                  flexDirection:'row',
                  display:'flex',
                }}>
                {this.props.ariane.map((ariane, index) => {
                  return(
                    <>
                      {index > 0 &&
                        <div
                          style={{
                            marginLeft:10,
                            marginRight:10,
                          }}>
                          <ALENText
                            verybold
                            size={FONTMINIBIG}
                            color={ALENGREENDARK}>
                            {">"}
                          </ALENText>
                        </div>
                      }
                      {ariane.link !== "current" ?
                        <div
                          onClick={() => window.location.href = ariane.link}
                          style={{
                            cursor:'pointer'
                          }}>
                          <ALENText
                            verybold
                            size={FONTMINIBIG}
                            color={ALENGREENDARK}>
                            {ariane.label}
                          </ALENText>
                        </div>
                        :
                        <ALENText

                          verybold
                          size={FONTMINIBIG}
                          style={{fontSize:40}}
                          color={ALENGREENDARK}>
                          {ariane.label}
                        </ALENText>
                      }
                    </>
                  )
                })}
                {this.props.isAsyncLoading &&
                <div
                  style={{marginLeft:20, marginTop:10}}>
                  <ALENLoading
                    little/>
                </div>
                }
              </div>
              {this.props.subMenus.length > 0 &&
                <div
                  style={{
                    marginRight:32,
                    marginTop:this.props.back ? -14 : 60,
                    display:'flex',
                    flexDirection:'row',
                  }}>
                  {this.props.subMenus.map((menu, index) => {
                    return(
                      <>
                        {index > 0 &&
                          <>
                            <div style={{width:24}}/>
                            <ALENText>
                              {"/"}
                            </ALENText>
                            <div style={{width:24}}/>
                          </>
                        }
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => window.location.href=menu.link}
                        >
                          <ALENText
                            bold={menu.selected}
                            color={menu.selected ? ALENBLACK : ALENDISABLEDDARK}
                            >
                            {menu.title}
                          </ALENText>
                        </div>
                      </>
                    )
                  })}
                </div>
              }
              {this.props.buttonLabel !== null &&
              <div
                style={{
                  marginRight:32,
                  marginTop:this.props.back ? -14 : 18,
                }}>
                <ALENButton
                  big
                  label={this.props.buttonLabel}
                  onPress={this.props.onPressButton}
                />
              </div>
              }
            </div>
          </>
        }
        <div
          style={{
            flex:1,
            maxWidth:'100%',
            margin:isMobile ? 0: 32,
            marginTop:isMobile ? 0:40,
            backgroundColor:ALENWHITE,
            borderRadius: 8,
            borderStyle:'solid',
            borderColor:ALENBORDER,
            boxSizing:'border-box',
            boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.2)',
            borderWidth: 1,
          }}>

          {this.props.children}
        </div>
      </div>
    )
  }
}

ALENLayout.propTypes = {
  title:PropTypes.string,
  buttonLabel: PropTypes.string,
  onPressButton: PropTypes.func,
  back: PropTypes.bool,
  subMenus: PropTypes.array,
  ariane: PropTypes.array,
};

ALENLayout.defaultProps = {
  title:null,
  buttonLabel: null,
  onPressButton: () => console.log('no handle for onPressButton method'),
  back: false,
  subMenus: [],
  ariane: [],
};

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
  }
}

export default connect(mapStateToProps)(withTranslation()(ALENLayout))
