import React, {Component} from 'react';
import ManageAccountView from './ManageAccountView'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ALENModalChangePassword from 'components/ALENModalChangePassword';

class ManageAccountContainer extends Component {
  constructor(props) {
    super(props)

    this.state={
      showChangePassword: false,
    }
  }

  changePassword = (newPassword) => {
    console.log('newPassword', newPassword)
  }

  componentDidMount() {
  }

  render()
  {
    return(
      <>
        {this.state.showChangePassword &&
          <ALENModalChangePassword
            onChange={this.changePassword}
            back={() => this.setState({showChangePassword: false})}
          />
        }
        <ManageAccountView
          user={this.props.userReducer}
          showChangePassword={() => this.setState({showChangePassword: true})}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLoad: (load) => {
      dispatch({type: "ADDLOAD", data: load})
    },
    updateLoads: (loadsList) => {
      dispatch({type: "UPDATELOADS", data: loadsList})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManageAccountContainer))
