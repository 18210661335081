import Api from 'api';
import ApiConstants from '../ApiConstants';

export function login(data) {
  return Api(
    ApiConstants.LOGIN,
    data,
    'POST',
    null,
  )
}
