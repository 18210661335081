const firstconnect = {
  "slogan" : {
    "1" : "Let's ",
    "2" : "digitize ",
    "3" : "and ",
    "4" : "optimize ",
    "5" : "the load of your ULDs",
  },
  "mainText" : "Euismod torquent eget lacus mus quis a vestibulum mi volutpat iaculis orci at cum enim a a pretium quis a. Cras iaculis euismod est sagittis ad cras parturient a vestibulum et eu scelerisque varius fringilla natoque diam nisl placerat rhoncus magna.",
  "buttonLabel" : "Start create your ULD Stock !",
}

export default firstconnect
