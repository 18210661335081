import React, {Component} from 'react';
import CreateTiedownView from './CreateTiedownView';
import { connect } from 'react-redux';
import { addParcel } from 'api/methods/parcel';
import { getAircraftList } from 'api/methods/load';
import { saveTiedown } from 'api/methods/tiedowns';

class CreateTiedownContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      aircraftList: []
    }
  }

  createTiedown = (tiedown) => {
    console.log('tiedown', tiedown)
    saveTiedown(this.props.userReducer.token, tiedown).then((response) => {
      if(response.status === 200 && response.ok) {
        this.props.addTiedown(response.data)
        window.location.href="/tiedowns/calculate/"+response.data.id
      }
    })

  }

  componentDidMount() {
    getAircraftList(this.props.userReducer.token).then((response) => {
      if(response.status === 200 && response.ok) {
        this.setState({
          aircraftList: response.data
        })
      }
    })
  }

  render() {
    return(
      <>
        <CreateTiedownView
          aircraftList={this.state.aircraftList}
          createTiedown={this.createTiedown}
        />
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addTiedown: (tiedown) => {
      dispatch({type: "ADDTIEDOWN", data: tiedown})
    }
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    loadsReducer: state.loadsReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTiedownContainer)
