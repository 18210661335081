import * as React from "react";

function SvgSearch(props) {
  return (
    <svg width={props.size} height={props.size} fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M17.783 16.391L22 20.607 20.607 22l-4.216-4.217a8.822 8.822 0 01-5.53 1.939C5.97 19.722 2 15.752 2 10.86S5.97 2 10.86 2c4.892 0 8.862 3.97 8.862 8.86a8.822 8.822 0 01-1.939 5.531zm-1.975-.73a6.87 6.87 0 001.945-4.8 6.89 6.89 0 00-6.892-6.892 6.89 6.89 0 00-6.892 6.892 6.89 6.89 0 006.892 6.892 6.87 6.87 0 004.8-1.945l.147-.147z"
        fill="#807F87"
      />
    </svg>
  );
}

export default SvgSearch;
