import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {ALENBLACK, ALENLINK} from "utils/colors";
import {FONTNORMAL} from 'utils/fontSizes'

class ALENText extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;

    let color = this.props.color
    let textDecoration = 'none'

    if(this.props.link) {
      color = ALENLINK
      textDecoration = 'underline'
    }

    if(this.props.underline) {
      textDecoration = 'underline'
    }

    if(this.props.lineThrough) {
      textDecoration= 'line-through'
    }

    return (
      <Typography
        style={{textAlign: this.props.center ? 'center' : 'left', textDecoration:textDecoration, lineHeight:this.props.verybold ? '49px' : 'auto', fontFamily:this.props.verybold ? 'ALENFontVeryBold' : this.props.bold ? 'ALENFontBold' : 'ALENFont' , color:color, fontSize:this.props.size}}
        >
        {this.props.children}
      </Typography>
    );
  }
}

ALENText.propTypes = {
  size:PropTypes.string,
  color:PropTypes.string,
  style:PropTypes.object,
  bold:PropTypes.bool,
  verybold: PropTypes.bool,
  link: PropTypes.bool,
  underline: PropTypes.bool,
  fontWeight:PropTypes.number,
  center: PropTypes.bool,
};

ALENText.defaultProps = {
  size:FONTNORMAL,
  color:ALENBLACK,
  style:{},
  bold:false,
  verybold: false,
  link: false,
  underline: false,
  center: false,
};

export default withTranslation()(ALENText)
