import React, {Component} from 'react';
import SvgBackgroundLogin from "components/icons/BackgroundLogin";
import { withTranslation } from 'react-i18next';
import {ALENBLUE, ALENBG, ALENRED} from 'utils/colors';
import {FONTBIG} from 'utils/fontSizes';
import ALENLayout from 'components/ALENLayout';
import ALENStockEmpty from 'components/ALENStockEmpty';
import ALENULDStockTable from 'components/ALENULDStockTable';
import ALENText from 'components/ALENText';
import ALENLoading from 'components/ALENLoading';

class StockManagementView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobile:false,

      firstAdd: true,

      valueSelected: "",
      stockSelected: 0,

      errorValue: false,
      errorStock: false,
      genericError: false,
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = ALENBG

    window.addEventListener("resize", this.resize);
    this.resize()
  }

  resize = () => {
    if( window.innerWidth <= 600) {
      this.setState({mobile: true})
    } else {
      this.setState({mobile:false})
    }
  }

  onSelectValue = (value) => {
    this.setState({valueSelected: value})
  }

  onSelectStock = (value) => {
    let newValue = value
    if(value <0) {
      newValue = 0
    }
    this.setState({stockSelected: newValue})
  }

  updateULD = (id, stock) => {
    let valid = true

    if(stock < 0 || stock === undefined || stock === null || stock === "") {
      this.setState({errorStock: true})
      valid = false
    }

    this.props.updateUldStock(id, stock)
  }

  addULD = () => {
    let exist = false
    let valid = true
    const id = this.state.valueSelected
    const stock = this.state.stockSelected
    this.setState({errorValue: false, errorStock: false})

    if(id === undefined || id === null || id === "") {
      this.setState({errorValue: true})
      valid = false
    }

    if(stock < 0 || stock === undefined || stock === null || stock === "") {
      this.setState({errorStock: true})
      valid = false
    }

    this.props.uldsBank.forEach((uld) => {
      if(uld.id === id) {
        exist = true
        const newULD = {
          uld_id: uld.id,
          nbr: this.state.stockSelected,
        }

        if(exist && valid) {
          this.setState({
            valueSelected: "",
            stockSelected:0
          })
          this.props.addUldStock(newULD)
        }
      }
    })

    if(!exist && valid === true) {
      this.setState({genericError: true})
    }
  }

  render()
  {
    let t = this.props.t

    return(
      <>
        <ALENLayout
          title={t('stockManagement.title')}
          isAsyncLoading={this.props.isAsyncLoading}
        >
          {this.props.isMainLoading ?
            <div
              style={{
                flexDirection:'row',
                width:'100%',
                height:600,
                flex:1,
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
              }}>
              <ALENLoading/>
            </div>
          :
          <>
            {(this.props.uldList.length > 0 || !this.state.firstAdd)?
              <ALENULDStockTable
                uldList={this.props.uldList}
                uldsBank={this.props.uldsBank}
                valueSelected={this.state.valueSelected}
                onSelectValue={this.onSelectValue}
                stockSelected={this.state.stockSelected}
                onSelectStock={this.onSelectStock}
                addULD={this.addULD}
                errorStock={this.state.errorStock}
                errorValue={this.state.errorValue}
                deleteUldStock={this.props.deleteUldStock}
                updateUldStock={this.updateULD}
                isAddLoading={this.props.isAddLoading}
                isUpdating={this.props.isUpdating}
              />
            :
              <ALENStockEmpty
                onPressNew={() => this.setState({firstAdd: false})}/>
            }
            {this.state.genericError &&
            <div style={{textAlign:'center', margin:32}}>
              <ALENText bold color={ALENRED} size={FONTBIG}>{t('commons.genericError')}</ALENText>
            </div>
            }
          </>
          }
        </ALENLayout>
      </>
    )
  }
}

export default withTranslation()(StockManagementView)
