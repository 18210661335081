import React from 'react';
import { withTranslation } from 'react-i18next';
import ALENText from 'components/ALENText';
import ALENButton from 'components/ALENButton';
import SvgOptimize from 'components/icons/Optimize';
import ALENFilter from './ALENFilter';
import ALENInput from 'components/ALENInput';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP} from 'utils/fontSizes'
import {ALENBLUE, ALENBORDERINPUT, ALENWHITE, ALENBLACK, ALENOPACITY2, ALENGREY, ALENGREEN, ALENDISABLEDDARK, ALENBORDER, STATUS} from "utils/colors";

function ALENParcelsListHeader(props) {
  const t = props.t
  const parcels = props.parcels
  const nbAll = parcels.length

  return(
    <>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center',
        }}>
        <div
          style={{
            width:'100%',
            padding:32,
            paddingLeft:48,
            paddingRight:48,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
          }}>
            <div>
              <ALENText
                size={FONTBIGSUP}
                color={ALENBLACK}
                bold>
                {props.nbParcels.toString() + " " + t('commons.parcel')+ ((props.nbParcels > 1) ? "s" : "")}
              </ALENText>
            </div>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
              }}>
              {process.env.REACT_APP_ENV === 'DEV' &&
              <div
                style={{
                  maxWidth:60
                }}>
                <ALENInput
                  label={''}
                  placeholder={""}
                  value={props.caseId}
                  onChange={(event) => props.changeCase(event.target.value)}
                />
              </div>}
              <div style={{width:100}}/>
              <ALENButton
                startIcon={props.iconAdd}
                label={t('parcelsList.header.add')}
                onPress={props.onPressAdd}
                medium
                variant/>
              <div style={{width:13}}/>
              <ALENButton
                startIcon={<SvgOptimize fill={ALENWHITE} style={{width:22, height:22}}/>}
                onPress={props.optimizeUld}
                label={t('parcelsList.header.optimize')}
                medium/>
            </div>
        </div>
      </div>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center',
        }}>
        {/*<div
          style={{
            flexDirection:'row',
            display:'flex',
            alignItems:'center',
            width:1077,
            borderColor:ALENBORDER,
            borderWidth:0,
            borderBottomWidth:1,
            borderStyle:'solid',
          }}>
          <div style={{width:9}}/>
          <ALENFilter
            count={parcels.length}
            onClick={() => props.onChangeFilter(0)}
            selected={props.selected===0}
            label={t('parcelsList.filter.all')}
            bg={STATUS.parcel[0]}
            textColor={ALENGREY}
          />
          <div style={{width:32}}/>
          <ALENFilter
            count={parcels.filter((parcel) => {return (parcel.status === (20).toString())}).length}
            onClick={() => props.onChangeFilter(4)}
            selected={props.selected===4}
            label={t('parcelsList.filter.loaded')}
            bg={STATUS.parcel[4]}
            textColor={ALENWHITE}
          />
          <div style={{width:32}}/>
          <ALENFilter
            count={parcels.filter((parcel) => {return (parcel.status === (15).toString())}).length}
            onClick={() => props.onChangeFilter(3)}
            selected={props.selected===3}
            label={t('parcelsList.filter.loading')}
            bg={STATUS.parcel[3]}
            textColor={ALENBLACK}
          />
          <div style={{width:32}}/>
          <ALENFilter
            count={parcels.filter((parcel) => {return (parcel.status === (10).toString())}).length}
            onClick={() => props.onChangeFilter(2)}
            selected={props.selected===2}
            label={t('parcelsList.filter.ready')}
            bg={STATUS.parcel[2]}
            textColor={ALENWHITE}
          />
          <div style={{width:32}}/>
          <ALENFilter
            count={parcels.filter((parcel) => {return (parcel.status === (99).toString())}).length}
            onClick={() => props.onChangeFilter(1)}
            selected={props.selected===1}
            label={t('parcelsList.filter.waiting')}
            bg={STATUS.parcel[1]}
            textColor={ALENWHITE}
          />
          <div style={{width:32}}/>
          <ALENFilter
            count={parcels.filter((parcel) => {return (parcel.status === (0).toString())}).length}
            onClick={() => props.onChangeFilter(5)}
            selected={props.selected===5}
            label={t('parcelsList.filter.notLoaded')}
            bg={STATUS.parcel[5]}
            textColor={ALENWHITE}
          />

        </div>*/}
      </div>
    </>
  )
}

export default withTranslation()(ALENParcelsListHeader)
