import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {ALENULTRABLACK, ALENWHITE, ALENBLACK, ALENBG, ALENRED, ALENGREY, TABLEBORDER, ALENDISABLEDDARK, STATUS} from "utils/colors";
import {FONTNORMAL, FONTBIG, FONTVERYBIG, FONTBIGSUP, FONTMINIBIG, FONTLITTLE2} from 'utils/fontSizes'
import ALENText from 'components/ALENText';
import ALENInput from 'components/ALENInput';
import ALENButton from 'components/ALENButton';
import SvgAdd from 'components/icons/Add';
import SvgEdit from 'components/icons/Edit';
import SvgDelete from 'components/icons/Delete';
import ALENSelect from 'components/ALENSelect';

function BalanceLine(props) {
  const { t } = props;
  let balance = props.balance;
  const aircraftLibelle = props.aircraftLibelle;

  return(
    <>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          alignItems:'center',
          height:91,
          paddingLeft:32,
          paddingRight:32,
        }}>
        <div
          style={{
            marginRight:16,
            minWidth:200,
            maxWidth:200,
          }}>
          <ALENText
            size={FONTMINIBIG}
            color={ALENULTRABLACK}
            bold>
            {balance.name}
          </ALENText>
        </div>
        <div
          style={{
            marginRight:16,
            minWidth:36,
            maxWidth:36,
          }}>
          <ALENText
            color={ALENULTRABLACK}
            >
            {aircraftLibelle}
          </ALENText>
        </div>

        <div style={{width:24}}/>
        <div
          style={{
            minWidth:70,
            maxWidth:70,
            flexDirection:'row',
            display:'flex',
            justifyContent:'flex-end'
          }}>
          {/*<ALENText
            size={FONTMINIBIG}
            color={ALENULTRABLACK}
            bold>
            {load.nbULDs}&nbsp;{t('commons.ULD') + (load.nbULDs > 0 ? "s" : "")}
          </ALENText>*/}
        </div>
        <div style={{flex:1}}/>
        <ALENButton
          medium
          label={t('loads.list.enterButton')}
          onPress={() => window.location.href='/balanceChart/'+balance.id}
        />
      </div>
    </>
  )
}

class ALENLoadsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockValues: [],
      isAdding: true,
      over: -1,
    }
  }

  render() {
    const { t, i18n } = this.props;

    console.log('this.props.balances', this.props.balances)

    return (
      <>
        <div
          style={{
            margin:32,
            marginTop:40,
            marginBottom:48,
            display:'flex',
            flexDirection:'column'
          }}>
          <div
            style={{
              alignSelf:'center',
              marginTop:24,
              width:'100%',
            }}>
          {this.props.balances.map((balance, index) => {
            let aircraftLibelle = ""
            this.props.aircraftList.forEach(aircraft => {
              if(aircraft.id === balance.aircraft_id) {
                aircraftLibelle = aircraft.libelle
              }
            })

            return(
              <div
                onMouseOver={() => this.setState({over: index})}
                onMouseLeave={() => this.setState({over: -1})}
                onClick={() => window.location.href='/balanceChart/'+balance.id}
                style={{
                  backgroundColor: this.state.over===index ? "rgba(241, 240, 247, 0.2)" : 'transparent',
                  boxShadow:this.state.over===index ? '0px 4px 16px rgba(18, 5, 80, 0.1)' : '',
                  borderColor:TABLEBORDER,
                  borderWidth:1,
                  borderStyle:'solid',
                  borderRadius:8,
                  width:'100%',
                  alignSelf:'center',
                  marginBottom:16,
                  cursor:'pointer'
                }}>
                <BalanceLine
                  t={t}
                  balance={balance}
                  aircraftLibelle={aircraftLibelle}
                />
              </div>
            )
          })}
          </div>
          {/*<div
            style={{
              width:1077,
              alignSelf:'center',
              marginTop:24,
            }}>
            {(!this.state.isAdding && this.props.loads.length !== 0) &&
              <ALENButton
                big
                variant
                onPress={() => this.setState({isAdding : true})}
                label={t('loads.list.createLabel')}
                startIcon=<SvgAdd fill={ALENGREY} style={{width:22, height:22}}/>
              />
            }
          </div>*/}
        </div>
      </>
    );
  }
}

ALENLoadsTable.propTypes = {
  loads: PropTypes.array,
  aircraftList: PropTypes.array,
  valueSelected: PropTypes.string,
  loadId: PropTypes.string,
  onChangeLoadId: PropTypes.func,
  onSelectAircraft: PropTypes.func,
  addLoad: PropTypes.func,
  errorLoadId: PropTypes.bool,
  errorAircraft: PropTypes.bool,
};

ALENLoadsTable.defaultProps = {
  loads: [],
  aircraftList: [],
  valueSelected: "",
  loadId: "",
  onChangeLoadId: () => console.log('no handle for onChangeLoadId'),
  onSelectAircraft: () => console.log('no handle for onSelectAircraft'),
  addLoad: () => console.log('no handle for addLoad'),
  errorLoadId: false,
  errorAircraft: false,
};

export default withTranslation()(ALENLoadsTable)
