import React, {useState} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ALENGREEN, ALENGREY, ALENBLUE, ALENGREENDARK} from 'utils/colors';
import ALENText from 'components/ALENText';
import SvgRight from 'components/icons/Aft';
import SvgLeft from 'components/icons/Bef';
import ALENInputBalance from 'components/ALENInputBalance';

function ALENBalanceChartMiniBox(props) {
  const [overTop, setOverTop] = useState(false);
  const [overBot, setOverBot] = useState(false);

  const errorTop = props.datas?.right?.WT > props.maxValue;
  const errorBot = props.datas?.left?.WT > props.maxValue;

  return(
    <div style={{flexDirection:'column', display:'flex', marginTop:32}}>
      <div
        style={{
          height:148,
          width:60,
          borderRadius:3,
          borderColor: ALENGREENDARK,
          borderStyle:'solid',
          borderWidth:2,
          borderLeftWidth: (props.index%2 === 0 && props.index !== 0) ? 0 : 2,
          marginLeft:props.index%2 !== 0 ? 4 : 0,
          backgroundColor:'white',
          boxShadow: '0px 16px 32px rgba(194, 193, 199, 0.4)',
        }}>
        <div
          onDragStart={props.onDragStartTop}
          onDragOver={() => {
            setOverTop(true)
            props.onMouseOverTop()
          }}
          onDragLeave={() => setOverTop(false)}
          onDragEnd={props.onDragEndTop}
          draggable="true"
          onMouseOver={() => {
            props.onMouseOverTop()
            setOverTop(true)
          }}
          onMouseLeave={() => setOverTop(false)}
          style={{
            borderRadius:3,
            borderColor: overTop ? ALENBLUE : 'transparent',
            borderStyle:'solid',
            borderWidth:overTop ? 2 : 0,
            backgroundColor:errorTop ? 'rgba(214, 13, 13, 0.2)' : 'white'
          }}>
          <div
            style={{
              height:26,
              width:58,
              borderColor:ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:0,
              borderBottomWidth:2,
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              alignItems:'center',
              borderBottomWidth:1,
          }}>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                flex:1,
              }}>
              <ALENText color={ALENBLUE} size={12}>
                {props.uld.id}
              </ALENText>
            </div>
          </div>
          <div
            style={{
              height:24,
              width:58,
              borderColor:ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:0,
              borderBottomWidth:2,
          }}>
          <ALENInputBalance
            value={props.datas?.right?.dest}
            placeholder={'Dest'}
            type={'text'}
            height={22}
            onChange={(val) => {
              props.onChangeValueBox(`OPT${props.uld.id.substr(0,2)}`, 'dest', 'right', val.target.value)
            }}
          />
          </div>
          <div
            style={{
              height:24,
              width:58,
              borderColor:ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:0,
              borderBottomWidth:2,
          }}>
          <ALENInputBalance
            value={props.datas?.right?.WT}
            placeholder={'WT'}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeValueBox(`OPT${props.uld.id.substr(0,2)}`, 'WT', 'right', val.target.value)
            }}
          />
          </div>
        </div>
        <div
          onDragStart={props.onDragStartBot}
          onDragOver={() => {
            setOverBot(true)
            props.onMouseOverBot()
          }}
          onDragLeave={() => setOverBot(false)}
          onDragEnd={props.onDragEndBot}
          draggable="true"
          onMouseOver={() => {
            props.onMouseOverBot()
            setOverBot(true)
          }}
          onMouseLeave={() => setOverBot(false)}
          style={{
            borderRadius:3,
            borderColor: overBot ? ALENBLUE : 'transparent',
            borderStyle:'solid',
            borderWidth:overBot ? 2 : 0,
            backgroundColor:errorBot ? 'rgba(214, 13, 13, 0.2)' : 'white'
          }}>
          <div
            style={{
              height:26,
              width:58,
              borderColor: overBot ? ALENBLUE : ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:0,
              borderBottomWidth:2,
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              alignItems:'center',
              borderBottomWidth:1,
          }}>
            <div
              style={{
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                flex:1,
              }}>
              <ALENText color={ALENBLUE} size={12}>
                {props.uld.id2}
              </ALENText>
            </div>
          </div>
          <div
            style={{
              height:24,
              width:58,
              borderColor:ALENGREENDARK,
              borderStyle:'solid',
              borderWidth:0,
              borderBottomWidth:2,
          }}>
            <ALENInputBalance
              value={props.datas?.left?.dest}
              placeholder={'Dest'}
              type={'text'}
              height={22}
              onChange={(val) => {
                props.onChangeValueBox(`OPT${props.uld.id.substr(0,2)}`, 'dest', 'left', val.target.value)
              }}
            />
          </div>
          <ALENInputBalance
            value={props.datas?.left?.WT}
            placeholder={'WT'}
            type={'number'}
            height={22}
            onChange={(val) => {
              props.onChangeValueBox(`OPT${props.uld.id.substr(0,2)}`, 'WT', 'left', val.target.value)
            }}
          />
        </div>
      </div>
      <div
        style={{
          flexDirection:'row',
          display:'flex',
          justifyContent:'center'
        }}>
        <ALENText color={ALENBLUE} size={12}>
          {props.maxValue}
        </ALENText>
      </div>
    </div>
  )
}

export default ALENBalanceChartMiniBox
